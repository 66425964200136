import _ from "lodash";
import React, { createRef } from "react";
import { Image, Platform, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BaseScene } from "../Core/BaseScene";
import { DraggableCollectionBook, DraggableCollectionBookData } from "./DraggableCollectionBook";
import { DraggableCollectionBookmark, DraggableCollectionBookmarkData } from "./DraggableCollectionBookmark";
import { DraggableCollectionBookshelf, DraggableCollectionBookshelfData } from "./DraggableCollectionBookshelf";
import { DraggableCollectionGroup, DraggableCollectionGroupData } from "./DraggableCollectionGroup";
import { DraggableCollectionItem } from "./DraggableCollectionItem";
import { DraggingData, DraggingItem } from "./DraggingItem";
import { addDroppableitem, DraggableData, DroppableItem, Inset, OverlapState, Position, Rect, removeDroppableitem } from "./DragHandler";

export interface Props {
  parentId: string;
  data: DraggableData[];
}

export class DraggableCollection extends BaseComponent<Props> implements DroppableItem {
  @state scrollEnabled = true;
  @state data: DraggableData[];
  @state isInTouch = false;

  @sharedState currentScene!: BaseScene;
  @sharedState draggingData?: DraggingData;
  @sharedState({ notify: false }) isFooterBottomSheetOpen?: boolean;

  @sharedState bookshelfScale = 1.0;

  draggingRef = createRef<DraggingItem>();
  view = createRef<View>();
  scrollView = createRef<ScrollView>();

  draggingItemX = 0;
  draggingitemY = 0;

  x = 0;
  y = 0;
  width = 0;
  height = 0;
  @state contentWidth = 0;
  @state contentHeight = 0;
  scrollX = 0;
  scrollY = 0;

  constructor(props: Props) {
    super(props);
    this.data = _.clone(props.data);
  }
  get droppableRect(): Rect {
    return { x: this.x, y: this.y, width: this.width, height: this.height };
  }
  order = 0;
  get enable(): boolean {
    return !this.isFooterBottomSheetOpen && !!this.context?.scene && this.context?.scene === this.currentScene;
  }

  onDragStart(): void {
    this.scrollEnabled = false;
  }
  onDragEnd(): void {
    this.scrollEnabled = true;
    this.isInTouch = false;
  }
  enter(_: Position): void {
    //
    this.isInTouch = true;
  }
  get margin(): Inset {
    return { left: 1 / 8, right: 1 / 8, top: 1 / 8, bottom: 1 / 8 };
  }
  overlapState?: OverlapState;
  move(_pos: Position, state: OverlapState): void {
    if (this.overlapState && this.scrollView.current) {
      if (state.isBottom && state.timestamp > state.enterBottomTimestamp + 500) {
        state.enterBottomTimestamp = state.timestamp;
        this.scrollView.current.scrollTo({ x: 0, y: this.scrollY + 200, animated: true });
      }
      if (state.isTop && state.timestamp > state.enterTopTimestamp + 500) {
        state.enterTopTimestamp = state.timestamp;
        this.scrollView.current.scrollTo({ x: 0, y: this.scrollY - 200, animated: true });
      }
    }
    //
  }
  leave(): void {
    this.isInTouch = false;
    //
  }

  onTouchStart(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }
  scrollDeltaYList: { x: number; y: number }[] = [];
  onScrollStart(): void {
    this.scrollDeltaYList = [];
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }
  onScroll({ x, y }: { x: number; y: number }): void {
    // this.scrollX = this.scrollX + x;
    // TODO: スクロール実際のあたいとずれる, そもそもscrollTo使うべきではない
    if (x === 0 && y === 0) {
      return;
    }
    this.scrollY = this.scrollY + y;
    this.scrollView.current?.scrollTo({ x: this.scrollX, y: this.scrollY, animated: false });
    this.scrollDeltaYList.push({ x, y });
  }
  onScrollEnd({ x, y }: { x: number; y: number }): void {
    // this.scrollY = this.scrollY + y * 10;
    // this.scrollY = this.scrollY + y * 10;
    // this.scrollView.current?.scrollTo({ x: this.scrollX, y: this.scrollY, animated: true });
    this.scrollDeltaYList.push({ x, y });
    const array = this.scrollDeltaYList.slice(-5);
    const avg = array.reduce((acc, cur) => acc + cur.y, 0) / array.length;

    this.scrollDeceleration(avg);
  }
  intervalId: any;
  scrollDeceleration(y: number): void {
    let delta = y;
    delta = Math.min(Math.max(delta, -15), 15);

    this.intervalId = setInterval(() => {
      this.scrollY = this.scrollY + delta;
      this.scrollY = Math.min(Math.max(this.scrollY, 0), this.contentHeight - this.height);
      this.scrollView.current?.scrollTo({ x: this.scrollX, y: this.scrollY, animated: false });

      if (Math.abs(delta) <= 1) {
        delta = 0;
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      } else {
        delta += delta > 0 ? -0.1 : 0.1;
      }
    }, 16);
  }

  drop(draggingData: DraggingData): void {
    if (this.props.parentId && draggingData.data.some((v) => v.typeName === "bookshelf")) {
      this.data = _.clone(this.props.data);
      return;
    }
    if (!this.props.parentId && draggingData.data.some((v) => v.typeName !== "bookshelf")) {
      this.data = _.clone(this.props.data);
      return;
    }
    if (draggingData.data.length <= 0) {
      return;
    }
    const targetId = draggingData.data[0].id;
    const data = this.data.filter((data) => !draggingData.data.some((v) => v.id !== targetId && v.id === data.id));
    const targetIndex = data.findIndex((v) => v.id === targetId);
    let leftTargetId: string | undefined = undefined;
    let rightTargetId: string | undefined = undefined;
    if (0 <= targetIndex - 1 && targetIndex - 1 < data.length) {
      leftTargetId = data[targetIndex - 1].id;
    }
    if (0 <= targetIndex + 1 && targetIndex + 1 < data.length) {
      rightTargetId = data[targetIndex + 1].id;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Bookshelf.moveItem({
      ids: draggingData.data.map((v) => v.id),
      parent: this.props.parentId,
      left: leftTargetId,
      right: rightTargetId,
    });
  }

  didPropChange(): void {
    this.data = _.clone(this.props.data);
  }

  onOverlap(state: OverlapState, target: DraggableData, dragging: DraggableData[]): void {
    if (dragging.length <= 0) {
      return;
    }
    if (state.isLeft || state.isTop) {
      this.moveLeft(target, dragging);
    }
    if (state.isRight || state.isBottom) {
      this.moveRight(target, dragging);
    }
  }

  moveLeft(target: DraggableData, dragging: DraggableData[]): void {
    if (dragging.some((v) => v.id === target.id)) {
      return;
    }
    for (const data of dragging) {
      const draggingIndex = this.data.findIndex((v) => v.id === data.id);
      if (draggingIndex >= 0) {
        this.data.splice(draggingIndex, 1);
      }
    }
    const targetId = target.id;
    const targetIndex = this.data.findIndex((v) => v.id === targetId);
    this.data.splice(targetIndex, 0, ...dragging);
    this.requestUpdate();
  }
  moveRight(target: DraggableData, dragging: DraggableData[]): void {
    if (dragging.some((v) => v.id === target.id)) {
      return;
    }
    for (const data of dragging) {
      const draggingIndex = this.data.findIndex((v) => v.id === data.id);
      if (draggingIndex >= 0) {
        this.data.splice(draggingIndex, 1);
      }
    }
    const targetId = target.id;
    const targetIndex = this.data.findIndex((v) => v.id === targetId);
    this.data.splice(targetIndex + 1, 0, ...dragging);
    this.requestUpdate();
  }

  transitionEnd = (): void => {
    this.view.current?.measure((_x, _y, _width, _height, pagex, pagey) => {
      this.x = pagex + this.scrollX;
      this.y = pagey + this.scrollY;
    });
  };

  componentDidMount(): void {
    super.componentDidMount();
    BaseScene.addListenerTransitionEnd(this.transitionEnd);
    addDroppableitem(this);
  }
  componentWillUnmount(): void {
    removeDroppableitem(this);
    BaseScene.addListenerTransitionEnd(this.transitionEnd);
    super.componentWillUnmount();
  }

  setBackgroundImage(): JSX.Element[] {
    const width = 200 * this.bookshelfScale - 1;
    const height = 346 * this.bookshelfScale - 1;

    const rowLength = Math.ceil(this.contentWidth / width);
    const columnLength = Math.ceil(this.contentHeight / height);

    const imageArray: JSX.Element[] = [];

    for (let column = 0; column < columnLength; column++) {
      for (let row = 0; row < rowLength; row++) {
        imageArray.push(
          <Image
            key={`${column}-${row}`}
            style={{ position: "absolute", width: width + 1, height: height + 1, left: row * width, top: column * height }}
            resizeMode={"contain"}
            source={require("@assets/images/bg_bookshelf.png")}
          ></Image>
        );
      }
    }

    return imageArray;
  }

  touches: { [key: string]: boolean } = {};
  renderComponent(): JSX.Element {
    this.view.current?.measure((_x, _y, _width, _height, pagex, pagey) => {
      this.x = pagex + this.scrollX;
      this.y = pagey + this.scrollY;
    });
    return (
      <ScrollView
        ref={this.scrollView}
        onLayout={(event) => {
          this.width = event.nativeEvent.layout.width;
          this.height = event.nativeEvent.layout.height;
        }}
        onScroll={(event) => {
          if (this.scrollEnabled) {
            this.scrollX = event.nativeEvent.contentOffset.x;
            this.scrollY = event.nativeEvent.contentOffset.y;
          }
          this.view.current?.measure((_x, _y, _width, _height, pagex, pagey) => {
            this.x = pagex + this.scrollX;
            this.y = pagey + this.scrollY;
          });
        }}
        scrollEventThrottle={16}
        scrollEnabled={this.scrollEnabled}
        nestedScrollEnabled={this.scrollEnabled}
        horizontal={false}
        style={{ width: "100%", height: "100%", backgroundColor: this.isInTouch ? "gray" : undefined }}
      >
        <View
          ref={this.view}
          onLayout={(_) => {
            this.view.current?.measure((_x, _y, _width, _height, pagex, pagey) => {
              this.x = pagex + this.scrollX;
              this.y = pagey + this.scrollY;
              this.contentWidth = _width;
              this.contentHeight = _height;
            });
          }}
          pointerEvents={"none"}
          style={{
            minHeight: Platform.OS === "web" ? "100%" : "100%",
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <View style={{ position: "absolute", width: "100%", height: "100%", flexDirection: "row" }}>
            <View style={{ position: "absolute", width: "100%", height: "100%", flexDirection: "row" }}>{this.props.parentId ? this.setBackgroundImage() : undefined}</View>
          </View>
          <View
            style={{
              paddingVertical: 30 * this.bookshelfScale,
              minHeight: "100%",
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {this.data.map((data) => {
              if (data.typeName === "book") {
                const book = data as DraggableCollectionBookData;
                return <DraggableCollectionBook key={data.id} data={book} parent={this}></DraggableCollectionBook>;
              } else if (data.typeName === "bookmark") {
                const bookshelf = data as DraggableCollectionBookmarkData;
                return <DraggableCollectionBookmark key={data.id} data={bookshelf} parent={this}></DraggableCollectionBookmark>;
              } else if (data.typeName === "group") {
                const group = data as DraggableCollectionGroupData;
                return <DraggableCollectionGroup key={data.id} data={group} parent={this}></DraggableCollectionGroup>;
              } else if (data.typeName === "bookshelf") {
                const bookshelf = data as DraggableCollectionBookshelfData;
                return <DraggableCollectionBookshelf style={{ width: "100%" }} key={data.id} data={bookshelf} parent={this}></DraggableCollectionBookshelf>;
              } else {
                return <DraggableCollectionItem key={data.id} data={data} parent={this}></DraggableCollectionItem>;
              }
            })}

            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
            <View style={{ height: 0, width: 90 * this.bookshelfScale, marginHorizontal: 4 * this.bookshelfScale }}></View>
          </View>
        </View>
      </ScrollView>
    );
  }
}
