import { FirebaseFirestore } from "@firebase/firestore-types";
import { CollectionReference, DocumentData, DocumentReference, firestore, Query, QueryDocumentSnapshot, QuerySnapshot, Transaction, TransactionWriteBatch } from "./firestore";
import { FirestoreData, FirestoreDocument, FirestoreKey } from "./firestore-document";
import { Condition, FirestoreQuery } from "./firestore-query";
import { FirestoreUtil } from "./firestore-util";

export abstract class FirestoreCollectionBase {}
export class FirestoreCollection<
  Key extends FirestoreKey,
  Data extends FirestoreData,
  Document extends FirestoreDocument<Key, Data> = FirestoreDocument<Key, Data>
> extends FirestoreCollectionBase {
  static firestore(): FirebaseFirestore {
    return firestore;
  }

  static ref(_key: unknown): CollectionReference | undefined {
    return;
  }

  public get static(): typeof FirestoreCollection {
    return this.constructor as typeof FirestoreCollection;
  }

  public get key(): Readonly<Key> | undefined {
    return this._key;
  }

  public set key(key: Readonly<Key> | undefined) {
    this._key = key;
    if (this._key !== undefined) {
      this.reference = (this.constructor as typeof FirestoreCollection).ref(this._key) as CollectionReference<Data>;
      this.condition = this._condition;
    } else {
      delete this.reference;
      delete this.query;
    }
  }

  public get condition(): Readonly<Condition> | undefined {
    return this._condition;
  }

  public set condition(condition: Readonly<Condition> | undefined) {
    this._condition = condition;
    if (this._condition !== undefined) {
      this.query = FirestoreQuery.buildQuery(this.reference, this._condition) as Query<Data>;
    } else {
      this.query = this.reference;
    }
  }

  public get documents(): { [id: string]: Document } {
    return this._documents;
  }

  public toArray(): Document[] {
    const array: Document[] = [];
    for (const key in this.documents) {
      const document = this.documents[key];
      array.push(document);
    }
    return array;
  }

  protected _ctor: { new (key?: Key, data?: Data | null, exist?: boolean): Document; defaultData: FirestoreData };
  protected _key?: Key;
  protected _condition?: Condition;
  protected _documents: { [id: string]: Document } = {};
  protected _cancelSnapshot?: () => void;

  protected reference?: CollectionReference<Data>;
  protected query?: Query<Data>;

  constructor(ctor: { new (): Document; defaultData: FirestoreData }, key?: Key, condition?: Condition) {
    super();
    this._ctor = ctor;
    this.key = key;
    this.condition = condition;
  }

  public _initialize(reference: CollectionReference<Data>, query?: Query<Data>): void {
    this.reference = reference;
    this.query = query ?? reference;
  }

  protected _apply(docs: QueryDocumentSnapshot<Data>[]): void {
    for (const doc of docs) {
      const document = doc.id in this._documents ? this._documents[doc.id] : new this._ctor();
      document._initialize(doc.ref, doc.data() as Data, true);
      this._documents[doc.id] = document;
    }
  }

  protected _applyDocChanges(docChanges: { doc: { id: string; ref: DocumentReference<DocumentData>; data(): Data }; type: string }[]): void {
    for (const docChange of docChanges) {
      const doc = docChange.doc;
      if (docChange.type === "added" || docChange.type === "modified") {
        const document = doc.id in this._documents ? this._documents[doc.id] : new this._ctor();
        document._initialize(doc.ref, doc.data(), true);
        this._documents[doc.id] = document;
      } else if (docChange.type === "removed") {
        delete this._documents[doc.id];
      }
    }
  }

  public async load(): Promise<void> {
    if (!this.query) {
      return;
    }

    const snapshot = await this.query.get();
    if (!snapshot.docs) {
      return;
    }

    this._documents = {};
    this._apply(snapshot.docs);
  }

  public async save(transaction?: TransactionWriteBatch): Promise<void> {
    const promises: Promise<unknown>[] = [];
    if (transaction) {
      for (const id in this._documents) {
        const document = this._documents[id];
        promises.push(document.save(false, transaction));
      }
    } else {
      for (const id in this._documents) {
        const document = this._documents[id];
        promises.push(document.save());
      }
    }
    await Promise.all(promises);
  }

  public first(): Document | undefined {
    for (const key in this.documents) {
      return this.documents[key];
    }
    return;
  }

  public get(id: string): Document | undefined {
    if (this.reference && id in this.documents) {
      return this.documents[id];
    }
    return undefined;
  }

  public async set(id: string, data: Data, transaction?: TransactionWriteBatch): Promise<Document | void> {
    if (!this.reference) {
      return Promise.resolve();
    }

    const reference = this.reference.doc(id);
    const document = new this._ctor();
    document._initialize(reference, data, false);
    await document.save(true, transaction);

    this._documents[reference.id] = document;
    return document;
  }

  public async add(data?: Data, transaction?: TransactionWriteBatch): Promise<Document | void> {
    if (!this.reference) {
      return Promise.resolve();
    }
    if (!data) {
      data = this._ctor.defaultData as Data;
    }

    if (!transaction) {
      const doc = await this.reference.add(data);
      const document = new this._ctor();
      document._initialize(this.reference.doc(doc.id), data, true);
      this._documents[doc.id] = document;
      return document;
    }

    return this.set(FirestoreUtil.newId(), data, transaction);
  }

  public async delete(id: string, transaction?: TransactionWriteBatch): Promise<Transaction | void> {
    if (!this.reference) {
      return;
    }

    const reference = this.reference.doc(id);

    let result: Transaction | void;
    if (transaction) {
      result = (transaction as Transaction).delete(reference);
    } else {
      result = await reference.delete();
    }

    if (id in this._documents) {
      delete this._documents[id];
    }

    return result;
  }

  public onSnapshot(onSnapshot: (snapshot: QuerySnapshot<Data>) => void): void {
    if (!this.query) {
      return;
    }
    this.cancelSnapshot();

    this.query.onSnapshot((snapshot: QuerySnapshot<Data>) => {
      this._applyDocChanges(snapshot.docChanges());
      onSnapshot(snapshot);
    });
  }

  public cancelSnapshot(): void {
    if (this._cancelSnapshot) {
      this._cancelSnapshot();
      delete this._cancelSnapshot;
    }
  }
}
