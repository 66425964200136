import React from "react";
import { Platform, View } from "react-native";
import { MaterialTabBar, MaterialTabItem, Tabs } from "react-native-collapsible-tab-view";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import { BaseComponent } from "@core/BaseComponent";
import { themeColor } from "@core/Styles";
import { CollapsibleScrollView } from "../Component/CollapsibleScrollView";
import { state } from "./State";

export class CollapsableTab {
  name: string;
  element: JSX.Element;

  constructor(name: string, element: JSX.Element) {
    this.name = name;
    this.element = element;
  }
}

export class CollapsableScrollTab {
  name: string;
  element: JSX.Element;
  bounces: boolean;

  constructor(name: string, element: JSX.Element, bounces = true) {
    this.name = name;
    this.element = element;
    this.bounces = bounces;
  }
}

// export class CollapsableWebTab {
//   name: string;
//   url: string;

//   constructor(name: string, url: string) {
//     this.name = name;
//     this.url = url;
//   }
// }

export class CollapsableListTab {
  name: string;
  items: any[];
  renderItem: (item: any) => JSX.Element;
  itemHeight: number;
  keyExtractor?: (item: any, index: number) => string;
  bounces: boolean;

  constructor(name: string, items: unknown[], renderItem: (item: any) => JSX.Element, itemHeight: number, keyExtractor?: (item: any, index: number) => string, bounces = true) {
    this.name = name;
    this.items = items;
    this.renderItem = renderItem;
    this.itemHeight = itemHeight;
    this.keyExtractor = keyExtractor;
    this.bounces = bounces;
  }
}

export interface Props {
  headerContents: JSX.Element;
  expandedHeaderHeight?: number;
  shrinkedHeaderHeight?: number;
  children: (CollapsableTab | CollapsableScrollTab | CollapsableListTab | CollapsableWebTab)[];
  style?: {};
  containerRef?: React.RefObject<any>;
  onIndexChange?: (index: number) => void;
  canSwipe?: boolean;
}

export const tabBar = (props) => (
  <MaterialTabBar
    {...props}
    TabItemComponent={(props) => <MaterialTabItem {...props} inactiveOpacity={1} pressColor={themeColor.mainTranslucent} />}
    indicatorStyle={{ backgroundColor: themeColor.main }}
    tabStyle={{ backgroundColor: themeColor.itemBG }}
    activeColor={themeColor.main}
    inactiveColor={themeColor.subText}
    labelStyle={{ fontWeight: "bold" }}
  />
);

export class CollapsableTabView extends BaseComponent<Props> {
  // @typesなかったんや許してくれ
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  twitter: any;

  headerRef = React.createRef<HTMLDivElement>();

  @state scrollViewElement?: HTMLDivElement | null;
  @state headerWidth = 0;
  @state headerHeight = 0;

  expandedHeaderHeight?: number;
  shrinkedHeaderHeight?: number;

  constructor(props: Props) {
    super(props);

    this.expandedHeaderHeight = this.props.expandedHeaderHeight ?? 0;
    this.shrinkedHeaderHeight = this.props.shrinkedHeaderHeight ?? this.expandedHeaderHeight ?? 0;
  }

  protected async initialize(): Promise<void> {
    await super.initialize();
    if (Platform.OS === "web") {
      this.twitter = await require("react-twitter-tabs");
    }
  }

  componentDidUpdate(): void {
    super.componentDidUpdate();
    const getBoundingClientRect = this.headerRef.current?.getBoundingClientRect();
    this.headerWidth = getBoundingClientRect?.width ?? 0;
    this.headerHeight = getBoundingClientRect?.height ?? 0;
  }

  header = (): JSX.Element => {
    if (Platform.OS === "web") {
      return <div ref={this.headerRef}>{this.props.headerContents}</div>;
    }
    return <View pointerEvents="box-none">{this.props.headerContents}</View>;
  };

  renderComponent(): JSX.Element {
    if (Platform.OS === "web") {
      return (
        <div ref={(el) => (this.scrollViewElement = el)} style={{ overflow: "scroll", width: "100%", height: "100%" }}>
          {this.header()}
          <style>{".tab-list{ z-index: 10; box-shadow: " + themeColor.colorShadow + "30 0px 5px 5px; border-bottom: 0px;}"}</style>
          <style>{".tab-list-item{ flex-grow:1; color: " + themeColor.subText + "}"}</style>
          <this.twitter.Tabs
            activeColor={themeColor.main}
            textColor={themeColor.main}
            hoveredColor={themeColor.mainTranslucent}
            tabBackgroundColor={themeColor.itemBG}
            arrowColor={themeColor.main}
            arrowBackGroundColor={themeColor.main}
          >
            {this.props.children.map((tab) => {
              // if (tab instanceof CollapsableWebTab) {
              //   return this.createWebTabView(tab.name, tab.url);
              // } else
              if (tab instanceof CollapsableListTab) {
                return this.createItemListTabView(tab.name, tab.items, tab.renderItem, tab.itemHeight, tab.keyExtractor, tab.bounces);
              } else {
                return this.createScrollTabView(tab.name, tab.element);
              }
            })}
          </this.twitter.Tabs>
        </div>
      );
    }

    return (
      <Tabs.Container
        lazy
        ref={this.props.containerRef}
        containerStyle={{ backgroundColor: themeColor.base }}
        TabBarComponent={tabBar}
        headerContainerStyle={{ width: "100%" }}
        HeaderComponent={this.header}
        headerHeight={this.expandedHeaderHeight}
        minHeaderHeight={this.shrinkedHeaderHeight}
        pagerProps={{ scrollEnabled: this.props.canSwipe }}
        onIndexChange={this.props.onIndexChange}
      >
        {this.props.children.map((tab) => {
          if (tab instanceof CollapsableListTab) {
            return this.createItemListTabView(tab.name, tab.items, tab.renderItem, tab.itemHeight);
          } else if (tab instanceof CollapsableScrollTab) {
            return this.createScrollTabView(tab.name, tab.element, tab.bounces);
          } else {
            return this.createTabView(tab.name, tab.element);
          }
        })}
      </Tabs.Container>
    );
  }

  createTabView(name: string, element: JSX.Element): JSX.Element {
    let ret: JSX.Element;
    if (Platform.OS === "web") {
      ret = (
        <this.twitter.Tab label={name} key={name}>
          <View
            pointerEvents="box-none"
            style={{
              backgroundColor: themeColor.base,
              paddingHorizontal: 0,
              paddingVertical: 0,
              flex: 1,
            }}
          >
            {element}
          </View>
        </this.twitter.Tab>
      );
    } else {
      ret = (
        <Tabs.Tab name={name} key={name}>
          <CollapsibleScrollView
            pointerEvents="box-none"
            bounces={false}
            style={{
              backgroundColor: themeColor.base,
              paddingHorizontal: 0,
              marginTop: 0,
              flex: 1,
            }}
          >
            {element}
          </CollapsibleScrollView>
        </Tabs.Tab>
      );
    }

    return ret;
  }

  createScrollTabView(name: string, element: JSX.Element, bounces = true): JSX.Element {
    let ret: JSX.Element;
    if (Platform.OS === "web") {
      ret = (
        <this.twitter.Tab label={name} key={name}>
          <View
            style={{
              backgroundColor: themeColor.base,
              paddingHorizontal: 0,
              paddingVertical: 0,
              flex: 1,
            }}
          >
            {element}
          </View>
        </this.twitter.Tab>
      );
    } else {
      ret = (
        <Tabs.Tab name={name} key={name}>
          <CollapsibleScrollView
            bounces={bounces}
            style={{
              backgroundColor: themeColor.base,
              paddingHorizontal: 0,
              marginTop: 0,
              flex: 1,
            }}
          >
            {element}
          </CollapsibleScrollView>
        </Tabs.Tab>
      );
    }

    return ret;
  }

  // createWebTabView(name: string, url: string): JSX.Element {
  //   let ret: JSX.Element;
  //   if (Platform.OS === "web") {
  //     ret = (
  //       <this.twitter.Tab label={name} key={name}>
  //         <View
  //           style={{
  //             backgroundColor: themeColor.base,
  //             paddingHorizontal: 0,
  //             paddingVertical: 0,
  //             flex: 1,
  //           }}
  //         ></View>
  //       </this.twitter.Tab>
  //     );
  //   } else {
  //     ret = (
  //       <Tabs.Tab name={name} key={name}>
  //         <WebView
  //           source={{ uri: url }}
  //           androidHardwareAccelerationDisabled
  //           style={{
  //             backgroundColor: themeColor.base,
  //             paddingHorizontal: 0,
  //             marginTop: 0,
  //             flex: 1,
  //           }}
  //         ></WebView>
  //       </Tabs.Tab>
  //     );
  //   }

  //   return ret;
  // }

  createItemListTabView(
    name: string,
    items: any[],
    renderItem: (item: { item: object; index: number; separators: object }) => JSX.Element,
    itemHeight: number,
    keyExtractor?: (item: any, index: number) => string,
    bounces = true
  ): JSX.Element {
    let ret: JSX.Element;

    if (Platform.OS === "web") {
      ret = (
        <this.twitter.Tab label={name} key={name}>
          <div id="parent" style={{ width: "100%", flex: 1, marginTop: -this.headerHeight ?? 0 }}>
            <WindowScroller id="WindowScroller" scrollElement={this.scrollViewElement ?? window}>
              {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => {
                return (
                  <AutoSizer id="AutoSizer" width={this.headerWidth} ref={registerChild}>
                    {({ width }) => {
                      return (
                        <List
                          autoHeight
                          width={width}
                          height={height}
                          rowCount={items.length + 1}
                          rowHeight={({ index }) => (index === 0 ? this.headerHeight : itemHeight)}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          scrollTop={scrollTop}
                          noRowsRenderer={() => <div>noRow</div>}
                          overscanRowCount={15}
                          bounces={bounces}
                          rowRenderer={({ style, index }) => {
                            if (index === 0) {
                              return <View style={{ height: this.headerHeight }}></View>;
                            }
                            index--;
                            return (
                              <View style={[style, { padding: 10, marginVertical: 10, flexShrink: 1 }]} key={index}>
                                {renderItem({ item: items[index], index: index, separators: {} })}
                              </View>
                            );
                          }}
                        />
                      );
                    }}
                  </AutoSizer>
                );
              }}
            </WindowScroller>
          </div>
        </this.twitter.Tab>
      );
    } else {
      ret = (
        <Tabs.Tab name={name} key={name}>
          <Tabs.FlatList
            key={name}
            style={{
              backgroundColor: themeColor.base,
              paddingHorizontal: 10,
              marginTop: 15,
            }}
            data={items}
            ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
            renderItem={renderItem}
            onEndReachedThreshold={0}
            keyExtractor={keyExtractor}
          ></Tabs.FlatList>
        </Tabs.Tab>
      );
    }

    return ret;
  }
}
