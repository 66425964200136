import { Collapse, Text } from "native-base";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { state } from "@core/State";
import styles, { isWidthLarger, themeColor } from "@core/Styles";
import { GridChildView } from "./GridChildView";

export interface Props extends BaseProps {
  title?: string;
  children?: string | string[];
}

export class CollapsableText extends BaseComponent<Props> {
  @state isExpanded = false;

  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <GridChildView
        titleComponent={
          <View pointerEvents="box-none" style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flexGrow: 1 }} pointerEvents="none">
              <Text
                style={[
                  isWidthLarger("md") ? styles.h2 : styles.h3,
                  {
                    flexGrow: 1,
                    // backgroundColor: "gray",
                  },
                ]}
              >
                {this.props.title ? this.props.title : "あらすじ"}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 15,
                height: 40,
              }}
              onPress={() => {
                this.isExpanded = !this.isExpanded;
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: themeColor.main,
                }}
              >
                {this.isExpanded ? "小さくする" : "さらに表示"}
              </Text>
            </TouchableOpacity>
          </View>
        }
      >
        <View pointerEvents="none">
          <Collapse
            style={{
              padding: 15,
              backgroundColor: themeColor.textArea,
              alignContent: "flex-end",
              borderRadius: 10,
            }}
            isOpen={this.isExpanded}
            startingHeight={100}
            duration={200}
          >
            <Text
              noOfLines={this.isExpanded ? 0 : 3}
              style={[
                styles.text,
                {
                  color: themeColor.mainText,
                  fontSize: 15,
                  lineHeight: 21,
                  overflow: "hidden",
                },
              ]}
            >
              {this.props.children?.map((v) => (typeof v === "string" ? v.trim() : v.data.trim())).join("\n") ?? "あらすじがありません。"}
            </Text>
          </Collapse>
        </View>
      </GridChildView>
    );
  }
}
