import _ from "lodash";
import { ParserManager } from "@core/ParserManager";
import { Firebase } from "./firebase/FirebaseUtility";
import { BookshelfCollection } from "./firebase/firestore/documents/bookshelf-documents";

class BookshelfUtility {
  async initialize(bookshelfCollection?: BookshelfCollection) {
    if (!bookshelfCollection) {
      const user = await Firebase.login();
      if (!user) {
        return;
      }
      bookshelfCollection = new BookshelfCollection({ uid: user.uid });
    }

    const items = bookshelfCollection.toArray();
    if (!items.some((v) => v.data.type === "bookshelf")) {
      await bookshelfCollection.add({
        type: "bookshelf",
        title: "本棚",
        index: 0,
      });
      await bookshelfCollection.save();
    }
    const bookshelf = _.sortBy(items, (v) => v.data.index).find((v) => v.data.type === "bookshelf");

    if (!bookshelf) {
      Log.error("bookshelfなければ作ったはずなのに、ないらしい", "BookshelfUtility");
      Log.error("string", "BookshelfUtility");
      return;
    }
    let changed = false;
    for (const item of items) {
      if (item.data.type !== "bookshelf" && !item.data.parent) {
        item.data.parent = bookshelf.id;
        changed = true;
      }
    }
    if (changed) {
      await bookshelfCollection.save();
    }
  }

  async addBookmark(url: string, title: string, parent: string): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }

    const bookshelfCollection = new BookshelfCollection({ uid: user.uid, parent: parent });
    await bookshelfCollection.load();

    const index = (_.maxBy(bookshelfCollection.toArray(), (v) => v.data.index)?.data.index ?? 0) + 1000;
    await bookshelfCollection.add({ title: title, type: "bookmark", url: url, index: index, parent: parent });
  }
  addBook(url: string, parent: string, type?: "other", title?: string): Promise<void>;
  addBook(url: string, parent: string, type?: "other", title?: string): Promise<void>;
  addBook(id: string, parent: string, type: "ilks", title?: string): Promise<void>;
  async addBook(url: string, parent: string, type: "ilks" | "other" = "other", title?: string): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }

    if (!title) {
      try {
        const book = await ParserManager.getIndex(url, type);
        title = book?.title ?? "タイトル不明";
      } catch (e) {
        Log.error(e);
        title = "タイトル不明";
      }
    }

    const bookshelfCollection = new BookshelfCollection({ uid: user.uid, parent: parent });
    await bookshelfCollection.load();

    const index = (_.maxBy(bookshelfCollection.toArray(), (v) => v.data.index)?.data.index ?? 0) + 1000;
    if (type === "other") {
      await bookshelfCollection.add({ title: title, type: "book", book: { types: "other", url: url }, index: index, parent: parent });
    } else {
      await bookshelfCollection.add({ title: title, type: "book", book: { types: "ilks", id: url }, index: index, parent: parent });
    }
  }

  async addGroup(title: string, parent: string): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }

    const bookshelfCollection = new BookshelfCollection({ uid: user.uid, parent: parent });
    await bookshelfCollection.load();
    const index = (_.maxBy(bookshelfCollection.toArray(), (v) => v.data.index)?.data.index ?? 0) + 1000;
    await bookshelfCollection.add({ title: title, type: "group", index: index, parent: parent });
  }
  async addBookshelf(title: string): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }

    const bookshelfCollection = new BookshelfCollection({ uid: user.uid, parent: "" });
    await bookshelfCollection.load();
    const index = (_.maxBy(bookshelfCollection.toArray(), (v) => v.data.index)?.data.index ?? 0) + 1000;
    await bookshelfCollection.add({ title: title, type: "bookshelf", index: index });
  }
  moveItem(_: { ids: string[]; parent: string; left?: string; right?: string }): Promise<void>;
  moveItem(_: { ids: string[]; left: string; right: string }): Promise<void>;
  moveItem(_: { ids: string[]; left: string; right?: string }): Promise<void>;
  moveItem(_: { ids: string[]; left?: string; right: string }): Promise<void>;
  async moveItem({ ids, parent, left, right }: { ids: string[]; parent?: string; left?: string; right?: string }): Promise<void> {
    if (left && right) {
      await this.moveItemImpl2({ ids, left, right });
    } else if (left) {
      await this.moveItemImpl2({ ids, left });
    } else if (right) {
      await this.moveItemImpl2({ ids, right });
    } else {
      await this.moveItemImpl1({ ids, parent: parent ?? "" });
    }
  }
  protected async moveItemImpl1({ ids, parent }: { ids: string[]; parent: string }): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }
    const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
    await bookshelfCollection.load();

    let index =
      (_.maxBy(
        bookshelfCollection.toArray().filter((v) => {
          v.data.parent === parent;
        }),
        (v) => v.data.index
      )?.data.index ?? 0) + 1000;
    for (const id of ids) {
      await bookshelfCollection.documents[id].load();
      if (bookshelfCollection.documents[id].exist) {
        bookshelfCollection.documents[id].data.index = index;
        bookshelfCollection.documents[id].data.parent = parent;
        index += 1000;
      } else {
        Log.error("そんなブックシェルフデータないけど", "BookshelfUtility", { id });
      }
    }
    await bookshelfCollection.save();
  }
  protected async moveItemImpl2({ ids, left, right }: { ids: string[]; left?: string; right?: string }): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }
    const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
    await bookshelfCollection.load();
    let leftIndex: number | undefined;
    let rightIndex: number | undefined;
    let parent: string | undefined;
    if (left) {
      if (!bookshelfCollection.documents[left]?.exist) {
        Log.error("なんかドキュメント見つからんねんけど0", "BookshelfUtility");
        return;
      }
      leftIndex = bookshelfCollection.documents[left].data.index;
      parent = bookshelfCollection.documents[left].data.parent;
    }
    if (right) {
      if (!bookshelfCollection.documents[right]?.exist) {
        Log.error("なんかドキュメント見つからんねんけど1", "BookshelfUtility");
        return;
      }
      rightIndex = bookshelfCollection.documents[right].data.index;
      if (parent && parent !== bookshelfCollection.documents[right].data.parent) {
        Log.error("なんかparent不整合起きてる", "BookshelfUtility", { left: parent ?? "", right: bookshelfCollection.documents[right].data.parent ?? "" });
      }
      parent = bookshelfCollection.documents[right].data.parent;
    }

    if (leftIndex !== undefined && rightIndex !== undefined) {
      //
    } else if (leftIndex !== undefined) {
      rightIndex = leftIndex + 1000 * ids.length;
    } else if (rightIndex !== undefined) {
      leftIndex = rightIndex - 1000 * ids.length;
    } else {
      leftIndex = 0;
      rightIndex = 0;
      Log.error("なんかindex不整合起きてる", "BookshelfUtility");
    }
    if (!parent) {
      parent = "";
    }
    // for (const id of ids) {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      await bookshelfCollection.documents[id].load();
      const index = leftIndex + (rightIndex - leftIndex) * ((i + 1) / (ids.length + 1));
      if (bookshelfCollection.documents[id].exist) {
        bookshelfCollection.documents[id].data.index = index;
        bookshelfCollection.documents[id].data.parent = parent;
      } else {
        Log.error("そんなブックシェルフデータないけど", "BookshelfUtility", { id });
      }
    }
    await bookshelfCollection.save();
  }
  async delete(ids: string[]): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }
    const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
    await bookshelfCollection.load();
    for (const id of ids) {
      await bookshelfCollection.delete(id);
    }
  }
}

export const Bookshelf = new BookshelfUtility();
