import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { sharedState } from "@core/SharedState";
import { Spacer } from "@core/Spacer";
import styles, { themeColor } from "@core/Styles";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BookshelfBookButton } from "../Component/BookshelfBookButton";
import { Tag } from "../Component/Tag";
import { TextLabel } from "../Component/TextLabel";
import { BookshelfScene } from "./BookshelfScene";
import { DraggableCollectionItem } from "./DraggableCollectionItem";
import { DraggingData } from "./DraggingItem";
import { DraggableData, Inset, OverlapState, Position } from "./DragHandler";

export class DraggableCollectionBookshelfData implements DraggableData {
  id: string;
  title: string;
  path: string;
  parent: string;
  type: "bookshelf";
  typeName = "bookshelf";
  constructor(id: string, title: string, path: string, parent: string, type: "bookshelf") {
    this.id = id;
    this.title = title;
    this.path = path;
    this.parent = parent;
    this.type = type;
  }

  renderDragging(): JSX.Element {
    return <DraggableCollectionBookshelf key={"dragging" + this.id} data={this}></DraggableCollectionBookshelf>;
  }
}

export class DraggableCollectionBookshelf extends DraggableCollectionItem {
  @sharedState bookshelfScale = 1.0;

  onTap(): void {
    if (this.isFloating || this.isSelected) {
      return;
    }

    if (this.draggingData && this.draggingData.data.every((v) => v.typeName === "bookshelf")) {
      this.draggingData.data.push(this.props.data);
      this.draggingData = _.clone(this.draggingData);
    } else {
      // this.currentScene?.navigation.push("Bookshelf", { path: this.draggableData.url });
      // if (this.currentScene.constructor.name === "BookshelfScene") {
      //   const bookshelfScene = this.currentScene as BookshelfScene;
      //   bookshelfScene.changePath(this.draggableData.path);
      // }
      const scene = this.context?.scene ?? this.currentScene;
      if ("changePath" in scene) {
        const bookshelfScene = scene as BookshelfScene;
        bookshelfScene.changePath(this.draggableData.path);
      }
    }
  }

  get margin(): Inset {
    return this.draggingData?.data.every((v) => v.typeName === "bookshelf") ? { left: 0, right: 0, top: 0.5, bottom: 0.5 } : { left: 0, right: 0, top: 0, bottom: 0 };
  }
  move(pos: Position, state: OverlapState): void {
    super.move(pos, state);
    if (this.overlapState && this.hover && this.overlapState.isCenter && this.overlapState.timestamp > this.overlapState.enterCenterTimestamp + 1000) {
      this.hover = false;
      // this.currentScene?.navigation.push("Bookshelf", { path: this.draggableData.url });
      // if (this.currentScene.constructor.name === "BookshelfScene") {
      //   const bookshelfScene = this.currentScene as BookshelfScene;
      //   bookshelfScene.changePath(this.draggableData.path);
      // }
      const scene = this.context?.scene ?? this.currentScene;
      if ("changePath" in scene) {
        const bookshelfScene = scene as BookshelfScene;
        bookshelfScene.changePath(this.draggableData.path);
      }
    }
  }

  drop(draggingData: DraggingData): void {
    if (!this.props.parent) {
      return;
    }
    if (this.overlapState?.isCenter) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Bookshelf.moveItem({
        ids: draggingData.data.map((v) => v.id),
        parent: this.draggableData.id,
      });
    }
  }

  get draggableData(): DraggableCollectionBookshelfData {
    return this.props.data as DraggableCollectionBookshelfData;
  }

  renderItem(): JSX.Element {
    let color: string | undefined = undefined;
    let opacity: number | undefined = undefined;
    if (this.isFloating) {
      color = "yellow";
      opacity = 1;
    } else if (this.into) {
      color = "red";
      opacity = 1;
    } else if (this.hover) {
      color = "magenta";
      opacity = 1;
    }
    if (this.touching) {
      color = "white";
      opacity = 0.2;
    }
    return (
      <View
        style={[
          styles.colorShadow,
          {
            alignSelf: "stretch",
            borderRadius: 10,
            backgroundColor: themeColor.background,
            borderColor: themeColor.border,
            borderWidth: 0.5,
            marginHorizontal: 15,
            marginVertical: 5,
            padding: 15,
          },
        ]}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Tag bgColor={themeColor.main}>{this.draggableData.title}</Tag>
          <TextLabel boldWeight color={themeColor.subText}>
            {"1冊"}
          </TextLabel>
        </View>
        <Spacer vertical space={5}></Spacer>
        <View style={{ overflow: "hidden", width: "100%", backgroundColor: themeColor.itemBGContents, borderRadius: 10, padding: 10 }}>
          <View style={{ flexDirection: "row" }}>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
            <BookshelfBookButton
              title={"中の本"}
              type="book"
              authorName={"作者名"}
              genre={"ジャンル"}
              style={color ? { backgroundColor: color, opacity: opacity } : {}}
              scale={0.75}
              onPress={() => {
                // this.currentScene?.navigation.navigate("BookSummary", {
                //   id: this.props.data.id,
                //   title: this.props.data.title,
                //   url: this.props.data.url,
                //   type: this.props.data.type,
                // });
              }}
            ></BookshelfBookButton>
          </View>
        </View>
      </View>
    );
  }
}
