import * as firestore from "@firebase/firestore-types";
import { FieldValue, Timestamp } from "../lib/firestore";
import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument, FirestoreKey } from "../lib/firestore-document";

export interface BookKey extends FirestoreKey {
  bookId: string;
}

export interface BookData extends FirestoreData {
  title: string;
  abstract: string;
  coverImagePath?: string;
  authorUid: string;
  author: AuthorData;
  novelUpdatedAt: Timestamp | firestore.FieldValue;
  readonly readonlyData?: BookReadonlyData;
}
export interface BookReadonlyData {
  episodes: {
    [key: string]: {
      title: string;
      no: number;
      id: string;
      chapterTitle: string;
      date: Timestamp | firestore.FieldValue;
    };
  };
}
export interface AuthorData extends FirestoreData {
  name: string;
}

export class BookCollection extends FirestoreCollection<FirestoreKey, BookData, BookDocument> {
  static ref(): CollectionReference<DocumentData> {
    return this.firestore().collection("books");
  }

  constructor(key: FirestoreKey) {
    super(BookDocument, key);
  }
}

export class BookDocument extends FirestoreDocument<BookKey, BookData> {
  static ref(key: BookKey): DocumentReference<DocumentData> {
    return BookCollection.ref().doc(key.bookId);
  }

  static get defaultData(): BookData {
    return {
      title: "転生したらいるくだった件",
      abstract: `突然路上で通り魔に刺されて死んでしまった、37歳のナイスガイ。意識が戻って自分の身体を確かめたら、いるくになっていた！
      え？…え？何でいるくなんだよ！！！などと言いながらも、日々を楽しくいるくライフ。
      出来る事も増えて、下僕も増えて。ゆくゆくは魔王でも目指しちゃおうかな？
      そんな、どこかずれた天然主人公の異世界いるくライフです。
      `,
      authorUid: "ilkilkilk",
      author: {
        name: "いるーく",
      },
      novelUpdatedAt: FieldValue.serverTimestamp(),
    };
  }
}
