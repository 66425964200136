import React from "react";
import { Button, StyleSheet, View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";
import { state } from "@core/State";

export interface Props {
  name: string;
  enthusiasmLevel?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export class Hello extends BaseComponent<Props> {
  @state enthusiasmLevel: number;
  constructor(props: Props) {
    super(props);
    this.enthusiasmLevel = props.enthusiasmLevel ?? 1;
  }

  onIncrement: () => void = () => {
    this.enthusiasmLevel = this.enthusiasmLevel + 1;
  };
  onDecrement: () => void = () => {
    this.enthusiasmLevel = Math.max(0, this.enthusiasmLevel - 1);
  };
  getExclamationMarks: (numChars: number) => string = (numChars: number) => Array(numChars + 1).join("!");

  renderComponent(): JSX.Element {
    return (
      <View style={styles.root}>
        {/* <Text style={styles.greeting}>Hello {this.props.name + this.getExclamationMarks(this.enthusiasmLevel)}</Text>
        <Text style={{ fontSize: 16, margin: 0, padding: 0, lineHeight: 15.48, color: "#FF0000" }}>{`
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
        `}</Text>
        <View style={{ height: 0, margin: 0, padding: 0, marginTop: -17 * 11 - 2.3 }}></View>
        <TextInput style={{ fontSize: 16, margin: 0, padding: 0, lineHeight: 17 }} editable={false} multiline>{`
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
ああああ
        `}</TextInput>
        <View style={{ transform: [{ rotate: "90deg" }], width: 200, height: 200 }}>
          <Text style={{ alignSelf: "stretch", flexDirection: "row", flexWrap: "wrap", fontSize: 16 }}>
            {[
              ["下", ""],
              ["a", ""],
              ["下", ""],
              ["下", ""],
              ["aaa", ""],
            ].map(([base, ruby]) => {
              return <RubyText base={base.toString()} ruby={ruby.toString()}></RubyText>;
            })}
          </Text>
          <Text style={{ alignSelf: "stretch", flexDirection: "row", flexWrap: "wrap", fontSize: 16 }}>
            {[
              ["下", "した"],
              ["a", "えー"],
              ["下", "a"],
              ["下", ""],
              ["aaa", ""],
            ].map(([base, ruby]) => {
              return <RubyText base={base.toString()} ruby={ruby.toString()}></RubyText>;
            })}
          </Text>
          <Text style={{ alignSelf: "stretch", flexDirection: "row", flexWrap: "wrap", fontSize: 16 }}>
            {[
              ["下", ""],
              ["a", ""],
              ["下", ""],
              ["下", ""],
              ["aaa", ""],
            ].map(([base, ruby]) => {
              return <RubyText base={base.toString()} ruby={ruby.toString()}></RubyText>;
            })}
          </Text>
          <Text style={{ alignSelf: "stretch", flexDirection: "row", flexWrap: "wrap", fontSize: 16 }}>
            {[
              ["下", "した"],
              ["a", "えー"],
              ["下", "a"],
              ["下", ""],
              ["aaa", ""],
            ].map(([base, ruby]) => {
              return <RubyText base={base.toString()} ruby={ruby.toString()}></RubyText>;
            })}
          </Text>
          <Text style={{ alignSelf: "stretch", flexDirection: "row", flexWrap: "wrap", fontSize: 16 }}>
            {[
              ["下", "した"],
              ["a", "えー"],
              ["下", "a"],
              ["下", ""],
              ["aaa", ""],
            ].map(([base, ruby]) => {
              return <RubyText base={base.toString()} ruby={ruby.toString()}></RubyText>;
            })}
          </Text>
        </View> */}
        <View style={styles.buttons}>
          <View style={styles.button}>
            <Button title="-" onPress={this.onDecrement} accessibilityLabel="decrement" color="red" />
          </View>

          <View style={styles.button}>
            <Button title="+" onPress={this.onIncrement} accessibilityLabel="increment" color="blue" />
          </View>
        </View>
      </View>
    );
  }
}

// styles

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    alignSelf: "center",
  },
  buttons: {
    flexDirection: "row",
    minHeight: 70,
    alignItems: "stretch",
    alignSelf: "center",
    borderWidth: 5,
  },
  button: {
    flex: 1,
    paddingVertical: 0,
  },
  greeting: {
    color: "#999",
    fontWeight: "bold",
  },
});
