import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { Spacer } from "@core/Spacer";
import styles, { themeColor } from "@core/Styles";
import { IconText } from "./IconText";

export interface Props extends BaseProps {
  authorName: string;
  authorId?: string;
  bookName?: string;
  genre: string;
}

export class DetailAuthorButton extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={{ marginVertical: 5, paddingHorizontal: 5, height: 80, backgroundColor: themeColor.textArea, borderRadius: 5 }}>
        <View style={{ flexGrow: 1, marginVertical: 5, marginHorizontal: 5, flex: 1, flexShrink: 1, flexDirection: "row", alignItems: "center" }}>
          <IconText iconWidth={50} iconHeight={50} iconRadius={25} space={5} icon={require("@assets/images/human.png")}></IconText>
          <View style={{ flexGrow: 1, flex: 1, flexShrink: 1, height: "100%", paddingVertical: 5 }}>
            <View style={{ flexShrink: 1, flexDirection: "row" }}>
              <Text
                style={[
                  styles.smallFont,
                  styles.textBold,
                  {
                    flexGrow: 1,
                    color: themeColor.mainText,
                  },
                ]}
              >
                {this.props.authorName}
              </Text>
              <Spacer space={5}></Spacer>
              <Text
                style={[
                  styles.verySmallFont,
                  styles.text,
                  {
                    flexShrink: 1,
                    color: themeColor.subText,
                  },
                ]}
              >
                {this.props.authorId ?? ""}
              </Text>
            </View>
            <Text
              noOfLines={2}
              style={[
                styles.smallFont,
                styles.text,
                {
                  color: themeColor.mainText,
                  flexShrink: 1,
                  flexGrow: 1,
                  lineHeight: 14,
                },
              ]}
            >
              {this.props.bookName}
            </Text>
            <Text
              style={[
                styles.verySmallFont,
                styles.textBold,
                {
                  color: themeColor.subText,
                },
              ]}
            >
              {this.props.genre}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}
