import React from "react";
import { themeColor } from "@core/Styles";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { WriteScene } from "../Write/WriteScene";

const WriteStack = createStackNavigator();

export function WriteStackScreen(): JSX.Element {
  return (
    <WriteStack.Navigator
      screenOptions={{
        headerTintColor: themeColor.mainText,
        headerStyle: {
          backgroundColor: themeColor.bar,
          shadowColor: themeColor.border,
          shadowOpacity: 0,
          elevation: 0,
        },
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(Stack.Screen, WriteScene)}
      {StackScreenRender(Stack.Screen, Detail2Scene)} */}
      {StackScreenRenderAll(WriteStack.Screen, WriteScene)}
    </WriteStack.Navigator>
  );
}
