import { Column } from "native-base";
import React, { createRef, useEffect, useState } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { PanGestureHandler, ScrollView, State } from "react-native-gesture-handler";
import Animated, { useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { EpisodeReader } from "@core/EpisodeReader";
import { Spacer } from "@core/Spacer";
import styles, { themeColor } from "@core/Styles";
import { BookshelfBookIcon } from "./BookshelfBookIcon";
import { BottomTitleButton } from "./BottomTitleButton";
import { DetailAuthorButton } from "./DetailAuthorButton";
import { DetailBookButton } from "./DetailBookButton";
import { EpisodeTitle } from "./EpisodeItem";
import { GridChildView } from "./GridChildView";
import { GridView } from "./GridView";
import { IconInfo } from "./IconInfo";
import { IconText } from "./IconText";
import { TagLabel } from "./TagLabel";
import { TextLabel } from "./TextLabel";

export interface Props {
  type: "ilks" | "other";
  id: string;
  no: number;
  title: string;
  url: string;
  onShowMenu?: (isShow: boolean) => void;
  onShowSettings: () => void;
  initialSnapIndex: number;
  direction: "column" | "row" | "row-reverse";
}

const normal = (x: number, y: number, p: number) => {
  return (p - x) / (y - x);
};

export const EpisodeReaderSnapView = (props: Props): JSX.Element => {
  const x = useSharedValue(0);
  const y = useSharedValue(0);
  const pagingDirection = useSharedValue(0);
  const snapedIndex = useSharedValue(props.initialSnapIndex);
  const snapPoints = useSharedValue([0]);
  const visible = useSharedValue(false);
  const pageCount = useSharedValue(4);
  const isShowFirstPage = useSharedValue(false);
  const isShowLastPage = useSharedValue(false);
  const sharedCanPrevPaging = useSharedValue(false);
  const sharedCanNextPaging = useSharedValue(false);
  const [canPrevPaging, setCanPrevPaging] = useState(false);
  const [canNextPaging, setCanNextPaging] = useState(false);
  const lastPageScrollViewPos = useSharedValue(0);

  const existPrevEpisode = useSharedValue(true);
  const existNextEpisode = useSharedValue(true);

  const [episodeReader, setEpisodeReader] = useState<EpisodeReader | null>();

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, context) => {
      if (props.direction === "column") {
        context.startY = y.value;
      } else {
        context.startX = x.value;
      }
    },

    onActive: (event, context) => {
      let transition = props.direction === "column" ? event.translationY : event.translationX;
      const startPos: number = props.direction === "column" ? context.startY : context.startX;

      if (pagingDirection.value === 0) {
        pagingDirection.value = transition < 0 ? -1 : 1;
      } else if (pagingDirection.value === -1 && transition > 0) {
        //次にスワイプと見せかけて前にスワイプはできなければ妨害する
        if (sharedCanPrevPaging.value) {
          pagingDirection.value = -1;
        } else {
          if (props.direction === "column") {
            y.value = startPos;
          } else {
            x.value = startPos;
          }
          return;
        }
      } else if (pagingDirection.value === 1 && transition < 0) {
        //前にスワイプと見せかけて次にスワイプはできなければ妨害する
        if (sharedCanNextPaging.value) {
          pagingDirection.value = 1;
        } else {
          if (props.direction === "column") {
            y.value = startPos;
          } else {
            x.value = startPos;
          }
          return;
        }
      }

      //次の話存在しなかったら引っ張りにくくする
      if (pagingDirection.value === (props.direction === "row-reverse" ? 1 : -1) && snapedIndex.value === 2) {
        transition /= existNextEpisode.value ? 1 : 3;
      }

      //前の話存在しなかったら引っ張りにくくする
      if (pagingDirection.value === (props.direction === "row-reverse" ? -1 : 1) && snapedIndex.value === 1) {
        transition /= existPrevEpisode.value ? 1 : 3;
      }

      if (props.direction === "column") {
        y.value = startPos + transition;
      } else {
        x.value = startPos + transition;
      }
    },

    onEnd: (event, context) => {
      if (props.direction === "column") {
        if (sharedCanNextPaging.value && event.translationY <= -15 && snapedIndex.value < pageCount.value - 1) {
          if (snapedIndex.value !== 2 || existNextEpisode.value) {
            snapedIndex.value = snapedIndex.value + 1;
          }
        } else if (sharedCanPrevPaging.value && event.translationY >= 15 && snapedIndex.value > 0) {
          if (snapedIndex.value !== 1 || existPrevEpisode.value) {
            snapedIndex.value = snapedIndex.value - 1;
          }
        }

        const nextValue = -snapPoints.value[snapedIndex.value];

        y.value = withSpring(nextValue, {
          damping: 800,
          mass: 1,
          stiffness: 800,
          overshootClamping: true,
          restDisplacementThreshold: 0.001,
          restSpeedThreshold: 0.001,
        });
      } else {
        let nextValue = 0;
        if (props.direction === "row-reverse") {
          if (sharedCanNextPaging.value && event.translationX <= -15 && snapedIndex.value > 0) {
            if (snapedIndex.value !== 1 || existPrevEpisode.value) {
              snapedIndex.value = snapedIndex.value - 1;
            }
          } else if (sharedCanPrevPaging.value && event.translationX >= 15 && snapedIndex.value < pageCount.value - 1) {
            if (snapedIndex.value !== 2 || existNextEpisode.value) {
              snapedIndex.value = snapedIndex.value + 1;
            }
          }

          nextValue = -snapPoints.value[pageCount.value - 1 - snapedIndex.value];
        } else {
          if (sharedCanNextPaging.value && event.translationX <= -15 && snapedIndex.value < pageCount.value - 1) {
            if (snapedIndex.value !== 2 || existNextEpisode.value) {
              snapedIndex.value = snapedIndex.value + 1;
            }
          } else if (sharedCanPrevPaging.value && event.translationX >= 15 && snapedIndex.value > 0) {
            if (snapedIndex.value !== 1 || existPrevEpisode.value) {
              snapedIndex.value = snapedIndex.value - 1;
            }
          }

          nextValue = -snapPoints.value[snapedIndex.value];
        }

        x.value = withSpring(nextValue, {
          damping: 800,
          mass: 1,
          stiffness: 800,
          overshootClamping: true,
          restDisplacementThreshold: 0.001,
          restSpeedThreshold: 0.001,
        });
      }

      pagingDirection.value = 0;
    },
  });

  const _handleStateChange = ({ nativeEvent }) => {
    if (nativeEvent.state === State.END) {
      if (snapedIndex.value === 0) {
        setPrevPageSnapable(false, "StateChange");
        setNextPageSnapable(false, "StateChange");

        const prevEpisode = async () => {
          await episodeReader?.showPrev(false);

          setTimeout(() => {
            //TODO: なんか読み込んだ後ちらつくからちょっと遅延させる
            setPage(1);
          }, 200);
        };

        void prevEpisode();
      } else if (snapedIndex.value === 1) {
        setPrevPageSnapable(isShowFirstPage.value, "StateChange");
        setNextPageSnapable(isShowLastPage.value, "StateChange");
      } else if (snapedIndex.value === 2) {
        if (props.direction === "column") {
          setPrevPageSnapable(lastPageScrollViewPos.value === 0, "StateChange");
          setNextPageSnapable(lastPageScrollViewPos.value === 1, "StateChange");
        } else {
          setPrevPageSnapable(true, "StateChange");
          setNextPageSnapable(true, "StateChange");
        }
      } else if (snapedIndex.value === 3) {
        setPrevPageSnapable(false, "StateChange");
        setNextPageSnapable(false, "StateChange");

        const nextEpisode = async () => {
          await episodeReader?.showNext(false);

          setTimeout(() => {
            //TODO: なんか読み込んだ後ちらつくからちょっと遅延させる
            setPage(1);
          }, 200);
        };

        void nextEpisode();
      } else {
        setPrevPageSnapable(false, "StateChange");
        setNextPageSnapable(true, "StateChange");
      }
    }
  };

  const setPrevPageSnapable = (canPrev: boolean, from?: "EpisodeReader" | "LastPageScrollView" | "NextPage" | "StateChange") => {
    if (props.direction === "row-reverse") {
      if (from === "StateChange") {
        setCanNextPaging(canPrev);
        sharedCanNextPaging.value = canPrev;
      } else if (snapedIndex.value === 1) {
        if (from === "EpisodeReader") {
          setCanNextPaging(canPrev);
          sharedCanNextPaging.value = canPrev;
        }
      } else if (snapedIndex.value === 2) {
        if (from === "LastPageScrollView") {
          setCanNextPaging(canPrev);
          sharedCanNextPaging.value = canPrev;
        }
      } else if (snapedIndex.value === 3) {
        if (from === "NextPage") {
          setCanNextPaging(canPrev);
          sharedCanNextPaging.value = canPrev;
        }
      } else {
        setCanNextPaging(canPrev);
        sharedCanNextPaging.value = canPrev;
      }
    } else {
      if (from === "StateChange") {
        setCanPrevPaging(canPrev);
        sharedCanPrevPaging.value = canPrev;
      } else if (snapedIndex.value === 1) {
        if (from === "EpisodeReader") {
          setCanPrevPaging(canPrev);
          sharedCanPrevPaging.value = canPrev;
        }
      } else if (snapedIndex.value === 2) {
        if (from === "LastPageScrollView") {
          setCanPrevPaging(canPrev);
          sharedCanPrevPaging.value = canPrev;
        }
      } else if (snapedIndex.value === 3) {
        if (from === "NextPage") {
          setCanPrevPaging(canPrev);
          sharedCanPrevPaging.value = canPrev;
        }
      } else {
        setCanPrevPaging(canPrev);
        sharedCanPrevPaging.value = canPrev;
      }
    }
  };

  const setNextPageSnapable = (canNext: boolean, from?: "EpisodeReader" | "LastPageScrollView" | "NextPage" | "StateChange") => {
    if (props.direction === "row-reverse") {
      if (from === "StateChange") {
        setCanPrevPaging(canNext);
        sharedCanPrevPaging.value = canNext;
      } else if (snapedIndex.value === 1) {
        if (from === "EpisodeReader") {
          setCanPrevPaging(canNext);
          sharedCanPrevPaging.value = canNext;
        }
      } else if (snapedIndex.value === 2) {
        if (from === "LastPageScrollView") {
          setCanPrevPaging(canNext);
          sharedCanPrevPaging.value = canNext;
        }
      } else if (snapedIndex.value === 3) {
        if (from === "NextPage") {
          setCanPrevPaging(canNext);
          sharedCanPrevPaging.value = canNext;
        }
      } else {
        setCanPrevPaging(canNext);
        sharedCanPrevPaging.value = canNext;
      }
    } else {
      if (from === "StateChange") {
        setCanNextPaging(canNext);
        sharedCanNextPaging.value = canNext;
      } else if (snapedIndex.value === 1) {
        if (from === "EpisodeReader") {
          setCanNextPaging(canNext);
          sharedCanNextPaging.value = canNext;
        }
      } else if (snapedIndex.value === 2) {
        if (from === "LastPageScrollView") {
          setCanNextPaging(canNext);
          sharedCanNextPaging.value = canNext;
        }
      } else if (snapedIndex.value === 3) {
        if (from === "NextPage") {
          setCanNextPaging(canNext);
          sharedCanNextPaging.value = canNext;
        }
      } else {
        setCanNextPaging(canNext);
        sharedCanNextPaging.value = canNext;
      }
    }
  };

  const lastPageScrollViewRef = createRef<ScrollView>();

  const insets = useSafeAreaInsets();

  const handleScroll = (event: { nativeEvent: { contentOffset: { y: number }; layoutMeasurement: { height: any }; contentSize: { height: number } } }) => {
    const pos = Math.round(normal(0, event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height, event.nativeEvent.contentOffset.y) * 10) / 10;

    if (props.direction === "column") {
      setPrevPageSnapable(pos === 0, "LastPageScrollView");
      setNextPageSnapable(pos === 1, "LastPageScrollView");
    }

    lastPageScrollViewPos.value = pos;
  };

  const animatedStyle = useAnimatedStyle(() => {
    if (props.direction === "column") {
      return {
        display: visible ? "flex" : "none",
        transform: [
          {
            translateY: y.value,
          },
        ],
      };
    }
    return {
      display: visible ? "flex" : "none",
      transform: [
        {
          translateX: x.value,
        },
      ],
    };
  });

  const [layoutEvent, setLayoutEvent] = useState<{ x: number; y: number; width: number; height: number } | undefined>();
  const [prevDirection, setPrevDirection] = useState<"column" | "row" | "row-reverse" | undefined>();

  useEffect(() => {
    visible.value = false;

    if (prevDirection !== props.direction && layoutEvent) {
      renderPageLayout?.(layoutEvent);
    }
  });

  const renderPageLayout = (event: { x: number; y: number; width: number; height: number }) => {
    snapPoints.value = [];

    const points = [];

    if (props.direction === "column") {
      for (let index = 0; index < pageCount.value; index++) {
        const height = event.height / pageCount.value;
        points.push(height * index);
      }

      snapPoints.value = points;

      y.value = -points[snapedIndex.value];
    } else {
      for (let index = 0; index < pageCount.value; index++) {
        const width = event.width / pageCount.value;
        points.push(width * index);
      }

      snapPoints.value = points;
    }

    if (Platform.OS !== "web") {
      setPage(snapedIndex.value, points);
    }

    visible.value = true;

    setPrevDirection(props.direction);
  };

  const setPage = (index: number, points?: number[]) => {
    if (index < 0 || index >= pageCount.value) return;
    snapedIndex.value = index;

    if (!points) points = snapPoints.value;

    if (props.direction === "column") {
      y.value = -points[index];
    } else {
      if (props.direction === "row-reverse") {
        x.value = -points[pageCount.value - 1 - index];
      } else {
        x.value = -points[index];
      }
    }
  };

  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, width: "100%", backgroundColor: themeColor.background }}>
        <EpisodeReader
          ref={(ref) => setEpisodeReader(ref)}
          type={props.type}
          id={props.id}
          no={props.no}
          title={props.title}
          url={props.url}
          onShowMenu={props.onShowMenu}
          onShowSettings={props.onShowSettings}
          onFirstPage={(canPaging) => {
            isShowFirstPage.value = canPaging;
            setPrevPageSnapable(canPaging, "EpisodeReader");
          }}
          onLastPage={(canPaging) => {
            isShowLastPage.value = canPaging;
            setNextPageSnapable(canPaging, "EpisodeReader");
          }}
          onLoadedEpisode={(prevEpisode, currentEpisode, nextEpisode) => {
            existPrevEpisode.value = prevEpisode !== undefined;
            existNextEpisode.value = nextEpisode !== undefined;

            lastPageScrollViewRef.current?.scrollTo({ y: 0 });
          }}
        ></EpisodeReader>
      </View>
    );
  }

  return (
    <View style={{ width: props.direction !== "column" ? "400%" : "100%", height: props.direction === "column" ? "400%" : "100%", opacity: 1 }}>
      <PanGestureHandler
        enabled={true}
        enableTrackpadTwoFingerGesture
        shouldCancelWhenOutside={false}
        activeOffsetX={[-20, 20]}
        activeOffsetY={[-20, 20]}
        failOffsetX={[canNextPaging ? -Number.MAX_VALUE : -20, canPrevPaging ? Number.MAX_VALUE : 20]}
        failOffsetY={[canNextPaging ? -Number.MAX_VALUE : -20, canPrevPaging ? Number.MAX_VALUE : 20]}
        simultaneousHandlers={props.direction === "column" ? lastPageScrollViewRef : undefined}
        onGestureEvent={gestureHandler}
        onHandlerStateChange={_handleStateChange}
      >
        <Animated.View
          onLayout={(event) => {
            setLayoutEvent(event.nativeEvent.layout);
            renderPageLayout(event.nativeEvent.layout);
          }}
          style={[animatedStyle, { flex: 1, overflow: "hidden", flexDirection: props.direction }]}
        >
          <View style={{ flex: 1, backgroundColor: themeColor.background, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator size="large" color={Platform.OS === "ios" ? "#999999" : themeColor.main} />
            <TextLabel boldWeight color={themeColor.subText}>
              前の話を読み込み中...
            </TextLabel>
          </View>
          <View style={{ flex: 1, backgroundColor: themeColor.background }}>
            <EpisodeReader
              ref={(ref) => setEpisodeReader(ref)}
              type={props.type}
              id={props.id}
              no={props.no}
              title={props.title}
              url={props.url}
              onShowMenu={props.onShowMenu}
              onShowSettings={props.onShowSettings}
              onFirstPage={(canPaging) => {
                isShowFirstPage.value = canPaging;
                setPrevPageSnapable(canPaging, "EpisodeReader");
              }}
              onLastPage={(canPaging) => {
                isShowLastPage.value = canPaging;
                setNextPageSnapable(canPaging, "EpisodeReader");
              }}
              onLoadedEpisode={(prevEpisode, currentEpisode, nextEpisode) => {
                existPrevEpisode.value = prevEpisode !== undefined;
                existNextEpisode.value = nextEpisode !== undefined;

                lastPageScrollViewRef.current?.scrollTo({ y: 0 });
              }}
            ></EpisodeReader>
          </View>
          <View style={{ right: 0, flex: 1, display: "flex", flexDirection: "column" }}>
            <ScrollView
              scrollEventThrottle={40}
              showsVerticalScrollIndicator={false}
              ref={lastPageScrollViewRef}
              bounces={props.direction !== "column"}
              style={{ width: "100%", minWidth: "100%", height: "100%", flexShrink: 0, backgroundColor: themeColor.base }}
              onMomentumScrollEnd={handleScroll}
            >
              <GridView style={{ paddingLeft: insets.left, paddingRight: insets.right, paddingTop: insets.top, paddingBottom: insets.bottom }}>
                <GridChildView oneColumn title="次の話">
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    <View style={{ flex: 1, flexBasis: 480, flexShrink: 1, marginTop: 15, marginHorizontal: 15 }}>
                      {episodeReader?.nextEpisodeDescription ? (
                        <View
                          style={{
                            flexGrow: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingRight: 10,
                            backgroundColor: themeColor.textArea,
                            maxHeight: 100,
                            borderRadius: 5,
                          }}
                        >
                          <View style={{ width: 40 }}>
                            <View
                              style={{
                                backgroundColor: themeColor.favorite,
                                width: 30,
                                height: 30,
                                borderRadius: 5,
                                display: "flex",
                              }}
                            ></View>
                          </View>
                          <View
                            style={{
                              flexGrow: 1,
                              flexShrink: 1,
                              marginVertical: 10,
                              alignSelf: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            <EpisodeTitle no={0} noOfLines={3}>
                              {episodeReader?.nextEpisodeDescription?.title ?? "次の話はありません。"}
                            </EpisodeTitle>
                            <TagLabel mini tagTitle="投稿" tagTitleBGColor={themeColor.gray} tagValueTextColor={themeColor.subText}>
                              {episodeReader?.nextEpisodeDescription?.date.toLocaleString() ?? ""}
                            </TagLabel>
                          </View>
                          <IconInfo
                            style={{ margin: 10 }}
                            title="予想読了時間"
                            icon={require("@assets/images/human.png")}
                            data="30"
                            unitName="分"
                            accentText="少し長め"
                            additionalData="12240"
                            additionalDataUnitName="文字"
                          ></IconInfo>
                        </View>
                      ) : (
                        <View
                          style={{
                            flexGrow: 1,
                            paddingRight: 10,
                            maxHeight: 100,
                          }}
                        >
                          <TextLabel boldWeight color={themeColor.mainText}>
                            {"次の話はありません。"}
                          </TextLabel>
                        </View>
                      )}
                    </View>
                    <GridChildView style={{ flexBasis: 500, flexShrink: 1 }}>
                      <View style={{ flexDirection: "column", flex: 1 }}>
                        {episodeReader?.nextEpisodeDescription ? (
                          <BottomTitleButton
                            title="次の話を読む"
                            horizontal
                            buttonColor={themeColor.main}
                            buttonColorGradation={themeColor.mainGradation}
                            height={64}
                            flex={2}
                            flexGrow={2}
                            icon={require("@assets/images/human.png")}
                            onPress={() => {}}
                          ></BottomTitleButton>
                        ) : undefined}
                        <BottomTitleButton
                          title="読書を終了する"
                          horizontal
                          height={64}
                          flex={1}
                          flexGrow={1}
                          icon={require("@assets/images/human.png")}
                          onPress={() => {}}
                        ></BottomTitleButton>
                      </View>
                    </GridChildView>
                  </View>
                </GridChildView>
                <GridChildView title="作品">
                  <Spacer vertical space={5}></Spacer>
                  <Column space={0}>
                    <View style={{ flexDirection: "row", flex: 2, marginHorizontal: 5 }}>
                      <BookshelfBookIcon small></BookshelfBookIcon>
                      <View
                        style={{
                          alignContent: "space-between",
                          flexGrow: 1,
                          flexShrink: 1,
                          flexDirection: "column",
                          marginHorizontal: 5,
                        }}
                      >
                        <IconText iconWidth={18} iconHeight={18} iconRadius={3} space={5} icon={require("@assets/images/human.png")}>
                          <TextLabel numberOfLines={1} color={themeColor.subText}>
                            syosetu.com
                          </TextLabel>
                        </IconText>
                        <Spacer vertical space={5}></Spacer>
                        <TextLabel boldWeight color={themeColor.mainText}>
                          {episodeReader?.book?.title?.trim() ?? "タイトルがありません。"}
                        </TextLabel>
                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around" }}>
                          <IconInfo style={{ margin: 10 }} title="総話数" data={(episodeReader?.episodes?.length ?? 0).toString()} unitName="話"></IconInfo>
                          <IconInfo title="本棚登録" color={themeColor.favorite} icon={require("@assets/images/favorite_icon.png")} data="334" unitName="人"></IconInfo>
                        </View>
                      </View>
                    </View>
                    <View style={{ height: 100, flexDirection: "row" }}>
                      <BottomTitleButton
                        title="本棚に追加"
                        buttonColor={themeColor.favorite}
                        textColor={themeColor.mainBlack}
                        flexGrow={1}
                        flex={1}
                        icon={require("@assets/images/human.png")}
                        onPress={() => {}}
                      ></BottomTitleButton>
                      <BottomTitleButton
                        title="更新通知を受け取る"
                        buttonColor={themeColor.accent}
                        flexGrow={1}
                        flex={1}
                        icon={require("@assets/images/human.png")}
                        onPress={() => {}}
                      ></BottomTitleButton>
                      <BottomTitleButton title="共有する" flex={1} flexGrow={1} icon={require("@assets/images/human.png")} onPress={() => {}}></BottomTitleButton>
                    </View>
                  </Column>
                </GridChildView>
                <GridChildView style={{ padding: 10, justifyContent: "flex-end" }}>
                  <View style={[styles.item, styles.colorShadow, { borderRadius: 12, margin: 25 }]}>
                    <View style={{ paddingVertical: 25, paddingHorizontal: 15 }}>
                      <TextLabel boldWeight color={themeColor.accent} textAlign="center">
                        面白いと思った分だけハートを連打しよう！
                      </TextLabel>
                      <View style={{ margin: 10, flexDirection: "row", justifyContent: "center" }}>
                        <BottomTitleButton
                          width={110}
                          height={100}
                          buttonColor={themeColor.accent}
                          icon={require("@assets/images/human.png")}
                          onPress={() => {}}
                        ></BottomTitleButton>
                      </View>
                      <View style={{ alignItems: "center" }}>
                        <IconText iconWidth={24} iconHeight={24} space={12} icon={require("@assets/images/human.png")}>
                          <TextLabel numberOfLines={2} boldWeight color={themeColor.accent} textAlign="center">
                            × 169169
                          </TextLabel>
                        </IconText>
                      </View>
                      <Spacer vertical space={10}></Spacer>
                      <TextLabel boldWeight fontSize="small" color={themeColor.subText} textAlign="center">
                        評価は、小説を執筆されている作者様の励みになります！
                      </TextLabel>
                    </View>
                  </View>
                </GridChildView>
                <GridChildView oneColumn title="作者">
                  <View style={{ marginHorizontal: 10 }}>
                    <DetailAuthorButton authorName={"いるく"} genre=""></DetailAuthorButton>
                  </View>
                  <View style={{ marginHorizontal: 5, flexDirection: "row", flexWrap: "wrap" }}>
                    <View style={{ flexDirection: "row", flexGrow: 1, alignItems: "center", justifyContent: "space-evenly" }}>
                      <IconInfo style={{ margin: 10 }} title="作品数" icon={require("@assets/images/human.png")} data="30" unitName="作品"></IconInfo>
                      <IconInfo style={{ margin: 10 }} title="フォロワー" data="334" unitName="人"></IconInfo>
                      <IconInfo style={{ margin: 10 }} title="フォロー" data="334" unitName="人"></IconInfo>
                    </View>
                    <BottomTitleButton
                      title="フォローする"
                      horizontal
                      buttonColor={themeColor.main}
                      buttonColorGradation={themeColor.mainGradation}
                      height={64}
                      minWidth={200}
                      flex={1}
                      flexGrow={1}
                      icon={require("@assets/images/human.png")}
                      onPress={() => {}}
                    ></BottomTitleButton>
                    <BottomTitleButton
                      title="フィードに登録"
                      horizontal
                      height={64}
                      minWidth={200}
                      flex={1}
                      flexGrow={1}
                      icon={require("@assets/images/human.png")}
                      onPress={() => {}}
                    ></BottomTitleButton>
                  </View>
                </GridChildView>
                <GridChildView oneColumn title="この作者の他の小説">
                  <ScrollView horizontal>
                    <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                    </View>
                  </ScrollView>
                </GridChildView>
                <GridChildView oneColumn title="この小説を読むあなたに">
                  <ScrollView horizontal>
                    <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                    </View>
                  </ScrollView>
                </GridChildView>
              </GridView>
            </ScrollView>
          </View>
          <View style={{ flex: 1, backgroundColor: themeColor.background, alignItems: "center", justifyContent: "center" }}>
            <ActivityIndicator size="large" color={Platform.OS === "ios" ? "#999999" : themeColor.main} />
            <TextLabel boldWeight color={themeColor.subText}>
              次の話を読み込み中...
            </TextLabel>
          </View>
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
};
