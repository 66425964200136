import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";
import styles, { isWidthLarger } from "@core/Styles";

export interface Props extends BasePropsJSXChildren {
  titleComponent?: {};
  oneColumn?: boolean;
  fullWidth?: boolean;
  minHeight?: number;
  title?: string;
  useBigTitle?: boolean;
}

export class GridChildView extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    const titleStyle = this.props.useBigTitle ? styles.h1 : isWidthLarger("md") ? styles.h2 : styles.h3;
    return (
      <View
        pointerEvents={this.props.pointerEvents}
        style={[
          {
            // marginHorizontal: 0,
            marginBottom: 20,
            flex: 1,
            flexShrink: 1,
            flexBasis: this.props.oneColumn ? "100%" : 360,
            marginHorizontal: 0,
            marginVertical: 10,
            minWidth: this.props.oneColumn ? 0 : 300,
            alignContent: "stretch",
            minHeight: this.props.minHeight ?? 50,
          },
          this.props.style ?? {},
        ]}
      >
        <View style={{ flexShrink: 1 }}>
          {this.props.titleComponent ??
            (this.props.title ? (
              <Text style={[titleStyle, this.props.useBigTitle ? { marginHorizontal: this.props.oneColumn ? 10 : 0, marginBottom: 15 } : {}]}>{this.props.title}</Text>
            ) : (
              <View></View>
            ))}
        </View>
        <View style={{ paddingHorizontal: this.props.oneColumn ? 0 : 10 }}>{this.props.children}</View>
      </View>
    );
  }
}
