import { Text } from "native-base";
import React from "react";
import { ColorValue, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { GridChildView } from "./GridChildView";

export interface Props {
  style?: {};
  title?: string;
  description: string;
  children: ColorValue[];
  onChange: (color: ColorValue) => void;
  value: ColorValue;
  pointerEvents?: "box-none" | "none" | "box-only" | "auto";
}

export class SettingsColorPicker extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <GridChildView
        titleComponent={
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={[styles.h4, { flexShrink: 1 }]}>{this.props.title}</Text>
            <TouchableOpacity
              onPress={() => {
                console.log("色変更ダイアログ", String(this.props.value));
              }}
              style={[styles.colorShadow, { backgroundColor: themeColor.white, borderRadius: 12, margin: 2, borderColor: themeColor.border, borderWidth: 0.25 }]}
            >
              <View style={{ width: 60, height: 24, backgroundColor: String(this.props.value), borderRadius: 12 }}></View>
            </TouchableOpacity>
          </View>
        }
      >
        <ScrollView horizontal style={{ backgroundColor: themeColor.textArea, borderRadius: 10, flexGrow: 1, height: 200, padding: 10 }}>
          <View style={{ flexDirection: "column", flexWrap: "wrap", height: "100%" }}>
            {this.props.children.map((color) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    this.props.onChange(color);
                  }}
                  key={String(color)}
                  style={[
                    styles.colorShadow,
                    {
                      backgroundColor: color === this.props.value ? themeColor.main : themeColor.white,
                      borderRadius: 5,
                      margin: 2,
                      borderColor: themeColor.border,
                      borderWidth: 0.25,
                    },
                  ]}
                >
                  <View style={{ margin: 2, width: 50, height: 50, backgroundColor: String(color), borderRadius: 5 }}></View>
                </TouchableOpacity>
              );
            })}
          </View>
        </ScrollView>
        <View style={{ margin: 10 }}>
          <Text style={[styles.smallFont, { color: themeColor.subText }]}>{this.props.description}</Text>
        </View>
      </GridChildView>
    );
  }
}
