import React from "react";
import { View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";

export interface Props {
  children?: JSX.Element[];
  vertical?: boolean;
  space?: number;
  style?: {};
}

export class Spacer extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return <View style={[this.props.space != null ? (this.props.vertical === true ? { height: this.props.space } : { width: this.props.space }) : {}, this.props.style ?? {}]} />;
  }
}
