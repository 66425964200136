import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument } from "../lib/firestore-document";
import { UserKey } from "./user-documents";

export interface BookshelfKey extends UserKey {
  parent?: string;
}
export interface BookshelfItemKey extends BookshelfKey {
  bookshelfItemId: string;
}

export interface IlksBookKey {
  types: "ilks";
  id: string;
}
export interface OtherBookKey {
  types: "other";
  url: string;
}

export type BookshelfData = BookshelfIlksBook | BookshelfOtherBook | BookshelfBookmark | BookshelfGroup | Bookshelf;

interface BookshelfDataBase extends FirestoreData {
  title: string;
  index: number;
  parent?: string;
}

export interface BookshelfIlksBook extends BookshelfDataBase {
  type: "book";
  book: IlksBookKey;
  parent: string;
}
export interface BookshelfOtherBook extends BookshelfDataBase {
  type: "book";
  book: OtherBookKey;
  parent: string;
}
export interface BookshelfBookmark extends BookshelfDataBase {
  type: "bookmark";
  url: string;
  parent: string;
}
export interface BookshelfGroup extends BookshelfDataBase {
  type: "group";
  parent: string;
}
export interface Bookshelf extends BookshelfDataBase {
  type: "bookshelf";
  parent?: undefined;
}

export class BookshelfCollection extends FirestoreCollection<BookshelfKey, BookshelfData, BookshelfDocument> {
  static ref(key: BookshelfKey): CollectionReference<DocumentData> {
    return this.firestore().collection("users").doc(key.uid).collection("bookshelf");
  }

  constructor(key: BookshelfKey) {
    super(BookshelfDocument, key);
    if (key.parent) {
      this.condition = { where: [{ fieldPath: "parent", opStr: "==", value: key.parent }] };
    }
  }
}
export class BookshelfDocument extends FirestoreDocument<BookshelfItemKey, BookshelfData> {
  static ref(key: BookshelfItemKey): DocumentReference<DocumentData> {
    return BookshelfCollection.ref(key).doc(key.bookshelfItemId);
  }
  public static get defaultData(): BookshelfData {
    return { title: "タイトル", type: "book", book: { types: "ilks", id: "0" }, parent: "", index: 0 };
  }
}
