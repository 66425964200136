import _ from "lodash";
import * as queryString from "query-string";
import { Platform } from "react-native";
import { DrawerContentComponentProps, DrawerContentOptions, DrawerNavigationHelpers } from "@react-navigation/drawer/lib/typescript/src/types";
import { DrawerNavigationState, NavigationContainerRef, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "./screens/Core/Navigator";

class RouterClass {
  firstReloaded = false;
  drawerNavigation?: DrawerNavigationHelpers;
  drawerState?: DrawerNavigationState<ParamListBase>;
  modalNavigator: NavigationContainerRef | null = null;
  stackNavigation: { [key: string]: StackNavigationProp<RootStackParamList> } = {};
  sceneNames: { [key: string]: string } = {
    ホーム: "Home",
    見つける: "Search",
    書く: "Write",
    本棚: "Bookshelf",
    アカウント: "Account",
  };
  names: { [key: string]: string } = {
    Home: "ホーム",
    Search: "見つける",
    Write: "書く",
    Bookshelf: "本棚",
    Account: "アカウント",
  };

  setDrawer(drawerContentProps: DrawerContentComponentProps<DrawerContentOptions>) {
    this.drawerNavigation = drawerContentProps.navigation;
    this.drawerState = drawerContentProps.state;

    if (!this.firstReloaded) {
      this.reload();
    }
  }

  setModalNavigator(modalNavigator: NavigationContainerRef | null) {
    this.modalNavigator = modalNavigator;
  }

  setStack(name: string, stackNavigation: StackNavigationProp<RootStackParamList>) {
    if (name in this.stackNavigation) {
      return;
    }
    this.stackNavigation[name] = stackNavigation;

    if (!this.firstReloaded) {
      this.reload();
    }
  }
  initialize(): void {
    return;
  }

  navigate<Name extends keyof RootStackParamList>(
    ...args: undefined extends RootStackParamList[Name] ? [Name] | [Name, RootStackParamList[Name]] : [Name, RootStackParamList[Name]]
  ): void {
    // const name = args[0];
    // const params = args[1];
    this.push(...args);
  }

  push<Name extends keyof RootStackParamList>(
    ...args: undefined extends RootStackParamList[Name] ? [Name] | [Name, RootStackParamList[Name]] : [Name, RootStackParamList[Name]]
  ): void {
    const name = args[0];
    const params = args[1];
    if (Platform.OS !== "web") {
      return;
    }
    if (!this.firstReloaded) {
      return;
    }
    if (!this.drawerNavigation) {
      return;
    }
    const state = this.drawerNavigation.dangerouslyGetState();

    let href = location.protocol + "//" + location.host + "/";
    href += this.sceneNames[state.routeNames[state.index]] + "/";
    if (this.sceneNames[state.routeNames[state.index]] !== name) {
      href += name;
    }
    if (params && Object.keys(params).length > 0) {
      href += "?" + queryString.stringify(params);
    }

    const urlParams = queryString.parseUrl(location.href).query;
    let path = location.pathname;
    if (path.startsWith("/")) {
      path = path.slice(1);
    }

    const paths = path.split("/");
    const drawerName = paths[0];
    const sceneName = paths[1] || paths[0];
    if (drawerName !== this.sceneNames[state.routeNames[state.index]] || sceneName !== name || !_.isEqual(params, urlParams)) {
      window.history.pushState({ prevURL: location.href }, state.routeNames[state.index], href);
    }
    // window.history.pushState({ test: "test" }, "title", "http://localhost:19006/Search/Detail2?text=asd");
  }

  // changeTab(tab: "ホーム" | "見つける" | "書く" | "本棚" | "アカウント"): void {
  changeTab(tab: string, prevTab: string): void {
    if (!this.firstReloaded) {
      return;
    }
    if (!this.drawerNavigation) {
      return;
    }

    const stackNavigationState = this.stackNavigation[this.sceneNames[tab]]?.dangerouslyGetState();
    if (!stackNavigationState) {
      return;
    }
    if (tab === prevTab && stackNavigationState.index === 0) {
      return;
    }
    if (Platform.OS !== "web") {
      if (stackNavigationState.index !== 0) {
        this.stackNavigation[this.sceneNames[tab]].popToTop();
      }
      return;
    }
    let href = location.protocol + "//" + location.host + "/";
    href += this.sceneNames[tab] + "/";
    // if (this.sceneNames[tab] !== stackNavigationState.routes[stackNavigationState.index].name) {
    //   href += stackNavigationState.routes[stackNavigationState.index].name;
    // }
    // const params = stackNavigationState.routes[stackNavigationState.index].params;
    // if (params && Object.keys(params).length > 0) {
    //   href += params ? "?" + queryString.stringify(params) : "";
    // }

    window.history.pushState(null, tab, href);

    if (stackNavigationState.index !== 0) {
      this.stackNavigation[this.sceneNames[tab]].popToTop();
    }
  }

  appNavigate(
    name: string,
    isModal = false,
    navigation: DrawerNavigationHelpers | null = null,
    state: DrawerNavigationState<ParamListBase> | null = null,
    params: any = undefined
  ): void {
    const navi = navigation ?? this.drawerNavigation;
    const st = state ?? this.drawerState;

    if (!navi) return;
    if (!st) return;

    const prevName = st.routeNames[st.index];

    if (isModal) {
      this.modalNavigator?.navigate(name, params);
    } else {
      navi.navigate(name);
      Router.changeTab(name, prevName);
    }
  }

  pop(): void {
    console.log("=================pop================");
    if (Platform.OS !== "web") {
      return;
    }
    if (window.history.state?.prevURL) {
      window.history.back();
    }
  }
  reload(): void {
    console.log("===============reload===============");
    let path = Platform.OS === "web" ? location.pathname : "";

    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    if (!this.drawerNavigation) {
      return;
    }
    const paths = path.split("/");
    const drawerName = paths[0];
    // alert(this.drawerNavigation.dangerouslyGetState().routes);
    const state = this.drawerNavigation.dangerouslyGetState();
    const names: { [key: string]: string } = {
      Home: "ホーム",
      Search: "検索",
      Write: "書く",
      Bookshelf: "本棚",
      Account: "アカウント",
    };

    if (!(drawerName in names)) {
      this.firstReloaded = true;
      return;
    }
    if (state.routeNames[state.index] !== names[drawerName]) {
      this.drawerNavigation.navigate(names[drawerName]);
    }

    if (!(paths[0] in this.stackNavigation)) {
      return;
    }

    const params = queryString.parseUrl(location.href).query;
    if (paths[1]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.stackNavigation[paths[0]].navigate(paths[1] as any, params as any);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.stackNavigation[paths[0]].navigate(paths[0] as any, params as any);
    }

    // alert(paths);
    this.firstReloaded = true;
  }
}
export const Router = new RouterClass();

if (Platform.OS === "web") {
  window.onpopstate = async (_event: Event) => {
    Router.reload();
  };
}
