import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import { DraggableData } from "./DragHandler";

export interface Props {}

export interface DraggingData {
  data: DraggableData[];
}
export class DraggingItem extends BaseComponent<Props> {
  @sharedState draggingData?: DraggingData;
  @state x = 0;
  @state y = 0;
  constructor(props: Props) {
    super(props);
  }

  setPosition(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  renderComponent(): JSX.Element {
    return (
      // 全然意味わからんけど left topが変わってもアンドロイドは変わってないって判定するのか再描画がされないので透明度ランダム
      <View style={{ position: "absolute", left: this.x, top: this.y, opacity: 0.8 + _.random(0, 0.001) }}>
        {this.draggingData?.data.map((v, i) => (
          <View key={i} style={{ position: "absolute", left: 15 * i, top: 15 * i, opacity: 1 }}>
            {v.renderDragging()}
          </View>
        ))}
      </View>
    );
  }
}
