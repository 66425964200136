import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";

export interface Props extends BaseProps {
  children: string;
  textColor?: string;
  onPress: () => void;
}

export class PlainTextButton extends BaseComponent<Props> {
  textColor: string;
  children: string;
  style: {};

  constructor(props: Props) {
    super(props);
    this.children = props.children;

    this.textColor = props.textColor ?? themeColor.main;

    this.style = props.style ?? {};
  }

  renderComponent(): JSX.Element {
    return (
      <View style={[this.style]}>
        <TouchableOpacity style={[{ height: 40 }]} onPress={this.props.onPress}>
          <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
            <Text
              style={[
                styles.textBold,
                styles.mediumFont,
                {
                  textAlign: "center",
                  color: this.textColor,
                },
              ]}
            >
              {this.props.children}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
