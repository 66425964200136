import { LinearGradient } from "expo-linear-gradient";
import firebase from "firebase";
import { Collapse, Image } from "native-base";
import React from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { getSharedState, sharedState, updateSharedState } from "@core/SharedState";
import { Spacer } from "@core/Spacer";
// import { UserFunction } from "@functions/user";
import { state } from "@core/State";
import { themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { Firebase, ProviderId } from "~/utilities/firebase/FirebaseUtility";
import { PlainTextButton } from "../Component/PlainTextButton";
import { RoundedInput } from "../Component/RoudedInput";
import { RoundedButton } from "../Component/RoundedButton";
import { TextLabel } from "../Component/TextLabel";
import { BaseScene } from "../Core/BaseScene";
import { SceneBase, ScreenProp } from "../Core/Navigator";

// import test from "./test.json";

type type = "Login";

class scene extends BaseScene {
  static sceneName: type = "Login";
  static Params: undefined;

  @state url = "";
  @state name = "";
  @state mail = "";
  @state password = "";
  @sharedState aaaaaa = "";
  @sharedState bbb = "";

  @state isShowLogin = false;

  constructor(props: ScreenProp<type>) {
    super(props);
  }

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerShown: false,
    };
  }

  protected async initialize(): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      return;
    }
    if (Firebase.user) {
      this.setUser();
    }
  }

  protected async login(id: ProviderId): Promise<void> {
    try {
      if (id === "password") {
        const user = await Firebase.signInEmailAndPassword(this.mail, this.password);
        console.log("logined:", user?.user);
      } else {
        const user = await Firebase.signInSNS(id);
        console.log("logined:", user?.user);
      }
      this.setUser();
    } catch (e) {
      console.error(e);
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }

  protected async register(id: ProviderId): Promise<void> {
    try {
      if (id === "password") {
        const user = await Firebase.signUpEmailAndPassword(this.name, this.mail, this.password);
        console.log("registered:", user?.user);
      } else {
        const user = await Firebase.signUpSNS(id);
        console.log("registered:", user?.user);
      }
      this.setUser();
    } catch (e) {
      console.error(e);
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
    await Firebase.updateLinked();
  }

  protected async unlink(id: ProviderId): Promise<void> {
    try {
      await Firebase.unlink(id);
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }
  protected isLinked(id: ProviderId): boolean {
    return Firebase.isLinked(id);
  }

  protected async loginAnonymously(): Promise<void> {
    try {
      await Firebase.signInAnonymously();
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }

  protected async logout(): Promise<void> {
    try {
      await Firebase.signInAnonymously();
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }
  protected async resetPassword(): Promise<void> {
    try {
      await Firebase.sendPasswordResetEmail(this.mail);
      alert(this.mail + "にメール送っといたわ");
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }

  protected setUser(): void {
    if (Firebase.user === null) {
      throw new Error("ログインに失敗しました");
    }
    updateSharedState("user", Firebase.user);
    const resolve = getSharedState("resolveUser") as ((user: firebase.User | null) => void) | undefined;
    resolve?.(this.user);
    if (this.user) {
      this.navigation.pop();
    }
  }

  renderComponent(): JSX.Element {
    return (
      <LinearGradient style={[{ width: "100%", height: "100%", backgroundColor: "#7FB3F8" }]} colors={themeColor.mainGradation} start={{ x: 0, y: 1 }} end={{ x: 0, y: 0 }}>
        <KeyboardAwareScrollView style={{ width: "100%", height: "100%" }}>
          <View
            style={{
              width: 360,
              maxWidth: 500,
              alignSelf: "center",
              alignContent: "center",
              flexShrink: 1,
              flexGrow: 1,
              backgroundColor: themeColor.translucentWhite,
              borderRadius: 40,
              paddingVertical: 30,
              paddingHorizontal: 30,
              marginHorizontal: 0,
              marginTop: 40,
              marginBottom: 100,
            }}
          >
            <Image
              style={{ width: 260, height: 60, marginHorizontal: 10, marginVertical: 15, alignSelf: "center" }}
              resizeMode={"contain"}
              source={require("@assets/images/logo_horizontal.png")}
              alt="ilks novel"
            ></Image>
            <TextLabel boldWeight fontSize={"small"} color={themeColor.mainBlack} textAlign={"center"}>
              小説をもっと読みやすく、書きやすく！
            </TextLabel>
            <Spacer vertical space={40}></Spacer>
            <TextLabel blackWeight fontSize={"large"} color={themeColor.mainBlack} textAlign={"center"}>
              アカウントを登録済みの方
            </TextLabel>
            <Spacer vertical space={20}></Spacer>
            <Collapse isOpen={this.isShowLogin} style={{}} startingHeight={0} duration={200}>
              <Spacer vertical space={10}></Spacer>
              <TextLabel boldWeight fontSize={"small"} color={themeColor.mainBlack}>
                SNSアカウントでログイン
              </TextLabel>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_apple.png")}
                backgroundColor={themeColor.black}
                textColor={themeColor.white}
                onPress={async () => {
                  await this.login("apple.com");
                }}
              >
                Appleでログイン
              </RoundedButton>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_google.png")}
                backgroundColor={themeColor.white}
                textColor={themeColor.black}
                onPress={async () => {
                  await this.login("google.com");
                }}
              >
                Googleでログイン
              </RoundedButton>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_twitter.png")}
                backgroundColor={themeColor.white}
                textColor={themeColor.black}
                onPress={async () => {
                  await this.login("twitter.com");
                }}
              >
                Twitterでログイン
              </RoundedButton>
              <Spacer vertical space={40}></Spacer>
              <TextLabel boldWeight fontSize={"small"} color={themeColor.mainBlack}>
                ilksアカウントでログイン
              </TextLabel>
              <KeyboardAvoidingView>
                <Spacer vertical space={10}></Spacer>
                <RoundedInput
                  showShadow
                  placeholder="メールアドレス"
                  backgroundColor={themeColor.white}
                  value={this.mail}
                  onChangeText={(value) => {
                    this.mail = value;
                  }}
                ></RoundedInput>
                <Spacer vertical space={10}></Spacer>
                <RoundedInput
                  type="password"
                  showShadow
                  placeholder="パスワード"
                  backgroundColor={themeColor.white}
                  value={this.password}
                  onChangeText={(value) => {
                    this.password = value;
                  }}
                ></RoundedInput>
                <Spacer vertical space={10}></Spacer>
                <PlainTextButton
                  onPress={async () => {
                    await this.resetPassword();
                  }}
                >
                  パスワードを忘れた方
                </PlainTextButton>
                <Spacer vertical space={20}></Spacer>
              </KeyboardAvoidingView>
            </Collapse>
            <RoundedButton
              onPress={async () => {
                if (this.isShowLogin === true) {
                  await this.login("password");
                } else {
                  this.isShowLogin = true;
                }
              }}
            >
              ログイン
            </RoundedButton>
            <Spacer vertical space={60}></Spacer>
            <TextLabel blackWeight fontSize={"large"} color={themeColor.mainBlack} textAlign={"center"}>
              はじめてのご利用の方
            </TextLabel>
            <Spacer vertical space={20}></Spacer>
            <Collapse isOpen={true} style={{}} startingHeight={0} duration={200}>
              <Spacer vertical space={10}></Spacer>
              <TextLabel boldWeight fontSize={"small"} color={themeColor.mainBlack}>
                Google・SNSからかんたん登録
              </TextLabel>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_apple.png")}
                backgroundColor={themeColor.black}
                textColor={themeColor.white}
                onPress={async () => {
                  await this.register("apple.com");
                }}
              >
                Appleで登録
              </RoundedButton>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_google.png")}
                backgroundColor={themeColor.white}
                textColor={themeColor.black}
                onPress={async () => {
                  await this.register("google.com");
                }}
              >
                Googleで登録
              </RoundedButton>
              <Spacer vertical space={10}></Spacer>
              <RoundedButton
                leftImage={require("@assets/images/logo_twitter.png")}
                backgroundColor={themeColor.white}
                textColor={themeColor.black}
                onPress={async () => {
                  await this.register("twitter.com");
                }}
              >
                Twitterで登録
              </RoundedButton>
              <Spacer vertical space={40}></Spacer>
              <TextLabel boldWeight fontSize={"small"} color={themeColor.mainBlack}>
                ilksアカウントを登録
              </TextLabel>
              <Spacer vertical space={10}></Spacer>
              <RoundedInput
                showShadow
                placeholder="ハンドルネーム"
                backgroundColor={themeColor.white}
                value={this.name}
                onChangeText={(value) => {
                  this.name = value;
                }}
              ></RoundedInput>
              <Spacer vertical space={10}></Spacer>
              <RoundedInput
                showShadow
                placeholder="メールアドレス"
                backgroundColor={themeColor.white}
                value={this.mail}
                onChangeText={(value) => {
                  this.mail = value;
                }}
              ></RoundedInput>
              <Spacer vertical space={10}></Spacer>
              <RoundedInput
                type="password"
                showShadow
                placeholder="パスワード"
                backgroundColor={themeColor.white}
                value={this.password}
                onChangeText={(value) => {
                  this.password = value;
                }}
              ></RoundedInput>
              <Spacer vertical space={20}></Spacer>
            </Collapse>
            <RoundedButton
              onPress={async () => {
                await this.register("password");
              }}
            >
              アカウント登録
            </RoundedButton>
            <Spacer vertical space={20}></Spacer>
            <PlainTextButton
              onPress={async () => {
                await this.loginAnonymously();
              }}
            >
              ログインせずに続ける
            </PlainTextButton>
          </View>
        </KeyboardAwareScrollView>
      </LinearGradient>
    );
  }
}
export const LoginScene: SceneBase<type> & typeof scene = scene;
