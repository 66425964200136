import { Row, Text } from "native-base";
import React from "react";
import { ColorValue, View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { Tag } from "./Tag";

export interface Props {
  children: string;
  tagTitle: string;
  tagTitleBGColor?: ColorValue;
  tagTitleTextColor?: ColorValue;
  tagValueTextColor?: ColorValue;
  mini?: boolean;
}

export class TagLabel extends BaseComponent<Props> {
  tagTitleBGColor: ColorValue;
  tagTitleTextColor: ColorValue;
  tagValueTextColor: ColorValue;
  value: string;
  isMini: boolean;

  constructor(props: Props) {
    super(props);
    this.value = props.children;

    this.tagTitleBGColor = props.tagTitleBGColor ?? themeColor.main;
    this.tagTitleTextColor = props.tagTitleTextColor ?? themeColor.white;
    this.tagValueTextColor = props.tagValueTextColor ?? themeColor.mainText;
    this.isMini = this.props.mini ?? false;
  }

  renderComponent(): JSX.Element {
    return (
      <Row space={2}>
        <Tag mini={this.isMini} bgColor={this.tagTitleBGColor} textColor={this.tagTitleTextColor}>
          {this.props.tagTitle}
        </Tag>
        <View
          style={{
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <Text
            style={[
              styles.text,
              {
                fontSize: this.isMini ? 13 : 15,
                color: this.tagValueTextColor,
                marginRight: 10,
              },
            ]}
          >
            {this.value}
          </Text>
        </View>
      </Row>
    );
  }
}
