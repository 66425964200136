import { FirebaseFirestore } from "@firebase/firestore-types";
import { DocumentData, DocumentReference, DocumentSnapshot, FieldValue, firestore, Transaction, TransactionWriteBatch } from "./firestore";

export interface FirestoreKey {}

export interface FirestoreData extends DocumentData {
  [key: string]: unknown;
  _id?: string;
}
export abstract class FirestoreDocumentBase {}
export class FirestoreDocument<Key extends FirestoreKey, Data extends FirestoreData> extends FirestoreDocumentBase {
  static firestore(): FirebaseFirestore {
    return firestore;
  }

  static ref(_key: unknown): DocumentReference | undefined {
    return;
  }

  static serialize(data: FirestoreData, keys?: string[]): { [key: string]: unknown } {
    const serializedData: { [key: string]: unknown } = {};

    if (keys) {
      for (const key of keys) {
        serializedData[key] = data[key];
      }
    } else {
      for (const key in data) {
        if (key === "_id") {
          continue;
        }
        serializedData[key] = data[key];
      }
    }

    return serializedData;
  }

  static unserialize(data: FirestoreData): FirestoreData {
    return data;
  }

  static unserializeSnapshot(document: DocumentSnapshot<DocumentData>): FirestoreData | undefined {
    if (!document.exists) {
      return undefined;
    }

    const data = document.data() as FirestoreData;
    data._id = document.ref.id;
    return this.unserialize(data);
  }

  public get static(): typeof FirestoreDocument {
    return this.constructor as typeof FirestoreDocument;
  }

  public reference: DocumentReference | undefined;

  public get key(): Readonly<Key> | undefined {
    return this._key;
  }

  public set key(key: Readonly<Key> | undefined) {
    this._key = key;
    if (this._key) {
      this.reference = this.static.ref(this._key);
    } else {
      delete this.reference;
    }
  }

  public get id(): string {
    return this._data?._id || "";
  }

  public get data(): Data {
    return this._proxyData;
  }

  public static get defaultData(): FirestoreData {
    return {};
  }

  public get exist(): boolean {
    return this._exist;
  }

  protected _key?: Key;
  protected _data: Data = {} as Data;
  protected _proxyData: Data = {} as Data;
  protected _exist: boolean;
  protected _updatedKeys: string[] = [];
  protected _updatedAll = false;
  protected _cancelSnapshot?: () => void;

  constructor(key?: Key, data: Data | null = null, exist = false) {
    super();
    this.key = key;
    if (data === null) {
      data = this.static.defaultData as Data;
    }
    this.set(data);
    this._exist = exist;
  }

  public _initialize(reference: DocumentReference, data: Data, exist: boolean): void {
    this.reference = reference;
    data._id = this.reference.id;
    this.set(this.static.unserialize(data) as Data);
    this._exist = exist;
  }

  public _changeDocumentId(id: string): void {
    if (this.reference) {
      this.reference = this.reference.parent.doc(id);
      this.data._id = id;
    }
  }

  public async load(): Promise<void> {
    if (!this.reference) {
      return;
    }

    const snapshot = await this.reference.get();
    this._exist = snapshot.exists;
    if (this._exist) {
      this.set(this.static.unserializeSnapshot(snapshot) as Data);
    } else {
      this.set(this.static.defaultData as Data);
    }
  }

  public async save(force = false, transaction?: TransactionWriteBatch): Promise<unknown> {
    if (!this.reference) {
      return;
    }

    let result: unknown;
    if (this._exist && !force && !this._updatedAll) {
      result = await this._update(transaction);
    } else {
      result = await this._set(transaction);
    }

    return result;
  }

  protected async _set(transaction?: TransactionWriteBatch): Promise<unknown> {
    if (!this.reference || !this._data) {
      return;
    }

    const saveData = this.static.serialize(this._data);

    let result: unknown;
    if (transaction) {
      result = (transaction as Transaction).set(this.reference, saveData);
    } else {
      result = await this.reference.set(saveData);
    }

    this._updatedKeys = [];
    this._updatedAll = false;
    this._exist = true;

    return result;
  }

  protected async _update(transaction?: TransactionWriteBatch): Promise<unknown> {
    if (!this.reference || !this._data) {
      return;
    }

    if (!this._updatedKeys || this._updatedKeys.length === 0) {
      return;
    }

    const saveData = this.static.serialize(this._data, this._updatedKeys);

    let result: unknown;
    if (transaction) {
      result = (transaction as Transaction).update(this.reference, saveData);
    } else {
      result = await this.reference.update(saveData);
    }

    this._updatedKeys = [];
    this._updatedAll = false;
    this._exist = true;

    return result;
  }

  protected _setValue<T>(key: string, value: T): void {
    this._updatedKeys.push(key);
    if (value === undefined) {
      if (this._data && key in this._data) {
        (this._data as FirestoreData)[key] = FieldValue.delete();
      }
    } else {
      (this._data as FirestoreData)[key] = value;
    }
  }

  protected _getValue<T>(key: string, defaultValue?: T): T | undefined {
    if (!this._data || !(key in this._data)) {
      return defaultValue;
    }
    return this._data[key] as T;
  }

  public set(data: Data): void {
    this._data = data;
    this._proxyData = new Proxy(this._data, {
      set: (_target, prop, value) => {
        this._setValue(prop as string, value);
        return true;
      },
      deleteProperty: (_target, prop) => {
        this._setValue(prop as string, undefined);
        return true;
      },
    });

    this._updatedKeys = [];
    this._updatedAll = true;
  }

  public get(): Data {
    return this.data;
  }

  public async delete(transaction?: TransactionWriteBatch): Promise<unknown> {
    if (!this.reference || !this._exist) {
      return;
    }

    this._exist = false;

    let result: unknown;
    if (transaction) {
      result = (transaction as Transaction).delete(this.reference);
    } else {
      result = await this.reference.delete();
    }
    return result;
  }

  public onSnapshot(onSnapshot: (snapshot: DocumentSnapshot<DocumentData>) => void): void {
    if (!this.reference) {
      return;
    }

    this.cancelSnapshot();

    this._cancelSnapshot = this.reference.onSnapshot((snapshot: DocumentSnapshot<DocumentData>) => {
      this.set(this.static.unserializeSnapshot(snapshot) as Data);
      onSnapshot(snapshot);
    });
  }

  public cancelSnapshot(): void {
    if (this._cancelSnapshot) {
      this._cancelSnapshot();
      delete this._cancelSnapshot;
    }
  }
}
