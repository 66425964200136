import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import { Spacer } from "@core/Spacer";
import styles, { themeColor } from "@core/Styles";
import Slider from "@react-native-community/slider";
import { state } from "../Core/State";
import { GridChildView } from "./GridChildView";
import { Tag } from "./Tag";

export interface Props {
  style?: {};
  title?: string;
  minValue: number;
  maxValue: number;
  unit: string;
  description: string;
  fullWidthNeeded?: boolean;
  onChange: (value: number) => void;
  value: number;
  pointerEvents?: "box-none" | "none" | "box-only" | "auto";
}

export class SettingsSlider extends BaseComponent<Props> {
  @state value: number;
  valueChangeTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.value = this.props.value;
  }

  renderComponent(): JSX.Element {
    return (
      <GridChildView
        oneColumn={this.props.fullWidthNeeded}
        titleComponent={
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={[styles.h4, { flexShrink: 1 }]}>{this.props.title}</Text>
            <Tag bgColor={themeColor.main}>{`${this.value}${this.props.unit}`}</Tag>
          </View>
        }
      >
        <View
          style={[
            {
              flexDirection: "column",
              backgroundColor: themeColor.textArea,
              borderRadius: 10,
              padding: 20,
              alignContent: "center",
            },
          ]}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            onResponderReject={() => {
              return true;
            }}
          >
            <TouchableOpacity
              style={[styles.colorShadowDeep, { width: 40, height: 40, borderRadius: 20, backgroundColor: themeColor.main, justifyContent: "center", alignItems: "center" }]}
              onPress={() => {
                const value = this.value - 1 >= this.props.minValue ? this.value - 1 : this.props.minValue;
                this.value = value;

                this.actionAfterWaiting(() => this.props.onChange(value));
              }}
            >
              <Text style={[styles.textBold, { color: "white", fontSize: 18 }]}>－</Text>
            </TouchableOpacity>
            <Spacer space={10}></Spacer>
            <View
              style={{
                flex: 1,
                marginLeft: 10,
                marginRight: 10,
                alignItems: "stretch",
                justifyContent: "center",
              }}
            >
              <Slider
                style={{ height: 40 }}
                maximumTrackTintColor={themeColor.border}
                maximumValue={this.props.maxValue}
                minimumTrackTintColor={themeColor.main}
                minimumValue={this.props.minValue}
                step={1}
                thumbTintColor={themeColor.main}
                value={this.value}
                onStartShouldSetResponder={() => true}
                onMoveShouldSetResponder={() => true}
                onStartShouldSetResponderCapture={() => true}
                onMoveShouldSetResponderCapture={() => true}
                onSlidingComplete={(value) => {
                  if (this.valueChangeTimer) {
                    clearTimeout(this.valueChangeTimer);
                  }

                  this.props.onChange(value);
                }}
                onValueChange={(value) => {
                  this.value = value;
                  this.actionAfterWaiting(() => this.props.onChange(value));
                }}
              />
            </View>
            <Spacer space={10}></Spacer>
            <TouchableOpacity
              style={[styles.colorShadowDeep, { width: 40, height: 40, borderRadius: 20, backgroundColor: themeColor.main, justifyContent: "center", alignItems: "center" }]}
              onPress={() => {
                const value = this.value + 1 <= this.props.maxValue ? this.value + 1 : this.props.maxValue;
                this.value = value;

                this.actionAfterWaiting(() => this.props.onChange(value));
              }}
            >
              <Text style={[styles.textBold, { color: "white", fontSize: 18 }]}>＋</Text>
            </TouchableOpacity>
          </View>
          <Spacer vertical space={10}></Spacer>
          <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Tag>{`${this.props.minValue}${this.props.unit}`}</Tag>
            <Tag>{`${this.props.maxValue}${this.props.unit}`}</Tag>
          </View>
        </View>
        <View style={{ margin: 10 }}>
          <Text style={[styles.smallFont, { color: themeColor.subText }]}>{this.props.description}</Text>
        </View>
      </GridChildView>
    );
  }

  actionAfterWaiting(afterAction: () => void): void {
    if (this.valueChangeTimer) {
      clearTimeout(this.valueChangeTimer);
    }

    this.valueChangeTimer = setTimeout(() => {
      afterAction();
    }, 300);
  }
}
