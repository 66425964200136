import _ from "lodash";
import { Container } from "native-base";
import React from "react";
import { ActivityIndicator, Platform } from "react-native";
import { BaseComponent, ErrorType } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import { getWidthType, themeColor, widthType } from "@core/Styles";
import { StackNavigationProp } from "@react-navigation/stack";
import { Router } from "~/router";
import { AppContext, AppContextType } from "~/screens/Core/AppContext";
import { RootStackParamList } from "./Navigator";

export class BaseScene extends BaseComponent<unknown> {
  _navigation: StackNavigationProp<RootStackParamList>;
  @state widthTypeData: widthType;
  @sharedState currentScene!: BaseScene;
  navigation = {
    push: <name extends keyof RootStackParamList>(
      ...args: undefined extends RootStackParamList[name] ? [name] | [name, RootStackParamList[name]] : [name, RootStackParamList[name]]
    ): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._navigation.push(args[0] as any, args[1] as any);
      Log.debug("======push======");
      Router.push(...args);
    },
    navigate: <name extends keyof RootStackParamList>(
      ...args: undefined extends RootStackParamList[name] ? [name] | [name, RootStackParamList[name]] : [name, RootStackParamList[name]]
    ): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._navigation.navigate(args[0] as any, args[1] as any);
      Router.navigate(...args);
      Log.debug("====navigate====");
    },
    pop: (num?: number): void => {
      this._navigation.pop(num);
      Log.debug("=======pop======");
    },
    popToTop: (): void => {
      this._navigation.popToTop();
      Log.debug("====popToTop====");
    },
  };
  onFocusForNavigation = (): void => {
    const routes = this._navigation.dangerouslyGetState().routes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Router.setStack(routes[0].name, this._navigation as any);
    this.currentScene = this;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Log.event("scene_view", { scene: (this.constructor as any)?.sceneName || "None", page_title: (this.constructor as any)?.sceneTitle || "None" });
    // Router.push(routes[routes.length - 1].name, routes[routes.length - 1].params);
  };

  static transitionEndCallbacks: (() => void)[] = [];
  static addListenerTransitionEnd(callback: () => void): void {
    this.transitionEndCallbacks.push(callback);
  }
  static removeListenerTransitionEnd(callback: () => void): void {
    this.transitionEndCallbacks = this.transitionEndCallbacks.filter((v) => v !== callback);
  }
  static transitionEnd = (): void => {
    for (const callback of BaseScene.transitionEndCallbacks) {
      callback();
    }
  };

  onFocus?: () => void;
  onBlur?: () => void;

  onPop = (): void => {
    const routes = this._navigation.dangerouslyGetState().routes;
    Router.setStack(routes[0].name, this._navigation as any);
    // Router.push(routes[routes.length - 1].name, routes[routes.length - 1].params);
    Router.pop();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: { navigation: any }) {
    super(props);
    this._navigation = props.navigation;
    this.widthTypeData = getWidthType();
    const routes = props.navigation.dangerouslyGetState().routes;
    if (routes.length > 0 && routes[0]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Router.setStack(routes[0].name, props.navigation as any);
    }
    this.currentScene = this;
    this._navigation.addListener("focus", this.onFocusForNavigation);
    this._navigation.addListener("beforeRemove", this.onPop);
    this._navigation.addListener("transitionEnd", BaseScene.transitionEnd);
    if (this.onFocus) {
      this._navigation.addListener("focus", this.onFocus);
    }
    if (this.onBlur) {
      this._navigation.addListener("blur", this.onBlur);
    }
  }
  componentDidMount(): void {
    super.componentDidMount();
  }
  componentDidUpdate(): void {
    super.componentDidUpdate();
  }
  componentWillUnmount(): void {
    super.componentWillUnmount();
    this._navigation.removeListener("focus", this.onFocusForNavigation);
    this._navigation.removeListener("beforeRemove", this.onPop);
    this._navigation.removeListener("transitionEnd", BaseScene.transitionEnd);
    if (this.onFocus) {
      this._navigation.removeListener("focus", this.onFocus);
    }
    if (this.onBlur) {
      this._navigation.removeListener("blur", this.onBlur);
    }
  }

  protected onError(error: Error, _type: ErrorType): void {
    Log.error(error);
    this.navigation.pop();
  }

  static options(_p: unknown): unknown {
    return {
      title: this.sceneTitle,
    };
  }

  static get sceneTitle(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any)?.sceneName || "None Title";
  }
  protected renderLoading(): JSX.Element {
    return (
      <Container style={{ maxWidth: "100%", width: "100%", height: "100%", alignItems: "stretch", justifyContent: "center", backgroundColor: themeColor.background }}>
        <ActivityIndicator size="large" color={Platform.OS === "ios" ? "#999999" : themeColor.main} />
      </Container>
    );
  }

  _context?: { original: AppContextType; addedScene: AppContextType };
  render(): JSX.Element | JSX.Element[] {
    if (!this._context || !_.isEqual(this.context, this._context.original)) {
      if (this.context) {
        this._context = { original: this.context, addedScene: { ...this.context, scene: this } };
      } else {
        this._context = undefined;
      }
    }
    const ret = super.render();
    return <AppContext.Provider value={this._context?.addedScene ?? null}>{ret}</AppContext.Provider>;
  }
}
