// import * as firebase from 'firebase/app';
import "firebase/app";
// import 'firebase/storage';
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import * as AppleAuthentication from "expo-apple-authentication";
import Constants, { AppOwnership } from "expo-constants";
import * as Google from "expo-google-app-auth";
import * as GoogleSignIn from "expo-google-sign-in";
import firebase from "firebase";
import _ from "lodash";
import { Platform } from "react-native";
import { NativeModules } from "react-native";
import { initializeFunctions } from "~/functions/src/lib/functions";
import { UserDocument } from "./firestore/documents/user-documents";
import { initializeFirestore } from "./firestore/lib/firestore";

let crashlytics: typeof import("@react-native-firebase/crashlytics").default | undefined;
if ((Platform.OS === "ios" || Platform.OS === "android") && Constants.appOwnership !== AppOwnership.Expo) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
  crashlytics = (require("@react-native-firebase/crashlytics") as any).default;
}

const { RNTwitterSignIn } = NativeModules;

interface Confing {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}
class FirebaseUtility {
  firebaseConfig: Confing;
  app: firebase.app.App;
  // analytics: firebase.analytics.Analytics;
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  // storage: firebase.storage.Storage;
  functions: firebase.functions.Functions;

  firestoreFieldValue = firebase.firestore.FieldValue;
  firestoreFieldPath = firebase.firestore.FieldPath;

  get serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  increment(num: number) {
    return firebase.firestore.FieldValue.increment(num);
  }
  get serverId() {
    return firebase.firestore.FieldPath.documentId();
  }

  constructor(firebaseConfig: Confing) {
    this.firebaseConfig = firebaseConfig;
    // this.app = firebase.apps.length > 0 ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
    if (firebase.apps?.length || 0 > 0) {
      const app = firebase.apps.find((v) => _.isEqual(v.options, firebaseConfig));
      if (app) {
        this.app = app;
      } else {
        this.app = firebase.initializeApp(firebaseConfig);
      }
    } else {
      this.app = firebase.initializeApp(firebaseConfig);
    }
    this.auth = firebase.auth(this.app);
    this.firestore = firebase.firestore(this.app);
    try {
      this.firestore.settings({ experimentalForceLongPolling: true });
    } catch (e) {
      console.log(e);
    }
    // this.storage = firebase.storage(this.app);
    this.functions = this.app.functions("asia-northeast1");
    // this.functions.useEmulator("localhost", 5001);
    initializeFunctions(this.functions);
    initializeFirestore(firebase.firestore, this.app);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.updateToken();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateTokenId?: any;
  nextUpdateTokenTime = 0;

  async onUserChange(): Promise<void> {
    if (crashlytics) {
      if (this.user) {
        await crashlytics().setUserId(this.user.uid);
        await crashlytics().setAttributes({
          email: this.user.email ?? "None",
          username: this.user.displayName ?? "None",
          platform: Platform.OS,
        });
        Log.event("user_change", {
          uid: this.user.uid,
          email: this.user.email ?? "None",
          username: this.user.displayName ?? "None",
          platform: Platform.OS,
        });
      } else {
        await crashlytics().setUserId("None");
        await crashlytics().setAttributes({
          email: "None",
          username: "None",
          platform: Platform.OS,
        });
        Log.event("user_change", {
          uid: "None",
          email: "None",
          username: "None",
          platform: Platform.OS,
        });
      }
    }
  }
  async updateToken(): Promise<void> {
    if (this.nextUpdateTokenTime > performance.now()) {
      return;
    }
    this.nextUpdateTokenTime = performance.now() + 45 * 60 * 1000;

    if (this.updateTokenId) {
      clearTimeout(this.updateTokenId);
      this.updateTokenId = null;
    }
    await this.user?.getIdToken(true);
    await this.updateLinked();
    this.updateTokenId = setTimeout(async () => {
      await this.updateToken();
    }, 50 * 60 * 1000); // 50minutes
  }
  get user(): firebase.User | null {
    return this.auth?.currentUser;
  }

  isEmailVerified(): boolean | null {
    const user = this.user;
    if (!user) {
      return null;
    }
    if (user.providerData.findIndex((v) => v?.providerId === "password") >= 0 && !user.emailVerified) {
      return false;
    }
    return true;
  }

  async signInAnonymously(): Promise<firebase.User | null> {
    try {
      const userCredential = await this.auth.signInAnonymously();
      this.linked = [];
      return userCredential.user;
    } catch (e) {
      console.error(e);
    }
    await this.onUserChange();
    return null;
  }

  async logout(): Promise<firebase.User | null> {
    try {
      await this.auth.signOut();
    } catch (e) {
      console.error(e);
    }
    await this.onUserChange();
    return null;
  }

  async signInSNS(id: "apple.com" | "google.com" | "twitter.com"): Promise<firebase.auth.UserCredential | null> {
    switch (id) {
      case "apple.com":
        return await this.signInApple();
      case "google.com":
        return await this.signInGoogle();
      case "twitter.com":
        return await this.signInTwitter();
    }
  }

  async signUpSNS(id: "apple.com" | "google.com" | "twitter.com"): Promise<firebase.auth.UserCredential | null> {
    switch (id) {
      case "apple.com":
        return await this.signUpApple();
      case "google.com":
        return await this.signUpGoogle();
      case "twitter.com":
        return await this.signUpTwitter();
    }
  }

  // #region CredentialでsignIn signUp
  async signInWithCredential(cred: firebase.auth.AuthCredential): Promise<firebase.auth.UserCredential | null> {
    const user = this.user;
    try {
      const result = await this.auth.signInWithCredential(cred);
      if (!result.user) {
        return null;
      }
      const userDocument = new UserDocument({ uid: result.user.uid });
      await userDocument.load();
      if (!userDocument.data.signedUp) {
        if (user) {
          await this.auth.updateCurrentUser(user);
        } else {
          await this.auth.signOut();
        }
        await result.user.delete();
        throw { code: "ilks-novel/no-account", message: "アカウントが登録されていません。" } as firebase.auth.Error;
      }
      userDocument.data.session = Date.now();
      await userDocument.save();
      await this.updateLinked();
      await this.onUserChange();
      return result;
    } catch (e) {
      const error = e as firebase.auth.AuthError;
      switch (error.code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return null;
        // case "auth/account-exists-with-different-credential": {
        //   if (error.credential && error.email) {
        //     let ret: firebase.auth.UserCredential | null;
        //     try {
        //       ret = await this.signInLink(error.credential, error.email);
        //     } catch (error) {
        //       throw { code: "ilks-novel/link", message: "連携に失敗しました。" } as firebase.auth.Error;
        //     }
        //     if (ret) {
        //       return ret;
        //     }
        //     throw error;
        //   }
        //   break;
        // }
        default: {
          const type: AuthErrorType = "signin";
          e.AuthErrorType = type;
          throw error;
        }
      }
    }
  }
  async signUpWithCredential(cred: firebase.auth.AuthCredential, user: { name: string; email: string; icon?: string }): Promise<firebase.auth.UserCredential | null> {
    const orginalFirebaseUser = this.user;
    try {
      if (!this.user) {
        await this.signInAnonymously();
      }
      const firebaseUser = this.user;
      if (!firebaseUser) {
        throw { code: "ilks-novel/initialize-account", message: "アカウントの初期化に失敗しました。" } as firebase.auth.Error;
      }
      const result = await firebaseUser.linkWithCredential(cred);

      if (!result.user) {
        return result;
      }
      await result.user.updateProfile({ displayName: user.name });

      const userDocument = new UserDocument({ uid: result.user.uid });
      await userDocument.load();
      userDocument.data.session = Date.now();
      userDocument.data.signedUp = true;
      userDocument.data.name = userDocument.data.name || user.name;
      userDocument.data.email = userDocument.data.email || user.email;

      await userDocument.save();
      if (cred.providerId === "email") {
        await result.user.sendEmailVerification();
      }

      await this.updateLinked(true);
      await this.onUserChange();
      return result;
    } catch (e) {
      const error = e as firebase.auth.AuthError;
      const type: AuthErrorType = "signup";
      e.AuthErrorType = type;
      switch (error.code) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          if (!orginalFirebaseUser) {
            await this.auth.signOut();
          }
          return null;
        default:
          if (!orginalFirebaseUser) {
            await this.auth.signOut();
          }
          throw error;
      }
    }
  }
  // #endregion

  // #region Eメール&パスワード
  async signInEmailAndPassword(mail: string, password: string): Promise<firebase.auth.UserCredential | null> {
    const credential = firebase.auth.EmailAuthProvider.credential(mail, password);
    return await this.signInWithCredential(credential);
  }

  async signUpEmailAndPassword(name: string, email: string, password: string): Promise<firebase.auth.UserCredential | null> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return await this.signUpWithCredential(credential, { name, email });
  }
  // #endregion

  // #region Google SNS ソーシャルログイン
  async signInGoogle(): Promise<firebase.auth.UserCredential | null> {
    const credential = await this.getGoogleCredential();
    return credential ? this.signInWithCredential(credential.cred) : null;
  }
  async signUpGoogle(): Promise<firebase.auth.UserCredential | null> {
    const credential = await this.getGoogleCredential();
    return credential ? this.signUpWithCredential(credential.cred, credential.user) : null;
  }
  // #endregion

  // #region Apple SNS ソーシャルログイン
  async signInApple(): Promise<firebase.auth.UserCredential | null> {
    if (Platform.OS === "web") {
      console.log("signInApple is not supported on platform web!!!!!");
      throw Error("signInApple is not supported on platform web!!!!!");
    }
    const credential = await this.getAppleCredential();
    return credential ? this.signInWithCredential(credential.cred) : null;
  }
  async signUpApple(): Promise<firebase.auth.UserCredential | null> {
    if (Platform.OS === "web") {
      console.log("signUpApple is not supported on platform web!!!!!");
      throw Error("signUpApple is not supported on platform web!!!!!");
    }
    const credential = await this.getAppleCredential();
    return credential ? this.signUpWithCredential(credential.cred, credential.user) : null;
  }
  // #endregion

  // #region Twitter ソーシャルログイン
  async signInTwitter(): Promise<firebase.auth.UserCredential | null> {
    const credential = await this.getTwitterCredential();
    return credential ? this.signInWithCredential(credential.cred) : null;
  }
  async signUpTwitter(): Promise<firebase.auth.UserCredential | null> {
    const credential = await this.getTwitterCredential();
    return credential ? this.signUpWithCredential(credential.cred, credential.user) : null;
  }
  // #endregion

  // #region SNS OAuth取得
  async getGoogleCredential(): Promise<AuthCredential | null> {
    if (Platform.OS === "web") {
      const provider = new firebase.auth.GoogleAuthProvider();
      return await this.getFirebaseAuthCredential(provider);
    }
    let accessToken: string;
    let idToken: string | null = null;
    const user: { name: string; email: string; icon: string | undefined } = { name: "", email: "", icon: undefined };
    if (Constants.appOwnership === AppOwnership.Expo) {
      try {
        const result = await Google.logInAsync({
          iosClientId: `103653660734-tr2ibl4fq0s3n5bejfi3jr6483k9laug.apps.googleusercontent.com`,
          androidClientId: `103653660734-kv83haaquvp9rt6688d4i90sge8enmtd.apps.googleusercontent.com`,
        });
        if (result.type === "success" && result.idToken && result.accessToken) {
          idToken = result.idToken;
          accessToken = result.accessToken;
        } else {
          if (result.type === "cancel") {
            return null;
          }
          console.error("expo login: Error:" + result.type);
          return null;
        }
      } catch (e) {
        console.error("expo login: Error:" + e);
        throw e;
      }
    } else {
      try {
        await GoogleSignIn.initAsync();

        // await GoogleSignIn.signInSilentlyAsync();
        await GoogleSignIn.askForPlayServicesAsync();

        const { type, user: googleUser } = await GoogleSignIn.signInAsync();
        user.name = googleUser?.displayName ?? "";
        user.email = googleUser?.email ?? "";
        user.icon = googleUser?.photoURL;
        if (type === "success" && googleUser?.auth && googleUser.auth.accessToken) {
          accessToken = googleUser.auth.accessToken;
          idToken = googleUser.auth.idToken ?? null;
        } else {
          if (type === "cancel") {
            return null;
          }
          console.error("standalone login: Error:" + type);
          return null;
        }
      } catch (e) {
        console.error("standalone login: Error:" + e);
        return null;
      }
    }
    if (accessToken) {
      return { cred: firebase.auth.GoogleAuthProvider.credential(idToken, accessToken), user };
    }
    return null;
  }

  async getAppleCredential(): Promise<AuthCredential | null> {
    if (Platform.OS === "web") {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      return await this.getFirebaseAuthCredential(provider);
    }
    let accessToken: string | null = null;
    let idToken: string | null = null;
    const user: { name: string; email: string; icon: string | undefined } = { name: "", email: "", icon: undefined };
    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [AppleAuthentication.AppleAuthenticationScope.FULL_NAME, AppleAuthentication.AppleAuthenticationScope.EMAIL],
      });
      if (credential.identityToken && credential.authorizationCode) {
        idToken = credential.identityToken;
        accessToken = credential.authorizationCode;
      }
      user.name = credential.fullName?.nickname ?? credential.email ?? "";
      user.email = credential.email ?? "";
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        return null;
      } else {
        throw e;
      }
    }

    if (idToken && accessToken) {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      return { cred: provider.credential({ idToken: idToken }), user };
    }
    return null;
  }

  async twitterInit(): Promise<void> {
    if (Platform.OS === "ios") {
      RNTwitterSignIn.init("JAQzfsNC7F8NKncvgiKgDCVo7", "zX59k3qLWNwttinKBdUpkLBU7GfNJ07G7KMtXjM8e994CGvNUF");
      await new Promise((resolve) => setTimeout(resolve, 400));
    } else {
      await RNTwitterSignIn.init("JAQzfsNC7F8NKncvgiKgDCVo7", "zX59k3qLWNwttinKBdUpkLBU7GfNJ07G7KMtXjM8e994CGvNUF");
    }
  }
  twitterLogin() {
    if (Platform.OS === "ios") {
      // const promise = new Promise<{ authToken: string; authTokenSecret: string; name: string; userName: string; email: string }>((resolve) => {
      //   const listener = (event: { url: string }) => {
      //     const url = event.url;
      //     if (url && typeof url === "string" && url.startsWith("twitterkit-JAQzfsNC7F8NKncvgiKgDCVo7://")) {
      //       const query = url.substring("twitterkit-JAQzfsNC7F8NKncvgiKgDCVo7://".length);
      //       const params = queryString.parse(query);
      //       resolve({
      //         authToken: params.token as string,
      //         authTokenSecret: params.secret as string,
      //         name: params.username as string,
      //         userName: params.username as string,
      //         email: params.email as string,
      //       });
      //     }
      //     Linking.removeEventListener("url", listener);
      //   };
      //   Linking.addEventListener("url", listener);
      // });
      // RNTwitterSignIn.logIn();
      // return promise;
      return RNTwitterSignIn.logIn();
    } else {
      return RNTwitterSignIn.logIn();
    }
  }
  async getTwitterCredential(): Promise<AuthCredential | null> {
    if (Platform.OS === "web") {
      const provider = new firebase.auth.TwitterAuthProvider();
      return await this.getFirebaseAuthCredential(provider);
    }
    // API Key
    // JAQzfsNC7F8NKncvgiKgDCVo7
    // API Secret Key
    // zX59k3qLWNwttinKBdUpkLBU7GfNJ07G7KMtXjM8e994CGvNUF
    // Bearer Token
    // AAAAAAAAAAAAAAAAAAAAAIAOQQEAAAAAURCNSVIjldn6gZbHIZFRUEwTGJ0%3Dojhis9K7XlV7gYy3aPZXnjPoDUvuLSENlOU8L3QbEXedMoZMgO
    await this.twitterInit();
    const result = await this.twitterLogin();

    const { authToken, authTokenSecret, userName, email } = result;
    const twitterCredential = firebase.auth.TwitterAuthProvider.credential(authToken, authTokenSecret);
    return { cred: twitterCredential, user: { name: userName, email: email } };
  }

  async getFirebaseAuthCredential(provider: firebase.auth.AuthProvider): Promise<AuthCredential | null> {
    const user = this.user;
    const result = await this.auth.signInWithPopup(provider);
    if (user) {
      await this.auth.updateCurrentUser(user);
    } else {
      await this.auth.signOut();
    }
    if (!result.user) {
      return null;
    }
    const userDocument = new UserDocument({ uid: result.user.uid });
    await userDocument.load();
    if (!userDocument.data.signedUp) {
      await result.user.delete();
    }
    await userDocument.save();
    if (result.credential) {
      return {
        cred: result.credential,
        user: {
          name: result.user.displayName ?? "",
          email: result.user.email ?? "",
          icon: result.user.photoURL ?? undefined,
        },
      };
    }
    return null;
  }
  // #endregion

  // #region 連携

  async unlink(id: ProviderId) {
    try {
      const user = await this.login();
      await user?.unlink(id);
      await this.updateLinked(true);
    } catch (e) {
      const type: AuthErrorType = "unlink";
      e.AuthErrorType = type;
      throw e;
    }
  }

  linked: string[] = [];
  async updateLinked(force?: boolean): Promise<string[]> {
    const mail = (await this.login())?.email ?? "";
    if (!mail) {
      return [];
    }
    if (!this.auth.currentUser) {
      return [];
    }

    const userDocument = new UserDocument({ uid: this.auth.currentUser.uid });
    await userDocument.load();
    if (force || userDocument.data.linked === undefined) {
      this.linked = await this.auth.fetchSignInMethodsForEmail(mail);
      userDocument.data.linked = this.linked;
      await userDocument.save();
      return this.linked;
    }
    this.linked = userDocument.data.linked;
    return this.linked;
  }
  isLinked(id: ProviderId): boolean {
    return this.linked.some((v) => v === id);
  }
  getProvider(id: ProviderId): firebase.auth.AuthProvider | null {
    switch (id) {
      case "password":
        return new firebase.auth.EmailAuthProvider();
      case "apple.com":
        return new firebase.auth.OAuthProvider("apple.com");
      case "google.com":
        return new firebase.auth.GoogleAuthProvider();
      case "twitter.com":
        return new firebase.auth.TwitterAuthProvider();
      default:
        return null;
    }
  }

  // #endregion

  loginTask?: Promise<void>;
  async login(force = false): Promise<firebase.User | null> {
    if (this.auth.currentUser) {
      await this.updateToken();
      if (force) {
        const userDocument = new UserDocument({ uid: this.auth.currentUser.uid });
        await userDocument.load();
        userDocument.data.session = Date.now();
        await userDocument.save();
        await this.updateLinked();
      }
      return this.auth.currentUser;
    }
    if (this.loginTask) {
      await this.loginTask;
      return this.auth.currentUser;
    }

    this.loginTask = new Promise<void>((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userDocument = new UserDocument({ uid: user.uid });
          await userDocument.load();
          userDocument.data.session = Date.now();
          await userDocument.save();
          await this.updateLinked();
          await this.onUserChange();
        } else {
          await this.onUserChange();
          // await this.signInAnonymously();
        }
        unsubscribe();
        resolve();
      });
    });
    await this.loginTask;
    return this.auth.currentUser;
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      return await this.auth.sendPasswordResetEmail(email);
    } catch (e) {
      const type: AuthErrorType = "sendmail";
      e.AuthErrorType = type;
      throw e;
    }
  }

  AuthErrorToJapaneseMessage(e: firebase.FirebaseError, type?: AuthErrorType): string {
    if (!type) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type = ((e as any)?.AuthErrorType as AuthErrorType) ?? "signin";
    }
    switch (e.code) {
      case "auth/cancelled-popup-request":
      case "auth/popup-closed-by-user":
        return "";
      case "auth/email-already-in-use":
        if (type === "signup") {
          return "このメールアドレスは使用されています";
        } else {
          return "メールアドレスまたはパスワードが違います";
        }
      case "auth/invalid-email":
        return "メールアドレスの形式が正しくありません";
      case "auth/user-disabled":
        return "サービスの利用が停止されています";
      case "auth/user-not-found":
        return "メールアドレスまたはパスワードが違います";
      case "auth/user-mismatch":
        if (type === "signup") {
          return "認証されているユーザーと異なるアカウントが選択されました";
        } else {
          return "メールアドレスまたはパスワードが違います";
        }
      case "auth/weak-password":
        return "パスワードは6文字以上にしてください";
      case "auth/wrong-password":
        return "メールアドレスまたはパスワードが違います";
      case "auth/popup-blocked":
        return "認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください";
      case "auth/operation-not-supported-in-this-environment":
      case "auth/auth-domain-config-required":
      case "auth/operation-not-allowed":
      case "auth/unauthorized-domain":
        return "現在この認証方法はご利用頂けません";
      case "auth/requires-recent-login":
        return "認証の有効期限が切れています";
      case "auth/account-exists-with-different-credential":
        return "このメールアドレスは使用されています。ログイン後、連携を行ってください。";
      case "auth/no-such-provider":
        if (type === "unlink") {
          return "すでに連携解除されています。";
        } else {
          return "エラーが発生しました。しばらく時間をおいてお試しください";
        }
      case "auth/credential-already-in-use":
        return "アカウントがすでに登録されています。ログインを行ってください";
      // ここから勝手に拡張したタイプ
      case "ilks-novel/no-account":
        return "アカウントが登録されていません。新規登録してください";
      case "ilks-novel/initialize-account":
        return "アカウントの初期化に失敗しました。";
      case "ilks-novel/cancel":
        return "";
      default:
        if (type === "signin") {
          return "認証に失敗しました。しばらく時間をおいて再度お試しください";
        } else {
          return "エラーが発生しました。しばらく時間をおいてお試しください";
        }
    }
  }
}

export const Firebase = new FirebaseUtility({
  apiKey: "AIzaSyAKbvNOHWLCXQ0S_hznRHKzHI9Gc9u56ow",
  authDomain: "novel-app-695ce.firebaseapp.com",
  projectId: "novel-app-695ce",
  storageBucket: "novel-app-695ce.appspot.com",
  messagingSenderId: "103653660734",
  appId: "1:103653660734:web:a7f6e48044446202f4196f",
  measurementId: "G-XP9187XFBH",
});
export type FieldValue = firebase.firestore.FieldValue;
export type FieldPath = firebase.firestore.FieldPath;

export type AuthErrorType = "signin" | "signup" | "unlink" | "sendmail" | "changemail";
export type ProviderId = "password" | "apple.com" | "google.com" | "twitter.com";
type AuthCredential = {
  cred: firebase.auth.AuthCredential;
  user: {
    name: string;
    email: string;
    icon?: string;
  };
};
