import React, { createRef } from "react";
import { ColorValue, Dimensions, Platform, StyleSheet, View } from "react-native";
import { ScrollView, TouchableWithoutFeedback } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import BottomSheet from "reanimated-bottom-sheet";
import { BaseComponent } from "@core/BaseComponent";
import { CurrentReaderData } from "@core/EpisodeReader";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import styles, { themeColor } from "@core/Styles";
import { UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { Episode } from "../BookSummary/BookSummaryScene";
import { TextLabel } from "./TextLabel";

const AnimatedView = Animated.View;

export interface Props {
  children?: JSX.Element;
  shrinkedContents: JSX.Element;
  footerItem: JSX.Element;
  expandedHeaderBGColor?: ColorValue;
  footerItemHeight?: number;
  simultaneousHandlers?: React.RefObject<any> | React.RefObject<any>[];
  onSnap?: () => void;
}

export class ScrollableSnapView extends BaseComponent<Props> {
  @state currentEpisode?: Episode & { type: "ilks" | "other" } = {};
  @state isEpisodeReaderMenuShow = true;
  @sharedState currentReaderData?: CurrentReaderData;
  @state imageUrl?: string;
  @firestoreState userDocument?: UserDocument;
  @sharedState avoidFooterBottomSheetSnap?: boolean = false;

  enabledInnerScrolling = false;

  @sharedState({ notify: false }) isFooterBottomSheetOpen = false;
  shrinkedContentsHeight = 50;
  height = this.shrinkedContentsHeight;

  windowHeight = Dimensions.get("window").height;
  snapPoints = [this.shrinkedContentsHeight, "100%"];

  bottomSheetRef = React.createRef<BottomSheet>();
  fall = new Animated.Value(1);

  footerItemPosition = Animated.interpolateNode(this.fall, {
    inputRange: [0.5, 1],
    outputRange: [-this.height, 0],
    extrapolate: Animated.Extrapolate.CLAMP,
  });

  footerItemOpacity = Animated.interpolateNode(this.fall, {
    inputRange: [0.5, 1],
    outputRange: [0, 1],
    extrapolate: Animated.Extrapolate.CLAMP,
  });

  scrollViewRef = createRef<ScrollView>();

  constructor(props: Props) {
    super(props);

    this.height = this.currentEpisode?.link ? this.shrinkedContentsHeight + (this.props.footerItemHeight ?? 0) : this.props.footerItemHeight ?? 0;

    this.snapPoints = [this.height, this.windowHeight + this.shrinkedContentsHeight];
  }

  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
  }

  openEpisode(episode: Episode & { type: "ilks" | "other" }, coverImageUrl?: string, isOpenSheet = true): void {
    this.currentEpisode = episode;

    this.imageUrl = coverImageUrl ?? "";

    // const getImage = async () => {
    //   const cover = await getCover(this.currentReaderData?.bookTitle ?? "", this.currentReaderData?.authorName ?? "", this.currentReaderData?.bookId ?? "");
    //   if (cover) {
    //     this.imageUrl = cover;
    //   }
    // };

    // void getImage();

    if (isOpenSheet) {
      if (Platform.OS === "web") {
        window.open(this.currentEpisode.link, "_blank", "noreferrer");
      } else {
        this.bottomSheetRef.current?.snapTo(1);
      }
    }
  }
  componentWillUnmount(): void {
    this.isFooterBottomSheetOpen = false;
    super.componentWillUnmount();
  }
  renderLoading(): JSX.Element[] {
    const safeAreaInsetsBottom = this.context?.safeAreaInsets?.bottom ?? 0;

    return [
      <View key={0} pointerEvents="box-none" style={{ width: "100%", height: "100%", position: "absolute" }}>
        <AnimatedView
          style={{ opacity: this.footerItemOpacity, width: "100%", height: this.props.footerItemHeight ?? 0, position: "absolute", zIndex: 200, bottom: this.footerItemPosition }}
        >
          <View style={{ width: "100%", zIndex: 2, bottom: safeAreaInsetsBottom }}>{this.props.footerItem}</View>
        </AnimatedView>
      </View>,
      <View key={1} pointerEvents="none" style={{ width: "100%", height: this.height + safeAreaInsetsBottom }}></View>,
    ];
  }

  renderComponent(): JSX.Element[] {
    const safeAreaInsetsBottom = this.context?.safeAreaInsets?.bottom ?? 0;

    this.height = this.shrinkedContentsHeight;

    this.snapPoints = [0, this.height + safeAreaInsetsBottom, 400 + safeAreaInsetsBottom, this.windowHeight - (this.context?.safeAreaInsets?.top ?? 0)];

    return [
      <View key={0} pointerEvents="box-none" style={{ width: "100%", height: "100%", position: "absolute" }}>
        <AnimatedView
          style={{
            opacity: this.footerItemOpacity,
            width: "100%",
            height: this.props.footerItemHeight ?? 0,
            position: "absolute",
            zIndex: 200,
            bottom: this.footerItemPosition,
          }}
        >
          <View style={{ width: "100%", zIndex: 2, bottom: safeAreaInsetsBottom }}>{this.props.footerItem}</View>
        </AnimatedView>
        <BottomSheet
          ref={this.bottomSheetRef}
          onOpenStart={() => {
            this.isFooterBottomSheetOpen = true;
            this.isScroll = true;
            // this.props.onSnap?.();
          }}
          onCloseStart={() => {
            // this.props.onSnap?.();
          }}
          onCloseEnd={() => {
            this.isFooterBottomSheetOpen = false;
            this.props.onSnap?.();
          }}
          onOpenEnd={() => {
            this.isScroll = true;
            // this.props.onSnap?.();
          }}
          initialSnap={0}
          callbackNode={this.fall}
          snapPoints={this.snapPoints}
          renderHeader={this.renderHeader}
          renderContent={this.renderContent}
          enabledBottomClamp
          simultaneousHandlers={this.props.simultaneousHandlers}
          enabledInnerScrolling={true}
          enabledGestureInteraction={true}
          enabledContentGestureInteraction={false}
          enabledContentTapInteraction={false}
        />
      </View>,
      <View key={1} pointerEvents="none" style={{ width: "100%", height: this.height + safeAreaInsetsBottom }}></View>,
    ];
  }

  snapTo(index: number): void {
    this.bottomSheetRef.current.snapTo(index);
  }

  animatedHeaderContentOpacity = Animated.interpolateNode(this.fall, {
    inputRange: [0.75, 1],
    outputRange: [0, 1],
    extrapolate: Animated.Extrapolate.CLAMP,
  });

  onHeaderPress = () => {
    if (Platform.OS === "web") return;
    this.bottomSheetRef.current?.snapTo(2);
  };

  @state isScroll = false;

  renderContent = () => {
    const animatedContentOpacity = Animated.interpolateNode(this.fall, {
      inputRange: [0.6, 0.8],
      outputRange: [1, 0],
      extrapolate: Animated.Extrapolate.CLAMP,
    });

    const option = this.userDocument?.data.option;
    let menuDirection: "column" | "row" | "row-reverse";

    if (option) {
      if (option.scrollType === "scroll") {
        if (option.textDirection === "vertical") {
          menuDirection = "row-reverse";
        } else {
          menuDirection = "column";
        }
      } else {
        if (option.scrollType === "pagingVertical") {
          if (option.textDirection === "vertical") {
            menuDirection = "column";
          } else {
            menuDirection = "column";
          }
        } else {
          if (option.textDirection === "vertical") {
            menuDirection = "row-reverse";
          } else {
            menuDirection = "row";
          }
        }
      }
    } else {
      menuDirection = "row";
    }

    return (
      <AnimatedView style={[this.footerStyles.contentContainer, {}]}>
        <AnimatedView style={[this.footerStyles.contentBackground, { opacity: 1 }]} />

        <AnimatedView style={{ opacity: animatedContentOpacity }}>
          <AnimatedView
            style={{
              top: 0,
              height: Animated.add(Animated.sub(this.height, this.windowHeight + this.height), 0),
              opacity: 1,
            }}
          />
          <View style={{ width: "100%", minHeight: "100%", elevation: -10, position: "absolute" }}></View>
          <View style={{ width: "100%", minHeight: "100%", elevation: 0 }}>{this.props.children}</View>
        </AnimatedView>
      </AnimatedView>
    );
  };

  renderHeader = () => {
    const animatedContentOpacity = Animated.interpolateNode(this.fall, {
      inputRange: [0.6, 0.8],
      outputRange: [1, 0.75],
      extrapolate: Animated.Extrapolate.CLAMP,
    });
    const animatedContentRadius = Animated.interpolateNode(this.fall, {
      inputRange: [0.3, 1],
      outputRange: [15, 0],
      extrapolate: Animated.Extrapolate.CLAMP,
    });
    const animatedShadowOpacity = Animated.interpolateNode(this.fall, {
      inputRange: [0.2, 1],
      outputRange: [0.2, 0.03],
      extrapolate: Animated.Extrapolate.CLAMP,
    });
    const animatedShadowRadius = Animated.interpolateNode(this.fall, {
      inputRange: [0.2, 1],
      outputRange: [10, 5],
      extrapolate: Animated.Extrapolate.CLAMP,
    });

    const animatedHandlerOpacity = Animated.interpolateNode(this.fall, {
      inputRange: [0, 0.05],
      outputRange: [0, 1],
      extrapolate: Animated.Extrapolate.CLAMP,
    });

    return [
      <TouchableWithoutFeedback key={"header-container"} onPress={this.onHeaderPress}>
        <AnimatedView
          style={[
            this.footerStyles.headerContainer,
            {
              opacity: animatedContentOpacity,
              shadowColor: themeColor.colorShadow,
              shadowOffset: {
                width: 0,
                height: Platform.OS === "web" ? -3 : -10,
              },
              shadowOpacity: Platform.OS === "web" ? 0.2 : animatedShadowOpacity,
              shadowRadius: Platform.OS === "web" ? 5 : animatedShadowRadius,
            },
          ]}
        >
          <AnimatedView
            style={{
              height: this.currentEpisode?.link ? this.shrinkedContentsHeight : 0,
              overflow: "hidden",
              borderTopLeftRadius: animatedContentRadius,
              borderTopRightRadius: animatedContentRadius,
            }}
          ></AnimatedView>
          <AnimatedView
            style={[
              {
                backgroundColor: this.props.expandedHeaderBGColor ?? themeColor.background,
                width: "100%",
                opacity: 1,
                // opacity: 1,
                position: "absolute",
                zIndex: 1,
                overflow: "hidden",
                borderTopLeftRadius: animatedContentRadius,
                borderTopRightRadius: animatedContentRadius,
                borderWidth: 0.5,
                borderTopColor: themeColor.itemBGEmphasis,
                borderLeftColor: themeColor.itemBGEmphasis,
                borderRightColor: themeColor.itemBGEmphasis,
                borderBottomColor: "transparent",
                elevation: 0,
              },
              { height: this.height - (this.props.footerItemHeight ?? 0) },
            ]}
          >
            <View style={{ width: "100%", height: "100%", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <TextLabel boldWeight>設定</TextLabel>
            </View>
          </AnimatedView>
          <AnimatedView
            style={[
              this.footerStyles.headerBackground,
              {
                opacity: 1,
                position: "absolute",
                zIndex: 2,
              },
            ]}
          >
            {this.renderHandler()}
          </AnimatedView>
        </AnimatedView>
      </TouchableWithoutFeedback>,
    ];
  };

  renderShadow = () => {
    const animatedShadowOpacity = Animated.interpolateNode(this.fall, {
      inputRange: [0, 1],
      outputRange: [0.5, 0],
    });

    return (
      <AnimatedView
        pointerEvents="none"
        style={[
          this.footerStyles.shadowContainer,
          {
            opacity: animatedShadowOpacity,
          },
        ]}
      />
    );
  };

  renderHandler = () => {
    const animatedBar1Rotation = (outputRange: number[]) =>
      Animated.interpolateNode(this.fall, {
        inputRange: [0, 1],
        outputRange: outputRange,
        extrapolate: Animated.Extrapolate.CLAMP,
      });

    return (
      <View style={this.footerStyles.handlerContainer}>
        <AnimatedView
          style={[
            this.footerStyles.handlerBar,
            {
              left: -3.45,
              transform: [
                {
                  rotate: Animated.concat(animatedBar1Rotation([0.7, -0.7]), "rad"),
                },
              ],
            },
          ]}
        />
        <AnimatedView
          style={[
            this.footerStyles.handlerBar,
            {
              right: -3.45,
              transform: [
                {
                  rotate: Animated.concat(animatedBar1Rotation([-0.7, 0.7]), "rad"),
                },
              ],
            },
          ]}
        />
      </View>
    );
  };

  footerStyles = StyleSheet.create({
    // Screen
    container: {
      flex: 1,
      backgroundColor: "#fff",
    },

    // Shadow
    shadowContainer: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "#000",
    },

    // Content
    contentContainer: {
      alignItems: "stretch",
      height: this.snapPoints[this.snapPoints.length - 1],
      overflow: "visible",
    },

    contentBackground: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: this.props.expandedHeaderBGColor ?? themeColor.background,
    },

    // Header
    headerContainer: {
      height: this.snapPoints[0],
      width: "100%",
    },

    headerBackground: {
      height: 30,
      width: "100%",
    },

    headerActionButton: {
      justifyContent: "center",
      alignItems: "center",
      minHeight: 50,
      minWidth: 50,
    },

    // Handler
    handlerContainer: {
      position: "absolute",
      alignSelf: "flex-end",
      justifyContent: "center",
      height: this.shrinkedContentsHeight,
      width: 20,
      right: 20,
    },

    handlerBar: {
      position: "absolute",
      backgroundColor: themeColor.subText,
      borderRadius: 2,
      height: 4,
      width: 16,
    },
  });
}
