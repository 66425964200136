import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";

export interface GoogleBooksAPIResult {
  kind: string;
  totalItems: number;
  items: BookData[];
}
export interface BookData {
  kind: string;
  id: string;
  etag: string;
  selfLink: string;

  volumeInfo: {
    title: string;
    subtitle: string;
    authors: string[];
    categories?: string[];
    publisher?: string;
    publishedDate: string;
    description: string;
    industryIdentifiers: {
      type: string;
      identifier: string;
    }[];
    readingModes: {
      text: boolean;
      image: boolean;
    };
    pageCount: number;
    printType: string;
    maturityRating: string;
    allowAnonLogging: boolean;
    contentVersion: string;
    panelizationSummary: {
      containsEpubBubbles: boolean;
      containsImageBubbles: boolean;
    };
    imageLinks?: {
      thumbnail: string;
    };

    language: string;
    previewLink: string;
    infoLink: string;
    canonicalVolumeLink: string;
    seriesInfo?: {
      kind: string;
      shortSeriesBookTitle: string;
      bookDisplayNumber: string;
      volumeSeries?: [
        {
          seriesId: string;
          seriesBookType: string;
          orderNumber: number;
        }
      ];
    };
  };

  saleInfo: {
    country: string;
    saleability: string;
    isEbook: boolean;
  };
  accessInfo: {
    country: string;
    viewability: string;
    embeddable: boolean;
    publicDomain: boolean;
    textToSpeechPermission: string;
    epub: {
      isAvailable: boolean;
    };
    pdf: {
      isAvailable: boolean;
    };
    webReaderLink: string;
    accessViewStatus: string;
    quoteSharingAllowed: boolean;
  };
  searchInfo: {
    textSnippet: string;
  };
}

export class GoogleBooksUtility {
  // toFullWidth(input: string): string {
  //   return input.replace(/[!-~]/g, function (input) {
  //     return String.fromCharCode(input.charCodeAt(0) + 0xfee0);
  //   });
  // }

  toHalfWidth(input: string): string {
    return input.replace(/[！-～]/g, function (input) {
      return String.fromCharCode(input.charCodeAt(0) - 0xfee0);
    });
  }
  transform(text: string): string {
    // 半角にする
    text = this.toHalfWidth(text);

    // 記号を取り除く
    // Template:General Category (Unicode)
    // https://en.wikipedia.org/wiki/Template:General_Category_(Unicode)
    // 正規表現
    // https://blog.tes.co.jp/entry/2018/06/29/145450
    text = text.replace(/\p{P}|\p{S}|\p{Z}|\p{C}|\s/gu, "");
    // web版など、要らなそうな文字も取り除く
    text = text.replace("web版", "");

    // 一応、タイトルに数字も取り除く
    // タイトル 零 サブタイトル
    // とかがマッチするように
    // ヒットした作品タイトルの処理用なので分けた方がいいかも？
    text = text.replace(/零|一|二|三|四|五|六|七|八|九|十/g, "");
    return text;
  }

  async getBooks(title: string, nazoMode = false): Promise<GoogleBooksAPIResult> {
    const encoded_title = encodeURIComponent(title);
    // const url = "https://www.googleapis.com/books/v1/volumes?q=" + encoded_title + "&maxResults=40&printType=books";
    let url = "https://www.googleapis.com/books/v1/volumes?q=" + encoded_title + "&maxResults=40&printType=books";
    if (nazoMode) {
      // 謎文字列を入れると結果が変わることがある謎
      url = "https://www.googleapis.com/books/v1/volumes?q=" + encoded_title + "+orderNumber:1" + "&maxResults=40&printType=books";
    }

    const text = await (await fetch(url)).text();

    return JSON.parse(text) as GoogleBooksAPIResult;
  }

  async getBook(title: string, author: string, nazoMode = false): Promise<BookData | null> {
    title = this.transform(title);
    const result = await this.getBooks(title, nazoMode);

    const books = result.items.filter((book) => {
      if (!book.volumeInfo.imageLinks?.thumbnail) {
        return false;
      }
      if (this.transform(book.volumeInfo.title).indexOf(title) < 0) {
        return false;
      }
      const authors = author.split("/");
      if (!book.volumeInfo.authors) {
        return;
      }
      if (!book.volumeInfo.authors.some((googleAuthor) => authors.some((author) => this.transform(googleAuthor).indexOf(this.transform(author)) >= 0))) {
        return false;
      }
      return true;
    });
    const book = _.sortBy(books, "volumeInfo.publishedDate").find(() => true) ?? null;

    return book;
  }

  async getCover(title: string, author: string, key?: string, force = false): Promise<string | null> {
    try {
      if (key) {
        const cache = await AsyncStorage.getItem("cacheImage:" + key);
        if (!force && cache !== null) {
          return cache;
        }
      } else {
        const cache = await AsyncStorage.getItem("cacheImage:" + title + ":" + author);
        if (!force && cache !== null) {
          return cache;
        }
      }
      let book = await this.getBook(title, author);
      const orderNumber = book?.volumeInfo.seriesInfo?.volumeSeries?.find(() => true)?.orderNumber;
      if (!book || (orderNumber && orderNumber >= 2)) {
        const book2 = await this.getBook(title, author, true);
        const orderNumber2 = book2?.volumeInfo.seriesInfo?.volumeSeries?.find(() => true)?.orderNumber;
        if (!book || (orderNumber && orderNumber2 && orderNumber2 < orderNumber)) {
          book = book2;
        }
      }
      const cover = book?.id ? "https://books.google.com/books/content/images/frontcover/" + book.id + "?fife=w1000" : "";

      await AsyncStorage.setItem("cacheImage:" + title + ":" + author, cover);
      if (key) {
        await AsyncStorage.setItem("cacheImage:" + key, cover);
      }
      return cover;
    } catch (e) {
      Log.error(e);
      return null;
    }
  }

  async getCacheCover(key: string): Promise<string | null> {
    return await AsyncStorage.getItem("cacheImage:" + key);
  }
}

export const GoogleBooks = new GoogleBooksUtility();
