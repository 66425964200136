export interface HttpsCallableResult {
  readonly data: unknown;
}
export interface HttpsCallable {
  (data?: unknown): Promise<HttpsCallableResult>;
}
export interface HttpsCallableOptions {
  timeout?: number;
}
export interface Functions {
  useFunctionsEmulator(url: string): void;
  httpsCallable(name: string, options?: HttpsCallableOptions): HttpsCallable;
}

export let functions: Functions;

export function initializeFunctions(func: Functions): Functions {
  functions = func;
  return functions;
}

export interface FunctionRequest {}
export interface FunctionResponse {}

export async function callFirebaseFunction<T extends FunctionRequest, U extends FunctionResponse>(name: string, data: T): Promise<U | null> {
  const callable = functions.httpsCallable(name);
  try {
    const result = await callable(data);
    return result?.data as U;
  } catch (e) {
    console.error(e);
  }
  return null;
}
