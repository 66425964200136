import Constants, { AppOwnership } from "expo-constants";
import * as Analytics from "expo-firebase-analytics";
import { Platform } from "react-native";

let crashlytics: typeof import("@react-native-firebase/crashlytics").default | undefined;
if ((Platform.OS === "ios" || Platform.OS === "android") && Constants.appOwnership !== AppOwnership.Expo) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
  crashlytics = (require("@react-native-firebase/crashlytics") as any).default;
}

interface LogUtilityOption {
  showComponentChangeName: boolean;
  showComponentChangeValue: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export class LogUtility {
  option: LogUtilityOption;
  constructor(option: LogUtilityOption) {
    this.option = option;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(...args: any): void {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...args: any): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      try {
        await Analytics.logEvent("js_log", {
          value: args,
          platform: Platform.OS + (Constants.appOwnership === AppOwnership.Expo ? "-expo" : ""),
          is_expo: Constants.appOwnership === AppOwnership.Expo ? "expo" : "native",
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
    // eslint-disable-next-line no-console
    console.log(...args);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...args: any): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await Analytics.logEvent("js_warn", {
        value: args,
        platform: Platform.OS + (Constants.appOwnership === AppOwnership.Expo ? "-expo" : ""),
        is_expo: Constants.appOwnership === AppOwnership.Expo ? "expo" : "native",
      });
    })();

    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  error(message: string, name: string, params?: { [key: string]: string }): void;
  error(message: Error, name: string, params?: { [key: string]: string }): void;
  error(message: Error, params?: { [key: string]: string }): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: string | Error, nameOrParams?: string | { [key: string]: string }, _params?: { [key: string]: string }): void {
    let name: string | undefined;
    let params = _params;

    if (message instanceof Error) {
      name = message.name;
    }
    if (typeof nameOrParams === "string") {
      name = nameOrParams;
    } else {
      name = name ?? "Noname Error";
      params = nameOrParams;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (crashlytics) {
        if (typeof message === "object" && message && "name" in message && "message" in message) {
          if (params) {
            await crashlytics().setAttributes(params);
          }
          crashlytics().recordError(message, name);
        } else {
          crashlytics().recordError(new Error(message?.toString() ?? ""));
        }
      } else {
        await Analytics.logEvent("js_error", {
          value: message,
          params: params,
          platform: Platform.OS + (Constants.appOwnership === AppOwnership.Expo ? "-expo" : ""),
          is_expo: Constants.appOwnership === AppOwnership.Expo ? "expo" : "native",
        });
      }
    })();

    // eslint-disable-next-line no-console
    console.error(message, params);
  }
  event(name: string, properties?: { [key: string]: unknown }): void {
    // Firebase.analytics.logEvent("test");
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await Analytics.logEvent(name, {
        ...{
          platform: Platform.OS + (Constants.appOwnership === AppOwnership.Expo ? "-expo" : ""),
          is_expo: Constants.appOwnership === AppOwnership.Expo ? "expo" : "native",
        },
        ...(properties ?? {}),
      });
    })();
  }
}
export const Log = new LogUtility({ showComponentChangeName: false, showComponentChangeValue: false });
