interface WebViewFiles {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  html: { [key: string]: (() => any) | undefined };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  js: { [key: string]: (() => any) | undefined };
}

const _webViewFiles: WebViewFiles = { html: {}, js: {} };
_webViewFiles.html["episode"] = () => require(`@assets/webview/episode.html.txt`);
_webViewFiles.js["episode"] = () => require(`@assets/webview/episode.js.txt`);
_webViewFiles.html["parser"] = () => require(`@assets//webview/parser.html.txt`);
_webViewFiles.js["parser"] = () => require(`@assets//webview/parser.js.txt`);
_webViewFiles.html["search"] = () => require(`@assets//webview/search.html.txt`);
_webViewFiles.js["search"] = () => require(`@assets//webview/search.js.txt`);

export const webViewFiles = _webViewFiles;
