import { FirestoreCollection, FirestoreCollectionBase } from "~/utilities/firebase/firestore/lib/firestore-collection";
import { FirestoreData, FirestoreDocument, FirestoreDocumentBase, FirestoreKey } from "~/utilities/firebase/firestore/lib/firestore-document";
import { BaseComponent } from "./BaseComponent";
import { addSharedStateListener, countSharedStateListener, getSharedState, removeSharedStateListener, updateSharedState } from "./SharedState";

export async function addFirestoreStateListener<Key extends FirestoreKey, Document extends FirestoreDocumentBase, Collection extends FirestoreCollectionBase, Props>(
  target: BaseComponent<Props>,
  collectionOrDocument: { new (key: Key): Collection } | { new (key: Key): Document },
  key: Key,
  propertyKey_: string | Collection | Document | undefined
): Promise<void> {
  // とりあえずプロパティキーを文字に
  let propertyKey: string;
  if (typeof propertyKey_ === "string") {
    propertyKey = propertyKey_;
  } else {
    propertyKey = target.__firestoreStateLastPropertyKey ?? "";
  }
  // propertyKey→sharedStateKeyなかったら初期化
  if (!target.__watchFirestoreKeyMap) {
    target.__watchFirestoreKeyMap = {};
  }

  // すでにあるなら監視中なので、前の奴を止める
  if (target.__watchFirestoreKeyMap[propertyKey]) {
    const removeKeyString = target.__watchFirestoreKeyMap[propertyKey];
    delete target.__watchFirestoreKeyMap[propertyKey];
    removeFirestoreStateListener(target, removeKeyString);
  }

  // propertyKey→sharedStateKeyを追加
  const colOrDoc = (new collectionOrDocument(key) as unknown) as FirestoreCollection<FirestoreKey, FirestoreData> | FirestoreDocument<FirestoreKey, FirestoreData>;
  const docName = colOrDoc.constructor.name;
  const keyString = docName + JSON.stringify(key);
  target.__watchFirestoreKeyMap[propertyKey] = keyString;

  if (!getSharedState(keyString)) {
    await colOrDoc.load();
    // なんかわからんけど…わからん…
    if ("exist" in colOrDoc && !colOrDoc.exist) {
      updateSharedState(keyString, colOrDoc, false);
    } else {
      updateSharedState(keyString, colOrDoc, false);
      // JSONで一意なんか？
      let first = true;
      colOrDoc.onSnapshot(() => {
        if (!first) {
          updateSharedState(keyString, colOrDoc);
        }
        first = false;
      });
    }
  }

  addSharedStateListener(target, keyString);
}
export function removeFirestoreStateListener<T extends BaseComponent<P>, P>(target: T, key?: string): void {
  const keys = key !== undefined ? [key] : (Object.values(target.__watchFirestoreKeyMap ?? {}) as string[]);
  for (const key of keys) {
    const e = getSharedState(key) as FirestoreCollection<FirestoreKey, FirestoreData> | FirestoreDocument<FirestoreKey, FirestoreData>;
    removeSharedStateListener(target, key);
    if (countSharedStateListener(key) === 0) {
      e?.cancelSnapshot?.();
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function firestoreState<T extends BaseComponent<P>, P>(_: T, propertyKey: string, _propertyDescriptor?: PropertyDescriptor): any {
  const descriptor: PropertyDescriptor = {
    get: function (this: T) {
      this.__firestoreStateLastPropertyKey = propertyKey;
      if (!this.__watchFirestoreKeyMap) {
        this.__watchFirestoreKeyMap = {};
      }
      const key = this.__watchFirestoreKeyMap[propertyKey];
      return getSharedState(key);
    },
    enumerable: true,
  };
  return descriptor;
}
