import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { IconText } from "./IconText";

export interface Props {
  style?: {};
  height: number;
  children?: JSX.Element[];
}

export class TabBar extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={{
          backgroundColor: themeColor.background,
          borderTopColor: themeColor.border,
          borderTopWidth: 0.25,
          shadowColor: "#73A9E8",
          shadowOffset: {
            width: 0,
            height: -2,
          },
          shadowOpacity: 0.2,
          shadowRadius: 2,
        }}
      >
        <View
          style={{
            height: this.props.height,
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-around",
            alignContent: "stretch",
          }}
        >
          {this.props.children}
        </View>
        <View style={{ height: this.context.safeAreaInsets?.bottom ?? 0 }} />
      </View>
    );
  }
}

export interface TabBarButtonProps {
  name: string;
  showFrame?: boolean;
  icon?: string;
  onPress?: () => void;
}

export class TabBarButton extends BaseComponent<TabBarButtonProps> {
  constructor(props: TabBarButtonProps) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={{ flex: 1, height: "100%" }}>
        <TouchableOpacity style={{ padding: 5, height: "100%", alignSelf: "stretch", borderRadius: 0 }} onPress={this.props.onPress}>
          <View
            style={{
              flexGrow: 1,
              margin: 5,
              padding: this.props.showFrame === true ? 4 : 0,
              backgroundColor: this.props.showFrame === true ? themeColor.bar : "transparent",
              borderRadius: 5,
            }}
          >
            <IconText vertical iconWidth={28} iconHeight={28} space={4} icon={this.props.icon}>
              <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                <Text style={[styles.textBold, styles.verySmallFont, { color: themeColor.mainText }]}>{this.props.name}</Text>
              </View>
            </IconText>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export interface DrawerButtonProps {
  name: string;
  icon?: string;
  onPress?: () => void;
}

export class DrawerButton extends BaseComponent<DrawerButtonProps> {
  constructor(props: DrawerButtonProps) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={{ flex: 1, alignSelf: "stretch", backgroundColor: themeColor.bar, height: 50, marginHorizontal: 15, marginVertical: 5, borderRadius: 10, borderWidth: 0 }}>
        <TouchableOpacity onPress={this.props.onPress}>
          <View style={{ flexDirection: "row", width: "100%", height: "100%", alignItems: "center", padding: 10 }}>
            <IconText iconWidth={28} iconHeight={28} space={10} icon={this.props.icon}>
              <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                <Text style={[styles.textBold, styles.mediumFont, { color: themeColor.mainText }]}>{this.props.name}</Text>
              </View>
            </IconText>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
