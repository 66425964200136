import { Text } from "native-base";
import React from "react";
import { Button, StyleSheet, View } from "react-native";
import { BaseScene } from "@core/BaseScene";
import { Ionicons } from "@expo/vector-icons";
import { state } from "@core/State";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { Hello } from "./Hello";

type type = "Detail2";

class scene extends BaseScene {
  static sceneName: type = "Detail2";
  static Params: {
    text: string;
  };
  @state text: string;
  constructor(props: ScreenProp<type>) {
    super(props);
    this.text = props.route.params.text;
  }

  renderComponent(): JSX.Element {
    return (
      <View style={styles.container}>
        <Ionicons name="ios-globe" size={300} color="#333399" />
        <Button
          title="詳細へ"
          onPress={() => {
            this.navigation.push("Detail2", { text: "これは、globeのアイコンです。" });
          }}
        />
        <Text>{this.text}</Text>
        <Hello name="World" enthusiasmLevel={2} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
export const Detail2Scene: SceneBase<type> & typeof scene = scene;
