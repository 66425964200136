import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument, FirestoreKey } from "../lib/firestore-document";
import { ParserData } from "./parser-documents";

export interface UserKey extends FirestoreKey {
  uid: string;
}

export interface UserData extends FirestoreData {
  session?: number;
  signedUp?: boolean;
  role?: "owner";

  name: string;
  email: string;
  icon?: string;

  lastEpisode?: {
    bookId: string;
    episodeId: string;
    title: string;
    chapterTitle: string;
    subTitle: string;
    authorName: string;
    line: number;
    text: string;
  };
  option?: UserOption;
  parsers?: UserParsers;
  searchTabs?: SearchTab[];
  linked?: string[];
}

export interface SearchTab {
  name: string;
  url: string;
}
export interface UserOption {
  font: string;
  fontSize: number;
  fontColor: string;
  backgroundColor: string;
  rubyColor: string;
  autoRubyColor: string;
  rubyComplate: boolean;
  rubyReuse: boolean;

  textDirection: "vertical" | "horizontal";
  letterSpacing: number;
  lineSpacing: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;

  scrollType: "pagingVertical" | "pagingHorizontal" | "scroll";
  newPageLine: number; // 改ページとして扱う空行の個数

  showTitle: boolean;
  showSubtitle: boolean;
  showPageCount: boolean;
  showStatusBar: boolean;

  defaultPreset: boolean;
}

export interface UserParsers {
  [key: string]: UserParser;
}
export interface UserParser {
  id: string;
  added: number;
  enable: boolean;
  data: ParserData;
}

export const defaultSearchTabs = [
  { name: "小説家になろう", url: "https://syosetu.com/" },
  { name: "カクヨム", url: "https://kakuyomu.jp/" },
  { name: "ノクターン", url: "https://noc.syosetu.com/top/top/" },
  { name: "ノベルアップ+", url: "https://novelup.plus/" },
  { name: "ハーメルン", url: "https://syosetu.org/" },
];
export class UserCollection extends FirestoreCollection<FirestoreKey, UserData, UserDocument> {
  static ref(): CollectionReference<DocumentData> {
    return this.firestore().collection("users");
  }

  constructor(key: FirestoreKey) {
    super(UserDocument, key);
  }
}
export class UserDocument extends FirestoreDocument<UserKey, UserData> {
  userDocument: any;
  static ref(key: UserKey): DocumentReference<DocumentData> {
    return UserCollection.ref().doc(key.uid);
  }
}
