import _ from "lodash";
import React, { createRef } from "react";
import { Image, View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { state } from "@core/State";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { DraggingData } from "./DraggingItem";
import { addDroppableitem, DroppableItem, Rect, removeDroppableitem } from "./DragHandler";

export interface Props extends BaseProps {}

export class Trash extends BaseComponent<Props> implements DroppableItem {
  @state visible = false;
  @state hover = false;
  view = createRef<View>();
  x = 0;
  y = 0;
  width = 0;
  height = 0;

  onDragStart(): void {
    //
    this.visible = true;
  }
  onDragEnd(): void {
    this.hover = false;
    this.visible = false;
  }

  get droppableRect(): Rect {
    if (!this.visible) {
      return { x: 0, y: 0, width: 0, height: 0 };
    }
    return { x: this.x, y: this.y, width: this.width, height: this.height };
  }
  order = 3;
  enter(): void {
    this.hover = true;
  }
  leave(): void {
    this.hover = false;
  }

  drop(draggingData: DraggingData): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Bookshelf.delete(draggingData.data.map((v) => v.id));
  }

  componentDidMount(): void {
    super.componentDidMount();
    addDroppableitem(this);
  }
  componentWillUnmount(): void {
    removeDroppableitem(this);
    super.componentWillUnmount();
  }

  renderComponent(): JSX.Element {
    let opacity: number | undefined = undefined;
    if (this.hover) {
      opacity = 0.5;
    }
    if (!this.visible) {
      opacity = 0;
    }
    return (
      <View
        ref={this.view}
        onLayout={(event) => {
          this.view.current?.measure((x, y, width, height, pagex, pagey) => {
            this.x = pagex - 20;
            this.y = pagey - 20;
            this.width = width + 40;
            this.height = height + 40;
          });
        }}
      >
        <View style={{ opacity: opacity, flex: 1 }}>
          <Image style={{ width: 450 / 5, height: 450 / 5 }} resizeMode={"contain"} source={require("@assets/images/dustbox_base.png")}></Image>
          <Image
            style={{ position: "absolute", left: -180 / 5, top: -50 / 5 - (this.hover ? 20 : 0), width: 600 / 5, height: 225 / 5 }}
            resizeMode={"contain"}
            source={require("@assets/images/dustbox_cap.png")}
          ></Image>
        </View>
      </View>
    );
  }
}
