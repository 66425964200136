import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { BaseScene } from "@core/BaseScene";
import { sharedState } from "@core/SharedState";
import { Ionicons } from "@expo/vector-icons";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";

type type = "Detail";

class scene extends BaseScene {
  static sceneName: type = "Detail";
  static Params: undefined;
  @sharedState({ key: "aaaaaa" }) asd = "";
  constructor(props: ScreenProp<type>) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={styles.container}>
        <Text>これは、キューブのアイコンです。{this.asd}</Text>
        <Ionicons name="ios-cube" size={300} color="#333399" />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
export const DetailScene: SceneBase<type> & typeof scene = scene;
