import Clipboard from "expo-clipboard";
import _ from "lodash";
import { Container } from "native-base";
import React from "react";
import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { BaseScene } from "@core/BaseScene";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import styles, { themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BookshelfCollection } from "~/utilities/firebase/firestore/documents/bookshelf-documents";
import { GoogleBooks } from "~/utilities/GoogleBooksUtility";
import { MiniIconTitleButton } from "../Component/MiniIconTitleButton";
import { SimpleDialog } from "../Component/SimpleDialog";
import { TextLabel } from "../Component/TextLabel";
import { Spacer } from "../Core/Spacer";
import { DraggableCollection } from "./DragableCollection";
import { DraggableCollectionBookData } from "./DraggableCollectionBook";
import { DraggableCollectionBookmarkData } from "./DraggableCollectionBookmark";
import { DraggableCollectionBookshelfData } from "./DraggableCollectionBookshelf";
import { DraggableCollectionGroupData } from "./DraggableCollectionGroup";
import { DraggableButton } from "./DroppableButton";
import { Trash } from "./Trash";

type type = "Bookshelf";
export interface BookshelfItem {
  id: string;
  title: string;
  type: "ilks" | "other" | "bookmark" | "group" | "bookshelf";
  url: string;
}
export class BookshelfScene extends BaseScene {
  static sceneName: type = "Bookshelf";
  static Params:
    | undefined
    | {
        path: string;
      };

  @firestoreState bookshelfCollection?: BookshelfCollection;
  @sharedState lastPath = "/";
  @sharedState({ notify: false }) isDialogOpen = false;
  @state isGroupDialogShow = false;
  @state isBookDialogShow = false;
  @state isBookshelfDialogShow = false;

  @state dialogText = "";

  @state path: string;
  @state parentId = "";

  @sharedState bookshelfScale = 1.0;

  constructor(props: ScreenProp<type>) {
    super(props);
    Log.log("test", "本棚を開きました");

    Log.error(new Error("テストだから気にしなくていいよ"), "気にしなくていい");
    this.path = props.route.params?.path ?? "/";
    if (this.lastPath) {
      this.changePath(this.lastPath);
    }
  }
  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerShown: false,
    };
  }

  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, BookshelfCollection, { uid: this.user.uid }, this.bookshelfCollection);
    await Bookshelf.initialize(this.bookshelfCollection);
  }

  changePath(path: string): void {
    this.lastPath = path;
    this.path = path;
    let parentId = this.path;
    parentId = _.trimEnd(parentId, "/");
    parentId = parentId.slice(parentId.lastIndexOf("/") + 1);
    this.parentId = parentId ?? "";
    this.requestUpdate();
  }

  imageList: { [key: string]: string } = {};
  async reloadImage(books: BookshelfItem[]): Promise<void> {
    for (const book of books) {
      if (book.url in this.imageList) {
        continue;
      }
      const cover = await GoogleBooks.getCacheCover(book.url);
      if (cover) {
        this.imageList[book.url] = cover;
        this.requestUpdate();
      }
    }
  }

  renderComponent(): JSX.Element {
    const books: BookshelfItem[] = [
      // { id: "1", title: "私、能力は平均値でって言ったよね！", type: "other", url: "https://ncode.syosetu.com/n6475db/" },
      // { id: "2", title: "ちびっこ賢者、Lv.1から異世界でがんばります！【Web版】", type: "other", url: "https://ncode.syosetu.com/n8991ct/" },
      // { id: "3", title: "ケモ耳少女はファンタジーの夢を見る(仮)", type: "other", url: "https://ncode.syosetu.com/n0054gb/" },
      // { id: "4", title: "アララストディラス", type: "other", url: "https://ncode.syosetu.com/n2380gv/" },
      // { id: "5", title: "蜘蛛ですが、なにか？", type: "other", url: "https://ncode.syosetu.com/n7975cr/" },
      // { id: "6", title: "察知されない最強職《ルール・ブレイカー》", type: "other", url: "https://ncode.syosetu.com/n5475dz/" },
      // { id: "7", title: "題名などという贅沢なものはこの小説には無い。", type: "other", url: "https://ncode.syosetu.com/n6320df/" },
      // { id: "8", title: "お隣の天使様にいつの間にか駄目人間にされていた件", type: "other", url: "https://ncode.syosetu.com/n8440fe/" },
      // { id: "9", title: "ひねくれた私と残念な俺様", type: "other", url: "https://ncode.syosetu.com/n7659ec/" },
      // { id: "10", title: "他人を寄せつけない無愛想な女子に説教したら、めちゃくちゃ懐かれた", type: "other", url: "https://ncode.syosetu.com/n8094fx/" },
      // { id: "11", title: "夢の軌跡Ⅱ〜護衛少女の力〜", type: "other", url: "https://syosetu.org/novel/156485/" },
      // { id: "12", title: "旦那様が愛人を連れていらしたので、円満に離縁したいと思います。", type: "other", url: "https://www.alphapolis.co.jp/novel/494727760/92486498" },
      // { id: "13", title: "人気女優の恋人は女子大生", type: "other", url: "https://kakuyomu.jp/works/16816452219852071831" },
      // { id: "14", title: "眼鏡を外したら美少女だったクラスメイトの彼女と同棲するハメになりました", type: "other", url: "https://kakuyomu.jp/works/16816452219802065750" },
      // { id: "15", title: "習作～はじめての投稿～（なろう）", type: "other", url: "https://ncode.syosetu.com/n4418ha/" },
      // { id: "16", title: "なんのチートもないけど私は元気です", type: "other", url: "https://syosetu.org/novel/255344/" },
      // { id: "17", title: "咲希とお兄ちゃんの内緒", type: "other", url: "https://novel18.syosetu.com/n8936gp/" },
      // { id: "18", title: "いるくいるくいる", type: "ilks", url: "Xc9OihkyiJhr5CfVO5qY" },
      // { id: "19", title: "ハーメルン牌画像変換ツール仕様", type: "other", url: "https://syosetu.org/novel/7135/" },
      // { id: "20", title: "習作ハーメルン", type: "other", url: "https://syosetu.org/novel/260800/" },
    ];
    // const tempBooks: BookshelfItem[] = [];

    const documents = _.orderBy(this.bookshelfCollection?.toArray(), (v) => v.data.index, "asc") ?? [];
    for (const document of documents) {
      if (this.parentId || document.data.parent) {
        if (this.parentId !== document.data.parent) {
          continue;
        }
      }

      if (document.data.type === "group") {
        books.push({ id: document.data._id ?? "", title: document.data.title, type: "group", url: this.path + (document.data._id ?? "") + "/" });
      } else if (document.data.type === "bookshelf") {
        books.push({ id: document.data._id ?? "", title: document.data.title, type: "bookshelf", url: this.path + (document.data._id ?? "") + "/" });
      } else if (document.data.type === "bookmark") {
        books.push({ id: document.data._id ?? "", title: document.data.title, type: "bookmark", url: document.data.url });
      } else if (document.data.book.types === "ilks") {
        books.push({ id: document.data._id ?? "", title: document.data.title, type: document.data.book.types, url: document.data.book.id });
      } else {
        books.push({ id: document.data._id ?? "", title: document.data.title, type: document.data.book.types, url: document.data.book.url });
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.reloadImage(books);
    return (
      <Container style={[styles.container]}>
        <SafeAreaView style={{ position: "relative", width: "100%", height: "100%" }} edges={["top", "left", "right"]}>
          <View style={{ flexDirection: "row", justifyContent: "space-between", height: 60 }}>
            <View style={{ flexDirection: "row", height: 60, flex: 1, paddingHorizontal: 5 }}>
              <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                {this.path !== "/" ? (
                  <DraggableButton
                    width={60}
                    height={50}
                    icon={require("@assets/images/arrow_left_white.png")}
                    onTap={async () => {
                      let path = this.path;
                      path = path.slice(0, path.lastIndexOf("/"));
                      path = path.slice(0, path.lastIndexOf("/"));
                      path += "/";
                      this.changePath(path);
                    }}
                    onInto={async () => {
                      let path = this.path;
                      path = path.slice(0, path.lastIndexOf("/"));
                      path = path.slice(0, path.lastIndexOf("/"));
                      path += "/";
                      this.changePath(path);
                    }}
                  >
                    本棚一覧
                  </DraggableButton>
                ) : undefined}
                <Spacer space={10}></Spacer>
                <TextLabel numberOfLines={2} fontSize="large" boldWeight color={themeColor.mainText} style={{ flexGrow: 1, flexShrink: 1 }}>
                  {(this.parentId ? this.bookshelfCollection?.documents[this.parentId].data.title : "本棚一覧") ?? ""}
                </TextLabel>
                <Spacer space={5}></Spacer>
                <MiniIconTitleButton
                  style={{ display: this.parentId ? "flex" : "none" }}
                  width={65}
                  title="本追加"
                  icon={require("@assets/images/bookgroup_add.png")}
                  onPress={async () => {
                    this.isBookDialogShow = true;
                    this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
                    this.dialogText = await Clipboard.getStringAsync();
                  }}
                  height={50}
                ></MiniIconTitleButton>
                <Spacer space={5}></Spacer>
                <MiniIconTitleButton
                  style={{ display: this.parentId ? "flex" : "none" }}
                  width={65}
                  title="グループ追加"
                  icon={require("@assets/images/bookgroup_add.png")}
                  onPress={() => {
                    this.isGroupDialogShow = true;
                    this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
                    this.dialogText = "";
                  }}
                  height={50}
                ></MiniIconTitleButton>
                <Spacer style={{ display: this.parentId ? "none" : "flex" }} space={5}></Spacer>
                <MiniIconTitleButton
                  style={{ display: this.parentId ? "none" : "flex" }}
                  width={65}
                  title="本棚追加"
                  icon={require("@assets/images/bookgroup_add.png")}
                  onPress={() => {
                    this.isBookshelfDialogShow = true;
                    this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
                    this.dialogText = "";
                  }}
                  height={50}
                ></MiniIconTitleButton>
              </View>
            </View>
          </View>

          <View
            onLayout={(event) => {
              const baseScale = event.nativeEvent.layout.width / 320;
              this.bookshelfScale = Math.min(baseScale / 1 + 0.05, 1.5);
            }}
          ></View>
          <DraggableCollection
            parentId={this.parentId}
            data={books.map((book) => {
              if (book.type === "bookshelf") {
                return new DraggableCollectionBookshelfData(book.id, book.title, book.url, this.parentId, book.type);
              }
              if (book.type === "group") {
                return new DraggableCollectionGroupData(book.id, book.title, book.url, this.parentId, book.type);
              }
              if (book.type === "bookmark") {
                return new DraggableCollectionBookmarkData(book.id, book.title, book.url, book.type);
              }
              return new DraggableCollectionBookData(book.id, book.title, book.url, book.type, this.imageList[book.url]);
            })}
          ></DraggableCollection>
          <View pointerEvents="none" style={{ position: "absolute", top: 50, width: "100%", flexDirection: "row", justifyContent: "center" }}>
            <Trash></Trash>
          </View>
        </SafeAreaView>

        <SimpleDialog visible={this.isBookDialogShow} title={"本を追加"} description={"本棚に本を追加します"}>
          {[
            {
              itemType: "input",
              placeholder: "url",
              label: "URL",
              value: this.dialogText,
              onChangeText: (v) => {
                this.dialogText = v;
              },
            },
            {
              itemType: "button",
              label: "OK",
              color: this.dialogText ? undefined : "gray",
              bold: true,
              disabled: !this.dialogText,
              onPress: async () => {
                await Bookshelf.addBook(this.dialogText, this.parentId, "other");
                this.isBookDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
            {
              itemType: "button",
              label: "キャンセル",
              onPress: () => {
                this.isBookDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
          ]}
        </SimpleDialog>
        <SimpleDialog visible={this.isGroupDialogShow} title={"グループを追加"} description={"本棚にグループを追加します"}>
          {[
            {
              itemType: "input",
              placeholder: "名前",
              value: this.dialogText,
              onChangeText: (v) => {
                this.dialogText = v;
              },
            },
            {
              itemType: "button",
              label: "OK",
              color: this.dialogText ? undefined : "gray",
              bold: true,
              disabled: !this.dialogText,
              onPress: async () => {
                await Bookshelf.addGroup(this.dialogText, this.parentId);
                this.isGroupDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
            {
              itemType: "button",
              label: "キャンセル",
              onPress: () => {
                this.isGroupDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
          ]}
        </SimpleDialog>
        <SimpleDialog visible={this.isBookshelfDialogShow} title={"本棚を追加"} description={"本棚を追加します"}>
          {[
            {
              itemType: "input",
              placeholder: "名前",
              value: this.dialogText,
              onChangeText: (v) => {
                this.dialogText = v;
              },
            },
            {
              itemType: "button",
              label: "OK",
              color: this.dialogText ? undefined : "gray",
              bold: true,
              disabled: !this.dialogText,
              onPress: async () => {
                await Bookshelf.addBookshelf(this.dialogText);
                this.isBookshelfDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
            {
              itemType: "button",
              label: "キャンセル",
              onPress: () => {
                this.isBookshelfDialogShow = false;
                this.isDialogOpen = this.isBookshelfDialogShow || this.isGroupDialogShow || this.isBookDialogShow;
              },
            },
          ]}
        </SimpleDialog>
      </Container>
    );
  }
}
export const BookshelfScene2: SceneBase<type> = BookshelfScene;
