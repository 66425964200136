import firebase from "firebase";
import _ from "lodash";
import { Center } from "native-base";
import React, { CSSProperties } from "react";
import { ActivityIndicator, Platform, View } from "react-native";
import { themeColor } from "@core/Styles";
import { AppContext, AppContextType } from "./AppContext";
import { removeFirestoreStateListener } from "./firestoreState";
import { addSharedStateListener, getSharedState, removeSharedStateListener } from "./SharedState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function customizer(objValue: any, othValue: any): boolean | undefined {
  if (typeof objValue === "function" && typeof othValue === "function") {
    return true;
  }
  if ((objValue && typeof objValue === "object" && "$$typeof" in objValue) || (othValue && typeof othValue === "object" && "$$typeof" in othValue)) {
    return false;
  }

  return;
}

export type ErrorType = "initialize";
export type State = { [key: string]: unknown };

export interface BasePropsJSXChildren extends BaseProps {
  children?: JSX.Element | JSX.Element[];
}

export interface BaseProps {
  pointerEvents?: "box-none" | "none" | "box-only" | "auto";
  style?: CSSProperties;
}

export class BaseComponent<Props> extends React.Component<Props, State> {
  firstRender = true;
  initialized = false;
  isMount = false;
  _state: State;
  props: Props;
  _functions: { [key: string]: unknown } = {};
  propChange = false;
  sharedStateKeys: { [key: string]: boolean };

  _state_default?: { [key: string]: unknown };
  _sharedStateKeys_default?: { [key: string]: boolean };
  __watchFirestoreKeyMap?: { [key: string]: string };
  __firestoreStateLastPropertyKey?: string;
  static contextType = AppContext;
  context!: AppContextType | null;

  get user(): firebase.User {
    return getSharedState("user") as firebase.User;
  }
  prevUid?: string;
  prevState?: State;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  protected callback<T extends (...a: any) => any>(callback: T): T {
    const hash = callback.toString();
    if (this._functions[hash]) {
      return this._functions[hash] as T;
    }
    this._functions[hash] = callback;
    return callback;
  }
  protected initializeOnMounted = false;

  protected addStateCount(): void {
    if (this.initialized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._setState((state: any) => {
        return { ...state, stateCount: (state.stateCount ?? 0) + 1 };
      });
    }
  }

  requestUpdate(): void {
    this.addStateCount();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State, nextContext: unknown): boolean {
    const isChangedProps = !_.isEqualWith(this.props, nextProps, customizer);
    const isChangedState = !_.isEqualWith(this.state, nextState, customizer);
    const isChangedContext = !_.isEqualWith(this.context, nextContext, customizer);

    this.propChange = isChangedProps;
    this.prevState = this.state;

    if (isChangedProps) {
      if (Log.option.showComponentChangeName) {
        Log.debug(`render ${this.constructor.name} from Props`);
      }
      if (Log.option.showComponentChangeValue) {
        if (typeof nextProps === "object") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const propsDiff = _.omitBy(nextProps as any, (v, k) => _.isEqualWith((this.props as any)[k], v, customizer));
          Log.debug(propsDiff);
        }
      }
    }

    if (isChangedState) {
      if (Log.option.showComponentChangeName) {
        Log.debug(`render ${this.constructor.name} from State`);
      }
      if (Log.option.showComponentChangeValue) {
        const stateDiff = _.omitBy(nextState, (v, k) => _.isEqualWith(this.state[k], v, customizer));
        Log.debug(stateDiff);
      }
    }
    if (isChangedContext) {
      if (Log.option.showComponentChangeName) {
        Log.debug(`render ${this.constructor.name} from Context`);
      }
    }

    return isChangedProps || isChangedState || isChangedContext;
  }

  componentDidUpdate(): void {
    if (this.propChange) {
      const promise = this.didPropChange?.();

      if (promise) {
        promise
          .then(() => {
            this.propChange = false;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this._setState((state: any) => {
              return { ...state, propCount: (state.propCount ?? 0) + 1 };
            });
          })
          .catch((e) => {
            Log.error("componentDidUpdate error", e);
            this.propChange = false;
          });
      } else {
        this.propChange = false;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.onUserChanged && this.user?.uid !== this.prevUid) {
      this.prevUid = this.user?.uid;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (this.user) {
        this.onUserChanged?.()?.then(() => {
          this.addStateCount();
        });
      } else {
        this.addStateCount();
      }
    }
  }

  didPropChange?(): Promise<void> | void;

  /** @deprecated 代わりに@stateを使ってください */
  setState<K extends keyof State>(
    state: ((prevState: Readonly<State>, props: Readonly<Props>) => Pick<State, K> | State | null) | (Pick<State, K> | State | null),
    callback?: () => void
  ): void {
    super.setState(state, callback);
  }

  _setState<K extends keyof State>(
    state: ((prevState: Readonly<State>, props: Readonly<Props>) => Pick<State, K> | State | null) | (Pick<State, K> | State | null),
    callback?: () => void
  ): void {
    if (this.isMount) {
      super.setState(state, callback);
    }
  }

  constructor(props: Props) {
    super(props);
    const stateDefault: unknown = _.cloneDeep(((this as unknown) as { _state_default: { [key: string]: unknown } })._state_default ?? {});
    const _stateDefault: unknown = _.cloneDeep(((this as unknown) as { _state_default: { [key: string]: unknown } })._state_default ?? {});
    this.state = stateDefault as State;
    this._state = _stateDefault as State;
    this.props = props;

    this.sharedStateKeys = _.cloneDeep(((this as unknown) as { _sharedStateKeys_default: { [key: string]: boolean } })._sharedStateKeys_default ?? {});
    for (const key in this.sharedStateKeys) {
      addSharedStateListener(this, key);
    }
  }

  render(): JSX.Element | JSX.Element[] {
    if (this.firstRender) {
      if (!this.initializeOnMounted) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this._initialize();
      }
      this.firstRender = false;
    }

    if (!this.initialized) {
      return this.renderLoading();
    } else if (!this.user && this.onUserChanged) {
      return this.renderNoUser();
    } else {
      return this.renderComponent(!!(this.propChange && this.didPropChange));
    }
  }

  public componentDidMount(): void {
    this.isMount = true;
    if (this.initializeOnMounted) {
      if (!this._initialize()) {
        this._setState(this._state);
      }
    }
  }

  public componentWillUnmount(): void {
    for (const key in this.sharedStateKeys) {
      removeSharedStateListener(this, key);
    }
    removeFirestoreStateListener(this);
    this.isMount = false;
  }

  protected _initialize(): Promise<void> | void {
    const promise = this.initialize();
    if (promise) {
      promise
        .then(() => {
          this.initialized = true;
          // this._state._____initialized = _.random(0, 1000000);
          // console.log(`state after ${this._state._____initialized}`);
          // this._setState(this._state);
          // TODO: なんか_stateに入れて更新すると更新されないけど、これでは_stateに代入されないっぽい
          this._setState({ ...this._state, initialized: true });
          this._state.initialized = true;
          // this._state = { ...this._state, initialized: true };
          // this._setState(this._state);
        })
        .catch((e) => {
          console.error(e);
          this.onError(e, "initialize");
        });
    } else {
      this.initialized = true;
    }
    return promise;
  }
  protected initialize(): Promise<void> | void {
    if (this.onUserChanged) {
      return (async () => {
        await this.waitUser();
        this.prevUid = this.user?.uid;
        if (this.user) {
          await this.onUserChanged?.();
        } else {
          this.addStateCount();
        }
      })();
    }
    return;
  }
  protected onUserChanged?(): Promise<void> | void;

  protected async waitUser(): Promise<void> {
    await getSharedState("asyncUser");
  }

  protected onError(error: Error, _type: ErrorType): void {
    Log.error(error);
  }
  protected renderComponent(_isPropChanging = false): JSX.Element | JSX.Element[] {
    return <View></View>;
  }
  protected renderLoading(): JSX.Element | JSX.Element[] {
    return (
      <Center style={{ alignContent: "center" }}>
        <ActivityIndicator size="large" color={Platform.OS === "ios" ? "#999999" : themeColor.main} />
      </Center>
    );
  }
  protected renderNoUser(): JSX.Element | JSX.Element[] {
    return this.renderLoading();
  }
}
