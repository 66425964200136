import { ParserMeta, Selector } from "novel-parser";
import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument, FirestoreKey } from "../lib/firestore-document";

export interface ParserKey extends FirestoreKey {
  uid: string;
}

export interface ParserData extends FirestoreData, Selector {
  metaData: ParserMeta & {
    description: string;
    domain: string;
    tag: string[];
  };
  raw: string;
}

export class ParserCollection extends FirestoreCollection<FirestoreKey, ParserData, ParserDocument> {
  static ref(): CollectionReference<DocumentData> {
    return this.firestore().collection("parsers");
  }

  constructor(key: FirestoreKey) {
    super(ParserDocument, key);
  }
}
export class ParserDocument extends FirestoreDocument<ParserKey, ParserData> {
  static ref(key: ParserKey): DocumentReference<DocumentData> {
    return ParserCollection.ref().doc(key.uid);
  }
}
