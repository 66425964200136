import { LinearGradient } from "expo-linear-gradient";
import { Center, Column } from "native-base";
import { Text } from "native-base";
import React from "react";
import { ColorValue, Image, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { Episode } from "../BookSummary/BookSummaryScene";
import { RoundedButton } from "./RoundedButton";
import { TagLabel } from "./TagLabel";
import { TextLabel } from "./TextLabel";

export interface Props {
  episode: Episode;
  readStatus: "unread" | "reading" | "alreadyRead" | "updated";
  onPress: (item: Episode) => void;
  onOtherButtonPress: () => void;
  style?: {};
}

export class EpisodeItem extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  onPress = (): void => this.props.onPress(this.props.episode);
  renderComponent(): JSX.Element {
    return (
      <TouchableOpacity
        style={[
          this.props.style ?? {},
          styles.colorShadow,
          styles.item,
          { backgroundColor: this.props.readStatus === "alreadyRead" ? themeColor.itemBGEmphasis : themeColor.itemBG },
        ]}
        key={this.props.episode.id}
        onPress={this.onPress}
      >
        <View
          style={{
            flexGrow: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 20,
          }}
        >
          <View style={{ width: 40 }}>
            <View
              style={{
                width: 35,
                height: 35,
                borderRadius: 5,
                display: this.props.readStatus === "alreadyRead" ? "none" : "flex",
                overflow: "hidden",
              }}
            >
              <Image style={{ width: 30, height: 30, alignSelf: "flex-start" }} resizeMode={"contain"} source={require("@assets/images/new_label.png")}></Image>
            </View>
          </View>
          <Column
            space={0}
            style={{
              flexGrow: 1,
              flexShrink: 1,
              marginVertical: 10,
              alignSelf: "flex-start",
            }}
          >
            <EpisodeTitle no={this.props.episode.no}>{this.props.episode.title}</EpisodeTitle>
            <TagLabel mini tagTitle="投稿" tagTitleBGColor={themeColor.gray} tagValueTextColor={themeColor.subText}>
              2020/12/15 17:50{/* {this.props.episode.date} */}
            </TagLabel>
            {/* <TagLabel mini tagTitle="改稿" tagTitleBGColor={themeColor.gray} tagValueTextColor={themeColor.subText}>
              2020/12/15 17:50
            </TagLabel> */}
          </Column>
          <Center style={{ width: 60, flexDirection: "column" }}>
            <RoundedButton style={{ width: 48 }} onPress={this.onPress} centerImage={require("@assets/images/others_white.png")}></RoundedButton>
          </Center>
        </View>
      </TouchableOpacity>
    );
  }
}

export interface EpisodeTitleProps {
  no: number;
  children: string;
  mini?: boolean;
  titleColor?: ColorValue;
  style?: {};
  noOfLines?: number;
}

export class EpisodeTitle extends BaseComponent<EpisodeTitleProps> {
  constructor(props: EpisodeTitleProps) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            height: this.props.mini === true ? 15 : 34,
            flexGrow: 1,
            // minWidth: 300,
            maxWidth: 580,
          },
          this.props.style ?? {},
        ]}
      >
        <LinearGradient
          colors={["#6CCCFA", "#7FB3F8"]}
          start={{ x: 1, y: 0 }}
          end={{ x: 0, y: 0 }}
          style={{
            backgroundColor: "#7FB3F8",
            width: this.props.mini === true ? 36 : 50,
            height: this.props.mini === true ? 16 : 24,
            borderRadius: 5,
            marginRight: this.props.mini === true ? 4 : 8,
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <Text
            noOfLines={this.props.mini === true ? 1 : 2}
            style={[
              styles.text,
              {
                fontSize: this.props.mini === true ? 11 : 15,
                color: "white",
                textAlign: "center",
              },
            ]}
          >
            {this.props.no}
          </Text>
        </LinearGradient>
        <TextLabel
          boldWeight
          fontSize={this.props.mini === true ? 11 : 14}
          color={this.props.titleColor ?? themeColor.mainText}
          numberOfLines={this.props.noOfLines ?? 2}
          style={{ flex: 1, lineHeight: this.props.mini === true ? 14 : 17 }}
        >
          {this.props.children}
        </TextLabel>
      </View>
    );
  }
}
