import React from "react";
import { View } from "react-native";
import { Animated } from "react-native";
import { BaseComponent } from "@core/BaseComponent";

export interface Props {
  visible: boolean;
  animationType?: "none" | "slide" | "fade";
  children?: JSX.Element;
}

export class CustomDialog extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.prevVisible = this.props.visible;
  }
  prevVisible: boolean;
  async didPropChange(): Promise<void> {
    if (this.prevVisible !== this.props.visible) {
      if (this.props.visible) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    }
    this.prevVisible = this.props.visible;
  }
  fadeIn(): void {
    Animated.timing(this.opacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.backgroundOpacity, {
      toValue: 0.4,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }
  fadeOut(): void {
    Animated.timing(this.opacity, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.backgroundOpacity, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }

  opacity = new Animated.Value(0);
  backgroundOpacity = new Animated.Value(0);

  renderComponent(): JSX.Element {
    return (
      <View pointerEvents={this.props.visible ? undefined : "none"} style={{ position: "absolute", width: "100%", height: "100%" }}>
        <Animated.View
          style={[
            {
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
            },
            {
              opacity: this.backgroundOpacity,
            },
          ]}
        ></Animated.View>
        <Animated.View
          style={[
            {
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            },
            {
              opacity: this.opacity,
            },
          ]}
        >
          {this.props.children}
        </Animated.View>
      </View>
    );
  }
}
