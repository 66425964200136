import { Asset } from "expo-asset";
import { readAsStringAsync } from "expo-file-system";
import React from "react";
import { Platform, View } from "react-native";
import WebView from "react-native-webview";
import { ShouldStartLoadRequest, WebViewNavigation, WebViewSource } from "react-native-webview/lib/WebViewTypes";
import { BaseComponent } from "./BaseComponent";
import { state } from "./State";
import { generateUuid } from "./uuid";

export type Props = {
  onLoadStart?: (webview: WebViewComponent) => boolean;
  onLoadEnd?: (webview: WebViewComponent) => boolean;
  onMessage?: (webview: WebViewComponent, message: { str: string; method: string; data?: string }) => boolean;
  onNavigationStateChange?: (webview: WebViewComponent, event: WebViewNavigation) => void;
  onShouldStartLoadWithRequest?: (webview: WebViewComponent, event: ShouldStartLoadRequest) => boolean;
} & ({ url: string; jsName?: string } | { name: string });

export class WebViewComponent extends BaseComponent<Props> {
  webview?: WebView;
  protected onChangeWebviewElement?: () => void;
  protected iframe?: HTMLIFrameElement;
  protected useragent?: string;
  @state html: string | number;
  @state js: string | number;
  @state file?: { source?: WebViewSource; js: string; css: string };
  @state baseURL?: string;

  protected get source(): WebViewSource | undefined {
    return this.file?.source;
  }

  constructor(props: Props) {
    super(props);
    this.html = "";
    this.js = "";
    if ("name" in props) {
      this.html = webViewFiles.html[props.name]?.() ?? "";
      this.js = webViewFiles.js[props.name]?.() ?? "";
      this.baseURL = undefined;
    } else {
      this.html = "";
      this.js = webViewFiles.js[props.jsName ?? ""]?.() ?? "";
      this.baseURL = props.url;
    }
  }

  protected async initialize(): Promise<void> {
    await super.initialize();
    this.file = await this.getFile();
    return;
  }

  protected async getFile(): Promise<{
    source: WebViewSource | undefined;
    js: string;
    css: string;
  }> {
    return {
      source: await this.getSource(),
      js: await this.getJavaScript(),
      css: "",
    };
  }

  protected async getSource(): Promise<WebViewSource | undefined> {
    if (this.baseURL !== undefined) {
      return { uri: this.baseURL };
    }

    if (Platform.OS === "android" || Platform.OS === "ios") {
      const file = Asset.fromModule(this.html);
      if (file.localUri === null) {
        await file.downloadAsync();
      }
      if (file === null) {
        return;
      }
      const html = await readAsStringAsync(file.localUri || "");
      return { html: html };
    } else if (Platform.OS === "web") {
      const file = Asset.fromModule(this.html);
      if (file.uri === null) {
        await file.downloadAsync();
      }
      // return { html: file.uri };
      const res = await fetch(file.uri);
      const text = await res.text();
      return { html: text };
    } else {
      return;
    }
  }

  protected async getJavaScript(): Promise<string> {
    if (this.baseURL !== undefined) {
      if (!this.js) {
        return "";
      }
    }
    const file = Asset.fromModule(this.js);

    if (file.localUri === null) {
      await file.downloadAsync();
    }
    if (file === null) {
      return "";
    }
    if (Platform.OS === "web") {
      const res = await fetch(file.uri);
      const text = await res.text();
      return text;
    } else {
      return await readAsStringAsync(file.localUri || "");
    }
  }

  protected onLoadStart(): void {
    if (this.props.onLoadStart && !this.props.onLoadStart(this)) {
      return;
    }
  }
  protected onLoadEnd(): void {
    if (this.props.onLoadEnd && !this.props.onLoadEnd(this)) {
      return;
    }
    this.onloaded();
  }
  protected onMessage(str: string): void {
    const colonIndex = str.indexOf(":");
    const method = colonIndex >= 0 ? str.substring(0, str.indexOf(":")) : str;
    const data = colonIndex >= 0 ? str.substring(str.indexOf(":") + 1) : undefined;

    if (this.props.onMessage && !this.props.onMessage(this, { str: str, method: method, data: data })) {
      return;
    }

    switch (method) {
      case "return": {
        if (!data) {
          Log.error("Not found return data", "WebviewComponentError");
          break;
        }
        const ret = JSON.parse(data);
        if (typeof ret !== "object" || !ret || !ret.id || typeof ret.id !== "string") {
          Log.error("returnのデータの形式がおかしい", "WebviewComponentError");
          break;
        }
        this.methodReturnList[ret.id]?.(ret.data);
        break;
      }
      case "log":
        console.log(data);
        break;
      case "warn":
        console.log(data);
        break;
      case "error":
        console.log(data);
        break;
      case "required":
        // webviewにjsが反映された時
        this.onLoadEnd();
        break;
      default:
        console.log("onMessage");
        console.log(str);
        break;
    }
  }

  protected injectJavaScript(code: string): void {
    if (Platform.OS === "web") {
      this.iframe?.contentWindow?.eval?.(code);
    } else {
      this.webview?.injectJavaScript(code);
    }
  }

  private methodReturnList: {
    [key: string]: (prop: unknown) => unknown;
  } = {};

  public methodAsync(method: string, params?: unknown): Promise<unknown> {
    const id = generateUuid();
    if (params) {
      const encodedParams = encodeURIComponent(JSON.stringify(params));
      const code = `window.methodAsync("${method}", "${id}", JSON.parse(decodeURIComponent("${encodedParams}")));`;
      this.injectJavaScript(code);
    } else {
      const code = `window.methodAsync("${method}", "${id}");`;
      this.injectJavaScript(code);
    }

    return new Promise<unknown>((resolve) => {
      this.methodReturnList[id] = resolve;
    });
  }

  public method(method: string, params?: unknown): void {
    if (params) {
      const encodedParams = encodeURIComponent(JSON.stringify(params));
      const code = `window.ReactNativeWebViewFuntion.${method}(JSON.parse(decodeURIComponent("${encodedParams}")));`;
      this.injectJavaScript(code);
    } else {
      const code = `window.ReactNativeWebViewFuntion.${method}();`;
      this.injectJavaScript(code);
    }
  }

  protected onloaded(): void {
    this.method("onloaded");
  }

  renderComponent(): JSX.Element {
    if (!this.file?.source) {
      return <View></View>;
    }

    let webView: JSX.Element;
    const js = this.file?.js || "";
    if (Platform.OS === "web") {
      if ("uri" in this.file.source) {
        return <View></View>;
      }
      const html = (this.file?.source?.html || "") as string;
      webView = (
        <div
          style={{ alignSelf: "stretch", flex: 1 }}
          ref={(element) => {
            if (element) {
              const iframe = document.createElement("iframe");
              this.iframe = iframe;
              element.appendChild(iframe);

              this.onLoadStart();

              iframe.style.border = "none";
              iframe.style.width = "100%";
              iframe.style.height = "100%";
              const jstag = `<script>eval(decodeURIComponent("${encodeURIComponent(js)}"))</script>`;
              const blob = new Blob([html + jstag], { type: "text/html" });
              const url = URL.createObjectURL(blob);

              iframe.src = url;
              if (iframe.contentWindow) {
                window.ReactNativeWebView = iframe.contentWindow.ReactNativeWebView = {
                  postMessage: (v: string) => {
                    this.onMessage(v);
                  },
                };
              }
            }
          }}
        ></div>
      );
    } else {
      webView = (
        <WebView
          androidLayerType="hardware"
          bounces={false}
          ref={(ref) => {
            this.webview = ref as WebView;
          }}
          source={this.source}
          userAgent={this.useragent}
          setSupportMultipleWindows={false}
          decelerationRate={1}
          mediaPlaybackRequiresUserAction={true}
          allowsInlineMediaPlayback={true}
          // onStartShouldSetResponderCapture={() => true}
          // onMoveShouldSetResponderCapture={() => true}
          // source={{ html: "<p><123123123/p>".repeat(50) }}
          // source={{ uri: "https://ncode.syosetu.com/n0054gb/2/" }}
          injectedJavaScript={js}
          // originWhitelist={["*"]}
          // mixedContentMode="always"
          // domStorageEnabled={true}
          // allowFileAccess={true}
          // allowUniversalAccessFromFileURLs={true}
          onLoadStart={() => {
            this.onLoadStart();
          }}
          onMessage={(event) => {
            this.onMessage(event.nativeEvent.data);
          }}
          onNavigationStateChange={(event) => {
            this.props.onNavigationStateChange?.(this, event);
          }}
          onShouldStartLoadWithRequest={(event) => {
            if (this.props.onShouldStartLoadWithRequest) {
              return this.props.onShouldStartLoadWithRequest(this, event);
            }
            return true;
          }}

          // onError={(syntheticEvent) => {
          //   const { nativeEvent } = syntheticEvent;
          //   console.warn("WebView error: ", nativeEvent);
          // }}
        />
      );
    }

    return <View style={{ flexDirection: "column", alignSelf: "stretch", flex: 1 }}>{webView}</View>;
  }
}
