import * as firestore from "@firebase/firestore-types";
import { FieldValue, Timestamp } from "../lib/firestore";
import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument } from "../lib/firestore-document";
import { BookKey } from "./book-documents";

export interface EpisodeKey extends BookKey {
  episodeId: string;
}

export interface EpisodeData extends FirestoreData {
  no: number;
  subTitle: string;
  prev: string;
  body: string;
  after: string;
  episodeUpdatedAt: Timestamp | firestore.FieldValue;
}

export class EpisodeCollection extends FirestoreCollection<BookKey, EpisodeData, EpisodeDocument> {
  static ref(key: BookKey): CollectionReference<DocumentData> {
    return this.firestore().collection("books").doc(key.bookId).collection("episodes");
  }

  constructor(key: BookKey) {
    super(EpisodeDocument, key);
  }
}

export class EpisodeDocument extends FirestoreDocument<EpisodeKey, EpisodeData> {
  static ref(key: EpisodeKey): DocumentReference<DocumentData> {
    return EpisodeCollection.ref(key).doc(key.episodeId);
  }

  public static get defaultData(): EpisodeData {
    return {
      no: 0,
      subTitle: "サブタイトル",
      prev: "",
      body: "本文",
      after: "",
      episodeUpdatedAt: FieldValue.serverTimestamp(),
    };
  }
}
