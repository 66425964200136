import { Center, Column, Spacer, Text } from "native-base";
import { Book } from "novel-parser";
import React from "react";
import { View } from "react-native";
import { Row } from "react-native-easy-grid";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { BookshelfBookIcon } from "./BookshelfBookIcon";
import { FaviconDomainText } from "./FaviconDomainText";
import { FlexWrap } from "./FlexWrap";
import { IconText } from "./IconText";
import { Tag } from "./Tag";
import { TextLabel } from "./TextLabel";
import { Episode } from "../BookSummary/BookSummaryScene";
import { format, parseISO } from "date-fns";
export interface Props {
  onPress: () => void;
  compact?: boolean;
  book?: Book;
  episode?: Episode;
}

export class DetailBookButton extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  // shouldComponentUpdate(_nextProps: Props, _nextState: any, _nextContext: any) {
  //   this.count++;
  //   console.log(`shouldComponentUpdate${this.count}    ${_.isEqual(this.state, _nextState)}     ${super.shouldComponentUpdate?.(_nextProps, _nextState, _nextContext)}`);
  //   console.log(Object.keys(this.state));
  //   console.log(Object.keys(_nextState));
  //   return this.count < 10;
  // }

  renderComponent(): JSX.Element {
    return (
      <View style={{ flexShrink: 1, flexGrow: 1, flex: 1, flexBasis: 300, maxWidth: 500, minWidth: 300, height: 250, margin: 5 }}>
        <TouchableOpacity
          bg={themeColor.itemBG}
          onPress={this.props.onPress}
          style={[
            styles.item,
            styles.colorShadow,

            {
              padding: 0,
              borderRadius: 5,
            },
          ]}
        >
          <View style={[{ width: this.props.compact ? 300 : "auto", maxWidth: 500, height: 250, flexGrow: 1, marginVertical: "auto", borderRadius: 5 }]}>
            <Row style={{ marginVertical: 5, marginHorizontal: 10 }}>
              {this.props.compact ? (
                <View></View>
              ) : (
                <Center>
                  <BookshelfBookIcon></BookshelfBookIcon>
                </Center>
              )}
              <View
                style={{
                  alignContent: "space-between",
                  flex: 2,
                  margin: 5,
                }}
              >
                <FaviconDomainText
                  iconWidth={18}
                  iconHeight={18}
                  iconRadius={3}
                  space={5}
                  icon={require("@assets/images/human.png")}
                  url={"https://ncode.syosetu.com/n4418ha/"}
                ></FaviconDomainText>
                <Spacer vertical space={2}></Spacer>
                <TextLabel numberOfLines={2} boldWeight color={themeColor.mainText}>
                  {this.props.book?.title ?? ""}
                </TextLabel>
                <Spacer vertical space={1} />
                <TextLabel numberOfLines={3} fontSize="small" color={themeColor.subText}>
                  {this.props.book?.abstract?.map((v) => (typeof v === "string" ? v.trim() : v.data.trim())).join("\n") ?? "あらすじがありません。"}
                </TextLabel>
                <Spacer vertical space={1} />
                <FlexWrap height={60}>
                  <Tag mini grow space={2}>
                    testR15
                  </Tag>
                  <Tag mini grow space={2}>
                    test 残酷な描写あり
                  </Tag>
                  <Tag mini grow space={2}>
                    test 異世界転生
                  </Tag>
                  <Tag mini grow space={2}>
                    test チート
                  </Tag>
                  <Tag mini grow space={2}>
                    test 俺ILKKKKKKKKKKKKKK
                  </Tag>
                </FlexWrap>
                <Spacer vertical />
                <View style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }}>
                  <Column space={1}>
                    <IconText iconWidth={16} iconHeight={16} icon={require("@assets/images/time_icon.png")}>
                      <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                        <Text style={[styles.text, { fontSize: 13, color: themeColor.subText }]}>114514</Text>
                        <Text style={[styles.textBold, styles.verySmallFont, { color: themeColor.subText }]}>分</Text>
                        <View style={{ width: 8 }}></View>
                        <Text style={[styles.text, { fontSize: 13, color: themeColor.subText }]}>1919810</Text>
                        <Text style={[styles.textBold, styles.verySmallFont, { color: themeColor.subText }]}>文字</Text>
                      </View>
                    </IconText>
                    <View style={{ flexDirection: "row" }}>
                      <IconText iconWidth={16} iconHeight={16} icon={require("@assets/images/favorite_icon.png")}>
                        <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                          <Text style={[styles.text, { fontSize: 13, color: themeColor.favorite }]}>114514</Text>
                        </View>
                      </IconText>
                      <View style={{ width: 8 }}></View>
                      <IconText iconWidth={16} iconHeight={16} icon={require("@assets/images/point_icon.png")}>
                        <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                          <Text style={[styles.text, { fontSize: 13, color: themeColor.accent }]}>1919</Text>
                        </View>
                      </IconText>
                    </View>
                  </Column>
                  <Spacer vertical space={2} />
                  <IconText iconWidth={22} iconHeight={22} iconRadius={11} icon={require("@assets/images/human.png")}>
                    <View style={{ flexDirection: "row", alignItems: "baseline" }}>
                      <Text style={[styles.textBold, { fontSize: 13, color: themeColor.subText }]}>{this.props.book?.author.name ?? ""}</Text>
                    </View>
                  </IconText>
                </View>
                <Spacer vertical space={1} />
                <View style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }}>
                  <Text style={[styles.text, styles.smallFont, { color: themeColor.subText }]}>全{this.props.book?.chapters.flatMap(v=>v.episodes).length}話</Text>
                  <Text style={[styles.text, styles.smallFont, { color: themeColor.subText }]}>投稿: {this.props.episode?.date ? format(parseISO(this.props.episode.date + ""), "yyyy/MM/dd HH:mm") : ""}</Text>
                </View>
              </View>
            </Row>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
