import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ColorValue, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";
import { themeColor } from "@core/Styles";
import { IconText } from "./IconText";
import { TextLabel } from "./TextLabel";

export interface Props extends BasePropsJSXChildren {
  icon?: string;
  title?: string;
  onPress: () => void;
  width?: number;
  minWidth?: number;
  height?: number;
  minHeight?: number;
  horizontal?: boolean;
  flex?: number;
  flexGrow?: number;
  flexShrink?: number;
  textColor?: ColorValue;
  buttonColor?: ColorValue;
  buttonColorGradation?: ColorValue[];
}

export class BottomTitleButton extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={{
          width: this.props.width,
          height: this.props.height,
          minWidth: this.props.minWidth ?? 50,
          minHeight: this.props.minHeight ?? this.props.horizontal === true ? 64 : 80,
          flex: this.props.flex ?? 0,
          flexGrow: this.props.flexGrow ?? 0,
          flexShrink: this.props.flexShrink ?? 0,
          justifyContent: "center",
          margin: 5,
        }}
      >
        <TouchableOpacity style={{ width: "100%", height: "100%", justifyContent: "center" }} onPress={this.props.onPress}>
          <LinearGradient
            style={[
              {
                position: "absolute",
                backgroundColor: this.props.buttonColor,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 12,
              },
            ]}
            colors={this.props.buttonColorGradation ?? [this.props.buttonColor ?? themeColor.mainBlack, this.props.buttonColor ?? themeColor.buttonBlack]}
            start={{ x: 1, y: 0 }}
            end={{ x: 0, y: 0 }}
          ></LinearGradient>
          <View
            style={{
              paddingVertical: 5,
              paddingHorizontal: 2,
              alignSelf: "center",
            }}
          >
            {this.props.children ? (
              this.props.children
            ) : (
              <IconText vertical={!this.props.horizontal} iconWidth={36} iconHeight={36} space={this.props.title ? 8 : 0} icon={this.props.icon ?? ""}>
                <TextLabel
                  numberOfLines={2}
                  blackWeight
                  fontSize={this.props.horizontal ? "small" : "small"}
                  color={this.props.textColor ?? themeColor.white}
                  textAlign="center"
                  style={{ display: this.props.title ? "flex" : "none" }}
                >
                  {this.props.title ?? ""}
                </TextLabel>
              </IconText>
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
