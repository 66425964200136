import React from "react";
import { View } from "react-native";
import { CollapsableListTab, CollapsableScrollTab, CollapsableTabView } from "@core/CollapsableTabView";
import styles, { themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { ParserData } from "~/utilities/firebase/firestore/documents/parser-documents";
import { UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { BottomTitleButton } from "../Component/BottomTitleButton";
import { GridChildView } from "../Component/GridChildView";
import { GridView } from "../Component/GridView";
import { RoundedInput } from "../Component/RoudedInput";
import { RoundedButton } from "../Component/RoundedButton";
import { SiteExtensionItem } from "../Component/SiteExtensionItem";
import { TextLabel } from "../Component/TextLabel";
import { BaseScene } from "../Core/BaseScene";
import { addFirestoreStateListener, firestoreState } from "../Core/firestoreState";
import { ParserManager } from "../Core/ParserManager";

type type = "SiteExtension";

class scene extends BaseScene {
  static sceneName: type = "SiteExtension";
  static Params: undefined;
  @firestoreState userDocument?: UserDocument;

  constructor(props: ScreenProp<type>) {
    super(props);
  }

  allParsers: ParserData[] = [];
  async initialize(): Promise<void> {
    await super.initialize();
    this.allParsers = await ParserManager.getAllParsers();
  }
  protected async onUserChanged(): Promise<void> {
    // 一応パーサー設定変わるのを監視する
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
  }

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerShown: false,
    };
  }

  protected renderComponent(): JSX.Element {
    const userParsers = ParserManager.getUserParsers();

    return (
      <CollapsableTabView
        headerContents={
          <View style={{ backgroundColor: themeColor.itemBG }}>
            <View style={{ flexGrow: 1, height: this.context?.safeAreaInsets?.top ?? 0 }}></View>
            <View style={{ flexGrow: 1, height: 50, paddingHorizontal: 15, justifyContent: "center" }}>
              <TextLabel blackWeight fontSize="large">
                サイト拡張
              </TextLabel>
            </View>
          </View>
        }
      >
        {[
          new CollapsableScrollTab(
            "新しい拡張",
            (
              <GridView>
                <GridChildView title="検索">
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexGrow: 1, flex: 1, flexShrink: 1 }}>
                      <RoundedInput placeholder="URL、サイト名を入力して検索"></RoundedInput>
                    </View>
                    <RoundedButton
                      width={40}
                      height={40}
                      onPress={() => {
                        /* */
                      }}
                      centerImage={require("@assets/images/icon_search_white.png")}
                    ></RoundedButton>
                  </View>
                </GridChildView>
                <GridChildView title="手動で追加">
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <BottomTitleButton
                      title="URLから追加"
                      buttonColor={themeColor.main}
                      buttonColorGradation={themeColor.mainGradation}
                      height={80}
                      flex={2}
                      flexGrow={2}
                      icon={require("@assets/images/icon_search_white.png")}
                      onPress={() => {}}
                    ></BottomTitleButton>
                    <BottomTitleButton
                      title="ファイルから追加"
                      buttonColor={themeColor.gray}
                      height={80}
                      flex={2}
                      flexGrow={2}
                      icon={require("@assets/images/icon_search_white.png")}
                      onPress={() => {}}
                    ></BottomTitleButton>
                    <BottomTitleButton
                      title="テキストを入力"
                      buttonColor={themeColor.gray}
                      height={80}
                      flex={2}
                      flexGrow={2}
                      icon={require("@assets/images/icon_search_white.png")}
                      onPress={() => {}}
                    ></BottomTitleButton>
                  </View>
                </GridChildView>
                <GridChildView oneColumn title="人気のサイト拡張">
                  <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {this.allParsers.map((v) => (
                      <SiteExtensionItem
                        key={v._id}
                        parser={v}
                        onPress={async () => {
                          await ParserManager.addParserSetting(v);
                        }}
                      ></SiteExtensionItem>
                    ))}
                  </View>
                </GridChildView>
              </GridView>
            )
          ),
          new CollapsableListTab(
            "導入済み",
            Object.keys(userParsers ?? {}),
            ({ item }) =>
              userParsers?.[item] ? (
                <View key={item} style={{ height: 250, flexGrow: 1 }}>
                  <SiteExtensionItem
                    parser={userParsers[item]}
                    onPress={async () => {
                      await ParserManager.removeParserSetting(userParsers[item].data);
                    }}
                  ></SiteExtensionItem>
                </View>
              ) : (
                <View></View>
              ),
            250
            // (item: string) => item
          ),
        ]}
      </CollapsableTabView>
    );
  }
}

export const SiteExtensionScene: SceneBase<type> & typeof scene = scene;
