import React from "react";
import { Image, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import { Spacer } from "@core/Spacer";
import styles, { themeColor } from "@core/Styles";
import { ParserData } from "~/utilities/firebase/firestore/documents/parser-documents";
import { UserParser } from "~/utilities/firebase/firestore/documents/user-documents";
import { FaviconDomainText } from "../Component/FaviconDomainText";
import { IconText } from "../Component/IconText";
import { TextLabel } from "../Component/TextLabel";

export interface Props {
  parser: UserParser | ParserData;
  onPress: () => void;
}

export class SiteExtensionItem extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    let parser: ParserData;
    let userParser: UserParser | undefined;
    if ("raw" in this.props.parser) {
      parser = this.props.parser;
    } else {
      userParser = this.props.parser as UserParser;
      parser = userParser.data;
    }
    return (
      <View style={{ paddingHorizontal: 10, paddingVertical: 5 }}>
        <View style={[styles.item, styles.colorShadow, { maxWidth: 600, height: 240, flexGrow: 1, margin: 10 }]}>
          <TouchableOpacity style={{ width: "100%", height: "100%" }} onPress={this.props.onPress}>
            <View style={{ padding: 15, justifyContent: "space-between" }}>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <FaviconDomainText
                  fontSize="large"
                  textColor={themeColor.mainText}
                  url={"http://" + parser.metaData.domain}
                  space={5}
                  iconWidth={22}
                  iconHeight={22}
                ></FaviconDomainText>
              </View>
              <Spacer vertical space={10}></Spacer>
              <View style={{ flexDirection: "row", height: 28, justifyContent: "space-between" }}>
                <TextLabel numberOfLines={2} fontSize="small" color={themeColor.subText}>
                  {parser.metaData.description}
                </TextLabel>
              </View>
              <Spacer vertical space={10}></Spacer>
              <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                <TextLabel boldWeight fontSize="small" color={themeColor.subText}>
                  作者
                </TextLabel>
                <Spacer space={10}></Spacer>
                <IconText iconWidth={22} iconHeight={22} iconRadius={11} space={5} icon={require("@assets/images/human.png")}>
                  <TextLabel boldWeight fontSize="small" color={themeColor.subText}>
                    森下 樹
                  </TextLabel>
                </IconText>
              </View>
              <View style={{ marginVertical: 10 }}>
                <TextLabel blackWeight fontSize="small" color={themeColor.subText}>
                  対応機能
                </TextLabel>
                <Spacer vertical space={5}></Spacer>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={{ backgroundColor: themeColor.itemBGEmphasis, alignItems: "center", justifyContent: "center", flex: 1, borderRadius: 5, height: 50 }}>
                    <TextLabel blackWeight fontSize="small" color={themeColor.subText}>
                      リーダー表示
                    </TextLabel>
                  </View>
                  <Spacer space={10}></Spacer>
                  <View style={{ backgroundColor: themeColor.itemBGEmphasis, alignItems: "center", justifyContent: "center", flex: 1, borderRadius: 5, height: 50 }}>
                    <TextLabel blackWeight fontSize="small" color={themeColor.subText}>
                      目次表示
                    </TextLabel>
                  </View>
                  <Spacer space={10}></Spacer>
                  <View
                    style={{
                      opacity: 0.25,
                      backgroundColor: themeColor.itemBGEmphasis,
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                      borderRadius: 5,
                      height: 50,
                    }}
                  >
                    <TextLabel blackWeight fontSize="small" color={themeColor.subText}>
                      更新通知
                    </TextLabel>
                  </View>
                </View>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", height: 26 }}>
                  <Image style={{ width: 18, height: 18, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                  <Spacer space={2}></Spacer>
                  <Image style={{ width: 18, height: 18, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                  <Spacer space={2}></Spacer>
                  <Image style={{ width: 18, height: 18, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                  <Spacer space={2}></Spacer>
                  <Image style={{ width: 18, height: 18, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                  <Spacer space={2}></Spacer>
                  <Image style={{ width: 18, height: 18, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                  <Spacer space={10}></Spacer>
                  <TextLabel color={themeColor.subText}>5.0</TextLabel>
                </View>
                <TextLabel fontSize="small" color={themeColor.subText}>
                  334件の評価
                </TextLabel>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
