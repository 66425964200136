import React from "react";
import { ColorValue, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";
import { themeColor } from "@core/Styles";
import { IconText } from "./IconText";
import { TextLabel } from "./TextLabel";

export interface Props extends BasePropsJSXChildren {
  icon: string;
  title?: string;
  onPress: () => void;
  width?: number;
  height?: number;
  backgroudColor?: ColorValue;
  textColor?: ColorValue;
}

export class MiniIconTitleButton extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <TouchableOpacity onPress={this.props.onPress}>
        <View
          style={[
            {
              width: this.props.width ?? 48,
              height: this.props.height,
              paddingVertical: 5,
              paddingHorizontal: 2,
              backgroundColor: this.props.backgroudColor ?? themeColor.buttonBlack,
              borderRadius: 5,
              justifyContent: "center",
            },
            this.props.style ?? {},
          ]}
        >
          {this.props.children ? (
            this.props.children
          ) : (
            <IconText vertical iconWidth={24} iconHeight={24} iconRadius={12} space={this.props.title ? 2 : 0} icon={this.props.icon ?? ""}>
              {this.props.title ? (
                <TextLabel
                  numberOfLines={2}
                  boldWeight
                  fontSize={9}
                  color={this.props.textColor ?? themeColor.white}
                  textAlign="center"
                  style={{ display: this.props.title ? "flex" : "none" }}
                >
                  {this.props.title ?? ""}
                </TextLabel>
              ) : undefined}
            </IconText>
          )}
        </View>
      </TouchableOpacity>
    );
  }
}
