import React from "react";
import { View } from "react-native";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";

export interface Props extends BasePropsJSXChildren {}

export class GridView extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        pointerEvents={this.props.pointerEvents}
        style={[{ flex: 1, flexDirection: "row", alignSelf: "stretch", width: "100%", flexWrap: "wrap", justifyContent: "center" }, this.props.style ?? {}]}
      >
        {this.props.children ?? {}}
      </View>
    );
  }
}
