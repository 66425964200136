import _ from "lodash";
import React from "react";
import { Linking, Platform, View } from "react-native";
import { sharedState } from "@core/SharedState";
import { BookshelfBookButton } from "../Component/BookshelfBookButton";
import { DialogComponent } from "../Core/DialogComponent";
import { DraggableCollectionItem } from "./DraggableCollectionItem";
import { DraggingData } from "./DraggingItem";
import { DraggableData } from "./DragHandler";

export class DraggableCollectionBookmarkData implements DraggableData {
  id: string;
  title: string;
  url: string;
  type: "bookmark";
  thumbnailUrl?: string;
  typeName = "bookmark";
  constructor(id: string, title: string, url: string, type: "bookmark", thumbnailUrl?: string) {
    this.id = id;
    this.title = title;
    this.url = url;
    this.type = type;
    this.thumbnailUrl = thumbnailUrl;
  }

  renderDragging(): JSX.Element {
    return <DraggableCollectionBookmark key={"dragging" + this.id} data={this}></DraggableCollectionBookmark>;
  }
}

export class DraggableCollectionBookmark extends DraggableCollectionItem {
  @sharedState bookshelfScale = 1.0;

  onTap(): void {
    if (this.isFloating || this.isSelected) {
      return;
    }

    if (this.draggingData && this.draggingData.data.every((v) => v.typeName !== "bookshelf")) {
      this.draggingData.data.push(this.props.data);
      this.draggingData = _.clone(this.draggingData);
    } else {
      if (Platform.OS === "web") {
        window.open(this.draggableData.url, "_blank", "noreferrer");
      } else {
        Linking.openURL(this.draggableData.url).catch((err) => {
          Log.error(err);
          DialogComponent.showNotify("エラー", "URLを開けませんでした。");
        });
      }
    }
  }

  drop(draggingData: DraggingData): void {
    if (!this.props.parent) {
      return;
    }
    this.props.parent?.drop(draggingData);
  }

  get draggableData(): DraggableCollectionBookmarkData {
    return this.props.data as DraggableCollectionBookmarkData;
  }

  renderItem(): JSX.Element {
    let color: string | undefined = undefined;
    let opacity: number | undefined = undefined;
    if (this.isFloating) {
      color = "yellow";
      opacity = 1;
    } else if (this.into) {
      color = "red";
      opacity = 1;
    } else if (this.hover) {
      color = "magenta";
      opacity = 1;
    }
    if (this.touching) {
      color = "white";
      opacity = 0.2;
    }
    if (this.holding) {
      color = "white";
      opacity = 0.8;
    }

    return (
      <View style={{ marginBottom: 35 * this.bookshelfScale }}>
        <BookshelfBookButton
          title={this.draggableData.title}
          type="book"
          thumbnailUrl={this.draggableData.thumbnailUrl}
          authorName={"ブクマ"}
          genre={"ブクマ"}
          style={color ? { backgroundColor: color, opacity: opacity } : {}}
          onPress={() => {
            // this.currentScene?.navigation.navigate("BookSummary", {
            //   id: this.props.data.id,
            //   title: this.props.data.title,
            //   url: this.props.data.url,
            //   type: this.props.data.type,
            // });
          }}
        ></BookshelfBookButton>
      </View>
    );
  }
}
