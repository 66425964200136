import React from "react";
import { View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";

export interface Props {
  children?: JSX.Element[];
  height?: number;
  space?: number;
  style?: {};
}

export class FlexWrap extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={[
          this.props.height != null ? { height: this.props.height } : {},
          {
            flexWrap: "wrap",
            flexShrink: 1,
            flexGrow: 1,
            flexDirection: "row",
            alignContent: "flex-start",
            marginRight: this.props.space ?? 0,
            marginBottom: this.props.space ?? 0,
          },
          this.props.style ?? {},
        ]}
      >
        {this.props.children ?? {}}
      </View>
    );
  }
}
