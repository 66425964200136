import _ from "lodash";
import { Book, Episode, EpisodeParser, IndexParser, selectors } from "novel-parser";
import React, { createRef } from "react";
import { View } from "react-native";
import { Platform } from "react-native";
import { BookDocument } from "~/utilities/firebase/firestore/documents/book-documents";
import { EpisodeCollection, EpisodeDocument } from "~/utilities/firebase/firestore/documents/book-episode-document";
import { ParserCollection, ParserData } from "~/utilities/firebase/firestore/documents/parser-documents";
import { UserDocument, UserParsers } from "~/utilities/firebase/firestore/documents/user-documents";
import { BaseComponent } from "./BaseComponent";
import { addFirestoreStateListener, firestoreState } from "./firestoreState";
import { ParserWebViewComponent } from "./ParserWebViewComponent";
import { generateUuid } from "./uuid";

export let instance: ParserManager | undefined;
let loadedResolver!: (value: boolean) => void;
let loaded: Promise<boolean> = new Promise<boolean>((resolver) => (loadedResolver = resolver));
const parserWebViewComponents: { ref: React.RefObject<ParserWebViewComponent>; isGetting: boolean }[] = [];
export class ParserManager extends BaseComponent<{}> {
  protected promise?: Promise<string>;
  protected resolver?: (value: string) => void;
  protected uid = generateUuid();
  @firestoreState userDocument?: UserDocument;

  // protected static getQueue: { url: string; resolver: (value: Book | Episode) => void }[] = [];
  protected static getQueue: { url: string; resolver: (value: any) => void }[] = [];

  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
  }

  protected static addParser(): void {
    parserWebViewComponents.push({ ref: createRef<ParserWebViewComponent>(), isGetting: false });
  }

  public static async getIndex(url: string, type: "ilks" | "other"): Promise<Book | undefined> {
    if (type === "ilks") {
      const book = await this.getIndexIlks(url);
      if (book === undefined) {
        Log.error(new Error("Parser Error url:" + url));
      }
      return book;
    } else {
      const book = await this.getIndexOther(url);
      if (book === undefined) {
        Log.error(new Error("Parser Error url:" + url));
      }
      return book;
    }
  }

  protected static async getIndexIlks(url: string): Promise<Book | undefined> {
    await loaded;
    const bookDocument = new BookDocument({ bookId: url });
    console.log(url);
    await bookDocument.load();
    const episodeCollection = new EpisodeCollection({ bookId: url });
    await episodeCollection.load();
    const book = bookDocument.data;

    return {
      title: book.title,
      url: book._id ?? "",
      abstract: book.abstract,
      chapters: [
        {
          title: "title",
          episodes: _.sortBy(Object.values(episodeCollection.documents), "data.no").map((v) => ({
            title: v.data.subTitle,
            date: new Date(),
            link: url + "," + (v.data._id ?? ""),
          })),
        },
      ],
      author: {
        name: book.author.name,
        link: book.authorUid,
      },
    };
  }
  protected static async getIndexOther(url: string): Promise<Book | undefined> {
    await loaded;
    if (Platform.OS === "web") {
      const location = new URL(url);
      if (isImplementedExtention) {
        const data = await fetch(url);
        const novel = new IndexParser(location.href, await data.text(), selectors[location.hostname].index);
        return novel.get();
      }
      return undefined;
    } else {
      return new Promise<Book | undefined>((resolver) => {
        this.getQueue.push({ url, resolver });
        instance?.requestUpdate();
      });
    }
  }

  public static async getEpisode(url: string, type: "ilks" | "other"): Promise<Episode | undefined> {
    if (type === "ilks") {
      const episode = await this.getEpisodeIlks(url);
      if (episode === undefined) {
        Log.error(new Error("Parser Error url:" + url));
      }
      return episode;
    } else {
      const episode = await this.getEpisodeOther(url);
      if (episode === undefined) {
        Log.error(new Error("Parser Error url:" + url));
      }
      return episode;
    }
  }
  public static async getEpisodeIlks(url: string): Promise<Episode | undefined> {
    await loaded;

    const [bookId, episodeId] = url.split(",");
    const bookDocument = new BookDocument({ bookId: bookId });
    await bookDocument.load();
    const book = bookDocument.data;
    const episodeDocument = new EpisodeDocument({ bookId: bookId, episodeId: episodeId });
    await episodeDocument.load();
    const episode = episodeDocument.data;
    console.log(bookId, episodeId);
    return {
      url: url,
      title: {
        novel: book.title,
        chapter: episode.subTitle,
        sub: episode.subTitle,
      },
      text: {
        prev: episode.prev,
        main: episode.body,
        after: episode.after,
      },
      author: {
        name: book.author.name,
        link: book.author.name,
      },
      link: {
        prev: null,
        index: bookId,
        next: null,
      },
    };
  }
  public static async getEpisodeOther(url: string): Promise<Episode | undefined> {
    await loaded;

    if (Platform.OS === "web") {
      const location = new URL(url);
      if (isImplementedExtention) {
        const data = await fetch(url);
        const novel = new EpisodeParser(location.href, await data.text(), selectors[location.hostname].episode);
        return novel.get();
      }
      return undefined;
    } else {
      return new Promise<Episode | undefined>((resolver) => {
        this.getQueue.push({ url, resolver });
        instance?.requestUpdate();
      });
    }
  }

  constructor(props: {}) {
    super(props);
    instance = this;
    if (parserWebViewComponents.length <= 0) {
      ParserManager.addParser();
      ParserManager.addParser();
      ParserManager.addParser();
    }
  }
  public componentDidMount(): void {
    super.componentDidMount();
    instance = this;
    if (parserWebViewComponents.length <= 0) {
      ParserManager.addParser();
      ParserManager.addParser();
      ParserManager.addParser();
    }

    loadedResolver?.(true);
  }
  public componentWillUnmount(): void {
    super.componentWillUnmount();
    loaded = new Promise<boolean>((resolver) => (loadedResolver = resolver));
  }

  public componentDidUpdate(): void {
    super.componentDidUpdate();
    const removeList: number[] = [];

    if (parserWebViewComponents.filter((v) => v.ref?.current && v.ref?.current.webview).length <= 0) {
      setTimeout(() => {
        this.requestUpdate();
      }, 1000);
    }
    for (let i = 0; i < ParserManager.getQueue.length; i++) {
      const get = ParserManager.getQueue[i];

      const parser = parserWebViewComponents.find((v) => !v.isGetting && v.ref?.current && v.ref?.current.webview);
      const component = parser?.ref.current;

      if (!parser || !component) {
        break;
      }

      removeList.push(i);

      parser.isGetting = true;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await loaded;
        get.resolver(JSON.parse((await component.get(get.url)) ?? undefined));

        setTimeout(() => {
          parser.isGetting = false;
          this.requestUpdate();
        }, 100);
      })();
    }
    ParserManager.getQueue = ParserManager.getQueue.filter((_, i) => !removeList.includes(i));
  }

  public renderComponent(): JSX.Element {
    // TODO ParserWebViewComponent 増やす
    if (parserWebViewComponents.length < 10 && parserWebViewComponents.filter((v) => !v.isGetting).length < ParserManager.getQueue.length) {
      const count = ParserManager.getQueue.length - parserWebViewComponents.filter((v) => !v.isGetting).length;
      for (let i = 0; i < count; i++) {
        ParserManager.addParser();
      }
    }

    if (Platform.OS === "web") {
      return <View style={{ width: 0, height: 0 }}></View>;
    } else {
      return (
        <View style={{ width: 0, height: 0 }}>
          {parserWebViewComponents.map((v, i) => (
            <ParserWebViewComponent key={i.toString()} name="parser" ref={v.ref}></ParserWebViewComponent>
          ))}
        </View>
      );
    }
  }

  protected static parsers: ParserData[] | undefined;
  protected static lastGetParsersTime: number | undefined;
  public static async getAllParsers(): Promise<ParserData[]> {
    const parsers = this.parsers;
    if (parsers) {
      if (!this.lastGetParsersTime || performance.now() - this.lastGetParsersTime < 60 * 60 * 1000) {
        return parsers;
      }
    }

    const parserCollection = new ParserCollection({});
    await parserCollection.load();
    const ret = parserCollection.toArray().map((v) => v.data);
    this.parsers = ret;
    this.lastGetParsersTime = performance.now();
    return ret;
  }
  public static getUserParsers(): UserParsers | undefined {
    if (instance?.userDocument?.data?.parsers) {
      return instance?.userDocument.data.parsers;
    } else {
      return undefined;
    }
  }
  public static async addParserSetting(parserData: ParserData): Promise<void> {
    const userDocument = instance?.userDocument;
    if (userDocument && userDocument.data && parserData._id) {
      if (!userDocument.data.parsers) {
        userDocument.data.parsers = {};
      }
      userDocument.data.parsers[parserData._id] = { id: parserData._id, added: Date.now(), enable: true, data: parserData };
      await userDocument.save(true);
    }
  }
  public static async removeParserSetting(parserData: ParserData): Promise<void> {
    const userDocument = instance?.userDocument;
    if (userDocument && userDocument.data && parserData._id) {
      if (!userDocument.data.parsers) {
        userDocument.data.parsers = {};
      }
      delete userDocument.data.parsers[parserData._id];
      await userDocument.save(true);
    }
  }
}
