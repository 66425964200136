import { random } from "lodash";
import { Container, Text } from "native-base";
import NovelParser, { Book } from "novel-parser";
import React, { createRef } from "react";
import { Platform, View } from "react-native";
import { Col, Grid } from "react-native-easy-grid";
import { PanGestureHandler, ScrollView } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { BaseScene } from "@core/BaseScene";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { FooterBottomSheetContext } from "@core/FooterBottomSheetContext";
import { ParserManager } from "@core/ParserManager";
import { sharedState } from "@core/SharedState";
import { Spacer } from "@core/Spacer";
import { state } from "@core/State";
import styles, { themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { Router } from "~/router";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { UserBookstatusDocument } from "~/utilities/firebase/firestore/documents/user-bookstatus-documents";
import { CollapsableText } from "../Component/CollapsableText";
import { DetailAuthorButton } from "../Component/DetailAuthorButton";
import { DetailBookButton } from "../Component/DetailBookButton";
import { GridChildView } from "../Component/GridChildView";
import { GridView } from "../Component/GridView";
import { IconInfo } from "../Component/IconInfo";
import { UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";

export interface Episode extends NovelParser.EpisodeDescription {
  id: string;
  no: number;
  chapterTitle: string;
}

type type = "Home";
export class HomeScene extends BaseScene {
  static sceneName: type = "Home";
  static Params: undefined;

  @sharedState aaaaaa = "";
  @state book?: Book;
  @state episodes: Episode[] = [];
  @state url = "https://ncode.syosetu.com/n4418ha/";
  @state type: "ilks" | "other" = "other";
  @firestoreState userDocument?: UserDocument;
  // @firestoreState userBookstatusDocument?: UserBookstatusDocument;
  @state lastEpisodeId?: string;

  constructor(props: ScreenProp<type>) {
    super(props);
  }

  protected async initialize(): Promise<void> {
    await super.initialize();
    this.book = await ParserManager.getIndex(this.url, this.type);
    if (!this.book) {
      return;
    }

    // this.episodes = this.book.chapters.flatMap((chapter) => chapter.episodes.map((episode) => ({ ...episode, chapterTitle: chapter.title })));
    let i = 0;
    this.episodes = [];
    for (const chapter of this.book.chapters) {
      for (const episode of chapter.episodes) {
        this.episodes.push({
          id: (i + 1).toString(),
          no: i + 1,
          chapterTitle: chapter.title,
          ...episode,
        });
        i++;
      }
    }
  }
  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
    // await addFirestoreStateListener(this, UserBookstatusDocument, { uid: this.user.uid, bookId: url }, this.userBookstatusDocument);

    const episode = this.userDocument?.data?.lastEpisode;
    if (episode) {
      this.url = episode.bookId;
      this.type = "other";
      this.lastEpisodeId = episode.episodeId ?? "";
    }
  }

  // static options(prop: ScreenProp<type>): StackNavigationOptions {
  //   return {
  //     title: random(1000) + ":" + prop.route.key,
  //     headerStyle: {
  //       backgroundColor: "#f4511e",
  //     },
  //     headerTintColor: "#FF0",
  //     headerTitleStyle: {
  //       fontWeight: "bold",
  //     },
  //   };
  // }

  static get sceneTitle(): string {
    return "ホーム";
  }

  scroll = createRef<ScrollView>();
  gestureRef = createRef<PanGestureHandler>();

  enabled = false;

  _lastScrollY = new Animated.Value(0);

  renderComponent(): JSX.Element {
    // return (
    //   <View style={{ backgroundColor: "gray", width: "100%", height: "100%" }}>
    //     <View style={{ backgroundColor: "orange", width: "200%", height: "100%" }}>
    //       <EpisodeReaderSnapView enabled={this.enabled} simultaneousHandlers={this.scroll} initialSnapIndex={0}></EpisodeReaderSnapView>
    //     </View>
    //   </View>
    // );

    return (
      <Container style={styles.container}>
        <ScrollView horizontal={false} style={{ alignSelf: "stretch" }}>
          <Grid style={{ backgroundColor: "#687784", height: 160 }}>
            <Col style={{ backgroundColor: "#00CE9F", height: 160, margin: 20 }}></Col>
            <Col style={{ backgroundColor: "#00CE9F", height: 160, margin: 20 }}></Col>
          </Grid>
          <Spacer vertical space={50}></Spacer>
          <GridView>
            <GridChildView useBigTitle oneColumn title="前回の続きから読む">
              <FooterBottomSheetContext.Consumer>
                {(value) => {
                  const book = this.book;
                  const episode = this.episodes.find(v=>v.id == this.lastEpisodeId) ?? this.episodes?.[0];
                  const type = this.type;
                  if(!episode && !book){
                    return <></>;
                  }
                  return (
                    <DetailBookButton
                      onPress={() => {
                        // this.navigation.navigate("Detail");
                        if (Platform.OS === "web") {
                          // window.open(location.origin + "/Episode?" + queryString.stringify({ ...this.episodes[0], type: this.type }), "_blank", "noreferrer");
                          Router.appNavigate("EpisodeReader", true);
                        } else {
                          value?.footerBottomSheetRef?.current?.openEpisode({ ...episode, type: type }, "");
                        }
                      }}
                      episode={episode}
                      book={book}
                    ></DetailBookButton>
                  );
                }}
              </FooterBottomSheetContext.Consumer>
            </GridChildView>
            <GridChildView useBigTitle title="本日のおすすめ">
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                <View style={{ flexDirection: "row", flex: 1, flexGrow: 1 }}>
                  {/* <BookshelfBookIcon></BookshelfBookIcon> */}
                  <View
                    style={{
                      alignContent: "space-between",
                      flexGrow: 1,
                      flexShrink: 1,
                      flexDirection: "column",
                      marginHorizontal: 5,
                    }}
                  >
                    <Text
                      style={[
                        styles.smallFont,
                        styles.textBold,
                        {
                          color: themeColor.subText,
                        },
                      ]}
                    >
                      ノンジャンル » ノンジャンル
                    </Text>
                    <Text
                      style={[
                        styles.mediumFont,
                        styles.textBold,
                        {
                          lineHeight: 19,
                          color: themeColor.mainText,
                        },
                      ]}
                    >
                      題名などという贅沢なものはこの小説には無い。
                    </Text>
                    <Spacer vertical space={1} />
                    <DetailAuthorButton
                      authorName="森下 樹"
                      authorId="@phenico"
                      bookName="題名などという贅沢なものはこの小説には無い。"
                      genre="ノンジャンル » ノンジャンル"
                    ></DetailAuthorButton>
                    <View style={{ flexDirection: "row", flexGrow: 1, justifyContent: "flex-start" }}>
                      <IconInfo
                        title="予想読了時間"
                        icon={require("@assets/images/human.png")}
                        data="30"
                        unitName="分"
                        accentText="少し長め"
                        additionalData="12240"
                        additionalDataUnitName="文字"
                      ></IconInfo>
                      <IconInfo title="本棚登録" color={themeColor.favorite} icon={require("@assets/images/favorite_icon.png")} data="334" unitName="人"></IconInfo>
                    </View>
                  </View>
                </View>
                <CollapsableText style={{ flex: 1 }}></CollapsableText>
              </View>
            </GridChildView>
          </GridView>
        </ScrollView>
      </Container>
    );
  }
}
export const HomeScene2: SceneBase<type> & typeof HomeScene = HomeScene;
