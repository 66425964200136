import React from "react";
import { themeColor } from "@core/Styles";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { AccountScene } from "../Account/AccountScene";

const AccountStack = createStackNavigator();

export function AccountStackScreen(): JSX.Element {
  return (
    <AccountStack.Navigator
      screenOptions={{
        headerTintColor: themeColor.mainText,
        headerStyle: {
          backgroundColor: themeColor.bar,
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(AccountStack.Screen, AccountScene)}
      {StackScreenRender(AccountStack.Screen, Detail2Scene)} */}
      {StackScreenRenderAll(AccountStack.Screen, AccountScene)}
    </AccountStack.Navigator>
  );
}
