import _, { isEqual } from "lodash";
import React, { createRef } from "react";
import { Platform, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView, { WebViewMessageEvent } from "react-native-webview";
import { BaseScene } from "@core/BaseScene";
import { CollapsableTab, CollapsableTabView } from "@core/CollapsableTabView";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { StackNavigationOptions } from "@react-navigation/stack";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BookshelfCollection } from "~/utilities/firebase/firestore/documents/bookshelf-documents";
import { defaultSearchTabs, UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { MiniIconTitleButton } from "../Component/MiniIconTitleButton";
import { DialogButton, DialogInput } from "../Component/SimpleDialog";
import { TextLabel } from "../Component/TextLabel";
import { DialogComponent } from "../Core/DialogComponent";
import { ParserManager } from "../Core/ParserManager";
import { Spacer } from "../Core/Spacer";
import { state } from "../Core/State";
import { themeColor } from "../Core/Styles";
import { WebViewComponent } from "../Core/WebViewComponent";

type type = "Search";
class SearchSceneTab {
  tabName: string;
  url: string;
  element: CollapsableTab;
  webViewRef: React.RefObject<WebViewComponent>;

  constructor(tabName: string, url: string, element: CollapsableTab, webViewRef: React.RefObject<WebViewComponent>) {
    this.tabName = tabName;
    this.url = url;
    this.element = element;
    this.webViewRef = webViewRef;
  }

  changeCurrentUrl(url: string) {
    this.url = url;
  }

  async addBookmarkToBookshelf(title: string, targetBookshelf: string): Promise<void> {
    await Bookshelf.addBookmark(this.url, title, targetBookshelf);
  }

  async addBookToBookshelf(targetBookshelf: string): Promise<void> {
    await Bookshelf.addBook(this.url, targetBookshelf);
  }
}

class scene extends BaseScene {
  static sceneName: type = "Search";
  static Params: undefined;

  tabDatas: { name: string; url: string }[];
  @state tabs: SearchSceneTab[] = [];
  @state webViewRefs: React.RefObject<WebViewComponent>[] = [];

  @firestoreState userDocument?: UserDocument;
  @firestoreState bookshelfCollection?: BookshelfCollection;

  targetBookshelf = "";

  tabsChangeCount = 0;

  constructor(props: ScreenProp<type>) {
    super(props);

    this.tabDatas = defaultSearchTabs;
    this.tabs = this.tabDatas.map((v) => this.createTab(v.name, v.url));
    this.tabsChangeCount++;
  }

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerTitle: () => {
        return undefined;
      },
      headerShown: false,
    };
  }

  onLoadStart(): void {}
  onMessage(event: WebViewMessageEvent): void {}

  protected async onUserChanged(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await addFirestoreStateListener(this, BookshelfCollection, { uid: this.user.uid }, this.bookshelfCollection);

      // TODO: 適当な本棚に入れるのはなんかちがう
      const bookshelf = _.sortBy(this.bookshelfCollection?.toArray(), (v) => v.data.index).find((v) => v.data.type === "bookshelf");
      this.targetBookshelf = bookshelf?.id ?? "";
    })();
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
    if (this.userDocument) {
      const data = this.userDocument.data;
      if (!data.searchTabs) {
        data.searchTabs = defaultSearchTabs;
        await this.userDocument.save();
      }
      this.tabDatas = data.searchTabs;
      this.tabs = this.tabDatas.map((v) => this.createTab(v.name, v.url));
      this.tabsChangeCount++;
    }
  }

  createTab(tabName: string, url: string): SearchSceneTab {
    const webViewRef = createRef<WebViewComponent>();

    const searchSceneTab = new SearchSceneTab(
      tabName,
      url,
      new CollapsableTab(
        tabName,
        (
          <View style={{ width: "100%", height: "100%" }}>
            <WebViewComponent
              ref={webViewRef}
              url={url}
              jsName={"search"}
              onLoadStart={(_webview) => {
                this.onLoadStart();
                return true;
              }}
              onNavigationStateChange={(_webview, event) => {
                searchSceneTab.changeCurrentUrl(event.url);
              }}
              onShouldStartLoadWithRequest={(_webview, event) => {
                const parsers = ParserManager.getUserParsers();

                const regexes = [
                  ...Object.values(parsers ?? {}).map((v) => new RegExp(v.data.metaData.url.index)),
                  ...Object.values(parsers ?? {}).map((v) => new RegExp(v.data.metaData.url.episode)),
                ];

                const isShowBookSummary = regexes.some((regex) => {
                  return regex.test(event.url);
                });

                if (isShowBookSummary) {
                  this.currentScene?.navigation.navigate("BookSummary", {
                    url: event.url,
                    type: "other",
                  });
                }

                return !isShowBookSummary;
              }}
            />
            <SafeAreaView edges={["left", "right"]} style={{ backgroundColor: themeColor.bar }}>
              <View
                style={{
                  flexGrow: 1,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  backgroundColor: themeColor.bar,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <TextLabel boldWeight>見つける</TextLabel> */}
                <MiniIconTitleButton
                  width={60}
                  icon={require("@assets/images/arrow_left_white.png")}
                  onPress={() => {
                    webViewRef.current?.webview?.goBack();
                  }}
                  height={40}
                ></MiniIconTitleButton>
                <MiniIconTitleButton
                  width={60}
                  icon={require("@assets/images/arrow_right_white.png")}
                  onPress={() => {
                    webViewRef.current?.webview?.goForward();
                  }}
                  height={40}
                ></MiniIconTitleButton>
                <Spacer style={{ flexGrow: 1 }}></Spacer>
                <MiniIconTitleButton
                  width={130}
                  title="ブックマークを本棚に追加"
                  icon={require("@assets/images/icon_safari.png")}
                  onPress={async () => {
                    const title = (await webViewRef.current?.methodAsync("getTitle")) as string;
                    await searchSceneTab.addBookmarkToBookshelf(title, this.targetBookshelf);
                  }}
                  height={40}
                ></MiniIconTitleButton>
              </View>
            </SafeAreaView>
          </View>
        )
      ),
      webViewRef
    );

    return searchSceneTab;
  }

  @state dialogText = "";
  renderComponent(): JSX.Element {
    if (this.userDocument?.data.searchTabs && !isEqual(this.tabDatas, this.userDocument.data.searchTabs)) {
      this.tabDatas = this.userDocument.data.searchTabs;
      this.tabs = this.tabDatas.map((v) => this.createTab(v.name, v.url));
      this.tabsChangeCount++;
    }
    return (
      <View style={{ width: "100%", height: "100%", flexDirection: "column-reverse" }}>
        <CollapsableTabView key={this.tabsChangeCount} headerContents={<View></View>} canSwipe={Platform.OS === "ios"}>
          {this.tabs.map((tab) => tab.element)}
        </CollapsableTabView>
        <SafeAreaView edges={["top", "left", "right"]} style={{ backgroundColor: themeColor.bar }}>
          <View
            style={{ flexGrow: 1, height: 50, paddingHorizontal: 10, backgroundColor: themeColor.bar, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
          >
            <TextLabel boldWeight>見つける</TextLabel>
            <Spacer style={{ flexGrow: 1 }}></Spacer>
            <MiniIconTitleButton
              width={70}
              title="サイト追加"
              icon={require("@assets/images/icon_safari.png")}
              onPress={() => {
                const dialog = DialogComponent.createDialog();
                DialogComponent.showCustom(
                  dialog,
                  "サイト追加",
                  "追加するサイトの名前とURLを設定してください",
                  [
                    {
                      itemType: "input",
                      placeholder: "名前",
                      onChangeText: (name) => {
                        const url = (dialog.children[1] as DialogInput)?.value ?? "";
                        const button = dialog.children[2] as DialogButton;
                        const isValid = name && isValidHttpUrl(url);
                        button.color = isValid ? undefined : "gray";
                        // button.disabled = !isValid;
                      },
                    },
                    {
                      itemType: "input",
                      placeholder: "URL (例: https://example.com)",
                      onChangeText: (url) => {
                        const name = (dialog.children[0] as DialogInput)?.value ?? "";
                        const button = dialog.children[2] as DialogButton;
                        const isValid = name && isValidHttpUrl(url);
                        button.color = isValid ? undefined : "gray";
                        // button.disabled = !isValid;
                      },
                    },
                    {
                      itemType: "button",
                      label: "OK",
                      color: "gray",
                      bold: true,
                      onPress: async () => {
                        const name = (dialog.children[0] as DialogInput)?.value ?? "";
                        if (!name) {
                          DialogComponent.showNotify("エラー", "名前を入力してください");
                          return;
                        }
                        const url = (dialog.children[1] as DialogInput)?.value ?? "";
                        if (!isValidHttpUrl(url)) {
                          DialogComponent.showNotify("エラー", "urlを正しく入力してください");
                          return;
                        }
                        if (this.userDocument) {
                          if (!this.userDocument.data.searchTabs) {
                            this.userDocument.data.searchTabs = defaultSearchTabs;
                          }
                          this.userDocument.data.searchTabs.push({ name, url });
                          await this.userDocument.save(true);
                        }
                        DialogComponent.close(dialog);
                      },
                    },
                    {
                      itemType: "button",
                      label: "キャンセル",
                      onPress: () => {
                        DialogComponent.close(dialog);
                      },
                    },
                  ]
                  // (url: string) => {
                  //   const isValid = isValidHttpUrl(url);
                  //   if (isValid) {
                  //   } else {
                  //     showDialogNotify("エラー", "urlを入力してください");
                  //   }
                  // }
                );
              }}
              height={45}
            ></MiniIconTitleButton>
          </View>
        </SafeAreaView>
      </View>
    );
  }
}
export const SearchScene: SceneBase<type> & typeof scene = scene;

function isValidHttpUrl(str: string): boolean {
  return /^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/.test(str);
}
