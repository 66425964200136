import { WebViewSource } from "react-native-webview/lib/WebViewTypes";
import { ParserManager } from "./ParserManager";
import { state } from "./State";
import { generateUuid } from "./uuid";
import { WebViewComponent } from "./WebViewComponent";

export class ParserWebViewComponent extends WebViewComponent {
  @state url?: string;

  protected promise?: Promise<string>;
  protected resolver?: (value: string) => void;
  protected uid = generateUuid();
  protected useragent?: string = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.106 Safari/537.36";

  protected get source(): WebViewSource | undefined {
    let cookies = "";
    if (this.url && this.url?.indexOf("syosetu.org") >= 0) {
      cookies = "over18=off";
    }
    // if (this.url?.indexOf("noc.syosetu.com")) { }
    if (this.url && this.url?.indexOf("syosetu.com") >= 0) {
      cookies = "over18=yes";
    }

    return this.url ? { uri: this.url, headers: { Cookie: cookies } } : this.file?.source;
  }

  public async get(url: string): Promise<string> {
    const webview = this.webview;
    if (!webview) {
      throw "WebViewカレントないけど";
    }
    if (this.promise) {
      throw "まだ前のやつ終わってない";
    }
    const start = performance.now();

    this.promise = new Promise<string>((resolver) => {
      this.resolver = resolver;
    });

    // ページ遷移をjsですると、アプリが開いたりして問題が出る
    // this.injectJavaScript(`window.location.href = decodeURI("${encodeURI(url)}");`);
    if (this.url === url) {
      setTimeout(() => {
        webview.reload();
      }, 10);
    } else {
      this.url = url;
    }
    const ret = await this.promise;

    if (typeof webview.clearHistory === "function") {
      webview.clearHistory();
    }

    const end = performance.now();
    console.log("total: " + (end - start), this.uid);

    return ret;
  }
  protected selector?: string;

  protected onMessage(str: string): void {
    const colonIndex = str.indexOf(":");
    const method = colonIndex >= 0 ? str.substring(0, str.indexOf(":")) : str;
    const data = colonIndex >= 0 ? str.substring(str.indexOf(":") + 1) : undefined;

    switch (method) {
      case "return":
        this.promise = undefined;
        if (this.resolver) {
          this.resolver(data ?? "null");
        }
        return;
      case "initialized":
        this.method(
          "parse",
          Object.values(ParserManager.getUserParsers() ?? {}).map((v) => v.data)
        );
        return;
    }
    super.onMessage(str);
  }

  protected onLoadStart(): void {
    //
  }
  protected onLoad(): void {
    //
  }
  protected onLoadEnd(): void {
    super.onLoadEnd();
    this.webview?.stopLoading();
  }
}
