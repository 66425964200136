import { Container } from "native-base";
import React from "react";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { state } from "@core/State";
import styles from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { Router } from "~/router";
import { UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { Episode } from "../BookSummary/BookSummaryScene";
import { EpisodeReaderSnapView } from "../Component/EpisodeReaderSnapView";
import { BaseScene } from "../Core/BaseScene";
import { SceneBase, ScreenProp } from "../Core/Navigator";

type type = "Episode";

class scene extends BaseScene {
  static sceneName: type = "Episode";
  static Params: Episode & { type: "ilks" | "other" };
  @state param: Episode & { type: "ilks" | "other" };
  @firestoreState userDocument?: UserDocument;

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerTitle: () => {
        return undefined;
      },
      // headerShown: false,
    };
  }

  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
  }

  constructor(props: ScreenProp<type>) {
    super(props);
    this.param = { ...props.route.params };
  }

  protected renderComponent(): JSX.Element {
    const option = this.userDocument?.data.option;
    let menuDirection: "column" | "row" | "row-reverse";

    if (option) {
      if (option.scrollType === "scroll") {
        if (option.textDirection === "vertical") {
          menuDirection = "row-reverse";
        } else {
          menuDirection = "column";
        }
      } else {
        if (option.scrollType === "pagingVertical") {
          if (option.textDirection === "vertical") {
            menuDirection = "column";
          } else {
            menuDirection = "column";
          }
        } else {
          if (option.textDirection === "vertical") {
            menuDirection = "row-reverse";
          } else {
            menuDirection = "row";
          }
        }
      }
    } else {
      menuDirection = "row";
    }
    return (
      <Container style={styles.container}>
        <EpisodeReaderSnapView
          type={this.param.type ?? "ilks"}
          id={this.param?.id ?? ""}
          no={this.param?.no ?? 0}
          title={this.param?.title ?? ""}
          url={this.param?.link ?? ""}
          onShowMenu={(isShow: boolean) => {}}
          onShowSettings={() => Router.appNavigate("Settings", true)}
          initialSnapIndex={1}
          direction={menuDirection}
        ></EpisodeReaderSnapView>
      </Container>
    );
  }
}
export const EpisodeScene: SceneBase<type> & typeof scene = scene;
