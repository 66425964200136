import React from "react";
import { EventMapBase, NavigationState, ParamListBase, RouteConfig, RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AccountScene } from "../Account/AccountScene";
import { BookshelfScene } from "../Bookshelf/BookshelfScene";
import { BookSummaryScene } from "../BookSummary/BookSummaryScene";
import { Detail2Scene } from "../Detail2Scene";
import { DetailScene } from "../DetailScene";
import { EpisodeScene } from "../Episode/EpisodeScene";
import { HomeScene } from "../Home/HomeScene";
import { LoginScene } from "../Login/LoginScene";
import { SearchScene } from "../Search/SearchScene";
import { SettingsScene } from "../Settings/SettingsScene";
import { SiteExtensionScene } from "../SiteExtensionScene/SiteExtensionScene";
import { WriteScene } from "../Write/WriteScene";
import { BaseScene } from "./BaseScene";

export interface SceneBase<RouteName extends keyof RootStackParamList> {
  sceneName: RouteName;
  Params: unknown;
}

export function StackScreenRenderAll<
  RouteName extends keyof RootStackParamList,
  ParamList extends ParamListBase,
  State extends NavigationState,
  ScreenOptions extends {},
  EventMap extends EventMapBase
>(
  Screen: <RouteName2 extends keyof ParamList>(_: RouteConfig<ParamList, RouteName2, State, ScreenOptions, EventMap>) => null,
  scene: SceneBase<RouteName> & typeof BaseScene
): JSX.Element[] {
  return [StackScreenRender(Screen, scene), ...SceneList.filter((v) => scene.sceneName !== v.sceneName).map((v) => StackScreenRender(Screen, v))];
}
export function StackScreenRender<
  RouteName extends keyof RootStackParamList,
  ParamList extends ParamListBase,
  State extends NavigationState,
  ScreenOptions extends {},
  EventMap extends EventMapBase
>(
  Screen: <RouteName2 extends keyof ParamList>(_: RouteConfig<ParamList, RouteName2, State, ScreenOptions, EventMap>) => null,
  scene: SceneBase<RouteName> & typeof BaseScene
): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Screen key={scene.sceneName} name={scene.sceneName} options={(scene as any)?.options} component={GetRender} />;
}

export class GetRender<RouteName extends keyof RootStackParamList> extends React.Component<ScreenProp<RouteName>> {
  scene: SceneBase<RouteName> & typeof BaseScene;
  constructor(props: ScreenProp<RouteName>) {
    super(props);
    const scene = SceneList.find((v) => v.sceneName === props.route.name);
    this.scene = (scene as unknown) as SceneBase<RouteName> & typeof BaseScene;
  }

  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Scene = this.scene as any;
    return <Scene key={Scene.sceneName} navigation={this.props.navigation} route={this.props.route} />;
  }
}

export const SceneList = [
  HomeScene,
  AccountScene,
  BookshelfScene,
  WriteScene,
  DetailScene,
  Detail2Scene,
  SearchScene,
  BookSummaryScene,
  EpisodeScene,
  SettingsScene,
  LoginScene,
  SiteExtensionScene,
];

export type RootStackParamList = {
  [HomeScene.sceneName]: typeof HomeScene.Params;
  [AccountScene.sceneName]: typeof AccountScene.Params;
  [BookshelfScene.sceneName]: typeof BookshelfScene.Params;
  [WriteScene.sceneName]: typeof WriteScene.Params;
  [DetailScene.sceneName]: typeof DetailScene.Params;
  [Detail2Scene.sceneName]: typeof Detail2Scene.Params;
  [SearchScene.sceneName]: typeof SearchScene.Params;
  [BookSummaryScene.sceneName]: typeof BookSummaryScene.Params;
  [EpisodeScene.sceneName]: typeof EpisodeScene.Params;
  [SettingsScene.sceneName]: typeof SettingsScene.Params;
  [LoginScene.sceneName]: typeof LoginScene.Params;
  [SiteExtensionScene.sceneName]: typeof SiteExtensionScene.Params;
};

export type ScreenProp<type extends keyof RootStackParamList> = {
  navigation: StackNavigationProp<RootStackParamList, type>;
  route: RouteProp<RootStackParamList, type>;
};
