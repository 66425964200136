import { Text } from "native-base";
import React from "react";
import { ColorValue, View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { IconText } from "./IconText";

export interface Props extends BaseProps {
  title: string;
  icon?: string;
  data: string;
  unitName: string;
  accentText?: string;
  additionalData?: string;
  additionalDataUnitName?: string;
  color?: ColorValue;
}

export class IconInfo extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={[{ alignItems: "center", width: this.props.icon ? 100 : 74, minWidth: this.props.icon ? 100 : 74, margin: 5 }, this.props.style]}>
        <Text
          style={[
            styles.smallFont,
            styles.textBlack,
            {
              color: this.props.color ?? themeColor.subText,
            },
          ]}
        >
          {this.props.title}
        </Text>
        <IconText iconWidth={32} iconHeight={32} iconRadius={16} space={5} icon={this.props.icon}>
          <View style={{ flexDirection: "row", alignItems: "baseline" }}>
            <Text
              style={[
                styles.veryLargeFont,
                styles.text,
                {
                  color: this.props.color ?? themeColor.mainText,
                },
              ]}
            >
              {this.props.data}
            </Text>
            <Text
              style={[
                styles.smallFont,
                styles.textBold,
                {
                  color: this.props.color ?? themeColor.mainText,
                },
              ]}
            >
              {this.props.unitName}
            </Text>
          </View>
        </IconText>
        <Text
          style={[
            styles.smallFont,
            styles.textBold,
            {
              color: themeColor.accent,
              display: this.props.additionalData ? "flex" : "none",
            },
          ]}
        >
          {this.props.accentText}
        </Text>
        <View style={{ display: this.props.additionalData ? "flex" : "none", flexDirection: "row", alignItems: "baseline" }}>
          <Text
            style={[
              styles.smallFont,
              styles.text,
              {
                color: this.props.color ?? themeColor.subText,
              },
            ]}
          >
            {this.props.additionalData}
          </Text>
          <Text
            style={[
              styles.verySmallFont,
              styles.text,
              {
                color: this.props.color ?? themeColor.subText,
              },
            ]}
          >
            {this.props.additionalDataUnitName}
          </Text>
        </View>
      </View>
    );
  }
}
