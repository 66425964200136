export function generateUuid(format = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"): string {
  const array = format.split("");
  for (let i = 0, len = array.length; i < len; i++) {
    switch (array[i]) {
      case "x":
        array[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        array[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return array.join("");
}
