import { Text } from "native-base";
import React from "react";
import { ScrollView, View } from "react-native";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { state } from "@core/State";
import styles, { themeColor } from "@core/Styles";
import { defaultUserOption } from "@webview/interface/episode";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { UserDocument, UserOption } from "~/utilities/firebase/firestore/documents/user-documents";
import { GridView } from "../Component/GridView";
import { SettingsColorPicker } from "../Component/SettingsColorPicker";
import { SettingsSelector } from "../Component/SettingsSelector";
import { SettingsSlider } from "../Component/SettingsSlider";
import { BaseScene } from "../Core/BaseScene";

type type = "Settings";

export const fullColors = [
  "#000000",
  "#111111",
  "#222222",
  "#333333",
  "#444444",
  "#555555",
  "#666666",
  "#777777",
  "#888888",
  "#999999",
  "#AAAAAA",
  "#BBBBBB",
  "#CCCCCC",
  "#DDDDDD",
  "#EEEEEE",
  "#FFFFFF",
  "red",
  "#CD5C5C",
  "#DC143C",
  "#B22222",
  "#FFC0CB",
  "#FF69B4",
  "#C71585",
  "#DB7093",
  "#FFA07A",
  "#FF6347",
  "#FF8C00",
  "#FFA500",
  "#FFD700",
  "yellow",
  "#FFFFE0",
  "#FFEFD5",
  "#FFDAB9",
  "#EEE8AA",
  "#BDB76B",
  "#ADFF2F",
  "#32CD32",
  "#90EE90",
  "#00FF7F",
  "#3CB371",
  "#228B22",
  "green",
  "#006400",
  "#6B8E23",
  "#808000",
  "#66CDAA",
  "#008080",
  "cyan",
  "#E0FFFF",
  "#7FFFD4",
  "#40E0D0",
  "#5F9EA0",
  "#4682B4",
  "#B0E0E6",
  "#87CEFA",
  "#00BFFF",
  "#1E90FF",
  "blue",
  "#4169E1",
  "#0000CD",
  "#000080",
  "#D8BFD8",
  "#EE82EE",
  "magenta",
  "#9370DB",
  "#800080",
  "#4B0082",
  "#7B68EE",
  "#FFF8DC",
  "#451722",
  "#F5DEB3",
  "#DAA520",
  "#D2691E",
  "#8B4513",
  "#A52A2A",
  "#800000",
  "#778899",
  "#2F4F4F",
  "#E6E6FA",
  "#FFFAFA",
  "#F0FFF0",
  "#F5FFFA",
  "#F0FFFF",
  "#F0F8FF",
  "#F8F8FF",
  "#F5F5F5",
  "#FFF5EE",
  "#F5F5DC",
  "#FDF5E6",
  "#FFFFF0",
  "#FAEBD7",
  "#FAF0E6",
  "#FFF0F5",
  "#FFE4E1",
];
class scene extends BaseScene {
  static sceneName: type = "Settings";
  static Params: undefined;
  @firestoreState userDocument?: UserDocument;
  @state modifiedOption: UserOption = defaultUserOption;

  constructor(props: ScreenProp<type>) {
    super(props);
  }

  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
    this.modifiedOption = this.userDocument?.data?.option ?? defaultUserOption;

    return;
  }

  protected setOption(): void {
    if (this.userDocument?.data) {
      this.userDocument.data.option = this.modifiedOption;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.userDocument.save();
    }
  }

  public componentWillUnmount(): void {
    this.setOption();
    super.componentWillUnmount();
  }

  protected renderComponent(): JSX.Element {
    return (
      <View style={{ backgroundColor: themeColor.base, alignItems: "center" }}>
        <ScrollView style={{ maxWidth: 1080, width: "100%" }}>
          <GridView>
            <Text style={[{ width: "100%" }, styles.h1]}>文字設定</Text>
            <SettingsSlider
              title="フォントサイズ"
              minValue={5}
              maxValue={80}
              unit="pt"
              description="小説本文で使用する文字の大きさを指定します。"
              value={this.modifiedOption.fontSize}
              onChange={(value) => {
                this.modifiedOption.fontSize = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsColorPicker
              title="文字色"
              description="小説画面の文字色を設定します。"
              value={this.modifiedOption.fontColor}
              onChange={(color) => {
                this.modifiedOption.fontColor = String(color);
                this.requestUpdate();
              }}
            >
              {/* {["#000000", "#FFFFFF", "#451722", "#FFD700", "#DC143C"]} */}
              {fullColors}
            </SettingsColorPicker>
            <SettingsColorPicker
              title="背景色"
              description="小説画面の背景色を設定します。"
              value={this.modifiedOption.backgroundColor}
              onChange={(color) => {
                this.modifiedOption.backgroundColor = String(color);
                this.requestUpdate();
              }}
            >
              {/* {["#FFFFFFFF", "#000000FF", "#FFF8DCFF", "#FEECDC"]} */}
              {fullColors}
            </SettingsColorPicker>
            <SettingsColorPicker
              title="ルビの色"
              description="小説画面のルビ（読みがな）色を設定します。"
              value={this.modifiedOption.rubyColor}
              onChange={(color) => {
                this.modifiedOption.rubyColor = String(color);
                this.requestUpdate();
              }}
            >
              {["#FFFFFFFF", "#000000FF", "#FFFF00FF", "#633112"]}
            </SettingsColorPicker>
            <Text style={[{ width: "100%" }, styles.h1]}>ビューア設定</Text>
            <SettingsSelector
              fullWidthNeeded
              title="テキストの方向"
              description="小説本文のテキストの方向を縦書きまたは横書きに変更できます。"
              value={this.modifiedOption.textDirection}
              onChange={(index) => {
                this.modifiedOption.textDirection = index === "vertical" ? "vertical" : "horizontal";
                this.requestUpdate();
              }}
            >
              {[
                { id: "vertical", label: "縦書き" },
                { id: "horizontal", label: "横書き" },
              ]}
            </SettingsSelector>
            <SettingsSlider
              title="文字間隔"
              minValue={0}
              maxValue={5}
              unit="px"
              description="小説本文の文字と文字のスペースを広げたり狭めたりできます。"
              value={this.modifiedOption.letterSpacing}
              onChange={(value) => {
                this.modifiedOption.letterSpacing = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSlider
              title="行間"
              minValue={0}
              maxValue={20}
              unit="px"
              description="小説本文の行と行のスペースを広げたり狭めたりできます。"
              value={this.modifiedOption.lineSpacing}
              onChange={(value) => {
                this.modifiedOption.lineSpacing = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSlider
              title="マージン天"
              minValue={0}
              maxValue={200}
              unit="px"
              description="小説本文の上側(天)の余白を広げたり狭めたりできます。"
              value={this.modifiedOption.marginTop}
              onChange={(value) => {
                this.modifiedOption.marginTop = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSlider
              title="マージン地"
              minValue={0}
              maxValue={200}
              unit="px"
              description="小説本文の下側(地)の余白を広げたり狭めたりできます。"
              value={this.modifiedOption.marginBottom}
              onChange={(value) => {
                this.modifiedOption.marginBottom = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSlider
              title="マージン左"
              minValue={0}
              maxValue={200}
              unit="px"
              description="小説本文の左側の余白を広げたり狭めたりできます。"
              value={this.modifiedOption.marginLeft}
              onChange={(value) => {
                this.modifiedOption.marginLeft = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSlider
              title="マージン右"
              minValue={0}
              maxValue={200}
              unit="px"
              description="小説本文の右側の余白を広げたり狭めたりできます。"
              value={this.modifiedOption.marginRight}
              onChange={(value) => {
                this.modifiedOption.marginRight = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            <SettingsSelector
              title="スクロールタイプ"
              description="小説画面でのページめくりの方法を選択できます。\nページングにすると、1画面が1ページ扱いになり、左右スライドでページめくりができます。"
              value={this.modifiedOption.scrollType}
              onChange={(id) => {
                this.modifiedOption.scrollType = id === "scroll" ? "scroll" : id === "pagingVertical" ? "pagingVertical" : "pagingHorizontal";
                this.requestUpdate();
              }}
            >
              {[
                { id: "scroll", label: "通常スクロール" },
                { id: "pagingVertical", label: "縦ページング" },
                { id: "pagingHorizontal", label: "横ページング" },
              ]}
            </SettingsSelector>
            <SettingsSlider
              title="改ページとして扱う連続改行の行数"
              minValue={0}
              maxValue={10}
              unit="行"
              description="スクロールタイプをページングに設定している場合、改行が連続した時に改ページとして扱います。\n 0の時は改ページを行いません。"
              value={this.modifiedOption.newPageLine}
              onChange={(value) => {
                this.modifiedOption.newPageLine = value;
                this.requestUpdate();
              }}
            ></SettingsSlider>
            {/* <GridChildView title="ステータスバーの表示">
            <View></View>
          </GridChildView> */}
          </GridView>
        </ScrollView>
      </View>
    );
  }
}

export const SettingsScene: SceneBase<type> & typeof scene = scene;
