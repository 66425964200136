import React from "react";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { BookshelfScene } from "../Bookshelf/BookshelfScene";

const BookshelfStack = createStackNavigator();

export function BookshelfStackScreen(): JSX.Element {
  return (
    <BookshelfStack.Navigator
      screenOptions={{
        title: "本棚",
        headerTintColor: "white",
        headerStyle: {
          backgroundColor: "transparent",
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        headerBackTitleVisible: false,
        headerTransparent: true,
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="screen"
    >
      {/* {StackScreenRender(BookshelfStack.Screen, BookshelfScene)}
      {StackScreenRender(BookshelfStack.Screen, Detail2Scene)} */}
      {StackScreenRenderAll(BookshelfStack.Screen, BookshelfScene)}
    </BookshelfStack.Navigator>
  );
}
