import { Text } from "native-base";
import React from "react";
import { Image, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, BaseProps, State } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import styles, { themeColor } from "@core/Styles";
import { TextLabel } from "./TextLabel";

export interface Props extends BaseProps {
  title: string;
  authorName: string;
  genre: string;
  type: "book" | "bookGroup";
  thumbnailUrl?: string; //Imageにしても良い？
  onPress: () => void;
  scale?: number;
}

export class BookshelfBookButton extends BaseComponent<Props> {
  @sharedState bookshelfScale = 1.0;

  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State, nextContext: unknown): boolean {
    return super.shouldComponentUpdate(nextProps, nextState, nextContext);
  }

  renderComponent(): JSX.Element {
    if (this.props.type === "bookGroup") {
      return (
        <View
          style={{
            width: 90 * (this.props.scale ?? this.bookshelfScale),
            height: 129 * (this.props.scale ?? this.bookshelfScale),
            margin: 4 * (this.props.scale ?? this.bookshelfScale),
          }}
        >
          <View
            style={{
              width: 90 * (this.props.scale ?? this.bookshelfScale),
              height: 129 * (this.props.scale ?? this.bookshelfScale),
              position: "absolute",
              overflow: "hidden",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Image
              style={{ width: 16 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "flex-start" }}
              resizeMode={"contain"}
              source={require("@assets/images/book_bungei_spine.png")}
            ></Image>
            <Image
              style={{ width: 16 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "flex-start" }}
              resizeMode={"contain"}
              source={require("@assets/images/book_fantasy_spine.png")}
            ></Image>
            <Image
              style={{ width: 16 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "flex-start" }}
              resizeMode={"contain"}
              source={require("@assets/images/book_nongenre_spine.png")}
            ></Image>
            <Image
              style={{ width: 16 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "flex-start" }}
              resizeMode={"contain"}
              source={require("@assets/images/book_renai_spine.png")}
            ></Image>
            <Image
              style={{ width: 16 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "flex-start" }}
              resizeMode={"contain"}
              source={require("@assets/images/book_others_spine.png")}
            ></Image>
          </View>
          <View style={{ height: "107%", justifyContent: "flex-end" }}>
            <Image
              style={{ width: 100 * (this.props.scale ?? this.bookshelfScale), height: 40 * (this.props.scale ?? this.bookshelfScale), alignSelf: "center" }}
              resizeMode={"stretch"}
              source={require("@assets/images/book_group_card.png")}
            ></Image>
            <View
              style={{
                position: "absolute",
                width: 100 * (this.props.scale ?? this.bookshelfScale),
                height: 40 * (this.props.scale ?? this.bookshelfScale),
                alignSelf: "center",
                justifyContent: "center",
                paddingVertical: 5,
                paddingHorizontal: 10,
              }}
            >
              <TextLabel boldWeight color="#000000" numberOfLines={2} textAlign="center" textAlignVertical="center" fontSize="small">
                {this.props.title}
              </TextLabel>
            </View>
          </View>
        </View>
      );
    }

    return (
      <View
        style={[
          {
            width: 90 * (this.props.scale ?? this.bookshelfScale),
            height: 129 * (this.props.scale ?? this.bookshelfScale),
            margin: 4 * (this.props.scale ?? this.bookshelfScale),
            shadowColor: themeColor.colorShadow,
            justifyContent: "center",
          },
          this.props.style ?? {},
        ]}
      >
        <TouchableOpacity style={{ width: 90 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale) }} onPress={this.props.onPress}>
          {this.props.thumbnailUrl ? (
            <View
              style={{
                width: 90 * (this.props.scale ?? this.bookshelfScale),
                height: 129 * (this.props.scale ?? this.bookshelfScale),
                position: "absolute",
                overflow: "hidden",
              }}
            >
              <Image
                source={{ uri: this.props.thumbnailUrl }}
                style={{ position: "absolute", width: 90 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale) }}
              />
              <Image
                source={require("@assets/images/book_overlay.png")}
                style={{ position: "absolute", width: 90 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale) }}
              />
            </View>
          ) : (
            <View
              style={{
                width: 90 * (this.props.scale ?? this.bookshelfScale),
                height: 129 * (this.props.scale ?? this.bookshelfScale),
                position: "absolute",
                overflow: "hidden",
              }}
            >
              {this.props.type === "book" ? (
                <Image
                  style={{ width: 90 * (this.props.scale ?? this.bookshelfScale), height: 129 * (this.props.scale ?? this.bookshelfScale), alignSelf: "center" }}
                  resizeMode={"contain"}
                  source={require("@assets/images/book_fantasy_thumb.png")}
                ></Image>
              ) : undefined}

              <View
                style={{
                  position: "absolute",
                  padding: 10 * (this.props.scale ?? this.bookshelfScale),
                  width: 90 * (this.props.scale ?? this.bookshelfScale),
                  height: 129 * (this.props.scale ?? this.bookshelfScale),
                  flexGrow: 1,
                }}
              >
                <View style={{ width: "100%", height: "100%", flexGrow: 1 }}>
                  <Text
                    noOfLines={6}
                    style={[
                      styles.textBold,
                      {
                        fontSize: 9 * (this.props.scale ?? this.bookshelfScale),
                        lineHeight: 11 * (this.props.scale ?? this.bookshelfScale),
                        fontWeight: "bold",
                        color: themeColor.black,
                        textAlignVertical: "top",
                        marginTop: 10 * (this.props.scale ?? this.bookshelfScale),
                        marginBottom: 10 * (this.props.scale ?? this.bookshelfScale),
                        flexGrow: 1,
                      },
                    ]}
                  >
                    {this.props.title}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </TouchableOpacity>
      </View>
    );
  }
}
