import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, State } from "@core/BaseComponent";
import { state } from "@core/State";
import styles, { themeColor } from "@core/Styles";

export interface Props {
  children: string;
  thumbnail?: string; //Imageにしても良い？
}

export class AuthorButton extends BaseComponent<Props> {
  @state thumbnail: string;

  constructor(props: Props) {
    super(props);
    this.thumbnail = props.thumbnail ?? "";
  }

  shouldComponentUpdate(nextProps: Props, nextState: State, nextContext: unknown): boolean {
    return super.shouldComponentUpdate(nextProps, nextState, nextContext);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={{
          height: 40,
          backgroundColor: themeColor.itemBG,
          shadowColor: "black",
          borderRadius: 5,
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.3,
          shadowRadius: 5,
          justifyContent: "center",
          padding: 10,
        }}
      >
        <TouchableOpacity>
          <Text
            noOfLines={1}
            style={[
              styles.textBold,
              {
                fontSize: 16,
                fontWeight: "bold",
                color: themeColor.subText,
                textAlignVertical: "center",
              },
            ]}
          >
            {this.props.children}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
