export interface Option {
  systemOption: {
    insets?: {
      bottom: number;
      left: number;
      right: number;
      top: number;
    };
    line: number;
  };
  userOption: UserOption;
}
export interface UserOption {
  font: string;
  fontSize: number;
  fontColor: string;
  backgroundColor: string;
  rubyColor: string;
  autoRubyColor: string;
  rubyComplate: boolean;
  rubyReuse: boolean;

  textDirection: "vertical" | "horizontal";
  letterSpacing: number;
  lineSpacing: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;

  scrollType: "pagingVertical" | "pagingHorizontal" | "scroll";
  newPageLine: number; // 改ページとして扱う空行の個数

  showTitle: boolean;
  showSubtitle: boolean;
  showPageCount: boolean;
  showStatusBar: boolean;

  defaultPreset: boolean;
}

export const defaultUserOption: UserOption = {
  font: "'Noto Serif JP', serif",
  fontSize: 16,
  fontColor: "#FFFFFFFF",
  backgroundColor: "#000000FF",
  rubyColor: "#FFFFFFFF",
  autoRubyColor: "#888888FF",
  rubyComplate: true,
  rubyReuse: true,

  textDirection: "vertical",
  letterSpacing: 1,
  lineSpacing: 2,
  marginTop: 30,
  marginBottom: 30,
  marginLeft: 5,
  marginRight: 5,

  scrollType: "pagingHorizontal",
  newPageLine: 4, // 改ページとして扱う空行の個数

  showTitle: true,
  showSubtitle: true,
  showPageCount: true,
  showStatusBar: false,

  defaultPreset: true,
};
