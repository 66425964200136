import { Episode } from "novel-parser";
import React from "react";
import { EdgeInsets, SafeAreaInsetsContext } from "react-native-safe-area-context";
import { UserBookstatusData } from "~/utilities/firebase/firestore/documents/user-bookstatus-documents";
import { UserOption } from "~/utilities/firebase/firestore/documents/user-documents";
import { WebViewComponent } from "./WebViewComponent";

export interface EpisodeReaderWebViewProps {
  name: string;
  onLoadStart?: (webview: WebViewComponent) => boolean;
  onLoadEnd?: (webview: WebViewComponent) => boolean;
  onMessage?: (webview: WebViewComponent, message: { str: string; method: string; data?: string }) => boolean;
}

export class EpisodeReaderWebViewComponent extends WebViewComponent {
  insets: EdgeInsets | null = null;

  renderComponent(): JSX.Element {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => {
          this.insets = insets;
          return super.renderComponent();
        }}
      </SafeAreaInsetsContext.Consumer>
    );
  }

  setLeftPage(): void {
    this.method("changeLeftPage");
  }
  setRightPage(): void {
    this.method("changeRightPage");
  }
  setPage(page: number): void {
    this.method("changePage", page);
  }
  showMenu(isShow: boolean): void {
    this.method("showMenu", isShow);
  }

  setEpisode(params: Episode, id: string, userOption: UserOption, userBookstatus?: UserBookstatusData): void {
    this.method("setEpisode", {
      data: params,
      option: { userOption: userOption, systemOption: { insets: this.insets, line: userBookstatus?.episodes?.[id]?.line } },
    });
  }

  setOption(id: string, userOption: UserOption, userBookstatus?: UserBookstatusData): void {
    this.method("setOption", { userOption: userOption, systemOption: { insets: this.insets, line: userBookstatus?.episodes?.[id]?.line } });
  }
}
