import "./global";
import Constants, { AppOwnership } from "expo-constants";
import * as Font from "expo-font";
import firebase from "firebase";
import _ from "lodash";
import { Button, NativeBaseProvider, Text, useColorMode } from "native-base";
import { Image } from "native-base";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, Linking, LogBox, Platform, ScaledSize, Text as rnText, View } from "react-native";
import { Appearance, AppearanceProvider } from "react-native-appearance";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaInsetsContext, SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { SharedItem } from "react-native-share-menu";
import { FooterBottomSheetContext } from "@core/FooterBottomSheetContext";
import { ParserManager } from "@core/ParserManager";
import { getSharedState, updateSharedState } from "@core/SharedState";
import { Spacer } from "@core/Spacer";
import styles, { getWidthType, isWidthLarger, themeColor } from "@core/Styles";
import { appTheme } from "@core/Styles";
import { Lato_400Regular, Lato_700Bold, Lato_900Black } from "@expo-google-fonts/lato";
import { NotoSansJP_400Regular, NotoSansJP_700Bold, NotoSansJP_900Black } from "@expo-google-fonts/noto-sans-jp";
import { createDrawerNavigator, DrawerContentComponentProps, DrawerContentOptions } from "@react-navigation/drawer";
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native";
import { createStackNavigator, TransitionPresets } from "@react-navigation/stack";
import { AppContext } from "~/screens/Core/AppContext";
import { Router } from "./router";
import { DragHandler } from "./screens/Bookshelf/DragHandler";
import { FooterBottomSheet } from "./screens/Component/FooterBottomSheet";
import { DrawerButton, TabBar, TabBarButton } from "./screens/Component/TabBar";
import { DialogComponent } from "./screens/Core/DialogComponent";
import { AccountStackScreen } from "./screens/Stack/AccountStackScreen";
import { BookshelfStackScreen } from "./screens/Stack/BookshelfStackScreen";
import { EpisodeStackScreen } from "./screens/Stack/EpisodeStackScreen";
import { HomeStackScreen } from "./screens/Stack/HomeStackScreen";
import { LoginStackScreen } from "./screens/Stack/LoginStackScreen";
import { SearchStackScreen } from "./screens/Stack/SearchStackScreen";
import { SettingsStackScreen } from "./screens/Stack/SettingsStackScreen";
import { WriteStackScreen } from "./screens/Stack/WriteStackScreen";
import { Bookshelf } from "./utilities/BookshelfUtility";
import { Firebase } from "./utilities/firebase/FirebaseUtility";
import { BookshelfCollection } from "./utilities/firebase/firestore/documents/bookshelf-documents";

let ShareMenu: typeof import("react-native-share-menu").default | undefined;
if ((Platform.OS === "ios" || Platform.OS === "android") && Constants.appOwnership !== AppOwnership.Expo) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
  ShareMenu = (require("react-native-share-menu") as any).default;
}

LogBox?.ignoreLogs(["Setting a timer for a long period of time"]);
LogBox?.ignoreLogs(["Require cycle: "]);
LogBox?.ignoreLogs(["Constants.installationId has been deprecated in favor of generating and storing your own ID. "]);

const listener = (event: { url: string }) => {
  console.log(event?.url);
  if (event?.url?.startsWith("jp.ilks.novel://")) {
    alert(event?.url);
  }
};
Linking.addEventListener("url", listener);
// Linking.removeEventListener("url", listener);

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

let drawerContentProps: DrawerContentComponentProps<DrawerContentOptions>;
const modalNavigator = createRef<NavigationContainerRef>();

export default function App() {
  const [isReady, setIsReady] = useState(false);
  const [widthTypeData, setWidthTypeData] = useState(getWidthType);
  const [dimensionWidth, setDimensionWidth] = useState(0);
  const [currentTheme, setCurrentTheme] = useState("light");
  const [safeAreaInsets, setSafeAreaInsets] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const { colorMode, toggleColorMode } = useColorMode();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Text as any).defaultProps = (Text as any).defaultProps || {};
  (rnText as any).defaultProps = (rnText as any).defaultProps || {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Text as any).defaultProps.allowFontScaling = false;
  (rnText as any).defaultProps.allowFontScaling = false;

  const handleShare = useCallback(async (item?: SharedItem) => {
    if (!item) {
      return;
    }
    const user = await Firebase.login();
    if (!user) {
      return;
    }

    const { mimeType, data, extraData } = item;

    updateSharedState("sharedItemData", data);
    updateSharedState("sharedItemMimeType", mimeType);
    updateSharedState("sharedItemExtraData", extraData);

    if ((extraData as any)?.type === "addBook") {
      // TODO: 適当な本棚に入れるのはなんかちがう
      const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
      await bookshelfCollection.load();
      const bookshelf = _.sortBy(bookshelfCollection.toArray(), (v) => v.data.index).find((v) => v.data.type === "bookshelf");
      await Bookshelf.addBook(data, bookshelf?.id ?? "");
    } else if (Platform.OS === "android") {
      // TODO: 適当な本棚に入れるのはなんかちがう
      const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
      await bookshelfCollection.load();
      const bookshelf = _.sortBy(bookshelfCollection.toArray(), (v) => v.data.index).find((v) => v.data.type === "bookshelf");
      await Bookshelf.addBook(data, bookshelf?.id ?? "");
    }
    console.log(item);
    alert(JSON.stringify({ data, mimeType, extraData }));
  }, []);

  useEffect(() => {
    ShareMenu?.getInitialShare(handleShare);
  }, [handleShare]);

  useEffect(() => {
    const listener = ShareMenu?.addNewShareListener(handleShare);
    return () => {
      listener?.remove();
    };
  }, [handleShare]);

  userInitialize();
  useEffect(() => {
    void (async () => {
      await Font.loadAsync({
        Lato_400Regular,
        Lato_700Bold,
        Lato_900Black,
        NotoSansJP_400Regular,
        NotoSansJP_700Bold,
        NotoSansJP_900Black,
      });

      if (!isReady) {
        Dimensions.addEventListener("change", onChange);
        Appearance.addChangeListener(onThemeChange);
      }

      setIsReady(true);
    })();
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  const onThemeChange = ({ colorScheme }: { colorScheme: string }) => {
    setCurrentTheme(colorScheme);
    toggleColorMode();
  };

  const onChange = ({ window }: { window: ScaledSize; screen: ScaledSize }): void => {
    setDimensionWidth(window.width);
  };

  const [footerBottomSheetRef] = useState(createRef<FooterBottomSheet>());

  const drawerContent = useCallback((v: DrawerContentComponentProps<DrawerContentOptions>): JSX.Element => {
    drawerContentProps = v;
    Router.setDrawer(drawerContentProps);
    return (
      <View style={{ backgroundColor: themeColor.background, height: "100%" }}>
        <SafeAreaView>
          <Spacer vertical space={50} />
          <View style={{ margin: 10, paddingHorizontal: 10, backgroundColor: "transparent" }}>
            <Image
              style={{ width: 240, height: 50, marginHorizontal: 10, marginVertical: 15, alignSelf: "center" }}
              resizeMode={"contain"}
              source={require("@assets/images/logo_horizontal.png")}
              alt="ilks novel"
            />
          </View>

          <ScrollView style={{ alignSelf: "stretch" }}>
            <Text style={[styles.h2]}>小説を読む</Text>
            <DrawerButton name="ホーム" icon={require("@assets/images/icon_home_black.png")} onPress={() => Router.appNavigate("ホーム")}></DrawerButton>
            <DrawerButton name="見つける" icon={require("@assets/images/icon_search_black.png")} onPress={() => Router.appNavigate("見つける")}></DrawerButton>
            <DrawerButton name="書く" icon={require("@assets/images/icon_write_black.png")} onPress={() => Router.appNavigate("書く")}></DrawerButton>
            <DrawerButton name="本棚" icon={require("@assets/images/icon_bookshelf_black.png")} onPress={() => Router.appNavigate("本棚")}></DrawerButton>
            <Spacer vertical space={50} />
            <Text style={[styles.h2]}>小説を書く</Text>
            <DrawerButton name="アカウント" icon={require("@assets/images/icon_account_black.png")} onPress={() => Router.appNavigate("アカウント")}></DrawerButton>
          </ScrollView>
        </SafeAreaView>
      </View>
      // </LinearGradient>
    );
  }, []);
  const DrawerNavigator = useCallback(() => {
    return (
      <View
        style={{
          backgroundColor: themeColor.background,
          width: "100%",
          height: "100%",
          margin: "auto",
          overflow: "hidden",
          flexDirection: Platform.OS === "web" ? "column-reverse" : "column",
        }}
      >
        <View style={{ width: "100%", flexGrow: 1, margin: "auto", overflow: "hidden", backgroundColor: themeColor.background }}>
          <Drawer.Navigator
            drawerType={isWidthLarger("md") ? "permanent" : "slide"}
            edgeWidth={20}
            minSwipeDistance={200}
            // overlayColor={themeColor.bar}
            drawerPosition={"right"}
            drawerContent={drawerContent}
            drawerStyle={{ width: 280, borderColor: "transparent" }}
            // sceneContainerStyle={{ maxWidth: 1280 }}
          >
            <Drawer.Screen name="ホーム" component={HomeStackScreen} options={{ headerRight: () => <Button>三</Button> }} />
            <Drawer.Screen name="見つける" component={SearchStackScreen} options={{ headerRight: () => <Button>三</Button> }} />
            <Drawer.Screen name="書く" component={WriteStackScreen} options={{ headerRight: () => <Button>三</Button> }} />
            <Drawer.Screen name="本棚" component={BookshelfStackScreen} options={{ headerRight: () => <Button>三</Button> }} />
            <Drawer.Screen name="アカウント" component={AccountStackScreen} options={{ headerRight: () => <Button>三</Button> }} />
          </Drawer.Navigator>
        </View>
        {Platform.OS === "web" ? (
          <View
            style={[
              styles.colorShadow,
              {
                backgroundColor: themeColor.white,
                borderBottomColor: themeColor.border,
                borderBottomWidth: 0.25,
                shadowColor: "#73A9E8",
                justifyContent: "center",
                height: 64,
              },
            ]}
          >
            <View
              style={{
                flexShrink: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <View style={{ flex: 1, flexShrink: 1 }}>
                <View style={{ marginVertical: 2, marginHorizontal: 20, height: 50 }}>
                  <Image style={{ width: 220, height: 50, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/logo_horizontal.png")} alt="ilks novel" />
                </View>
              </View>
              <View style={{ flexGrow: 1 }}></View>
              <View style={{ justifyContent: "center", flexDirection: "row", flexGrow: 1, flexShrink: 1 }}>
                <TabBarButton showFrame name="ホーム" icon={require("@assets/images/icon_home_black.png")} onPress={() => Router.appNavigate("ホーム")} />
                <TabBarButton showFrame name="見つける" icon={require("@assets/images/icon_search_black.png")} onPress={() => Router.appNavigate("見つける")} />
                <TabBarButton showFrame name="書く" icon={require("@assets/images/icon_write_black.png")} onPress={() => Router.appNavigate("書く")} />
                <TabBarButton showFrame name="本棚" icon={require("@assets/images/icon_bookshelf_black.png")} onPress={() => Router.appNavigate("本棚")} />
                <TabBarButton showFrame name="アカウント" icon={require("@assets/images/icon_account_black.png")} onPress={() => Router.appNavigate("アカウント")} />
              </View>
              <View style={{ flexGrow: 1 }}></View>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
        ) : (
          <FooterBottomSheet
            ref={footerBottomSheetRef}
            onShowSettings={() => Router.appNavigate("Settings")}
            shrinkedContentsHeight={64}
            footerItemHeight={64}
            footerItem={
              <TabBar height={64}>
                <TabBarButton name="ホーム" icon={require("@assets/images/icon_home_black.png")} onPress={() => Router.appNavigate("ホーム")} />
                <TabBarButton name="見つける" icon={require("@assets/images/icon_search_black.png")} onPress={() => Router.appNavigate("見つける")} />
                <TabBarButton name="書く" icon={require("@assets/images/icon_write_black.png")} onPress={() => Router.appNavigate("書く")} />
                <TabBarButton name="本棚" icon={require("@assets/images/icon_bookshelf_black.png")} onPress={() => Router.appNavigate("本棚")} />
                <TabBarButton name="アカウント" icon={require("@assets/images/icon_account_black.png")} onPress={() => Router.appNavigate("アカウント")} />
              </TabBar>
            }
          ></FooterBottomSheet>
        )}
      </View>
    );
  }, [drawerContent, footerBottomSheetRef]);

  if (!isReady) {
    return (
      <View style={{ width: "100%", height: "100%" }}>
        <ActivityIndicator size="large" color={Platform.OS === "android" ? themeColor.main : "#999999"} />
      </View>
    );
  }

  // if (Platform.OS === "web" && location.pathname.startsWith("/Episode")) {
  //   return (
  //     <SafeAreaProvider>
  //       <FooterBottomSheetContext.Provider value={{ footerBottomSheetRef: footerBottomSheetRef }}>
  //         <SafeAreaInsetsContext.Consumer>
  //           {(insets) => {
  //             return (
  //               <AppContext.Provider value={{ safeAreaInsets: insets, theme: currentTheme }}>
  //                 <NativeBaseProvider theme={appTheme}>
  //                   <AppearanceProvider>
  //                     <NavigationContainer>
  //                       <Stack.Navigator
  //                         screenOptions={{
  //                           headerTintColor: "#39445d",
  //                           headerStyle: {
  //                             backgroundColor: "#f2f4f8",
  //                           },
  //                           gestureEnabled: true,
  //                           cardOverlayEnabled: true,
  //                           ...TransitionPresets.ModalPresentationIOS,
  //                         }}
  //                         headerMode="none"
  //                         mode="modal"
  //                       >
  //                         <Stack.Screen name="Episode">{() => <EpisodeStackScreen  />}</Stack.Screen>
  //                       </Stack.Navigator>
  //                     </NavigationContainer>
  //                   </AppearanceProvider>
  //                 </NativeBaseProvider>
  //               </AppContext.Provider>
  //             );
  //           }}
  //         </SafeAreaInsetsContext.Consumer>
  //       </FooterBottomSheetContext.Provider>
  //     </SafeAreaProvider>
  //   );
  // }

  return (
    <SafeAreaProvider>
      <FooterBottomSheetContext.Provider value={{ footerBottomSheetRef: footerBottomSheetRef }}>
        <NativeBaseProvider theme={appTheme}>
          <ParserManager></ParserManager>
          <SafeAreaInsetsContext.Consumer>
            {(insets) => {
              return (
                <AppContext.Provider value={{ safeAreaInsets: insets, theme: currentTheme }}>
                  <AppearanceProvider>
                    <DialogComponent></DialogComponent>
                    <DragHandler style={{ width: "100%", height: "100%" }}>
                      <NavigationContainer
                        ref={(ref) => {
                          Router.setModalNavigator(ref);
                        }}
                        onReady={() => {
                          userInitialize();
                        }}
                      >
                        <Stack.Navigator
                          screenOptions={{
                            headerTintColor: "#39445d",
                            headerStyle: {
                              backgroundColor: "transparent",
                            },
                            gestureEnabled: true,
                            cardOverlayEnabled: true,
                            ...(Platform.OS === "web" ? TransitionPresets.ModalTransition : TransitionPresets.ModalPresentationIOS),
                          }}
                          headerMode="none"
                          mode="modal"
                        >
                          <Stack.Screen name="Main" component={DrawerNavigator} />
                          <Stack.Screen name="Episode" component={EpisodeStackScreen} />
                          <Stack.Screen name="Settings" component={SettingsStackScreen} />
                          <Stack.Screen options={{ gestureEnabled: false }} name="Login" component={LoginStackScreen} />
                        </Stack.Navigator>
                      </NavigationContainer>
                    </DragHandler>
                  </AppearanceProvider>
                </AppContext.Provider>
              );
            }}
          </SafeAreaInsetsContext.Consumer>
        </NativeBaseProvider>
      </FooterBottomSheetContext.Provider>
    </SafeAreaProvider>
  );
}
function userInitialize() {
  const sharedUser = getSharedState("user");
  const sharedAsyncUser = getSharedState("asyncUser");
  if (!sharedUser || !sharedAsyncUser) {
    const user = Firebase.user;
    if (user) {
      updateSharedState("user", user);
      updateSharedState("asyncUser", Promise.resolve(user));
      const resolve = getSharedState("resolveUser") as ((user: firebase.User) => void) | undefined;
      resolve?.(user);
    } else if (!sharedAsyncUser) {
      const promise = new Promise<firebase.User>((resolve, reject) => {
        const __reject = getSharedState("rejectUser") as (() => void) | undefined;
        __reject?.();
        updateSharedState("resolveUser", resolve);
        updateSharedState("rejectUser", reject);
        Firebase.login()
          .then((user) => {
            if (user) {
              updateSharedState("user", user);
              resolve(user);
            } else {
              // Router.appNavigate("Login", true);
            }
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });

      updateSharedState("asyncUser", promise);
    } else {
      Firebase.login()
        .then((user) => {
          if (user) {
            const resolve = getSharedState("resolveUser") as ((user: firebase.User) => void) | undefined;
            updateSharedState("user", user);
            resolve?.(user);
          } else {
            // Router.appNavigate("Login", true);
            Router.appNavigate("Login");
          }
        })
        .catch((e) => {
          const __reject = getSharedState("rejectUser") as (() => void) | undefined;
          __reject?.(e);
        });
    }
  }
}
const appStyles = {
  get drawer() {
    return {
      flex: 1,
    };
  },
  get drawerButton() {
    return {
      alignSelf: "stretch",
      backgroundColor: themeColor.bar,
      height: 50,
      margin: 10,
      borderRadius: 10,
      borderWidth: 0,
    };
  },
  get drawerButtonActiveText() {
    return {
      color: "white",
      fontWeight: "bold",
      fontSize: 20,
      margin: 10,
    };
  },
  get drawerButtonInactiveText() {
    return {
      color: themeColor.mainText,
      fontWeight: "bold",
      fontSize: 20,
      margin: 10,
    };
  },
  get tabBarButtonActiveText() {
    return {
      color: themeColor.main,
      fontWeight: "bold",
      fontSize: 13,
      textAlign: "center",
      flex: 1,
    };
  },
  get tabBarButtonInactiveText() {
    return {
      color: themeColor.gray,
      fontWeight: "bold",
      fontSize: 13,
      textAlign: "center",
      flex: 1,
    };
  },
  get shadowOffset() {
    return {
      width: 0,
      height: -3,
      shadowOpacity: 0.25,
      shadowRadius: 2,
    };
  },
};
