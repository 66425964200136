import React from "react";
import { ColorValue } from "react-native";
import { Text } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";

export interface Props extends BaseProps {
  backgroundColor?: ColorValue;
  children: string;
  type?: "text" | "password";
  onChange?: () => void;
  boldWeight?: boolean;
  blackWeight?: boolean;
  color?: ColorValue;
  textAlign?: "left" | "center" | "right";
  textAlignVertical?: "top" | "center" | "bottom";
  fontSize?: number | "verySmall" | "small" | "medium" | "large" | "extraLarge" | "veryLarge";
  numberOfLines?: number;
}

export class TextLabel extends BaseComponent<Props> {
  textStyle: {};
  fontStyle: {};

  constructor(props: Props) {
    super(props);

    if (this.props.boldWeight === true) {
      this.textStyle = styles.textBold;
    } else if (this.props.blackWeight === true) {
      this.textStyle = styles.textBlack;
    } else {
      this.textStyle = styles.text;
    }

    if (!this.props.fontSize) {
      this.fontStyle = styles.mediumFont;
    } else if (this.props.fontSize === "verySmall") {
      this.fontStyle = styles.verySmallFont;
    } else if (this.props.fontSize === "small") {
      this.fontStyle = styles.smallFont;
    } else if (this.props.fontSize === "medium") {
      this.fontStyle = styles.mediumFont;
    } else if (this.props.fontSize === "large") {
      this.fontStyle = styles.largeFont;
    } else if (this.props.fontSize === "extraLarge") {
      this.fontStyle = styles.extraLargeFont;
    } else if (this.props.fontSize === "veryLarge") {
      this.fontStyle = styles.veryLargeFont;
    } else {
      this.fontStyle = {
        shadowColor: themeColor.colorShadow,
        fontSize: this.props.fontSize,
      };
    }
  }

  renderComponent(): JSX.Element {
    return (
      <Text
        numberOfLines={this.props.numberOfLines}
        style={[
          this.textStyle,
          this.fontStyle,
          {
            color: this.props.color ?? themeColor.mainText,
            textAlign: this.props.textAlign ?? "left",
            backgroundColor: this.props.backgroundColor,
            // textAlignVertical: this.props.textAlignVertical ?? "left",
            // flex: 1,
          },
          this.props.style,
        ]}
      >
        {this.props.children}
      </Text>
    );
  }
}
