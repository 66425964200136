import React from "react";
import { themeColor } from "@core/Styles";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { SettingsScene } from "../Settings/SettingsScene";

const SettingsStack = createStackNavigator();

export function SettingsStackScreen(): JSX.Element {
  return (
    <SettingsStack.Navigator
      screenOptions={{
        headerTintColor: themeColor.mainText,
        headerStyle: {
          backgroundColor: themeColor.bar,
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        headerBackTitleVisible: false,
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(SettingsStack.Screen, SettingsScene)}
      {StackScreenRender(SettingsStack.Screen, Detail2Scene)} */}
      {StackScreenRenderAll(SettingsStack.Screen, SettingsScene)}
    </SettingsStack.Navigator>
  );
}
