import { Image } from "native-base";
import React from "react";
import { View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";

export interface IconTextProps {
  children?: JSX.Element;
  icon?: string;
  iconurl?: string;
  iconWidth: number;
  iconHeight: number;
  space?: number;
  vertical?: boolean;
  iconRadius?: number;
  style?: {};
}

export class IconText extends BaseComponent<IconTextProps> {
  constructor(props: IconTextProps) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View style={[{ flexDirection: this.props.vertical === true ? "column" : "row", alignItems: "center" }, this.props.style ?? {}]}>
        {this.props.icon ? (
          <View style={{ width: this.props.iconWidth, height: this.props.iconHeight, borderRadius: this.props.iconRadius ?? 0, overflow: "hidden" }}>
            <Image
              style={{ width: this.props.iconWidth, height: this.props.iconHeight, alignSelf: "center" }}
              resizeMode={"contain"}
              source={this.props.iconurl ? { uri: this.props.iconurl } : this.props.icon}
              alt="image"
            />
            {/* <Svg width={this.props.iconWidth} height={this.props.iconHeight} radius={this.props.iconRadius} path={this.props.icon}></Svg> */}
          </View>
        ) : undefined}
        <View style={this.props.vertical === true ? { height: this.props.space ?? 3 } : { width: this.props.space ?? 3 }}></View>
        {this.props.children}
      </View>
    );
  }
}
