import { Image } from "native-base";
import React from "react";
import { ColorValue, View } from "react-native";
import { URL } from "react-native-url-polyfill";
import { BaseComponent } from "@core/BaseComponent";
import { Spacer } from "@core/Spacer";
import { themeColor } from "@core/Styles";
import { IconTextProps } from "./IconText";
import { TextLabel } from "./TextLabel";

export interface Props extends IconTextProps {
  url: string;
  textColor?: ColorValue;
  fontSize?: number | "verySmall" | "small" | "medium" | "large" | "extraLarge" | "veryLarge";
  space?: number;
}

export class FaviconDomainText extends BaseComponent<Props> {
  icon?: string | { uri: string };
  text?: string;

  constructor(props: Props) {
    super(props);

    if (!this.props.url) return;

    const urlObject = new URL(this.props.url ?? "");

    const hostname = urlObject.hostname;
    const origin = urlObject.origin;

    this.icon = origin ? { uri: origin + "/favicon.ico" } : this.props.icon;

    // const match = hostname.match(/[^.]+\.[^.]+$/);
    // this.text = match?.[0] ?? "";
    this.text = hostname;
  }

  renderComponent(): JSX.Element {
    return (
      <View style={[{ flexDirection: this.props.vertical === true ? "column" : "row", alignItems: "center" }, this.props.style ?? {}]}>
        <View style={{ width: this.props.iconWidth, height: this.props.iconHeight, borderRadius: this.props.iconRadius ?? 0, overflow: "hidden" }}>
          <Image style={{ width: this.props.iconWidth, height: this.props.iconHeight, alignSelf: "center" }} resizeMode={"contain"} source={this.icon} alt="⚪️" />
        </View>
        <Spacer space={this.props.space}></Spacer>
        <View style={this.props.vertical === true ? { height: this.props.space ?? 3 } : { width: this.props.space ?? 3 }}></View>
        <TextLabel fontSize={this.props.fontSize} numberOfLines={1} color={this.props.textColor ?? themeColor.subText}>
          {this.text ?? ""}
        </TextLabel>
      </View>
    );
  }
}
