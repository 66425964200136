import "react-virtualized/styles.css";
import { LinearGradient } from "expo-linear-gradient";
import _ from "lodash";
import { Box, Column, Wrap } from "native-base";
import NovelParser, { Book } from "novel-parser";
import React from "react";
import { Image, Linking, Platform, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { CollapsableListTab, CollapsableScrollTab, CollapsableTabView } from "@core/CollapsableTabView";
import { CurrentReaderData } from "@core/EpisodeReader";
import { addFirestoreStateListener, firestoreState } from "@core/firestoreState";
import { FooterBottomSheetContext } from "@core/FooterBottomSheetContext";
import { ParserManager } from "@core/ParserManager";
import { sharedState } from "@core/SharedState";
import { Spacer } from "@core/Spacer";
import { state } from "@core/State";
import styles, { isWidthLarger, themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BookshelfCollection } from "~/utilities/firebase/firestore/documents/bookshelf-documents";
import { UserBookstatusDocument } from "~/utilities/firebase/firestore/documents/user-bookstatus-documents";
import { GoogleBooks } from "~/utilities/GoogleBooksUtility";
import { AuthorButton } from "../Component/AuthorButton";
import { BookshelfBookIcon } from "../Component/BookshelfBookIcon";
import { CollapsableText } from "../Component/CollapsableText";
import { DetailAuthorButton } from "../Component/DetailAuthorButton";
import { DetailBookButton } from "../Component/DetailBookButton";
import { EpisodeItem } from "../Component/EpisodeItem";
import { FaviconDomainText } from "../Component/FaviconDomainText";
import { FlexWrap } from "../Component/FlexWrap";
import { GridChildView } from "../Component/GridChildView";
import { GridView } from "../Component/GridView";
import { RoundedButton } from "../Component/RoundedButton";
import { Tag } from "../Component/Tag";
import { TagLabel } from "../Component/TagLabel";
import { TextLabel } from "../Component/TextLabel";
import { BaseScene } from "../Core/BaseScene";
import { DialogComponent } from "../Core/DialogComponent";
import { SceneBase, ScreenProp } from "../Core/Navigator";

const IMAGE_HEIGHT = 250;
const HEADER_HEIGHT = 50;
const SCROLL_HEIGHT = IMAGE_HEIGHT - HEADER_HEIGHT;
const THEME_COLOR = "rgba(85,186,255, 1)";
const FADED_THEME_COLOR = "rgba(85,186,255, 0.8)";

type type = "BookSummary";

export interface Episode extends NovelParser.EpisodeDescription {
  id: string;
  no: number;
  chapterTitle: string;
}

class scene extends BaseScene {
  static sceneName: type = "BookSummary";
  static Params: {
    title?: string;
    url: string;
    type: "ilks" | "other";
  };

  @state title: string;
  @state url: string;
  @state type: "ilks" | "other";
  @state book?: Book;
  @state episodes: Episode[];
  @state index: number;
  @state isExpanded: boolean;
  @state scrollViewElement: any;
  @state headerWidth: number;
  @state headerHeight: number;
  @state lastEpisodeIndex?: number;
  @firestoreState userBookstatusDocument?: UserBookstatusDocument;
  @state imageUrl?: string;
  @sharedState currentReaderData?: CurrentReaderData;

  protected initializeOnMounted = true;

  nScroll = new Animated.Value(0);

  scroll = new Animated.Value(0);

  headerRef = React.createRef<HTMLDivElement>();

  // @typesなかったんや許してくれ
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  twitter: any;

  @firestoreState bookshelfCollection?: BookshelfCollection;
  targetBookshelf = "";

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerTitle: () => {
        return undefined;
      },
      // headerShown: false,
    };
  }

  constructor(props: ScreenProp<type>) {
    super(props);

    this.title = props.route.params.title ?? "";
    this.url = props.route.params.url;
    this.type = props.route.params.type;
    this.episodes = [];
    this.index = 0;
    this.isExpanded = false;
    this.headerWidth = 0;
    this.headerHeight = 0;
  }

  protected async initialize(): Promise<void> {
    await super.initialize();
    this.book = await ParserManager.getIndex(this.url, this.type);

    if (!this.book) {
      DialogComponent.showNotify("パースに失敗しました", "アカウントからサイト拡張を調整してください。");
      throw new Error("Parser");
    }
    // this.episodes = this.book.chapters.flatMap((chapter) => chapter.episodes.map((episode) => ({ ...episode, chapterTitle: chapter.title })));
    let i = 0;
    this.episodes = [];
    for (const chapter of this.book.chapters) {
      for (const episode of chapter.episodes) {
        this.episodes.push({
          id: (i + 1).toString(),
          no: i + 1,
          chapterTitle: chapter.title,
          ...episode,
        });
        i++;
      }
    }
    this.updateLastReadEpisode();

    const cover = await GoogleBooks.getCover(this.book.title ?? "", this.book.author.name ?? "", this.url);
    if (cover) {
      this.imageUrl = cover;
    }
  }

  protected async onUserChanged(): Promise<void> {
    const user = this.user;
    if (user) {
      const url = encodeURIComponent(this.url);
      await addFirestoreStateListener(this, UserBookstatusDocument, { uid: user.uid, bookId: url }, this.userBookstatusDocument);

      // if (!this.userBookstatusDocument) return;

      this.updateLastReadEpisode();
    } else {
      this.lastEpisodeIndex = undefined;
    }

    void (async () => {
      await addFirestoreStateListener(this, BookshelfCollection, { uid: this.user.uid }, this.bookshelfCollection);

      // TODO: 適当な本棚に入れるのはなんかちがう
      const bookshelf = _.sortBy(this.bookshelfCollection?.toArray(), (v) => v.data.index).find((v) => v.data.type === "bookshelf");
      this.targetBookshelf = bookshelf?.id ?? "";
    })();
  }

  protected updateLastReadEpisode(): void {
    const lastReadEpisode = this.userBookstatusDocument?.data?.lastReadEpisode ?? 0;

    const index = this.episodes.findIndex((v) => v.id === lastReadEpisode);

    this.lastEpisodeIndex = index >= 0 ? index : undefined;
  }

  componentDidUpdate(): void {
    super.componentDidUpdate();
  }

  getEpisodeItem(item: Episode): JSX.Element {
    return (
      <View key={item.id}>
        <FooterBottomSheetContext.Consumer>
          {(value) => {
            let readStatus: "alreadyRead" | "unread" | "reading" | "updated";

            if (this.userBookstatusDocument?.data?.episodes[item.id]?.alreadyRead) {
              readStatus = "alreadyRead";
            } else {
              readStatus = "unread";
            }
            return (
              <EpisodeItem
                key={item.id}
                episode={item}
                onPress={(item) => {
                  if (this.type === "ilks" && Platform.OS === "web") {
                    this.navigation.navigate("Episode", { ...item, type: this.type });
                  } else if (Platform.OS === "web") {
                    //TODO:拡張機能が入ってなければただのリンクにするように修正が必要
                    this.navigation.navigate("Episode", { ...item, type: this.type });
                  } else {
                    if (!this.book) return;
                    value?.footerBottomSheetRef?.current?.openEpisode({ ...item, type: this.type }, this.imageUrl);
                  }
                }}
                readStatus={readStatus}
                onOtherButtonPress={(item) => {
                  this.navigation.navigate("Episode", { ...item, type: this.type });
                }}
              ></EpisodeItem>
            );
          }}
        </FooterBottomSheetContext.Consumer>
      </View>
    );
  }

  getRecentlyEpisodeItems(): JSX.Element[] {
    const lastReadEpisode = Number(this.userBookstatusDocument?.data?.lastReadEpisode ?? 1) - 1;

    const episodeRangeStart = Math.max(0, lastReadEpisode - 1);
    const episodeRangeEnd = Math.min(this.episodes.length, episodeRangeStart + 5);

    const episodeItems: JSX.Element[] = [];

    for (let index = episodeRangeStart; index < episodeRangeEnd; index++) {
      episodeItems.push(this.getEpisodeItem(this.episodes[index]));
    }

    return episodeItems;
  }

  protected renderComponent(): JSX.Element {
    return (
      <View style={{ width: "100%", height: "100%", flex: 1, flexDirection: "row", justifyContent: "center", backgroundColor: themeColor.background }}>
        <View style={{ width: "100%", maxWidth: 1600, height: "100%", flex: 1, flexDirection: "row" }}>
          {false ? ( //{isWidthLarger("md") ? (
            <View style={{ width: 300, height: "100%", backgroundColor: themeColor.background }}>
              <ScrollView>
                <GridView>
                  <GridChildView title="作者">
                    <DetailAuthorButton authorName="森下 樹" authorId="@phenico" genre="ファンタジー"></DetailAuthorButton>
                  </GridChildView>
                  <GridChildView title="目次"></GridChildView>
                </GridView>
              </ScrollView>
            </View>
          ) : (
            <View></View>
          )}
          <CollapsableTabView
            headerContents={
              <View pointerEvents="box-none" style={[{ flexGrow: 1, backgroundColor: themeColor.itemBG }]}>
                <View pointerEvents="none" style={{ position: "absolute", width: "100%", opacity: 1, height: 315 }}>
                  <Animated.Image
                    source={{ uri: this.imageUrl ?? "https://upload.wikimedia.org/wikipedia/commons/c/c5/Moraine_Lake_17092005.jpg" }}
                    style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, opacity: 1 }}
                  ></Animated.Image>
                  <LinearGradient
                    colors={["rgba(0,0,0,0.25)", "rgba(0,0,0,0.45)", "rgba(0,0,0,1)"]}
                    locations={[0, 0.55, 1]}
                    style={{ position: "absolute", height: "100%", width: "100%" }}
                  />
                </View>
                <View pointerEvents="box-none" style={{ height: 300 }}>
                  <View
                    pointerEvents="box-none"
                    style={{ flexDirection: "row", position: "absolute", bottom: 0, width: "100%", paddingHorizontal: 15, paddingVertical: 15, alignItems: "flex-end" }}
                  >
                    <BookshelfBookIcon imageUrl={this.imageUrl} small={!isWidthLarger("md")}></BookshelfBookIcon>
                    <Spacer vertical space={5}></Spacer>
                    <View pointerEvents="box-none" style={{ flexGrow: 1, flexShrink: 1 }}>
                      <View pointerEvents="box-none" style={{ flexGrow: 1, flexShrink: 1 }}>
                        <TouchableOpacity
                          style={{}}
                          onPress={() => {
                            if (this.book?.url) {
                              if (Platform.OS === "web") {
                                window.open(this.book.url, "_blank", "noreferrer");
                              } else {
                                Linking.openURL(this.book.url).catch((err) => console.error("URLを開けませんでした。", err));
                              }
                            }
                          }}
                        >
                          <FaviconDomainText
                            iconWidth={18}
                            iconHeight={18}
                            iconRadius={3}
                            space={5}
                            icon={require("@assets/images/human.png")}
                            iconurl={"https://syosetu.com/favicon.ico"}
                            textColor={themeColor.lightGray}
                            url={this.book?.url ?? ""}
                          ></FaviconDomainText>
                        </TouchableOpacity>
                        <Spacer vertical space={3}></Spacer>
                        <View pointerEvents="none">
                          <TextLabel blackWeight fontSize={isWidthLarger("md") ? "extraLarge" : "large"} textAlignVertical="bottom" color="white">
                            {this.book?.title?.trim() ?? this.title.trim()}
                          </TextLabel>
                        </View>
                        <Spacer vertical space={10}></Spacer>
                      </View>
                      <AuthorButton>{this.book?.author?.name?.trim() ?? ""}</AuthorButton>
                    </View>
                  </View>
                </View>
                <View
                  pointerEvents="box-none"
                  style={{
                    backgroundColor: themeColor.itemBG,
                    borderRadius: 15,
                    paddingHorizontal: 15,
                    paddingVertical: 20,
                  }}
                >
                  <View pointerEvents="none" style={{ marginBottom: 20 }}>
                    <Wrap spacing={4} align="center" direction="row">
                      <Box>
                        <TagLabel tagTitle="読者数" tagTitleBGColor={themeColor.favorite} tagTitleTextColor={themeColor.black}>
                          334人
                        </TagLabel>
                      </Box>
                      <Box>
                        <TagLabel tagTitle="最終更新">2016/5/23 23:56</TagLabel>
                      </Box>
                    </Wrap>
                  </View>
                  <View pointerEvents="box-none" style={{ flexDirection: "row", flex: 1, width: "100%", marginBottom: 0 }}>
                    <FooterBottomSheetContext.Consumer>
                      {(value) => {
                        const index = this.lastEpisodeIndex;
                        console.log(index);
                        return index !== undefined ? (
                          <RoundedButton
                            style={{ flexGrow: 1, marginRight: 15 }}
                            onPress={() => {
                              if (Platform.OS === "web") {
                                this.navigation.navigate("Episode", { ...this.episodes[index], type: this.type });
                              } else {
                                value?.footerBottomSheetRef?.current?.openEpisode({ ...this.episodes[index], type: this.type }, this.imageUrl);
                              }
                            }}
                          >
                            {"続きから読む\n" + this.episodes[index].no + "話　" + this.episodes[index].title}
                          </RoundedButton>
                        ) : (
                          <RoundedButton
                            style={{ flexGrow: 1, marginRight: 15 }}
                            onPress={() => {
                              if (Platform.OS === "web") {
                                this.navigation.navigate("Episode", { ...this.episodes[0], type: this.type });
                              } else {
                                value?.footerBottomSheetRef?.current?.openEpisode({ ...this.episodes[0], type: this.type }, this.imageUrl);
                              }
                            }}
                          >
                            最初から読む
                          </RoundedButton>
                        );
                      }}
                    </FooterBottomSheetContext.Consumer>

                    <TouchableOpacity
                      style={[
                        styles.colorShadow,
                        {
                          width: 50,
                          height: 50,
                          backgroundColor: themeColor.white,
                          borderRadius: 25,
                          elevation: 3,
                          marginRight: 10,
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                      onPress={async () => {
                        await Bookshelf.addBook(this.url, this.targetBookshelf);
                      }}
                    >
                      <Image style={{ width: 24, height: 24, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_favorite_yellow.png")}></Image>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.colorShadow,
                        {
                          width: 50,
                          height: 50,
                          backgroundColor: themeColor.white,
                          borderRadius: 25,
                          elevation: 3,
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Image style={{ width: 24, height: 24, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/icon_notify_accent.png")}></Image>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            }
          >
            {[
              new CollapsableScrollTab(
                "概要",
                (
                  <GridView>
                    <CollapsableText>{this.book?.abstract ?? ""}</CollapsableText>
                    <GridChildView title="最近読んだ話">
                      <Column space={2}>{this.getRecentlyEpisodeItems()}</Column>
                    </GridChildView>
                    <GridChildView title="しおり"></GridChildView>
                    <GridChildView title="キーワード">
                      <FlexWrap>
                        <Tag big grow space={2}>
                          R15
                        </Tag>
                        <Tag big grow space={2}>
                          残酷な描写あり
                        </Tag>
                        <Tag big grow space={2}>
                          異世界転生
                        </Tag>
                        <Tag big grow space={2}>
                          チート
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                        <Tag big grow space={2}>
                          俺ILKKKKKKKKKKKKKK
                        </Tag>
                      </FlexWrap>
                    </GridChildView>
                    <GridChildView title="評価と感想"></GridChildView>
                    <GridChildView title="この作品が好きな人がよく読む作品" oneColumn={true}>
                      <ScrollView horizontal>
                        <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                          <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                          <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                          <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                        </View>
                      </ScrollView>
                    </GridChildView>
                  </GridView>
                )
              ),
              new CollapsableListTab(
                "目次",
                this.episodes,
                ({ item }) => {
                  return this.getEpisodeItem(item);
                },
                80,
                (item: Episode) => item.id
              ),
              new CollapsableScrollTab(
                "しおり",
                (
                  <GridView>
                    <GridChildView title="最近読んだ話"></GridChildView>
                    <GridChildView title="しおり"></GridChildView>
                    <GridChildView title="キーワード"></GridChildView>
                    <GridChildView title="評価と感想"></GridChildView>
                    <GridChildView title="この小説をブックマークしている人はこんな小説も読んでいます！" oneColumn={true}></GridChildView>
                  </GridView>
                )
              ),
              new CollapsableScrollTab(
                "感想",
                (
                  <GridView>
                    <GridChildView title="最近読んだ話"></GridChildView>
                    <GridChildView title="しおり"></GridChildView>
                    <GridChildView title="キーワード"></GridChildView>
                    <GridChildView title="評価と感想"></GridChildView>
                    <GridChildView title="この小説をブックマークしている人はこんな小説も読んでいます！" oneColumn={true}></GridChildView>
                  </GridView>
                )
              ),
            ]}
          </CollapsableTabView>
        </View>
      </View>
    );
  }

  textColor = this.scroll.interpolate({
    inputRange: [0, SCROLL_HEIGHT / 5, SCROLL_HEIGHT],
    outputRange: [THEME_COLOR, FADED_THEME_COLOR, "white"],
  });

  tabBg = this.scroll.interpolate({
    inputRange: [0, SCROLL_HEIGHT],
    outputRange: ["white", THEME_COLOR],
  });

  tabY = this.nScroll.interpolate({
    inputRange: [0, SCROLL_HEIGHT, SCROLL_HEIGHT + 1],
    outputRange: [0, 0, 1],
  });

  headerBg = this.scroll.interpolate({
    inputRange: [0, SCROLL_HEIGHT, SCROLL_HEIGHT + 1],
    outputRange: ["transparent", "transparent", THEME_COLOR],
  });

  imgScale = this.nScroll.interpolate({
    inputRange: [-25, 0],
    outputRange: [1.1, 1],
  });

  imgOpacity = this.nScroll.interpolate({
    inputRange: [0, SCROLL_HEIGHT],
    outputRange: [1, 0],
  });
}

export const BookSummaryScene: SceneBase<type> & typeof scene = scene;
