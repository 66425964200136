import { Text } from "native-base";
import React from "react";
import { ColorValue, View } from "react-native";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";

export interface Props {
  children: string;
  mini?: boolean;
  big?: boolean;
  autoWidth?: boolean;
  bgColor?: ColorValue;
  textColor?: ColorValue;
  space?: number;
  style?: {};
  grow?: boolean;
}

export class Tag extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={[
          this.props.autoWidth === true ? { width: this.props.mini === true ? 50 : this.props.big === true ? 90 : 90 } : {},
          {
            backgroundColor: this.props.bgColor ?? themeColor.gray,
            flexGrow: this.props.grow === true ? 1 : 0,
            paddingHorizontal: this.props.mini === true ? 10 : 16,
            alignContent: "center",
            height: this.props.mini === true ? 18 : this.props.big === true ? 28 : 24,
            borderRadius: this.props.mini === true ? 9 : this.props.big === true ? 14 : 12,
            marginRight: this.props.space ?? 0,
            marginBottom: this.props.space ?? 0,
            justifyContent: "center",
          },
          this.props.style ?? {},
        ]}
      >
        <Text
          noOfLines={1}
          style={[
            this.props.mini === true ? { fontSize: 11 } : this.props.big === true ? styles.mediumFont : styles.smallFont,
            styles.textBold,
            { color: this.props.textColor ?? themeColor.white, textAlign: "center" },
          ]}
        >
          {this.props.children}
        </Text>
      </View>
    );
  }
}
