import { LinearGradient } from "expo-linear-gradient";
import { Image, Text } from "native-base";
import React from "react";
import { ColorValue, ImageSourcePropType, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";

export interface Props extends BaseProps {
  children?: string;
  backgroundColor?: ColorValue;
  buttonColorGradation?: [ColorValue, ColorValue];
  textColor?: string;
  leftImage?: ImageSourcePropType;
  centerImage?: ImageSourcePropType;
  rightImage?: ImageSourcePropType;
  width?: number;
  height?: number;
  onPress: () => void;
}

export class RoundedButton extends BaseComponent<Props> {
  buttonColorGradation?: ColorValue[];
  textColor: string;
  children?: string;
  height: number;

  constructor(props: Props) {
    super(props);
    this.children = props.children;
    this.height = this.props.height ?? 50;

    this.buttonColorGradation =
      props.buttonColorGradation ?? (this.props.backgroundColor ? [this.props.backgroundColor, this.props.backgroundColor] : undefined) ?? themeColor.mainGradation;
    this.textColor = props.textColor ?? themeColor.white;
  }

  renderComponent(): JSX.Element {
    return (
      <View style={[{ width: this.props.width }, this.props.style ?? {}]}>
        <TouchableOpacity style={[styles.colorShadow, { height: this.height, borderRadius: this.height / 2 }]} onPress={this.props.onPress}>
          <LinearGradient
            style={[
              {
                position: "absolute",
                backgroundColor: "#7FB3F8",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: this.height,
                justifyContent: "center",
                borderRadius: this.height / 2,
              },
            ]}
            colors={this.buttonColorGradation}
            start={{ x: 1, y: 0 }}
            end={{ x: 0, y: 0 }}
          >
            <View
              style={{
                width: "80%",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                flexGrow: 1,
              }}
            >
              {this.props.leftImage ? (
                <Image style={{ width: this.height / 2, height: this.height / 2, alignSelf: "center" }} resizeMode={"contain"} source={this.props.leftImage} alt="ilks novel" />
              ) : (
                <View></View>
              )}
              {this.props.children ? (
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: this.textColor,
                    textAlign: "center",
                  }}
                >
                  {this.props.children}
                </Text>
              ) : undefined}
              {this.props.centerImage ? (
                <Image style={{ width: this.height / 2, height: this.height / 2, alignSelf: "center" }} resizeMode={"contain"} source={this.props.centerImage} alt="ilks novel" />
              ) : undefined}
              {this.props.rightImage ? (
                <Image style={{ width: this.height / 2, height: this.height / 2, alignSelf: "center" }} resizeMode={"contain"} source={this.props.rightImage} alt="ilks novel" />
              ) : (
                <View></View>
              )}
            </View>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}
