import React from "react";
import { themeColor } from "@core/Styles";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { SearchScene } from "../Search/SearchScene";

const SearchStack = createStackNavigator();

export function SearchStackScreen(): JSX.Element {
  return (
    <SearchStack.Navigator
      screenOptions={{
        title: "見つける",
        headerTintColor: themeColor.mainText,
        headerStyle: {
          backgroundColor: themeColor.bar,
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        headerBackTitleVisible: false,
        headerTransparent: true,
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(Stack.Screen, SearchScene)}
      {StackScreenRender(Stack.Screen, Detail2Scene)} */}
      {StackScreenRenderAll(SearchStack.Screen, SearchScene)}
    </SearchStack.Navigator>
  );
}
