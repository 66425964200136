import * as firestore from "@firebase/firestore-types";
import { OrderByDirection, WhereFilterOp } from "@firebase/firestore-types";
import { DocumentSnapshot, Query } from "./firestore";

export interface Where {
  fieldPath: string | firestore.FieldValue;
  opStr: WhereFilterOp;
  value: unknown;
}

export interface Condition {
  where?: Where[];
  limit?: number;
  limitToLast?: number;
  orderBy?: {
    fieldPath: string | firestore.FieldValue;
    directionStr?: OrderByDirection | undefined;
  };
  startAfter?: DocumentSnapshot<unknown>;
  startAt?: DocumentSnapshot<unknown>;
  endBefore?: DocumentSnapshot<unknown>;
  endAt?: DocumentSnapshot<unknown>;
}

export class FirestoreQuery {
  static buildQuery(ref?: Query<unknown>, condition?: Condition): Query<unknown> | undefined {
    if (!ref) {
      return;
    }

    if (!condition) {
      return ref;
    }

    if (condition.where !== undefined) {
      for (const where of condition.where) {
        ref = ref.where(where.fieldPath as string, where.opStr, where.value);
      }
    }

    if (condition.limit !== undefined) {
      ref = ref.limit(condition.limit);
    }

    if (condition.limitToLast !== undefined) {
      ref = ref.limitToLast(condition.limitToLast);
    }

    if (condition.orderBy !== undefined) {
      ref = ref.orderBy(condition.orderBy.fieldPath as string, condition.orderBy.directionStr);
    }

    if (condition.startAfter !== undefined) {
      ref = ref.startAfter(condition.startAfter);
    }

    if (condition.startAt !== undefined) {
      ref = ref.startAt(condition.startAt);
    }

    if (condition.endBefore !== undefined) {
      ref = ref.endBefore(condition.endBefore);
    }

    if (condition.endAt !== undefined) {
      ref = ref.endAt(condition.endAt);
    }

    return ref;
  }
}
