import React from "react";
import { ColorValue, KeyboardTypeOptions, ReturnKeyTypeOptions, TextInput } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { themeColor } from "@core/Styles";

export interface Props extends BaseProps {
  backgroundColor?: ColorValue;
  placeholder?: string;
  type?: "text" | "password";
  autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined;
  autoFocus?: boolean;
  onChangeText?: (value: string) => void;
  value?: string;
  underlineColorAndroid?: ColorValue;
  keyboardType?: KeyboardTypeOptions | undefined;
  autoCompleteType?:
    | "password"
    | "cc-csc"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-number"
    | "email"
    | "name"
    | "postal-code"
    | "street-address"
    | "tel"
    | "username"
    | "off"
    | undefined;
  returnKeyType?: ReturnKeyTypeOptions | undefined;
  multiline?: boolean;
  selectTextOnFocus?: boolean;
  onChange?: () => void;
  showShadow?: boolean;
}

export class RoundedInput extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <TextInput
        onChange={this.props.onChange}
        secureTextEntry={this.props.type === "password"}
        placeholderTextColor={themeColor.gray}
        autoCapitalize={this.props.autoCapitalize}
        autoFocus={this.props.autoFocus}
        onChangeText={this.props.onChangeText}
        value={this.props.value}
        underlineColorAndroid={this.props.underlineColorAndroid}
        keyboardType={this.props.keyboardType}
        autoCompleteType={this.props.autoCompleteType}
        returnKeyType={this.props.returnKeyType}
        multiline={this.props.multiline}
        selectTextOnFocus={this.props.selectTextOnFocus}
        style={{
          backgroundColor: this.props.backgroundColor ?? themeColor.textArea,
          borderWidth: 0,
          borderColor: "transparent",
          shadowColor: themeColor.colorShadow,
          marginHorizontal: 10,
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowOpacity: this.props.showShadow === true ? 0.3 : 0,
          shadowRadius: this.props.showShadow === true ? 0 : 5,
          height: 40,
          fontSize: 16,
          borderRadius: 20,
          paddingHorizontal: 20,
        }}
        placeholder={this.props.placeholder ?? ""}
      />
    );
  }
}
