import { extendTheme } from "native-base";
import { Dimensions, Platform } from "react-native";
import { Appearance } from "react-native-appearance";

export const appTheme = extendTheme({
  config: {
    useSystemColorMode: false,
    // Changing initialColorMode to 'dark'
    // initialColorMode: "light",
  },
  components: {
    Button: {
      variants: {
        rounded: ({ colorScheme }: any) => {
          return {
            bg: ``,
            rounded: "full",
          };
        },
      },
    },
  },
  colors: {
    transparent: {
      50: "transparent",
      100: "transparent",
      200: "transparent",
      300: "transparent",
      400: "transparent",
      500: "transparent",
      600: "transparent",
      700: "transparent",
      800: "transparent",
      900: "transparent",
    },

    drawerItem: {
      50: "#ffffff20",
      100: "#ffffff20",
      200: "#ffffff20",
      300: "#ffffff20",
      400: "#ffffff20",
      500: "#ffffff20",
      600: "#ffffff20",
      700: "#ffffff20",
      800: "#ffffff20",
      900: "#ffffff20",
    },

    itemBG: {
      50: "white",
      100: "white",
      200: "white",
      300: "white",
      400: "white",
      500: "black",
      600: "black",
      700: "black",
      800: "black",
      900: "black",
    },
  },
});

function scheme() {
  return Appearance.getColorScheme();
}

export const constants = {
  CONTENT_MAX_WIDTH: 640,
};

export const themeColor = {
  get black(): string {
    {
      return appTheme.colors.black;
    }
  },
  get mainText(): string {
    {
      return scheme() === "light" ? "#39445D" : appTheme.colors.white;
    }
  },
  get subText(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[500] : appTheme.colors.blueGray[200];
    }
  },
  get frame(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[300] : appTheme.colors.blueGray[500];
    }
  },
  get border(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[300] : appTheme.colors.blueGray[500];
    }
  },
  get bar(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[100] : appTheme.colors.blueGray[800];
    }
  },
  get base(): string {
    {
      return scheme() === "light" ? "#F9FAFB" : appTheme.colors.black;
    }
  },
  get favorite(): string {
    {
      return appTheme.colors.yellow[400];
    }
  },
  get lightGray(): string {
    {
      return appTheme.colors.blueGray[200];
    }
  },
  get sideBar(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[100] : appTheme.colors.blueGray[900];
    }
  },
  get itemBG(): string {
    {
      return scheme() === "light" ? appTheme.colors.white : appTheme.colors.blueGray[900];
    }
  },
  get textArea(): string {
    {
      return scheme() === "light" ? "#E8EEF6" : appTheme.colors.blueGray[800];
    }
  },
  get itemBGContents(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[50] : appTheme.colors.blueGray[800];
    }
  },
  get itemBGEmphasis(): string {
    {
      return scheme() === "light" ? appTheme.colors.blueGray[300] : appTheme.colors.blueGray[700];
    }
  },
  get mainBlack(): string {
    {
      return "#39445D";
    }
  },
  get buttonBlack(): string {
    {
      return scheme() === "light" ? "#39445D" : appTheme.colors.blueGray[500];
    }
  },
  get gray(): string {
    {
      return appTheme.colors.blueGray[500];
    }
  },
  get main(): string {
    {
      return "#7FB3F8"; //"#6CCCFA";
    }
  },
  get mainTranslucent(): string {
    {
      return "#6CCCFA30";
    }
  },
  get colorShadow(): string {
    {
      return "#73A9E8";
    }
  },
  get accent(): string {
    {
      return appTheme.colors.rose[500];
    }
  },
  get shadow(): string {
    {
      return "#000000";
    }
  },
  get white(): string {
    {
      return "white";
    }
  },
  get translucentWhite(): string {
    {
      return "#FFFFFF90";
    }
  },
  get background(): string {
    {
      return scheme() === "light" ? "white" : "black";
    }
  },
  get drawerItem(): string {
    {
      return "#ffffff20";
    }
  },
  get mainGradation(): string[] {
    {
      return ["#6CCCFA", "#7FB3F8"];
    }
  },
};

export type widthType = "lg" | "md" | "sm";

export function isWidthLarger(type: widthType): boolean {
  const dimensions = Dimensions.get("window");
  switch (type) {
    case "lg":
      return dimensions.width >= 1024;
    case "md":
      return dimensions.width >= 768;
    case "sm":
      return dimensions.width >= 320;
  }

  return true;
}

export function getWidthType(): widthType {
  const dimensions = Dimensions.get("window");

  if (dimensions.width >= 1024) {
    return "lg";
  }

  if (dimensions.width >= 768) {
    return "md";
  }

  return "sm";
}

// export const themes = extendTheme({ appTheme });

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get container() {
    return {
      flex: 1,
      maxWidth: "100%",
      backgroundColor: themeColor.base,
      alignItems: "center",
      justifyContent: "center",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get modal() {
    return {
      flex: 1,
      backgroundColor: "rgba(0,0,0, 0.8)",
      alignItems: "center",
      justifyContent: "center",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get colorShadow() {
    return {
      shadowColor: themeColor.colorShadow,
      shadowOffset: {
        width: 0,
        height: Platform.OS === "web" ? 2 : 3,
      },
      shadowOpacity: Platform.OS === "web" ? 0.4 : 0.25,
      shadowRadius: Platform.OS === "web" ? 8 : 4,
      elevation: 0,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get colorShadowDeep() {
    return {
      shadowColor: themeColor.colorShadow,
      shadowOffset: {
        width: 0,
        height: Platform.OS === "web" ? 5 : 6,
      },
      shadowOpacity: Platform.OS === "web" ? 0.5 : 0.4,
      shadowRadius: Platform.OS === "web" ? 12 : 8,
      elevation: 5,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get shadow() {
    return {
      shadowColor: themeColor.shadow,
      shadowOffset: {
        width: 0,
        height: Platform.OS === "web" ? 3 : 5,
      },
      shadowOpacity: Platform.OS === "web" ? 0.2 : 0.1,
      shadowRadius: Platform.OS === "web" ? 12 : 10,
      elevation: 0,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get item() {
    return {
      marginHorizontal: 0,
      marginVertical: 0,
      backgroundColor: themeColor.itemBG,
      borderWidth: Platform.OS === "android" || scheme() === "dark" ? 0.5 : 0,
      borderColor: themeColor.border,
      overflow: scheme() === "light" ? "visible" : "hidden",
      borderRadius: 5,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get verySmallFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 10,
      lineHeight: 12,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get smallFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 12,
      lineHeight: 14,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get mediumFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 16,
      lineHeight: 19,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get largeFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 20,
      lineHeight: 23,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get extraLargeFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 24,
      lineHeight: 27,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get veryLargeFont() {
    return {
      shadowColor: themeColor.colorShadow,
      fontSize: 32,
      lineHeight: 36,
      textAlign: "left",
    };
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get text() {
    return {
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_400Regular",
      fontWeight: "400",
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get textBold() {
    return {
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_700Bold",
      fontWeight: "700",
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get textBlack() {
    return {
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_900Black",
      fontWeight: "800",
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get h1() {
    return {
      fontSize: 30,
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_900Black",
      fontWeight: "800",
      color: themeColor.mainText,
      margin: 10,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get h2() {
    return {
      fontSize: 24,
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_900Black",
      fontWeight: "800",
      color: themeColor.mainText,
      margin: 10,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get h3() {
    return {
      fontSize: 20,
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_700Bold",
      fontWeight: "800",
      color: themeColor.mainText,
      margin: 10,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get h4() {
    return {
      fontSize: 18,
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_700Bold",
      fontWeight: "800",
      color: themeColor.mainText,
      margin: 10,
      textAlign: "left",
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get h5() {
    return {
      fontSize: 16,
      fontFamily: Platform.OS === "ios" ? "Futura" : "Lato_700Bold",
      fontWeight: "800",
      color: themeColor.mainText,
      margin: 10,
      textAlign: "left",
    };
  },
};
