import * as _firestore from "@firebase/firestore-types";

export type DocumentData = _firestore.DocumentData;
export type DocumentReference<T = DocumentData> = _firestore.DocumentReference<T>;
export type CollectionReference<T = DocumentData> = _firestore.CollectionReference<T>;
export type DocumentSnapshot<T = DocumentData> = _firestore.DocumentSnapshot<T>;
export type Query<T = DocumentData> = _firestore.Query<T>;
export type QuerySnapshot<T = DocumentData> = _firestore.QuerySnapshot<T>;
export type QueryDocumentSnapshot<T = DocumentData> = _firestore.QueryDocumentSnapshot<T>;

export type Transaction = _firestore.Transaction;
export type WriteBatch = _firestore.WriteBatch;
export type TransactionWriteBatch = Transaction | WriteBatch;

export type SnapshotOptions = _firestore.SnapshotOptions;
export type SnapshotListenOptions = _firestore.SnapshotListenOptions;
export type SnapshotMetadata = _firestore.SnapshotMetadata;

export let firestore: _firestore.FirebaseFirestore;

export interface GeoPoint {
  new (latitude: number, longitude: number): _firestore.GeoPoint;
}
export let GeoPoint: GeoPoint;

export interface Timestamp {
  new (seconds: number, nanoseconds: number): _firestore.Timestamp;
  now(): _firestore.Timestamp;
  fromDate: (date: Date) => _firestore.Timestamp;
  fromMillis: (milliseconds: number) => _firestore.Timestamp;
}
export let Timestamp: Timestamp;

export interface Blob {
  fromBase64String: (base64: string) => _firestore.Blob;
  fromUint8Array: (array: Uint8Array) => _firestore.Blob;
}
export let Blob: Blob;

export interface FieldValue {
  serverTimestamp: () => _firestore.FieldValue;
  delete: () => _firestore.FieldValue;
  arrayUnion: (...elements: unknown[]) => _firestore.FieldValue;
  arrayRemove: (...elements: unknown[]) => _firestore.FieldValue;
  increment: (n: number) => _firestore.FieldValue;
}
export let FieldValue: FieldValue;

export interface FieldPath {
  documentId(): _firestore.FieldPath;
}
export let FieldPath: FieldPath;

interface FirestoreLib {
  (app?: unknown): _firestore.FirebaseFirestore;
  GeoPoint: GeoPoint;
  Timestamp: Timestamp;
  Blob: Blob;
  FieldValue: FieldValue;
  FieldPath: FieldPath;
}

export function initializeFirestore(firestoreLib: unknown, app?: unknown): _firestore.FirebaseFirestore {
  const fs = firestoreLib as FirestoreLib;
  firestore = app ? fs(app) : fs();
  GeoPoint = fs.GeoPoint;
  Timestamp = fs.Timestamp;
  Blob = fs.Blob;
  FieldValue = fs.FieldValue;
  FieldPath = fs.FieldPath;
  return firestore;
}
