import { LinearGradient } from "expo-linear-gradient";
import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { GridChildView } from "./GridChildView";

export interface Props {
  style?: {};
  title?: string;
  description: string;
  children: { id: string; label: string }[];
  fullWidthNeeded?: boolean;
  onChange: (id: string) => void;
  value: string;
  pointerEvents?: "box-none" | "none" | "box-only" | "auto";
}

export class SettingsSelector extends BaseComponent<Props> {
  // @state selectItem = { item: "", index: 0 };

  constructor(props: Props) {
    super(props);
  }

  // setSelectItem(item: string, index: number): void {
  //   this.selectItem = { item: item, index: index };
  // }

  // protected async initialize(): Promise<void> {
  //   this.setSelectItem(
  //     this.props.value,
  //     this.props.children.findIndex((value) => value === this.props.value)
  //   );
  //   return;
  // }

  // componentDidUpdate(): void {}

  renderComponent(): JSX.Element {
    return (
      <GridChildView
        oneColumn={this.props.fullWidthNeeded}
        titleComponent={
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Text style={[styles.h4, { flexShrink: 1 }]}>{this.props.title}</Text>
          </View>
        }
      >
        <View style={{ backgroundColor: themeColor.textArea, borderRadius: 10, flexGrow: 1, padding: 10, flexDirection: "row", flexWrap: "wrap" }}>
          {this.props.children.map((item) => {
            console.log("セレクト", item, this.props.value === item.id, this.props.value);
            const isSelected = this.props.value === item.id;
            return (
              <View key={item.id} style={[styles.colorShadow, { flexGrow: 1, flexShrink: 1, margin: 2, flexBasis: 100, minWidth: 200, flex: 1 }]}>
                <TouchableOpacity
                  onPress={() => {
                    this.props.onChange(item.id);
                  }}
                  style={[styles.colorShadow, { backgroundColor: themeColor.itemBG, borderRadius: 5, borderColor: themeColor.border, borderWidth: 0.25 }]}
                >
                  <LinearGradient
                    style={[{ margin: 2, height: 50, justifyContent: "center", borderRadius: 5 }]}
                    colors={isSelected ? themeColor.mainGradation : [themeColor.base, themeColor.base]}
                    start={{ x: 1, y: 0 }}
                    end={{ x: 0, y: 0 }}
                  >
                    <View style={{ height: 50, borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
                      <Text style={[styles.mediumFont, styles.textBold, { color: isSelected ? themeColor.white : themeColor.subText }]}>{item.label}</Text>
                    </View>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
        <View style={{ margin: 10 }}>
          <Text style={[styles.smallFont, { color: themeColor.subText }]}>{this.props.description}</Text>
        </View>
      </GridChildView>
    );
  }
}
