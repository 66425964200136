import { LinearGradient } from "expo-linear-gradient";
import { Image, Text } from "native-base";
import React from "react";
import { Button, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { CollapsableListTab, CollapsableScrollTab, CollapsableTabView } from "@core/CollapsableTabView";
import { Spacer } from "@core/Spacer";
import styles, { isWidthLarger, themeColor } from "@core/Styles";
import { StackNavigationOptions } from "@react-navigation/stack";
import { Router } from "~/router";
import { SceneBase, ScreenProp } from "~/screens/Core/Navigator";
import { CollapsableText } from "../Component/CollapsableText";
import { DetailBookButton } from "../Component/DetailBookButton";
import { GridChildView } from "../Component/GridChildView";
import { GridView } from "../Component/GridView";
import { IconInfo } from "../Component/IconInfo";
import { MiniIconTitleButton } from "../Component/MiniIconTitleButton";
import { TextLabel } from "../Component/TextLabel";
import { BaseScene } from "../Core/BaseScene";

type type = "Account";

declare module "native-base" {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export class DefaultTabBar extends React.Component<any, any> {}
}
class scene extends BaseScene {
  static sceneName: type = "Account";
  static Params: undefined;

  constructor(props: ScreenProp<type>) {
    super(props);
  }

  static options(_prop: ScreenProp<type>): StackNavigationOptions {
    return {
      headerShown: false,
    };
  }

  protected renderComponent(): JSX.Element {
    return (
      <CollapsableTabView
        headerContents={
          <View pointerEvents="box-none" style={[{ flexGrow: 1, backgroundColor: themeColor.itemBG, height: 400 }]}>
            <View pointerEvents="none" style={{ position: "absolute", width: "100%", opacity: 1, height: 400, zIndex: -2 }}>
              <Animated.Image
                source={{ uri: "https://upload.wikimedia.org/wikipedia/commons/c/c5/Moraine_Lake_17092005.jpg" }}
                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, opacity: 1 }}
              ></Animated.Image>
              <LinearGradient
                colors={["rgba(0,0,0,0.25)", "rgba(0,0,0,0.45)", "rgba(0,0,0,1)"]}
                locations={[0, 0.55, 1]}
                style={{ position: "absolute", height: "100%", width: "100%" }}
              />
            </View>
            <View
              pointerEvents="box-none"
              style={{
                backgroundColor: themeColor.itemBG,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                left: 0,
                right: 0,
                bottom: 0,
                height: 280,
                zIndex: -1,
                position: "absolute",
              }}
            ></View>
            <View pointerEvents="box-none" style={{ height: 280, bottom: 0, width: "100%", position: "absolute" }}>
              <View style={{ flexDirection: "column", width: "100%", paddingHorizontal: 15, maxWidth: 800, alignSelf: "center" }}>
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={[
                      {
                        width: isWidthLarger("md") ? 128 : 96,
                        height: isWidthLarger("md") ? 128 : 96,
                        borderRadius: isWidthLarger("md") ? 64 : 48,
                        backgroundColor: "lightgray",
                        marginTop: isWidthLarger("md") ? -48 : -32,
                      },
                      styles.colorShadowDeep,
                    ]}
                  >
                    <View style={{ width: "100%", height: "100%", overflow: "hidden", borderRadius: isWidthLarger("md") ? 64 : 48, borderWidth: 3, borderColor: "white" }}>
                      <Image
                        style={{ width: isWidthLarger("md") ? 128 : 96, height: isWidthLarger("md") ? 128 : 96, alignSelf: "center" }}
                        resizeMode={"contain"}
                        source={require("@assets/images/human.png")}
                        alt="image"
                      />
                    </View>
                  </View>
                  <Spacer space={5}></Spacer>
                  <View pointerEvents="box-none" style={{ flexDirection: "row", marginTop: 15, flexGrow: 1, flexShrink: 1, alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <MiniIconTitleButton
                      title="サイト拡張"
                      icon={require("@assets/images/icon_setting.png")}
                      onPress={() => this.currentScene?.navigation.navigate("SiteExtension")}
                      width={90}
                      height={45}
                    ></MiniIconTitleButton>
                    <MiniIconTitleButton
                      title="プロフィール編集"
                      icon={require("@assets/images/human.png")}
                      onPress={() => Router.appNavigate("Settings", true)}
                      width={90}
                      height={45}
                    ></MiniIconTitleButton>
                  </View>
                </View>
                <Spacer vertical space={10}></Spacer>
                <View pointerEvents="none" style={{ flexGrow: 1, flexShrink: 1 }}>
                  <Spacer vertical space={15}></Spacer>
                  <TextLabel blackWeight fontSize={isWidthLarger("md") ? "extraLarge" : "large"} color={themeColor.mainText}>
                    罵倒 太郎@新作小説12/31発売
                  </TextLabel>
                  <Spacer vertical space={5}></Spacer>
                  <TextLabel numberOfLines={1} color={themeColor.subText}>
                    @ilksoftware
                  </TextLabel>
                  <Spacer vertical space={15}></Spacer>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <TextLabel boldWeight fontSize="small" numberOfLines={1} color={themeColor.subText}>
                      アカウント連携中
                    </TextLabel>
                    <Spacer space={10}></Spacer>
                    <Image style={{ width: 24, height: 24, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/logo_apple_black.png")} alt="apple" />
                    <Spacer space={5}></Spacer>
                    <Image style={{ width: 24, height: 24, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/logo_google.png")} alt="google" />
                    <Spacer space={5}></Spacer>
                    <Image style={{ width: 24, height: 24, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/logo_twitter.png")} alt="twitter" />
                    <Spacer space={5}></Spacer>
                  </View>
                  <Spacer vertical space={10}></Spacer>
                  <View style={{ flexDirection: "row", justifyContent: "space-around", flexGrow: 1, alignItems: "center" }}>
                    <IconInfo title="作品数" data="334" unitName="作品"></IconInfo>
                    <IconInfo title="フォロー" data="334" unitName="人"></IconInfo>
                    <IconInfo title="フォロワー" data="334" unitName="人"></IconInfo>
                  </View>
                </View>
              </View>
            </View>
          </View>
        }
      >
        {[
          new CollapsableScrollTab(
            "概要",
            (
              <GridView>
                <CollapsableText title="自己紹介">
                  {[
                    "合同会社いるく商事の罵倒太郎と申します！代表作は、「今、このエリアをご挨拶まわりをしておりまして、少しお話し伺ってもいいですか？」「題名などという贅沢なものはこの小説には無い。」などです！ファンタジー作品をよく読みます。",
                  ]}
                </CollapsableText>
                <GridChildView title="閲覧履歴" oneColumn={true}>
                  <ScrollView horizontal>
                    <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                      <DetailBookButton compact onPress={() => {}}></DetailBookButton>
                    </View>
                  </ScrollView>
                </GridChildView>
                <GridChildView title="テスト用">
                  <View style={styles.container}>
                    <Text>Home333</Text>
                    <Button title="詳細へ" onPress={() => this.navigation.navigate("Detail2", { text: "テキスト" })} />
                    <Button title="ログイン画面" onPress={() => Router.appNavigate("Login", true)} />
                  </View>
                </GridChildView>
              </GridView>
            )
          ),
          new CollapsableListTab(
            "履歴",
            [
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "ふゆり",
              "テステス",
              "なろう",
              "カクヨム",
              "ハーメルン",
              "ノベルアップ",
              "イルクスノベル",
              "履歴",
              "フォロー",
              "フォロワー",
              "ホーム",
              "見つける",
              "書く",
              "本棚",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "ふゆり",
              "テステス",
              "なろう",
              "カクヨム",
              "ハーメルン",
              "ノベルアップ",
              "イルクスノベル",
              "履歴",
              "フォロー",
              "フォロワー",
              "ホーム",
              "見つける",
              "書く",
              "本棚",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "ふゆり",
              "テステス",
              "なろう",
              "カクヨム",
              "ハーメルン",
              "ノベルアップ",
              "イルクスノベル",
              "履歴",
              "フォロー",
              "フォロワー",
              "ホーム",
              "見つける",
              "書く",
              "本棚",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "ふゆり",
              "テステス",
              "なろう",
              "カクヨム",
              "ハーメルン",
              "ノベルアップ",
              "イルクスノベル",
              "履歴",
              "フォロー",
              "フォロワー",
              "ホーム",
              "見つける",
              "書く",
              "本棚",
            ],
            ({ item }) => (
              <View key={item} style={{ height: 250, flexGrow: 1 }}>
                <DetailBookButton onPress={() => {}}></DetailBookButton>
              </View>
            ),
            250
            // (item: string) => item
          ),
          new CollapsableListTab(
            "フォロー",
            [
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "テスト",
              "いるく",
              "三好",
              "かしば",
              "テスト",
              "いるく",
              "三好",
              "かしば",
            ],
            ({ item }) => (
              <View style={{ backgroundColor: "white", width: "100%", height: 200 }}>
                <Text>{item}いるく</Text>
              </View>
            ),
            200,
            (item: string) => item
          ),
          new CollapsableScrollTab(
            "フォロワー",
            (
              <GridView>
                <GridChildView title="最近読んだ話"></GridChildView>
                <GridChildView title="しおり"></GridChildView>
                <GridChildView title="キーワード"></GridChildView>
                <GridChildView title="評価と感想"></GridChildView>
                <GridChildView title="この小説をブックマークしている人はこんな小説も読んでいます！" oneColumn={true}></GridChildView>
              </GridView>
            )
          ),
        ]}
      </CollapsableTabView>
    );
  }
}

export const AccountScene: SceneBase<type> & typeof scene = scene;
