import { BaseComponent } from "./BaseComponent";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function state<T extends BaseComponent<P>, P>(this: unknown, target: T, key: string, propertyDescriptor?: PropertyDescriptor): any {
  const initializer = (propertyDescriptor as { initializer?: () => {} } | undefined)?.initializer;
  if (initializer && typeof initializer === "function") {
    const value = initializer();
    const t = (target as unknown) as { _state_default: { [key: string]: unknown } };
    if (!t._state_default) {
      t._state_default = {};
    }
    t._state_default[key] = value;
  }

  const descriptor: PropertyDescriptor = {
    set: function (this: T, value: unknown) {
      this._state[key] = value;
      if (this.initialized) {
        this._setState(this._state);
      } else {
        (this.state as { [key: string]: unknown })[key] = value;
      }
    },
    get: function (this: T) {
      return this._state[key];
    },
    enumerable: true,
  };
  return descriptor;
}
