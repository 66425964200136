import React from "react";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { ScreenProp } from "../Core/Navigator";
import { EpisodeScene } from "../Episode/EpisodeScene";

const EpisodeStack = createStackNavigator();

export function EpisodeStackScreen(routeProps: ScreenProp<"Episode">): JSX.Element {
  return (
    <EpisodeStack.Navigator
      screenOptions={{
        // headerTintColor: themeColor.mainText,
        // headerStyle: {
        //   backgroundColor: themeColor.bar,
        //   shadowColor: "transparent",
        //   shadowOpacity: 0,
        //   elevation: 0,
        // },
        // headerBackTitleVisible: false,
        headerTitle: undefined,

        headerStyle: {
          backgroundColor: "transparent",
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        headerBackTitleVisible: false,
        headerTransparent: true,

        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(EpisodeStack.Screen, EpisodeScene)}
      {StackScreenRender(EpisodeStack.Screen, Detail2Scene)} */}
      {/* {StackScreenRenderAll(EpisodeStack.Screen, EpisodeScene)} */}
      <EpisodeStack.Screen
        key={"Episode"}
        name={"Episode"}
        options={EpisodeScene.options}
        component={(props) => {
          props.route.params = routeProps.route.params;
          return <EpisodeScene navigation={props.navigation} route={props.route} />;
        }}
      />
    </EpisodeStack.Navigator>
  );
}
