import React, { createRef, CSSProperties } from "react";
import { ColorValue, View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import { MiniIconTitleButton } from "../Component/MiniIconTitleButton";
import { BaseScene } from "../Core/BaseScene";
import { DraggingData } from "./DraggingItem";
import { DraggableData, DraggableItem, DroppableItem, OverlapState, Position, Rect } from "./DragHandler";
import { addDraggableitem, addDroppableitem, removeDraggableitem, removeDroppableitem } from "./DragHandler";

export interface Props extends BaseProps {
  onTap(): void;
  onInto(): void;

  children: string;
  backgroundColor?: ColorValue;
  buttonColorGradation?: [ColorValue, ColorValue];
  textColor?: string;
  style?: CSSProperties;
  icon?: string;
  width?: number;
  height?: number;
}
export class DraggableDummyData implements DraggableData {
  id = "dummy";
  typeName = "dummy";

  renderDragging(): JSX.Element {
    return <View></View>;
  }
}

export class DraggableButton extends BaseComponent<Props> implements DraggableItem, DroppableItem {
  @state hover = false;
  // TODO: やっぱりDroppableとかの当たり判定はちゃんと表示されているのかどうか的なのを判定する方法を確立しないとダメな気がする
  @sharedState({ notify: false }) draggingData?: DraggingData;
  @sharedState currentScene!: BaseScene;
  @sharedState({ notify: false }) isFooterBottomSheetOpen?: boolean;

  view = createRef<View>();
  x = 0;
  y = 0;
  width = 0;
  height = 0;

  get draggableRect(): Rect {
    return { x: this.x, y: this.y, width: this.width, height: this.height };
  }

  onDragStart(): void {
    //
  }
  onDragEnd(): void {
    this.hover = false;
  }

  get droppableRect(): Rect {
    return this.draggableRect;
  }
  order = 2;

  get enable(): boolean {
    return !this.isFooterBottomSheetOpen && !!this.context?.scene && this.context?.scene === this.currentScene;
  }

  startTimestamp = 0;
  enter(pos: Position): void {
    this.hover = true;
    this.startTimestamp = pos.timestamp;
  }
  prevOverlapState?: OverlapState;
  move(pos: Position, _state: OverlapState): void {
    if (this.hover && pos.timestamp > this.startTimestamp + 500) {
      this.props.onInto();
      this.hover = false;
    }
  }
  leave(): void {
    this.hover = false;
  }

  @state touching = false;
  onTapDown(): void {
    this.touching = true;
  }
  onTapUp(): void {
    this.touching = false;
  }
  onTap(): void {
    this.props.onTap();
  }
  onScrollStart(): void {
    this.touching = false;
  }

  get draggableData(): DraggableData {
    return new DraggableDummyData();
  }

  transitionEnd = (): void => {
    this.view.current?.measure((_x, _y, width, height, pagex, pagey) => {
      this.x = pagex;
      this.y = pagey;
      this.width = width;
      this.height = height;
    });
  };

  componentDidMount(): void {
    super.componentDidMount();
    addDraggableitem(this);
    addDroppableitem(this);
    BaseScene.addListenerTransitionEnd(this.transitionEnd);
  }
  componentWillUnmount(): void {
    removeDraggableitem(this);
    removeDroppableitem(this);
    BaseScene.removeListenerTransitionEnd(this.transitionEnd);
    super.componentWillUnmount();
  }

  renderComponent(): JSX.Element {
    let opacity: number | undefined = undefined;
    if (this.hover) {
      opacity = 0.5;
    }
    if (this.touching) {
      opacity = 0.5;
    }

    return (
      <View
        pointerEvents="none"
        ref={this.view}
        onLayout={(_event) => {
          this.view.current?.measure((_x, _y, width, height, pagex, pagey) => {
            this.x = pagex;
            this.y = pagey;
            this.width = width;
            this.height = height;
          });
        }}
        style={[this.props.style, { width: this.props.width, height: this.props.height, opacity: opacity }]}
      >
        <MiniIconTitleButton
          width={this.props.width}
          icon={this.props.icon}
          title={this.props.children}
          onPress={() => {
            //
          }}
          height={this.props.height}
        ></MiniIconTitleButton>
      </View>
    );
  }
}
