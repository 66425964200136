import _ from "lodash";
import React, { createRef } from "react";
import { GestureResponderEvent, NativeTouchEvent, Platform, View } from "react-native";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { DraggingData, DraggingItem } from "./DraggingItem";

export interface Props extends BasePropsJSXChildren {
  onResponderGrant?: (event: GestureResponderEvent) => void;
  onResponderMove?: (event: GestureResponderEvent) => void;
  onResponderRelease?: (event: GestureResponderEvent) => void;
  onResponderTerminate?: (event: GestureResponderEvent) => void;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface Inset {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface Position {
  x: number;
  y: number;
  globalX: number;
  globalY: number;
  timestamp: number;
}

export interface OverlapState {
  isLeft: boolean;
  enterLeftTimestamp: number;
  isRight: boolean;
  enterRightTimestamp: number;
  isTop: boolean;
  enterTopTimestamp: number;
  isBottom: boolean;
  enterBottomTimestamp: number;
  isCenter: boolean;
  enterCenterTimestamp: number;
  timestamp: number;
}

export interface DraggableData {
  typeName: string;
  id: string;
  renderDragging: () => JSX.Element;
}
export interface DraggableItem {
  draggableRect: Rect;
  draggableData: DraggableData;
  enable?: boolean;

  onTap?: () => void;
  onDrag?: () => void;
  onTapDown?: () => void;
  onTapUp?: () => void;
  onScrollStart?: () => void;
  onScrollEnd?: (delta: { x: number; y: number; timestamp: number }) => void;
  onScroll?: (delta: { x: number; y: number }) => void;
  onHold?: () => void;
}
export interface DroppableItem {
  droppableRect: Rect;
  order: number;
  enable?: boolean;

  isOn?: boolean;
  overlapState?: OverlapState;
  readonly margin?: Inset;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;

  enter?: (position: Position) => void;
  move?: (position: Position, state: OverlapState) => void;
  leave?: () => void;
  drop?: (draggingData: DraggingData) => void;
  onScrollStart?: () => void;
  onScrollEnd?: (delta: { x: number; y: number; timestamp: number }) => void;
  onScroll?: (delta: { x: number; y: number }) => void;
}

const draggableItems: DraggableItem[] = [];
export function addDraggableitem(item: DraggableItem): void {
  draggableItems.push(item);
}
export function removeDraggableitem(item: DraggableItem): void {
  const index = draggableItems.findIndex((v) => v === item);
  if (index >= 0) {
    draggableItems.splice(index, 1);
  }
}

const droppableItems: DroppableItem[] = [];
export function addDroppableitem(item: DroppableItem): void {
  droppableItems.push(item);
}
export function removeDroppableitem(item: DroppableItem): void {
  const index = droppableItems.findIndex((v) => v === item);
  if (index >= 0) {
    droppableItems.splice(index, 1);
  }
}

function contain(rect: Rect, x: number, y: number): boolean {
  return rect.x <= x && x <= rect.x + rect.width && rect.y <= y && y <= rect.y + rect.height;
}

function getDraggableItem(x: number, y: number): DraggableItem | undefined {
  return draggableItems.find((item) => item.enable !== false && contain(item.draggableRect, x, y));
}
function getDroppableItem(x: number, y: number): DroppableItem | undefined {
  return _.orderBy(droppableItems, ["order"], ["desc"]).find((item) => item.enable !== false && contain(item.droppableRect, x, y));
}
function getContainDroppableItems(x: number, y: number): DroppableItem[] {
  return droppableItems.filter((item) => item.enable !== false && contain(item.droppableRect, x, y));
}

interface Touch {
  initialValue: NativeTouchEvent;
  // 長押しから動かしたらドラッグ, すぐに動かしたらスクロール, まだ判定できないならウェイティング
  dragging: "drag" | "scroll" | "waiting" | "holding";
  draggableItem?: DraggableItem;
  droppableItem?: DroppableItem;
  offset: { x: number; y: number };
  prevPage?: { x: number; y: number; timestamp: number };
  isStarter: boolean;
  id: string;
}
export class DragHandler extends BaseComponent<Props> {
  @sharedState({ notify: false }) draggingData?: DraggingData;
  draggingRef = createRef<DraggingItem>();

  constructor(props: Props) {
    super(props);
  }

  touches: { [key: string]: Touch } = {};
  touchStarter?: Touch;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intervalId: any;
  lastTouches?: NativeTouchEvent[];
  lastTouchesTime: number = performance.now();
  componentDidMount(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      const now = performance.now();
      if (this.lastTouches && now > this.lastTouchesTime + 100) {
        const span = now - this.lastTouchesTime;
        this.lastTouches = this.lastTouches.map((v) => _.clone(v));
        for (const touch of this.lastTouches) {
          touch.timestamp += span;
          this.onTouchMove(touch);
        }
        this.lastTouchesTime = now;
      }
    }, 32);
  }
  componentWillUnmount(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      delete this.intervalId;
    }
  }

  chackTouchType(event: NativeTouchEvent): void {
    const touch = this.touches[event.identifier];
    if (!touch) {
      return;
    }
    const draggableItem = touch.draggableItem;
    const droppableItem = touch.droppableItem;
    if (touch.dragging === "scroll") {
      return;
    }
    if (draggableItem) {
      if (touch.dragging === "holding") {
        // 長押ししたら掴む
        if (
          (Math.abs(touch.initialValue.pageX - event.pageX) > 5 || Math.abs(touch.initialValue.pageY - event.pageY) > 5) &&
          Math.abs(touch.initialValue.timestamp - event.timestamp) > 250
        ) {
          touch.dragging = "drag";
          if (this.touchStarter) {
            draggableItem.onDrag?.();
          } else {
            draggableItem.onDrag?.();
            if (this.draggingData) {
              this.draggingRef.current?.setPosition(event.pageX - touch.offset.x, event.pageY - touch.offset.y);
              touch.isStarter = true;
              this.touchStarter = touch;
              for (const droppableItem of droppableItems) {
                droppableItem.onDragStart?.();
              }
            }
          }
        }
      }
      if (touch.dragging === "waiting") {
        if (Math.abs(touch.initialValue.timestamp - event.timestamp) > 250) {
          touch.dragging = "holding";
          draggableItem.onHold?.();

          return;
        }
      }
    }

    if (touch.dragging === "waiting" && (draggableItem || droppableItem)) {
      // 長押しせずに動かしたらスクロールとする
      if (Math.abs(touch.initialValue.pageX - event.pageX) > 20 || Math.abs(touch.initialValue.pageY - event.pageY) > 20) {
        touch.dragging = "scroll";
        if (draggableItem) {
          draggableItem.onScrollStart?.();
        } else if (droppableItem) {
          droppableItem.onScrollStart?.();
        }
        return;
      }
    }
  }

  onMoveShouldSetResponder = (event: GestureResponderEvent): boolean => {
    this.chackTouchType(event.nativeEvent);
    return !!this.touchStarter;
  };
  onTouchStart(event: NativeTouchEvent): void {
    const draggableItem = getDraggableItem(event.pageX, event.pageY);
    const droppableItem = getDroppableItem(event.pageX, event.pageY);
    if (!draggableItem && !droppableItem) {
      return;
    }
    draggableItem?.onTapDown?.();
    droppableItem?.onTouchStart?.();
    this.touches[event.identifier] = {
      id: event.identifier,
      initialValue: event,
      dragging: "waiting",
      draggableItem: draggableItem,
      droppableItem: droppableItem,
      offset: { x: event.pageX - (draggableItem?.draggableRect.x ?? 0), y: event.pageY - (draggableItem?.draggableRect.y ?? 0) },
      isStarter: false,
    };
  }
  onTouchMove(event: NativeTouchEvent): void {
    this.chackTouchType(event);
    const touch = this.touches[event.identifier];
    if (!touch) {
      return;
    }
    if (touch.dragging === "drag") {
      if (touch.isStarter) {
        this.draggingRef.current?.setPosition(event.pageX - touch.offset.x, event.pageY - touch.offset.y);
        const containDroppableItems = getContainDroppableItems(event.pageX, event.pageY);
        const leaveDroppableItem = droppableItems.filter((droppbleItem) => droppbleItem.isOn && containDroppableItems.every((v) => v !== droppbleItem));
        for (const droppableItem of leaveDroppableItem) {
          droppableItem.leave?.();
          droppableItem.overlapState = undefined;
          droppableItem.isOn = false;
        }

        for (const droppableItem of containDroppableItems) {
          if (!droppableItem.isOn) {
            droppableItem.isOn = true;
            droppableItem.enter?.({
              x: event.pageX - droppableItem.droppableRect.x,
              y: event.pageY - droppableItem.droppableRect.y,
              globalX: event.pageX,
              globalY: event.pageY,
              timestamp: event.timestamp,
            });
          } else {
            const pos = {
              x: event.pageX - droppableItem.droppableRect.x,
              y: event.pageY - droppableItem.droppableRect.y,
              globalX: event.pageX,
              globalY: event.pageY,
              timestamp: event.timestamp,
            };
            const prevOverlapState = droppableItem.overlapState;
            const margin = droppableItem.margin || { left: 1 / 3, right: 1 / 3, top: 1 / 3, bottom: 1 / 3 };

            const timestamp = pos.timestamp;
            const isLeft = pos.x <= droppableItem.droppableRect.width * margin.left;
            const isRight = pos.x >= droppableItem.droppableRect.width * (1 - margin.right);
            const isTop = pos.y <= droppableItem.droppableRect.height * margin.top;
            const isBottom = pos.y >= droppableItem.droppableRect.height * (1 - margin.bottom);
            const isCenter = !isLeft && !isRight && !isTop && !isBottom;
            const enterLeftTimestamp = isLeft && !prevOverlapState?.isLeft ? timestamp : prevOverlapState?.enterLeftTimestamp ?? 0;
            const enterRightTimestamp = isRight && !prevOverlapState?.isRight ? timestamp : prevOverlapState?.enterRightTimestamp ?? 0;
            const enterTopTimestamp = isTop && !prevOverlapState?.isTop ? timestamp : prevOverlapState?.enterTopTimestamp ?? 0;
            const enterBottomTimestamp = isBottom && !prevOverlapState?.isBottom ? timestamp : prevOverlapState?.enterBottomTimestamp ?? 0;
            const enterCenterTimestamp = isCenter && !prevOverlapState?.isCenter ? timestamp : prevOverlapState?.enterCenterTimestamp ?? 0;

            const state: OverlapState = {
              isLeft,
              enterLeftTimestamp,
              isRight,
              enterRightTimestamp,
              isTop,
              enterTopTimestamp,
              isBottom,
              enterBottomTimestamp,
              isCenter,
              enterCenterTimestamp,
              timestamp,
            };
            droppableItem.move?.(pos, state);
            droppableItem.overlapState = state;
          }
        }
      }
    }
    if (touch.dragging === "scroll") {
      if (touch.prevPage) {
        if (touch.draggableItem) {
          touch.draggableItem.onScroll?.({ x: touch.prevPage.x - event.pageX, y: touch.prevPage.y - event.pageY });
        } else if (touch.droppableItem) {
          touch.droppableItem.onScroll?.({ x: touch.prevPage.x - event.pageX, y: touch.prevPage.y - event.pageY });
        }
      }
    }
    touch.prevPage = { x: event.pageX, y: event.pageY, timestamp: event.timestamp };
  }
  onTouchEnd(event: NativeTouchEvent): void {
    const touch = this.touches[event.identifier];
    if (!touch) {
      return;
    }
    touch.droppableItem?.onTouchEnd?.();
    const draggableItem = touch.draggableItem;
    draggableItem?.onTapUp?.();
    if (touch && draggableItem && touch.dragging === "waiting") {
      this.onTap(event);
    }

    if (touch.isStarter) {
      const droppableItem = getDroppableItem(event.pageX, event.pageY);

      if (this.draggingData) {
        droppableItem?.drop?.(this.draggingData);
      }

      for (const droppableItem of droppableItems) {
        droppableItem.onDragEnd?.();
      }
      this.draggingData = undefined;
      this.touchStarter = undefined;
    }

    const droppableItem = touch.droppableItem;
    if (touch && (draggableItem || droppableItem) && touch.dragging === "scroll") {
      if (draggableItem) {
        draggableItem.onScrollEnd?.(
          touch.prevPage
            ? { x: touch.prevPage.x - event.pageX, y: touch.prevPage.y - event.pageY, timestamp: event.timestamp - touch.prevPage.timestamp }
            : { x: 0, y: 0, timestamp: 0 }
        );
      } else if (droppableItem) {
        droppableItem.onScrollEnd?.(
          touch.prevPage
            ? { x: touch.prevPage.x - event.pageX, y: touch.prevPage.y - event.pageY, timestamp: event.timestamp - touch.prevPage.timestamp }
            : { x: 0, y: 0, timestamp: 0 }
        );
      }
    }

    delete this.touches[event.identifier];
  }
  onTouchCancel(event: NativeTouchEvent): void {
    const touch = this.touches[event.identifier];
    if (!touch) {
      return;
    }

    const draggableItem = touch.draggableItem;
    draggableItem?.onTapUp?.();
    if (touch.isStarter) {
      for (const droppableItem of droppableItems) {
        droppableItem.onDragEnd?.();
      }
      this.draggingData = undefined;
      this.touchStarter = undefined;
    }
    delete this.touches[event.identifier];
  }

  onTap(event: NativeTouchEvent): void {
    const item = this.touches[event.identifier].draggableItem;
    if (item) {
      item.onTap?.();
    }
  }

  addDraggingData(draggableData: DraggableData): void {
    if (!this.draggingData?.data.some((v) => v.id === draggableData.id)) {
      this.draggingData?.data.push(draggableData);
      this.draggingData = _.clone(this.draggingData);
    }
  }

  renderComponent(): JSX.Element {
    return (
      <View
        onStartShouldSetResponder={(event) => {
          // console.log("onStartShouldSetResponder");
          if (Platform.OS === "web") {
            return true;
          } else {
            this.lastTouches = event.nativeEvent.touches;
            this.lastTouchesTime = performance.now();

            for (const touch of event.nativeEvent.changedTouches) {
              this.onTouchStart(touch);
            }
            return false;
          }
        }}
        onMoveShouldSetResponder={(event) => {
          // console.log("onMoveShouldSetResponder");
          if (Platform.OS === "web") {
            return true;
          } else {
            // return false;
            const ret = this.onMoveShouldSetResponder(event);
            this.lastTouches = event.nativeEvent.touches;
            this.lastTouchesTime = performance.now();
            for (const touch of event.nativeEvent.changedTouches) {
              this.onTouchMove(touch);
            }
            // return ret;
            return this.touches[event.nativeEvent.identifier]?.dragging === "drag" || this.touches[event.nativeEvent.identifier]?.dragging === "scroll";
            // return false;
          }
        }}
        onResponderStart={(event) => {
          // console.log("onResponderStart");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();

          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchStart(touch);
          }
          // }
        }}
        onResponderMove={(event) => {
          // console.log("onResponderMove");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();
          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchMove(touch);
          }
          // }
        }}
        onResponderEnd={(event) => {
          // console.log("onResponderEnd");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();
          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchEnd(touch);
          }
          // }
        }}
        onResponderRelease={(event) => {
          // console.log("onResponderRelease");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();
          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchEnd(touch);
          }
          // }
        }}
        onResponderReject={(event) => {
          // console.log("onResponderReject");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();
          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchCancel(touch);
          }
          // }
        }}
        onResponderTerminate={(event) => {
          // console.log("onResponderTerminate");
          // if (Platform.OS === "web") {
          this.lastTouches = event.nativeEvent.touches;
          this.lastTouchesTime = performance.now();
          for (const touch of event.nativeEvent.changedTouches) {
            this.onTouchCancel(touch);
          }
          // }
        }}
        onResponderTerminationRequest={(event) => {
          // console.log("onResponderTerminationRequest");
          return true;
        }}
        onTouchStart={(event) => {
          // console.log("onTouchStart");
          if (Platform.OS !== "web") {
            this.lastTouches = event.nativeEvent.touches;
            this.lastTouchesTime = performance.now();
            for (const touch of event.nativeEvent.changedTouches) {
              this.onTouchStart(touch);
            }
          }
        }}
        // onTouchMove={(event) => {
        //   // console.log("onTouchMove");
        //   if (Platform.OS !== "web") {
        //     this.lastTouches = event.nativeEvent.touches;
        //     this.lastTouchesTime = performance.now();
        //     for (const touch of event.nativeEvent.changedTouches) {
        //       this.onTouchMove(touch);
        //     }
        //   }
        // }}
        onTouchEnd={(event) => {
          // console.log("onTouchEnd");
          if (Platform.OS !== "web") {
            this.lastTouches = event.nativeEvent.touches;
            this.lastTouchesTime = performance.now();
            for (const touch of event.nativeEvent.changedTouches) {
              this.onTouchEnd(touch);
            }
          }
        }}
        onTouchCancel={(event) => {
          // console.log("onTouchCancel");
          if (Platform.OS !== "web") {
            this.lastTouches = event.nativeEvent.touches;
            this.lastTouchesTime = performance.now();
            for (const touch of event.nativeEvent.changedTouches) {
              this.onTouchCancel(touch);
            }
          }
        }}
        style={this.props.style ?? {}}
      >
        {this.props.children}

        <DraggingItem ref={this.draggingRef}></DraggingItem>
      </View>
    );
  }
}
