import * as firestore from "@firebase/firestore-types";
import { Timestamp } from "../lib/firestore";
import { CollectionReference, DocumentData, DocumentReference } from "../lib/firestore";
import { FirestoreCollection } from "../lib/firestore-collection";
import { FirestoreData, FirestoreDocument, FirestoreKey } from "../lib/firestore-document";
import { UserKey } from "./user-documents";

export interface UserBookstatusKey extends FirestoreKey {
  uid: string;
  bookId: string;
}

export interface UserBookstatusData extends FirestoreData {
  episodes: {
    [key: string]: {
      line: number;
      updatedAt: Timestamp | firestore.FieldValue;
      alreadyRead: boolean;
      text: string;
    };
  };
  lastReadEpisode?: string;
}

export class UserBookstatusCollection extends FirestoreCollection<UserKey, UserBookstatusData, UserBookstatusDocument> {
  static ref(key: UserKey): CollectionReference<DocumentData> {
    return this.firestore().collection("users").doc(key.uid).collection("bookstatus");
  }

  constructor(key: UserKey) {
    super(UserBookstatusDocument, key);
  }
}
export class UserBookstatusDocument extends FirestoreDocument<UserBookstatusKey, UserBookstatusData> {
  static ref(key: UserBookstatusKey): DocumentReference<DocumentData> {
    return UserBookstatusCollection.ref(key).doc(key.bookId);
  }
  public static get defaultData(): UserBookstatusData {
    return { episodes: {} };
  }
}
