import firebase from "firebase";
import _ from "lodash";
import { Container, Flex } from "native-base";
import React from "react";
import { Button, Text, TextInput } from "react-native";
import { getSharedState, sharedState, updateSharedState } from "@core/SharedState";
// import { UserFunction } from "@functions/user";
import { state } from "@core/State";
import styles from "@core/Styles";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { Firebase, ProviderId } from "~/utilities/firebase/FirebaseUtility";
import { BookCollection } from "~/utilities/firebase/firestore/documents/book-documents";
import { EpisodeCollection } from "~/utilities/firebase/firestore/documents/book-episode-document";
import { BookshelfCollection } from "~/utilities/firebase/firestore/documents/bookshelf-documents";
import { ParserCollection, ParserData } from "~/utilities/firebase/firestore/documents/parser-documents";
import { UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { BaseScene } from "../Core/BaseScene";
import { SceneBase, ScreenProp } from "../Core/Navigator";

// import test from "./test.json";

type type = "Write";

class scene extends BaseScene {
  static sceneName: type = "Write";
  static Params: undefined;

  @state text = "ログインちう";
  @state url = "";
  @state name = "";
  @state mail = "";
  @state password = "";
  @sharedState aaaaaa = "";
  @sharedState bbb = "";

  constructor(props: ScreenProp<type>) {
    super(props);
    // this.text = "ログインちう";
  }

  protected async onUserChanged(): Promise<void> {
    const userDocument = new UserDocument({ uid: this.user.uid });
    await userDocument.load();
    this.text = this.user.uid + ": " + userDocument.data.session + "\n" + this.user.email;
    // const a = await UserFunction.export({});
    // console.log(JSON.stringify(a));
    // if (a) {
    //   await UserFunction.import({ input: a.output, path: "/test/aaa" });
    // }
    // await UserFunction.import({ input: test.output });
  }

  protected async addBook(): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      this.text = "えらったっぽい？";
      return;
    }

    // TODO: 適当な本棚に入れるのはなんかちがう
    const bookshelfCollection = new BookshelfCollection({ uid: user.uid });
    await bookshelfCollection.load();
    const bookshelf = _.sortBy(bookshelfCollection.toArray(), (v) => v.data.index).find((v) => v.data.type === "bookshelf");
    await Bookshelf.addBook(this.url, bookshelf?.data._id ?? "", "other");
  }

  protected async createParser(): Promise<void> {
    const parserCollection = new ParserCollection({});
    await parserCollection.load();
    const parser = await parserCollection.add();
    if (parser) {
      const parserData: ParserData = {
        metaData: {
          name: "test",
          domain: "test",
          description: "test説明文",
          tag: [],
          version: 1,
          metaVersion: 1,
          recommend: true,
          url: { index: "https://ncode.syosetu.com/[^/]+/$", episode: "https://ncode.syosetu.com/[^/]+/[0-9]*" },
        },
        episode: {
          title: '//div[contains(@class, "contents1")]/a[1]',
          author: 'RAWHTML!!SAND??name="twitter:creator" content="??">',
          chapterTitle: '//p[contains(@class, "chapter_title")]',
          subTitle: '//p[contains(@class, "novel_subtitle")]',
          text: {
            prev: {
              text: '//div[@id="novel_p"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
              image: {
                check: '<img src="//[0-9]*.mitemin.net/userpageimage/viewimagebig/icode/i[0-9]*/">',
                url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                alt: "",
              },
            },
            main: {
              text: '//div[@id="novel_honbun"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
              image: {
                check: '<img src="//[0-9]*.mitemin.net/userpageimage/viewimagebig/icode/i[0-9]*/">',
                url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                alt: "",
              },
            },
            after: {
              text: '//div[@id="novel_a"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
              image: {
                check: '<img src="//[0-9]*.mitemin.net/userpageimage/viewimagebig/icode/i[0-9]*/">',
                url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                alt: "",
              },
            },
          },
          link: {
            author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href',
            next: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "次へ")]/@href',
            prev: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "前へ")]/@href',
            index: 'VAL!//div[contains(@class, "contents1")]/a[1]/@href',
          },
        },
        index: {
          title: '//p[contains(@class, "novel_title")]',
          author: '//div[contains(@class, "novel_writername")]/a!!REPLACE??作者：??',
          abstract:
            '//div[@id="novel_ex"]!!REPLACE??\n??!!REPLACE??<br>??\n!!REPLACE??<span>…</span><span class="hidden">??!!REPLACE??</span><a class="more">&gt;&gt;続きを読む</a>??',
          link: { author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href' },
          item: {
            container: '//div[contains(@class, "index_box")]/*',
            chapter: { check: 'contains(./@class, "chapter_title")', title: "." },
            episode: {
              check: 'contains(./@class, "novel_sublist2")',
              title: './dd[contains(@class, "subtitle")]/a',
              link: 'VAL!./dd[contains(@class, "subtitle")]/a/@href',
              date: './dt[contains(@class, "long_update")]',
              dateFormat: "YYYY/MM/DD HH:mm",
            },
          },
        },
        raw: "",
      };

      const data: { [key: string]: unknown } = parserData;
      delete data.raw;
      for (const key in data) {
        parser.data[key] = data[key];
      }
      parser.data.raw = JSON.stringify(data);
      await parser.save();
    }
  }
  protected async createBook(): Promise<void> {
    const user = await Firebase.login();
    if (!user) {
      this.text = "えらったっぽい？";
      return;
    }

    const bookCollection = new BookCollection({});
    await bookCollection.load();

    const book = await bookCollection.add();

    if (book) {
      book.data.authorUid = user.uid;
      book.data.author = {
        name: user.uid,
      };
      book.data.abstract = "テストがいよう";
      await book.save();
      const id = book.data._id;
      if (id) {
        const episodeCollection = new EpisodeCollection({ bookId: id });
        await episodeCollection.load();
        await episodeCollection.add({ no: 0, subTitle: "第一話", prev: "", body: "第一話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });
        await episodeCollection.add({ no: 1, subTitle: "第二話", prev: "", body: "第二話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });
        await episodeCollection.add({ no: 2, subTitle: "第三話", prev: "", body: "第三話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });
        await episodeCollection.add({ no: 3, subTitle: "第四話", prev: "", body: "第四話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });
        await episodeCollection.add({ no: 4, subTitle: "第五話", prev: "", body: "第五話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });
        await episodeCollection.add({ no: 5, subTitle: "第六話", prev: "", body: "第六話本文", after: "", episodeUpdatedAt: Firebase.serverTimestamp });

        await Bookshelf.addBook(this.url, "ilks");
      }
      console.log(book.data);
    }
  }
  protected async login(id: ProviderId): Promise<void> {
    try {
      if (id === "password") {
        const user = await Firebase.signInEmailAndPassword(this.mail, this.password);
        console.log("logined:", user?.user);
      } else {
        const user = await Firebase.signInSNS(id);
        console.log("logined:", user?.user);
      }
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
      console.error(e);
    }
    this.text = Firebase.user?.uid + "\n" + Firebase.user?.email + "\n" + Firebase.linked;
  }
  protected async register(id: ProviderId): Promise<void> {
    try {
      if (id === "password") {
        const user = await Firebase.signUpEmailAndPassword(this.name, this.mail, this.password);
        console.log("registered:", user?.user);
      } else {
        const user = await Firebase.signUpSNS(id);
        console.log("logined:", user?.user);
      }
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
      console.error(e);
    }
    await Firebase.updateLinked();
    this.text = Firebase.user?.uid + "\n" + Firebase.user?.email + "\n" + Firebase.linked;
  }

  protected async unlink(id: ProviderId): Promise<void> {
    try {
      await Firebase.unlink(id);
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
      console.error(e);
    }
    this.text = Firebase.user?.uid + "\n" + Firebase.user?.email + "\n" + Firebase.linked;
  }
  protected isLinked(id: ProviderId): boolean {
    return Firebase.isLinked(id);
  }

  protected async logout(): Promise<void> {
    try {
      await Firebase.logout();
      this.setUser();
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
      console.error(e);
    }
    this.text = Firebase.user?.uid + "\n" + Firebase.user?.email + "\n" + Firebase.linked;
  }
  protected async resetPassword(): Promise<void> {
    try {
      await Firebase.sendPasswordResetEmail(this.mail);
      alert(this.mail + "にメール送っといたわ");
    } catch (e) {
      const message = Firebase.AuthErrorToJapaneseMessage(e);
      alert(message);
    }
  }
  protected setUser(): void {
    updateSharedState("user", Firebase.user);
    const resolve = getSharedState("resolveUser") as ((user: firebase.User | null) => void) | undefined;
    resolve?.(this.user);
  }

  renderNoUser(): JSX.Element {
    return this.renderComponent();
  }

  renderComponent(): JSX.Element {
    return (
      <Container style={[styles.container, { backgroundColor: "white" }]}>
        <Text>Home②</Text>
        <Text>{this.text}</Text>
        <Button
          title="詳細へ"
          onPress={() => {
            this.navigation.navigate("Detail2", { text: "これは、globeのアイコンです。" });
          }}
        />
        <Button
          title="適当なパーサー設定を投稿"
          onPress={async () => {
            await this.createParser();
          }}
        ></Button>
        <Button
          title="適当な本作成・本棚に追加"
          onPress={async () => {
            await this.createBook();
          }}
        />
        <TextInput
          autoCapitalize="none"
          autoFocus={false}
          onChangeText={(value: string) => {
            this.url = value;
          }}
          value={this.url}
          onBlur={() => {
            console.log("onBlur");
          }}
          onFocus={() => {
            console.log("onFocus");
          }}
          underlineColorAndroid="transparent"
          keyboardType={"default"}
          returnKeyType={"done"}
          placeholder={"URL"}
          multiline={false}
          selectTextOnFocus={false}
          onSubmitEditing={() => {
            console.log("onSubmitEditing");
          }}
          blurOnSubmit={true}
        />
        <Button
          title="URLの本を本棚に追加"
          onPress={async () => {
            await this.addBook();
          }}
        />
        <Text>name: </Text>
        <TextInput
          autoCapitalize="none"
          autoFocus={false}
          onChangeText={(value: string) => {
            this.name = value;
            this.aaaaaa = value;
          }}
          value={this.name}
          underlineColorAndroid="transparent"
          keyboardType={"default"}
          returnKeyType={"done"}
          placeholder={"名前"}
          multiline={false}
          selectTextOnFocus={false}
        />
        <Text>mail: </Text>
        <TextInput
          autoCapitalize="none"
          autoFocus={false}
          onChangeText={(value: string) => {
            this.mail = value;
          }}
          value={this.mail}
          underlineColorAndroid="transparent"
          keyboardType={"default"}
          autoCompleteType={"email"}
          returnKeyType={"done"}
          placeholder={"mail"}
          multiline={false}
          selectTextOnFocus={false}
        />
        <Text>password: </Text>
        <TextInput
          autoCapitalize="none"
          autoFocus={false}
          onChangeText={(value: string) => {
            this.password = value;
          }}
          value={this.password}
          underlineColorAndroid="transparent"
          keyboardType={"default"}
          autoCompleteType={"password"}
          returnKeyType={"done"}
          placeholder={"password"}
          multiline={false}
          selectTextOnFocus={false}
        />

        <Flex direction="row" wrap="wrap">
          <Button
            title="ログイン"
            onPress={async () => {
              await this.login("password");
            }}
          />
          <Button
            title="新規作成"
            onPress={async () => {
              await this.register("password");
            }}
          />
          <Button
            title={this.isLinked("password") ? "連携解除" : "未登録"}
            onPress={async () => {
              await this.unlink("password");
            }}
          />
        </Flex>

        <Flex direction="row" wrap="wrap">
          <Button
            title="Googleログイン"
            onPress={async () => {
              await this.login("google.com");
            }}
          />
          <Button
            title="新規登録"
            onPress={async () => {
              await this.register("google.com");
            }}
          />
          <Button
            title={this.isLinked("google.com") ? "連携解除" : "未登録"}
            onPress={async () => {
              await this.unlink("google.com");
            }}
          />
        </Flex>

        <Flex direction="row" wrap="wrap">
          <Button
            title="Twitterログイン"
            onPress={async () => {
              await this.login("twitter.com");
            }}
          />
          <Button
            title="新規登録"
            onPress={async () => {
              await this.register("twitter.com");
            }}
          />
          <Button
            title={this.isLinked("twitter.com") ? "連携解除" : "未登録"}
            onPress={async () => {
              await this.unlink("twitter.com");
            }}
          />
        </Flex>

        <Flex direction="row" wrap="wrap">
          <Button
            title="Appleログイン"
            onPress={async () => {
              await this.login("apple.com");
            }}
          />
          <Button
            title="新規登録"
            onPress={async () => {
              await this.register("apple.com");
            }}
          />
          <Button
            title={this.isLinked("apple.com") ? "連携解除" : "未登録"}
            onPress={async () => {
              await this.unlink("apple.com");
            }}
          />
        </Flex>

        <Button
          title="ログアウト"
          onPress={async () => {
            await this.logout();
          }}
        />
        <Button
          title="パスワード忘れた"
          onPress={async () => {
            await this.resetPassword();
          }}
        />

        {/* <Hello name="World" enthusiasmLevel={1} /> */}
      </Container>
    );
  }
}
export const WriteScene: SceneBase<type> & typeof scene = scene;
