import _ from "lodash";
import React, { createRef } from "react";
import { Platform, View } from "react-native";
import { BaseComponent, BaseProps } from "@core/BaseComponent";
import { sharedState } from "@core/SharedState";
import { state } from "@core/State";
import { BaseScene } from "../Core/BaseScene";
import { DraggableCollection } from "./DragableCollection";
import { DraggingData } from "./DraggingItem";
import { DraggableData, DraggableItem, DroppableItem, Inset, OverlapState, Position, Rect } from "./DragHandler";
import { addDraggableitem, addDroppableitem, removeDraggableitem, removeDroppableitem } from "./DragHandler";

export interface Props extends BaseProps {
  data: DraggableCollectionData;
  parent?: DraggableCollection;
}

export class DraggableCollectionData implements DraggableData {
  id = "dummy";
  typeName = "dummy";

  renderDragging(): JSX.Element {
    return <DraggableCollectionItem key={"dragging" + this.id} data={this}></DraggableCollectionItem>;
  }
}

export class DraggableCollectionItem extends BaseComponent<Props> implements DraggableItem, DroppableItem {
  @sharedState currentScene!: BaseScene;
  @sharedState draggingData?: DraggingData;
  @sharedState({ notify: false }) isFooterBottomSheetOpen?: boolean;
  @sharedState({ notify: false }) isDialogOpen?: boolean;
  @sharedState bookshelfScale = 1.0;

  @state isFloating;
  @state hover = false;
  @state into = false;
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  view = createRef<View>();

  get draggableRect(): Rect {
    if (this.isFloating) {
      return { x: 0, y: 0, width: 0, height: 0 };
    }
    if (Platform.OS === "web" && this.view.current) {
      const div = (this.view.current as unknown) as HTMLDivElement;
      return div.getBoundingClientRect();
    }
    if (!this.props.parent) {
      return { x: this.x, y: this.y, width: this.width, height: this.height };
    }
    const x = this.props.parent.x - this.props.parent.scrollX + this.x;
    const y = this.props.parent.y - this.props.parent.scrollY + this.y;
    const width = this.width;
    const height = this.height;

    const left = Math.max(x, this.props.parent.x);
    const top = Math.max(y, this.props.parent.y);
    const right = Math.min(x + width, this.props.parent.x + this.props.parent.width);
    const bottom = Math.min(y + height, this.props.parent.y + this.props.parent.height);
    return {
      x: left,
      y: top,
      width: right - left,
      height: bottom - top,
    };
  }
  onDragStart(): void {
    //
  }
  onDragEnd(): void {
    this.hover = false;
    this.into = false;
  }

  get droppableRect(): Rect {
    return this.draggableRect;
  }
  order = 2;
  get enable(): boolean {
    return !this.isDialogOpen && !this.isFooterBottomSheetOpen && !!this.context?.scene && this.context?.scene === this.currentScene;
  }
  overlapState?: OverlapState;
  enter(_pos: Position): void {
    if (this.isFloating || this.isSelected) {
      return;
    }
    this.hover = true;
  }

  get margin(): Inset {
    return { left: 1 / 3, right: 1 / 3, top: 1 / 3, bottom: 1 / 3 };
  }
  move(_pos: Position, state: OverlapState): void {
    if (this.isFloating || this.isSelected) {
      return;
    }
    if (state.isCenter) {
      this.into = true;
    }

    if (this.draggingData) {
      this.props.parent?.onOverlap(state, this.props.data, this.draggingData.data);
    }
  }
  leave(): void {
    this.hover = false;
    this.into = false;
  }

  @state touching = false;
  onTapDown(): void {
    this.touching = true;
  }
  onTapUp(): void {
    this.touching = false;
    this.holding = false;
  }
  onTap(): void {
    //
  }

  @state holding = false;
  onHold(): void {
    this.holding = true;

    if (this.props.parent) {
      this.props.parent.scrollEnabled = false;
    }
  }
  onDrag(): void {
    if (this.draggingData) {
      if (!this.draggingData?.data.some((v) => v.id === this.props.data.id)) {
        this.draggingData.data.push(this.props.data);
        this.draggingData = _.clone(this.draggingData);
      }
    } else {
      this.draggingData = { data: [this.props.data] };
    }

    //
  }

  onTouchStart(): void {
    this.props.parent?.onTouchStart();
  }
  onScrollStart(): void {
    this.touching = false;
    this.props.parent?.onScrollStart();
  }
  onScroll({ x, y }: { x: number; y: number }): void {
    this.props.parent?.onScroll({ x, y });
  }
  onScrollEnd({ x, y }: { x: number; y: number }): void {
    this.props.parent?.onScrollEnd({ x, y });
  }

  drop(_draggingData: DraggingData): void {
    //
  }

  constructor(props: Props) {
    super(props);
    this.isFloating = !props.parent;
  }

  get draggableData(): DraggableData {
    return this.props.data;
  }

  componentDidMount(): void {
    super.componentDidMount();
    addDraggableitem(this);
    addDroppableitem(this);
  }
  componentWillUnmount(): void {
    removeDraggableitem(this);
    removeDroppableitem(this);

    super.componentWillUnmount();
  }

  get isSelected(): boolean {
    if (this.draggingData && this.draggingData.data.some((v) => v.id === this.props.data.id)) {
      return true;
    } else {
      return false;
    }
  }

  renderItem(): JSX.Element {
    return <View></View>;
  }

  renderComponent(): JSX.Element {
    if (!this.isFloating && this.isSelected) {
      this.x = 0;
      this.y = 0;
      this.width = 0;
      this.height = 0;
      if (this.props.data.id === this.draggingData?.data[0]?.id) {
        return (
          <View
            key={this.isFloating ? "hold" : this.draggableData.id}
            style={{ width: 90 * this.bookshelfScale, height: 129 * this.bookshelfScale, marginHorizontal: 5 * this.bookshelfScale }}
          ></View>
        );
      } else {
        return <View></View>;
      }
    }

    return (
      <View
        ref={this.view}
        onLayout={(event) => {
          this.x = event.nativeEvent.layout.x;
          this.y = event.nativeEvent.layout.y;
          this.width = event.nativeEvent.layout.width;
          this.height = event.nativeEvent.layout.height;
        }}
        key={this.isFloating ? "hold" : this.draggableData.id}
        style={this.props.style ?? {}}
      >
        {this.renderItem()}
      </View>
    );
  }
}
