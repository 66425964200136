import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { sharedState } from "@core/SharedState";
import { Bookshelf } from "~/utilities/BookshelfUtility";
import { BookshelfBookButton } from "../Component/BookshelfBookButton";
import { BookshelfScene } from "./BookshelfScene";
import { DraggableCollectionItem } from "./DraggableCollectionItem";
import { DraggingData } from "./DraggingItem";
import { DraggableData, OverlapState, Position } from "./DragHandler";

export class DraggableCollectionGroupData implements DraggableData {
  id: string;
  title: string;
  path: string;
  parent: string;
  type: "group";
  typeName = "group";
  constructor(id: string, title: string, path: string, parent: string, type: "group") {
    this.id = id;
    this.title = title;
    this.path = path;
    this.parent = parent;
    this.type = type;
  }

  renderDragging(): JSX.Element {
    return <DraggableCollectionGroup key={"dragging" + this.id} data={this}></DraggableCollectionGroup>;
  }
}

export class DraggableCollectionGroup extends DraggableCollectionItem {
  @sharedState bookshelfScale = 1.0;

  onTap(): void {
    if (this.isFloating || this.isSelected) {
      return;
    }

    if (this.draggingData && this.draggingData.data.every((v) => v.typeName !== "bookshelf")) {
      this.draggingData.data.push(this.props.data);
      this.draggingData = _.clone(this.draggingData);
    } else {
      // this.currentScene?.navigation.push("Bookshelf", { path: this.draggableData.url });
      // if (this.currentScene.constructor.name === "BookshelfScene") {
      //   const bookshelfScene = this.currentScene as BookshelfScene;
      //   bookshelfScene.changePath(this.draggableData.path);
      // }
      const scene = this.context?.scene ?? this.currentScene;
      if ("changePath" in scene) {
        const bookshelfScene = scene as BookshelfScene;
        bookshelfScene.changePath(this.draggableData.path);
      }
    }
  }

  move(pos: Position, state: OverlapState): void {
    super.move(pos, state);
    if (this.overlapState && this.hover && this.overlapState.isCenter && this.overlapState.timestamp > this.overlapState.enterCenterTimestamp + 1000) {
      this.hover = false;
      // this.currentScene?.navigation.push("Bookshelf", { path: this.draggableData.url });
      // if (this.currentScene.constructor.name === "BookshelfScene") {
      //   const bookshelfScene = this.currentScene as BookshelfScene;
      //   bookshelfScene.changePath(this.draggableData.path);
      // }
      const scene = this.context?.scene ?? this.currentScene;
      if ("changePath" in scene) {
        const bookshelfScene = scene as BookshelfScene;
        bookshelfScene.changePath(this.draggableData.path);
      }
    }
  }

  drop(draggingData: DraggingData): void {
    if (!this.props.parent) {
      return;
    }
    if (this.overlapState?.isCenter) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Bookshelf.moveItem({
        ids: draggingData.data.map((v) => v.id),
        parent: this.draggableData.id,
      });
    }
  }

  get draggableData(): DraggableCollectionGroupData {
    return this.props.data as DraggableCollectionGroupData;
  }

  renderItem(): JSX.Element {
    let color: string | undefined = undefined;
    let opacity: number | undefined = undefined;
    if (this.isFloating) {
      color = "yellow";
      opacity = 1;
    } else if (this.into) {
      color = "red";
      opacity = 1;
    } else if (this.hover) {
      color = "magenta";
      opacity = 1;
    }
    if (this.touching) {
      color = "sandybrown";
      opacity = 0.2;
    }
    return (
      <View style={{ marginBottom: 35 * this.bookshelfScale }}>
        <BookshelfBookButton
          key={this.isFloating ? "hold" : this.props.data.id}
          type="bookGroup"
          title={this.draggableData.title}
          authorName={"作者名"}
          genre={"ジャンル"}
          style={color ? { backgroundColor: color, opacity: opacity, borderRadius: 20 } : { backgroundColor: "sandybrown", borderRadius: 20 }}
          onPress={() => {
            // this.currentScene?.navigation.navigate("BookSummary", {
            //   id: this.props.data.id,
            //   title: this.props.data.title,
            //   url: this.props.data.url,
            //   type: this.props.data.type,
            // });
          }}
        ></BookshelfBookButton>
      </View>
    );
  }
}
