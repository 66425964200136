import React from "react";
import { themeColor } from "@core/Styles";
import { CardStyleInterpolators, createStackNavigator } from "@react-navigation/stack";
import { StackScreenRenderAll } from "~/screens/Core/Navigator";
import { HomeScene } from "../Home/HomeScene";

const HomeStack = createStackNavigator();

export function HomeStackScreen(): JSX.Element {
  return (
    <HomeStack.Navigator
      screenOptions={{
        headerTintColor: themeColor.mainText,
        headerStyle: {
          backgroundColor: themeColor.bar,
          shadowColor: "transparent",
          shadowOpacity: 0,
          elevation: 0,
        },
        gestureEnabled: true,
        gestureDirection: "horizontal",
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      }}
      headerMode="float"
    >
      {/* {StackScreenRender(HomeStack.Screen, HomeScene)}
      {StackScreenRender(HomeStack.Screen, DetailScene)} */}
      {StackScreenRenderAll(HomeStack.Screen, HomeScene)}
    </HomeStack.Navigator>
  );
}
