import React from "react";
import { EdgeInsets } from "react-native-safe-area-context";
import { BaseScene } from "~/screens/Core/BaseScene";

export interface AppContextType {
  safeAreaInsets: EdgeInsets | null;
  theme: string;
  scene?: BaseScene;
}

export const AppContext = React.createContext<AppContextType | null>(null);
