import _ from "lodash";
import React from "react";
import { View } from "react-native";
import { DialogInput, DialogItem, DialogSwitch, Props as SimpleDialogProps, SimpleDialog } from "../Component/SimpleDialog";
import { BaseComponent } from "./BaseComponent";
import { state } from "./State";
import { generateUuid } from "./uuid";

export interface Props {}

export let instance: DialogComponent | undefined;

export interface Dialog extends SimpleDialogProps {
  id: string;
  removed: boolean;
}
export class DialogComponent extends BaseComponent<Props> {
  @state private dialogData: Dialog[] = [];

  static requestUpdate(): void {
    instance?.requestUpdate();
  }
  static showNotify(title: string, description: string, buttonText?: string, callback?: () => void): Dialog | undefined {
    return instance?.showNotify(title, description, buttonText, callback);
  }
  static showClosed(title: string, description: string, buttonTexts?: string[], callback?: (value: string) => void): Dialog | undefined {
    return instance?.showClosed(title, description, buttonTexts, callback);
  }
  static showSimpleInput(
    title: string,
    description: string,
    buttonTexts?: string[],
    inputOption?: { defaultText: string; placeholder: string },
    callback?: (value: string) => void
  ): Dialog | undefined {
    return instance?.showSimpleInput(title, description, buttonTexts, inputOption, callback);
  }

  static createDialog(): Dialog {
    return { visible: false, children: [], id: generateUuid() + "-" + performance.now(), removed: false };
  }
  static showCustom(dialog: Dialog | null, title: string, description: string, items: DialogItem[]): Dialog | undefined {
    return instance?.showCustom(dialog, title, description, items);
  }
  static close(props: Dialog): void {
    instance?.close(props);
  }

  renderComponent(): JSX.Element {
    this.dialogData = this.dialogData.filter((v) => !v.removed);
    // 全部同時に表示できるようにしたかった感じはあるけど、なんか表示されないので諦める
    // return this.dialogData.map((v) => <SimpleDialog {..._.cloneDeep(v)}></SimpleDialog>);
    // return (
    //   <View>
    //     {this.dialogData.map((v) => (
    //       <SimpleDialog key={v.id} {..._.cloneDeep(v)}></SimpleDialog>
    //     ))}
    //   </View>
    // );
    return this.dialogData[this.dialogData.length - 1] ? <SimpleDialog {..._.cloneDeep(this.dialogData[this.dialogData.length - 1])}></SimpleDialog> : <View></View>;
  }

  showNotify(title: string, description: string, buttonText?: string, callback?: () => void): Dialog {
    const dialogData: Dialog = {
      visible: true,
      title,
      description,
      children: [
        {
          itemType: "button",
          label: buttonText ?? "OK",
          onPress: async () => {
            dialogData.visible = false;
            dialogData.removed = true;
            this.requestUpdate();
            callback?.();
          },
        },
      ],
      id: generateUuid() + "-" + performance.now(),
      removed: false,
    };
    this.dialogData.push(dialogData);
    this.requestUpdate();
    return dialogData;
  }
  showClosed(title: string, description: string, buttonTexts?: string[], callback?: (value: string) => void): Dialog {
    const dialogData: Dialog = {
      visible: true,
      title,
      description,
      children: (buttonTexts ?? ["はい", "いいえ"]).map((v) => ({
        itemType: "button",
        label: v,
        onPress: () => {
          dialogData.visible = false;
          dialogData.removed = true;
          this.requestUpdate();
          callback?.(v);
        },
      })),
      id: generateUuid() + "-" + performance.now(),
      removed: false,
    };
    this.dialogData.push(dialogData);
    this.requestUpdate();
    return dialogData;
  }
  showSimpleInput(
    title: string,
    description: string,
    buttonTexts?: string[],
    inputOption: { defaultText: string; placeholder: string } = { defaultText: "", placeholder: "" },
    callback?: (value: string) => void
  ): Dialog {
    const input: DialogInput = {
      itemType: "input",
      placeholder: inputOption.placeholder,
      value: inputOption.defaultText,
    };
    input.onChangeText = (v) => {
      input.value = v;
      this.requestUpdate();
    };

    const dialogData: Dialog = {
      visible: true,
      title,
      description,
      children: [
        input,
        ...((buttonTexts ?? ["はい", "いいえ"]).map((v) => ({
          itemType: "button",
          label: v,
          onPress: () => {
            dialogData.visible = false;
            dialogData.removed = true;
            this.requestUpdate();
            callback?.(v);
          },
        })) as DialogItem[]),
      ],
      id: generateUuid() + "-" + performance.now(),
      removed: false,
    };

    this.dialogData.push(dialogData);
    this.requestUpdate();
    return dialogData;
  }
  showCustom(dialog: Dialog | null, title: string, description: string, items: DialogItem[]): Dialog {
    if (!dialog) {
      dialog = DialogComponent.createDialog();
    }
    dialog.visible = true;
    dialog.title = title;
    dialog.description = description;
    dialog.children = items;
    for (const item of dialog.children) {
      switch (item.itemType) {
        case "input": {
          const input = item as DialogInput;
          const onchange = input.onChangeText;
          input.onChangeText = (text: string) => {
            input.value = text;
            this.requestUpdate();
            onchange?.(text);
          };
          break;
        }
        case "switch": {
          const sw = item as DialogSwitch;
          const onchange = sw.onValueChange;
          sw.onValueChange = (toggle: boolean) => {
            sw.value = toggle;
            this.requestUpdate();
            onchange?.(toggle);
          };
          break;
        }
      }
    }
    this.dialogData.push(dialog);
    this.requestUpdate();
    return dialog;
  }

  close(props: Dialog): void {
    props.visible = false;
    this.requestUpdate();
  }

  constructor(props: {}) {
    super(props);
    instance = this;
  }
  public componentDidMount(): void {
    super.componentDidMount();
    instance = this;
  }
}
