import React, { LegacyRef } from "react";
import { Platform, SwitchProps, TextInput, TextInputProps, TextProps, TouchableOpacity, View, ViewStyle } from "react-native";
import { Switch, Text } from "react-native";
import Dialog from "react-native-dialog";
import { BaseComponent } from "@core/BaseComponent";
import styles, { themeColor } from "@core/Styles";
import { CustomDialog } from "./CustomDialog";
import { TextLabel } from "./TextLabel";

// export type DialogInput = typeof Dialog.Input;
// export type DialogSwitch = typeof Dialog.Switch;
// export type DialogButton = typeof Dialog.Button;
// export type DialogItem = DialogInput | DialogSwitch | DialogButton;
export type DialogItem = DialogSwitch | DialogInput | DialogButton;
export interface DialogInput extends TextInputProps {
  itemType: "input";
  label?: string;
  wrapperStyle?: ViewStyle;
  textInputRef?: LegacyRef<TextInput>;
}
export interface DialogSwitch extends SwitchProps {
  itemType: "switch";
  label?: string;
}
export interface DialogButton extends TextProps {
  itemType: "button";
  label: string;
  color?: string;
  bold?: boolean;
  disabled?: boolean;
  onPress: () => void;
}
export interface Props {
  visible: boolean;
  children: DialogItem[];
  // children: ReactElement<any, DialogInput | DialogSwitch | DialogButton>[];
  title?: string;
  titleStyle?: ViewStyle;
  description?: string;
  descriptionStyle?: ViewStyle;
  headerStyle?: unknown;
  contentStyle?: unknown;
  footerStyle?: unknown;
}

export class SimpleDialog extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    if (Platform.OS === "web") {
      return (
        <CustomDialog visible={this.props.visible}>
          <View style={[styles.shadow, { backgroundColor: themeColor.background, borderRadius: 15, minWidth: 300, minHeight: 260, paddingTop: 20 }]}>
            {this.props.title ? (
              <View style={(this.props.titleStyle, { alignItems: "center", paddingVertical: 5, padding: 15 })}>
                <TextLabel boldWeight>{this.props.title}</TextLabel>
              </View>
            ) : undefined}
            {this.props.description ? (
              <View style={(this.props.descriptionStyle, { alignItems: "center", paddingVertical: 5, padding: 10 })}>
                <TextLabel fontSize="small">{this.props.description}</TextLabel>
              </View>
            ) : undefined}
            {this.props.children.map((v, i) => {
              if (v.itemType === "input") {
                return (
                  <TextInput
                    style={{
                      backgroundColor: themeColor.textArea,
                      borderWidth: 0,
                      borderColor: "transparent",
                      height: 40,
                      fontSize: 16,
                      borderRadius: 5,
                      paddingHorizontal: 10,
                      marginHorizontal: 15,
                      marginTop: 10,
                      marginBottom: 20,
                    }}
                    key={i}
                    {...v}
                  ></TextInput>
                );
              } else if (v.itemType === "switch") {
                return <Switch key={i} {...v}></Switch>;
              } else if (v.itemType === "button") {
                return (
                  <View style={{ flexGrow: 1, borderTopWidth: 0.5, borderColor: themeColor.border, justifyContent: "center", alignItems: "center" }} key={i}>
                    <TouchableOpacity style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} key={i} onPress={v.onPress}>
                      <Text style={{ fontWeight: "bold", color: themeColor.main }}>{v.label}</Text>
                    </TouchableOpacity>
                  </View>
                );
              } else {
                return undefined;
              }
            })}
          </View>
        </CustomDialog>
      );
    }
    return (
      <Dialog.Container visible={this.props.visible}>
        {this.props.title ? <Dialog.Title style={this.props.titleStyle ?? {}}>{this.props.title}</Dialog.Title> : undefined}
        {this.props.description ? <Dialog.Description style={this.props.descriptionStyle ?? {}}>{this.props.description}</Dialog.Description> : (undefined as any)}
        {this.props.children.map((v, i) => {
          if (v.itemType === "input") {
            return <Dialog.Input key={i} {...v} />;
          } else if (v.itemType === "switch") {
            return <Dialog.Switch key={i} {...v} />;
          } else if (v.itemType === "button") {
            return <Dialog.Button key={i} {...v} />;
          } else {
            return undefined;
          }
        })}
      </Dialog.Container>
    );
  }
}
