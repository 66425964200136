import _ from "lodash";
import { Center, Container, Text } from "native-base";
import { Book, Episode, EpisodeDescription } from "novel-parser";
import React, { createRef } from "react";
import { Image, Platform, View } from "react-native";
import { Tabs } from "react-native-collapsible-tab-view";
import { PanGestureHandler, ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { EdgeInsets, SafeAreaView } from "react-native-safe-area-context";
import Slider from "@react-native-community/slider";
import { defaultUserOption, UserOption } from "@webview/interface/episode";
import { Router } from "~/router";
import { MiniIconTitleButton } from "~/screens/Component/MiniIconTitleButton";
import { TextLabel } from "~/screens/Component/TextLabel";
import { Firebase } from "~/utilities/firebase/FirebaseUtility";
import { UserBookstatusData, UserBookstatusDocument } from "~/utilities/firebase/firestore/documents/user-bookstatus-documents";
import { UserData, UserDocument } from "~/utilities/firebase/firestore/documents/user-documents";
import { GridView } from "../Component/GridView";
import { ScrollableSnapView } from "../Component/ScrollableSnapView";
import { SettingsColorPicker } from "../Component/SettingsColorPicker";
import { SettingsSelector } from "../Component/SettingsSelector";
import { SettingsSlider } from "../Component/SettingsSlider";
import { fullColors } from "../Settings/SettingsScene";
import { BaseComponent } from "./BaseComponent";
import { tabBar } from "./CollapsableTabView";
import { EpisodeReaderWebViewComponent } from "./EpisodeReaderWebViewComponent";
import { addFirestoreStateListener, firestoreState } from "./firestoreState";
import { ParserManager } from "./ParserManager";
import { sharedState } from "./SharedState";
import { Spacer } from "./Spacer";
import { state } from "./State";
import styles, { constants, themeColor } from "./Styles";
import { WebViewComponent } from "./WebViewComponent";

export interface Props {
  type: "ilks" | "other";
  id: string;
  no: number;
  title: string;
  url: string;
  onShowMenu?: (isShow: boolean) => void;
  onShowSettings: () => void;
  onFirstPage: (isFirstPage: boolean) => void;
  onLastPage: (isLastPage: boolean) => void;
  onLoadedEpisode?: (prevEpisode?: EpisodeDescription, currentEpisode?: EpisodeDescription, nextEpisode?: EpisodeDescription) => void;
}

export class EpisodeReader extends BaseComponent<Props> {
  episodeData?: Episode;
  book?: Book;
  @state selectedText?: string;
  @state userBookstatus?: UserBookstatusData;
  @state isShowMenu = false;
  @state lastPageRelativePosition = 0;
  @state pageCount?: number;
  // TODO: ちょっと更新範囲系を考えた方がいいかも
  nowPage?: number;
  pageRef = createRef<Page>();
  @state type: "ilks" | "other";
  @state id: string;
  @state no: number;
  @state title: string;
  @state url: string;
  @sharedState currentReaderData?: CurrentReaderData;
  @sharedState avoidFooterBottomSheetSnap?: boolean;
  @firestoreState userDocument?: UserDocument;
  @firestoreState userBookstatusDocument?: UserBookstatusDocument;

  @state prevEpisodeDescription?: EpisodeDescription;
  @state currentEpisodeDescription?: EpisodeDescription;
  @state nextEpisodeDescription?: EpisodeDescription;

  @state menuDirection?: "column" | "row" | "row-reverse";
  viewingOption: UserOption = defaultUserOption;

  webViewRef = createRef<EpisodeReaderWebViewComponent>();
  spaceRef = createRef<Space>();
  viewRef = createRef<View>();
  episodes?: EpisodeDescription[];

  resolver?: () => void;

  constructor(props: Props) {
    super(props);
    // this.state = { ...props };
    this.type = props.type;
    this.id = props.id;
    this.no = props.no;
    this.title = props.title;
    this.url = props.url;
  }

  protected initializeOnMounted = true;
  protected async onUserChanged(): Promise<void> {
    await addFirestoreStateListener(this, UserDocument, { uid: this.user.uid }, this.userDocument);
    this.viewingOption = this.userDocument?.data?.option ?? defaultUserOption;
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();

    if (!this.url) return;

    await this.loadPage(this.url, this.type);
  }

  async didPropChange(): Promise<void> {
    if (!this.props.url) return;
    this.type = this.props.type;
    this.id = this.props.id;
    this.no = this.props.no;
    this.title = this.props.title;
    this.url = this.props.url;

    this.loadEpisode({
      url: null,
      title: {
        novel: null,
        chapter: null,
        sub: null,
      },
      text: {
        prev: [],
        main: [],
        after: [],
      },
      author: {
        name: null,
        link: null,
      },
      link: {
        prev: null,
        index: null,
        next: null,
      },
    });

    await this.loadPage(this.url, this.type);
  }

  protected onLoadEnd(): boolean {
    if (this.episodeData) {
      this.loadEpisode(this.episodeData);
    }
    return true;
  }

  protected onMessage(_: WebViewComponent, { method, data }: { str: string; method: string; data?: string }): boolean {
    switch (method) {
      case "onSelectStart":
        if (data && data.length > 0) {
          this.selectedText = data;
        } else {
          this.selectedText = undefined;
        }
        return false;
      case "onSelectChange":
        if (data && data.length > 0) {
          this.selectedText = data;
        } else {
          this.selectedText = undefined;
        }
        return false;
      case "onSelectEnd":
        if (data && data.length > 0) {
          this.selectedText = data;
        } else {
          this.selectedText = undefined;
        }
        return false;
      case "onRenderEnd":
        this.resolver?.();
        this.resolver = undefined;
        return false;
      case "onClick":
        this.showMenu(!this.isShowMenu);
        return false;
      case "onChangePage": {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsed: any = JSON.parse(data ?? "");
        if (!parsed || typeof parsed !== "object") {
          return false;
        }
        const { line, lineCount, page, pageCount, text, textType } = parsed;
        this.nowPage = page;
        this.requestUpdate();
        this.pageCount = pageCount;
        if (line === undefined || lineCount === undefined || page === undefined || pageCount === undefined || textType === undefined || text === undefined) {
          return false;
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.savePage(line, text);
        return false;
      }
      case "showFirstPage":
        this.props.onFirstPage(Number(data) === 1);

        return false;
      case "showLastPage":
        // this.lastPageRelativePosition = Number(data);
        this.spaceRef.current?.setX(1 - Number(data));
        this.spaceRef.current?.setY(1 - Number(data));
        this.props.onLastPage(Number(data) === 1);

        if (Number(data) > 0) {
          if (this.userBookstatusDocument && this.userBookstatusDocument.data.episodes[this.id]) {
            this.userBookstatusDocument.data.episodes[this.id].alreadyRead = true;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.userBookstatusDocument.save();
          }
        }
        return false;
    }

    return true;
  }

  protected async loadPage(url: string, type: "ilks" | "other", isShowMenu = true): Promise<void> {
    this.episodeData = await ParserManager.getEpisode(url, type);
    if (!this.book || this.book.url !== this.episodeData.link.index) {
      if (this.episodeData.link.index) {
        this.book = await ParserManager.getIndex(this.episodeData.link.index, type);
      } else {
        this.book = undefined;
      }
    }

    if (!this.episodeData || !this.episodeData.link?.index) {
      return;
    }

    const user = (await Firebase.login()) ?? undefined;
    if (!user || !this.id) {
      return;
    }

    this.episodes = this.book?.chapters.flatMap((v) => v.episodes);

    this.type = type;
    // TODO: もうちょっとID周り考えた方がいい気がするね
    // というか idとnoの制度は無くした方がいいかも
    const index = this.book?.chapters.flatMap((v) => v.episodes).findIndex((v) => v.link === url);
    this.id = ((index ?? 0) + 1).toString();
    this.no = (index ?? 0) + 1;
    this.title = this.episodeData.title.sub ?? this.title;
    this.url = url;

    const indexUrl = encodeURIComponent(this.episodeData.link?.index);

    await addFirestoreStateListener(this, UserBookstatusDocument, { uid: user.uid, bookId: indexUrl }, this.userBookstatusDocument);
    if (this.userBookstatusDocument) {
      this.userBookstatusDocument.data.lastReadEpisode = this.id;
      await this.userBookstatusDocument.save();
      this.userBookstatus = this.userBookstatusDocument.data;
    }

    this.setEpisodeDescription();

    this.loadEpisode(this.episodeData, isShowMenu);

    const promise = new Promise<void>((resolve) => {
      this.resolver?.();
      this.resolver = resolve;
    });

    await promise;
  }

  setEpisodeDescription(): void {
    if (!this.episodes) return;

    const index = this.no - 1;

    this.currentEpisodeDescription = this.episodes[index];

    const prevEpisodeNo = index - 1;
    const prevEpisodeDescription = prevEpisodeNo >= 0 ? this.episodes[prevEpisodeNo] : undefined;
    this.prevEpisodeDescription = prevEpisodeDescription;

    const nextEpisodeNo = index + 1;
    const nextEpisodeDescription = nextEpisodeNo < this.episodes.length ? this.episodes[nextEpisodeNo] : undefined;
    this.nextEpisodeDescription = nextEpisodeDescription;

    this.props.onLoadedEpisode?.(prevEpisodeDescription, this.episodes[index], nextEpisodeDescription);
  }

  async showPrev(isShowMenu = true): Promise<void> {
    const data = this.episodeData;
    const book = this.book;

    if (data?.link.prev) {
      await this.loadPage(data.link.prev, this.type, isShowMenu);
    } else if (data && book && this.prevEpisodeDescription) {
      await this.loadPage(this.prevEpisodeDescription.link, this.type, isShowMenu);
    }
  }

  async showNext(isShowMenu = true): Promise<void> {
    const data = this.episodeData;
    const book = this.book;

    if (data?.link.next) {
      await this.loadPage(data.link.next, this.type, isShowMenu);
    } else if (data && book && this.nextEpisodeDescription) {
      await this.loadPage(this.nextEpisodeDescription.link, this.type, isShowMenu);
    }
  }

  protected async savePage(line: number, text: string): Promise<void> {
    if (!this.episodeData || !this.episodeData.link?.index) {
      return;
    }

    this.currentReaderData = {
      bookTitle: this.episodeData.title.novel,
      authorName: this.episodeData.author.name,
      bookId: this.url,
      chapterTitle: this.episodeData.title.chapter,
      episodeId: this.id,
      subTitle: this.episodeData.title.sub,
      line: line,
      text: text,
    };

    const user = (await Firebase.login()) ?? undefined;
    if (!user) {
      return;
    }

    const userDocument = new UserDocument({ uid: user.uid });
    await userDocument.load();

    userDocument.data.lastEpisode = {
      bookId: this.book?.url ?? this.url,
      episodeId: this.id,
      title: this.episodeData.title.novel ?? "",
      chapterTitle: this.episodeData.title.chapter ?? "",
      subTitle: this.episodeData.title.sub ?? "",
      authorName: this.episodeData.author.name ?? "",
      line: line,
      text: text,
    };

    await userDocument.save();
    if (this.userBookstatusDocument) {
      this.userBookstatusDocument.data.episodes[this.id] = {
        alreadyRead: this.userBookstatusDocument?.data?.episodes[this.id]?.alreadyRead ?? false,
        line: line,
        text: text,
        updatedAt: Firebase.serverTimestamp,
      };
      await this.userBookstatusDocument.save(true);
    }
  }

  protected loadEpisode(params: Episode, isShowMenu = true): void {
    let userOption = this.userDocument?.data?.option;
    if (!userOption) {
      userOption = defaultUserOption;
      if (this.userDocument?.data) {
        this.userDocument.data.option = userOption;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.userDocument.save();
      }
    }

    this.webViewRef.current?.setEpisode(params, this.id, userOption, this.userBookstatus);
    this.showMenu(isShowMenu);
  }

  protected showMenu(isShow = true): void {
    this.isShowMenu = isShow;
    this.webViewRef.current?.showMenu(isShow);
    this.props.onShowMenu?.(isShow);

    if (isShow) {
      this.settingsSnapViewRef?.current?.snapTo(0);
    }
  }

  protected previewOption(): void {
    this.webViewRef.current?.setOption(this.id, this.viewingOption, this.userBookstatus);
    this.requestUpdate();
  }

  protected saveOption(): void {
    let userOption = this.userDocument?.data?.option;

    if (!userOption) {
      userOption = defaultUserOption;
    }

    if (this.userDocument?.data && this.userDocument.data.option !== this.viewingOption) {
      this.userDocument.data.option = this.viewingOption;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.userDocument.save();
      console.log("西武4", this.userDocument.data.option, this.viewingOption);
    }
  }

  componentDidUpdate(): void {
    super.componentDidUpdate();
    console.log("コンポーネントアップデート");

    if (this.userDocument?.data && !_.isEqual(this.viewingOption, this.userDocument.data.option)) {
      if (this.userDocument.data.option) {
        this.viewingOption = this.userDocument.data.option;
        this.previewOption();
      }
    }

    const option = this.viewingOption;
    if (option) {
      if (option.scrollType === "scroll") {
        if (option.textDirection === "vertical") {
          this.menuDirection = "row-reverse";
        } else {
          this.menuDirection = "column";
        }
      } else {
        if (option.scrollType === "pagingVertical") {
          if (option.textDirection === "vertical") {
            this.menuDirection = "column";
          } else {
            this.menuDirection = "column";
          }
        } else {
          if (option.textDirection === "vertical") {
            this.menuDirection = "row-reverse";
          } else {
            this.menuDirection = "row";
          }
        }
      }
    } else {
      this.menuDirection = "row-reverse";
    }
  }

  panGestureRef = createRef<PanGestureHandler>();
  lastPageScrollViewRef = createRef<ScrollView>();

  panGesturing = false;

  insets: EdgeInsets | null = null;
  userDocumentDataPrev?: UserData;

  settingsSnapViewRef = createRef<ScrollableSnapView>();
  collapsableTabViewRef = createRef<any>();

  protected renderComponent(isPropChanging: boolean): JSX.Element {
    return (
      <Container style={{ maxWidth: "100%", width: "100%", height: "100%", alignItems: "stretch", justifyContent: "center", backgroundColor: "#FEECDC" }}>
        {isPropChanging ? <Center style={{ position: "absolute", zIndex: -1, width: "100%", height: "100%" }}>{this.renderLoading()}</Center> : null}
        <Container style={{ maxWidth: "100%", width: "100%", height: "100%", alignItems: "stretch", justifyContent: "center", opacity: isPropChanging ? 0 : 1 }}>
          <EpisodeReaderWebViewComponent
            ref={this.webViewRef}
            name="episode"
            onLoadEnd={() => this.onLoadEnd()}
            onMessage={this.onMessage.bind(this)}
          ></EpisodeReaderWebViewComponent>
          <View pointerEvents="box-none" style={{ position: "absolute", width: "100%", height: "100%" }}>
            <ScrollableSnapView
              ref={this.settingsSnapViewRef}
              shrinkedContentsHeight={64}
              footerItemHeight={0}
              footerItem={<View></View>}
              onSnap={() => {
                this.saveOption();
              }}
            >
              <Tabs.Container TabBarComponent={tabBar}>
                <Tabs.Tab name="フォント設定">
                  <Tabs.ScrollView style={{ height: "100%" }}>
                    <View style={{ paddingBottom: "100%" }}>
                      <GridView>
                        <SettingsSlider
                          title="フォントサイズ"
                          minValue={5}
                          maxValue={80}
                          unit="px"
                          description="小説本文で使用する文字の大きさを指定します。"
                          value={this.viewingOption.fontSize}
                          onChange={(value) => {
                            this.viewingOption.fontSize = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsColorPicker
                          title="文字色"
                          description="小説画面の文字色を設定します。"
                          value={this.viewingOption.fontColor}
                          onChange={(color) => {
                            this.viewingOption.fontColor = String(color);
                            this.previewOption();
                          }}
                        >
                          {/* {["#000000", "#FFFFFF", "#451722", "#FFD700", "#DC143C"]} */}
                          {fullColors}
                        </SettingsColorPicker>
                        <SettingsColorPicker
                          title="背景色"
                          description="小説画面の背景色を設定します。"
                          value={this.viewingOption.backgroundColor}
                          onChange={(color) => {
                            this.viewingOption.backgroundColor = String(color);
                            this.previewOption();
                          }}
                        >
                          {/* {["#FFFFFFFF", "#000000FF", "#FFF8DCFF", "#FEECDC"]} */}
                          {fullColors}
                        </SettingsColorPicker>
                        <SettingsColorPicker
                          title="ルビの色"
                          description="小説画面のルビ（読みがな）色を設定します。"
                          value={this.viewingOption.rubyColor}
                          onChange={(color) => {
                            this.viewingOption.rubyColor = String(color);
                            this.previewOption();
                          }}
                        >
                          {["#FFFFFFFF", "#000000FF", "#FFFF00FF", "#633112"]}
                        </SettingsColorPicker>
                      </GridView>
                    </View>
                  </Tabs.ScrollView>
                </Tabs.Tab>
                <Tabs.Tab name="レイアウト設定">
                  <Tabs.ScrollView style={{ height: "100%" }}>
                    <View style={{ paddingBottom: "100%" }}>
                      <GridView>
                        <SettingsSlider
                          title="文字間隔"
                          minValue={0}
                          maxValue={5}
                          unit="px"
                          description="小説本文の文字と文字のスペースを広げたり狭めたりできます。"
                          value={this.viewingOption.letterSpacing}
                          onChange={(value) => {
                            this.viewingOption.letterSpacing = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsSlider
                          title="行間"
                          minValue={0}
                          maxValue={20}
                          unit="px"
                          description="小説本文の行と行のスペースを広げたり狭めたりできます。"
                          value={this.viewingOption.lineSpacing}
                          onChange={(value) => {
                            this.viewingOption.lineSpacing = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsSlider
                          title="マージン天"
                          minValue={0}
                          maxValue={200}
                          unit="px"
                          description="小説本文の上側(天)の余白を広げたり狭めたりできます。"
                          value={this.viewingOption.marginTop}
                          onChange={(value) => {
                            this.viewingOption.marginTop = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsSlider
                          title="マージン地"
                          minValue={0}
                          maxValue={200}
                          unit="px"
                          description="小説本文の下側(地)の余白を広げたり狭めたりできます。"
                          value={this.viewingOption.marginBottom}
                          onChange={(value) => {
                            this.viewingOption.marginBottom = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsSlider
                          title="マージン左"
                          minValue={0}
                          maxValue={200}
                          unit="px"
                          description="小説本文の左側の余白を広げたり狭めたりできます。"
                          value={this.viewingOption.marginLeft}
                          onChange={(value) => {
                            this.viewingOption.marginLeft = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                        <SettingsSlider
                          title="マージン右"
                          minValue={0}
                          maxValue={200}
                          unit="px"
                          description="小説本文の右側の余白を広げたり狭めたりできます。"
                          value={this.viewingOption.marginRight}
                          onChange={(value) => {
                            this.viewingOption.marginRight = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                      </GridView>
                    </View>
                  </Tabs.ScrollView>
                </Tabs.Tab>
                <Tabs.Tab name="動作設定">
                  <Tabs.ScrollView style={{ height: "100%" }}>
                    <View style={{ paddingBottom: "100%" }}>
                      <GridView>
                        <SettingsSelector
                          fullWidthNeeded
                          title="テキストの方向"
                          description="小説本文のテキストの方向を縦書きまたは横書きに変更できます。"
                          value={this.viewingOption.textDirection}
                          onChange={(index) => {
                            this.viewingOption.textDirection = index === "vertical" ? "vertical" : "horizontal";
                            this.previewOption();
                          }}
                        >
                          {[
                            { id: "vertical", label: "縦書き" },
                            { id: "horizontal", label: "横書き" },
                          ]}
                        </SettingsSelector>
                        <SettingsSelector
                          title="スクロールタイプ"
                          description="小説画面でのページめくりの方法を選択できます。\nページングにすると、1画面が1ページ扱いになり、左右スライドでページめくりができます。"
                          value={this.viewingOption.scrollType}
                          onChange={(id) => {
                            this.viewingOption.scrollType = id === "scroll" ? "scroll" : id === "pagingVertical" ? "pagingVertical" : "pagingHorizontal";
                            this.previewOption();
                          }}
                        >
                          {[
                            { id: "pagingVertical", label: "縦ページング" },
                            { id: "pagingHorizontal", label: "横ページング" },
                            { id: "scroll", label: "通常スクロール" },
                          ]}
                        </SettingsSelector>
                        <SettingsSlider
                          title="改ページとして扱う連続改行の行数"
                          minValue={0}
                          maxValue={10}
                          unit="行"
                          description="スクロールタイプをページングに設定している場合、改行が連続した時に改ページとして扱います。\n 0の時は改ページを行いません。"
                          value={this.viewingOption.newPageLine}
                          onChange={(value) => {
                            this.viewingOption.newPageLine = value;
                            this.previewOption();
                          }}
                        ></SettingsSlider>
                      </GridView>
                    </View>
                  </Tabs.ScrollView>
                </Tabs.Tab>
              </Tabs.Container>
            </ScrollableSnapView>
          </View>
          <View
            pointerEvents={this.isShowMenu && Platform.OS !== "web" ? "auto" : "none"}
            style={{ width: "100%", height: "100%", position: "absolute", opacity: this.isShowMenu ? 1 : 0 }}
          >
            <TouchableOpacity
              onPress={() => {
                this.showMenu(false);
              }}
              style={[
                {
                  width: "100%",
                  height: "100%",
                },
              ]}
            ></TouchableOpacity>
          </View>

          <View
            pointerEvents={this.isShowMenu ? "auto" : "none"}
            style={[
              styles.colorShadow,
              {
                width: "100%",

                zIndex: 100,
                top: 0,
                position: "absolute",
                backgroundColor: themeColor.background,
                opacity: this.isShowMenu ? 1 : 0,
              },
            ]}
          >
            <SafeAreaView style={{ width: "100%", height: "100%" }} edges={["top", "left", "right"]}>
              <View
                style={{ justifyContent: "center", maxWidth: constants.CONTENT_MAX_WIDTH, alignSelf: "center", height: 100, alignItems: "center", marginBottom: 0, padding: 10 }}
              >
                <Text style={[styles.smallFont, styles.textBold, { color: themeColor.subText }]}>{this.currentReaderData?.bookTitle}</Text>
                <Text style={[styles.mediumFont, styles.textBold, { color: themeColor.mainText }]}>{this.title}</Text>
                <View style={{ marginHorizontal: 5, marginTop: 10, flexGrow: 1, flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity
                    style={{ height: 40 }}
                    onPress={async () => {
                      await this.showNext();
                    }}
                  >
                    <Text style={[styles.smallFont, styles.text, { color: themeColor.subText }]}>次の話</Text>
                  </TouchableOpacity>
                  <Center style={{ flexGrow: 1, justifyContent: "center" }}>
                    <Text style={[styles.smallFont, styles.text, { color: themeColor.subText }]}>
                      {this.currentReaderData?.episodeId ?? 0} / {this.episodes?.length ?? 0}
                    </Text>
                  </Center>
                  <TouchableOpacity
                    style={{ height: 40 }}
                    onPress={async () => {
                      await this.showPrev();
                    }}
                  >
                    <Text style={[styles.smallFont, styles.text, { color: themeColor.subText }]}>前の話</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </SafeAreaView>
          </View>
          <View
            pointerEvents={this.isShowMenu ? "auto" : "none"}
            style={[styles.colorShadow, { width: "100%", bottom: 0, position: "absolute", backgroundColor: themeColor.background, opacity: this.isShowMenu ? 1 : 0 }]}
          >
            <SafeAreaView style={{ width: "100%", height: "100%" }} edges={["bottom", "left", "right"]}>
              <View
                style={{
                  marginBottom: 0,
                  maxWidth: constants.CONTENT_MAX_WIDTH,
                  alignSelf: "center",
                  height: 140,
                  paddingVertical: 15,
                  paddingHorizontal: 10,
                  alignItems: "flex-start",
                }}
              >
                <View style={{ marginHorizontal: 5, marginTop: 0, height: 60, flexGrow: 1, flexDirection: "row", alignItems: "flex-start" }}>
                  <View style={{ flexShrink: 1, flexDirection: "column", alignItems: "flex-end" }}>
                    <Page ref={this.pageRef} now={this.nowPage} max={this.pageCount}></Page>
                    <TextLabel boldWeight fontSize="verySmall" color={themeColor.subText}>
                      ページ
                    </TextLabel>
                  </View>
                  <Spacer style={{ flexGrow: 1 }}></Spacer>
                  <MiniIconTitleButton
                    title="Web表示"
                    icon={require("@assets/images/icon_safari.png")}
                    onPress={() => Router.appNavigate("Settings", true)}
                    height={45}
                  ></MiniIconTitleButton>
                  <MiniIconTitleButton
                    title="共有"
                    icon={require("@assets/images/share.png")}
                    onPress={() => Router.appNavigate("Settings", true)}
                    height={45}
                  ></MiniIconTitleButton>
                  <MiniIconTitleButton
                    title="設定"
                    icon={require("@assets/images/icon_setting.png")}
                    onPress={() => {
                      this.settingsSnapViewRef?.current?.snapTo(2);
                      this.showMenu(false);
                      // Router.appNavigate("Settings", true);
                    }}
                    height={45}
                  ></MiniIconTitleButton>
                  <MiniIconTitleButton
                    title="しおり"
                    icon={require("@assets/images/shiori.png")}
                    onPress={() => Router.appNavigate("Settings", true)}
                    height={45}
                  ></MiniIconTitleButton>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 5,
                  }}
                  onResponderReject={() => {
                    return true;
                  }}
                >
                  <TouchableOpacity
                    style={[styles.colorShadowDeep, { width: 40, height: 40, borderRadius: 20, backgroundColor: themeColor.main, justifyContent: "center", alignItems: "center" }]}
                    onPress={() => {
                      //左ページへ
                      if (this.menuDirection === "row-reverse") {
                        this.webViewRef.current?.setRightPage();
                      } else {
                        this.webViewRef.current?.setLeftPage();
                      }
                    }}
                  >
                    <Image style={{ width: 16, height: 16, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/arrow_left_white.png")} alt="＜" />
                  </TouchableOpacity>
                  <Spacer space={5}></Spacer>
                  <View
                    style={{
                      flex: 1,
                      marginLeft: 5,
                      marginRight: 5,
                      alignItems: "stretch",
                      justifyContent: "center",
                    }}
                  >
                    <Slider
                      style={{ height: 40 }}
                      maximumTrackTintColor={themeColor.border}
                      maximumValue={this.pageCount}
                      minimumTrackTintColor={themeColor.main}
                      minimumValue={1}
                      inverted={this.menuDirection === "row-reverse"}
                      step={1}
                      thumbTintColor={themeColor.main}
                      value={(this.nowPage ?? 0) + 1}
                      onResponderStart={() => {
                        this.avoidFooterBottomSheetSnap = true;
                      }}
                      onSlidingStart={() => {
                        this.avoidFooterBottomSheetSnap = true;
                      }}
                      onSlidingComplete={() => {
                        this.avoidFooterBottomSheetSnap = false;
                      }}
                      onValueChange={(value) => {
                        this.nowPage = value - 1;
                        if (this.pageRef.current) {
                          this.pageRef.current.now = this.nowPage;
                        }
                        this.webViewRef.current?.setPage(value - 1);
                      }}
                    />
                  </View>
                  <Spacer space={5}></Spacer>
                  <TouchableOpacity
                    style={[styles.colorShadowDeep, { width: 40, height: 40, borderRadius: 20, backgroundColor: themeColor.main, justifyContent: "center", alignItems: "center" }]}
                    onPress={() => {
                      //左ページへ
                      if (this.menuDirection === "row-reverse") {
                        this.webViewRef.current?.setLeftPage();
                      } else {
                        this.webViewRef.current?.setRightPage();
                      }
                    }}
                  >
                    <Image style={{ width: 16, height: 16, alignSelf: "center" }} resizeMode={"contain"} source={require("@assets/images/arrow_right_white.png")} alt="＞" />
                  </TouchableOpacity>
                </View>
              </View>
            </SafeAreaView>
          </View>
        </Container>
      </Container>
    );
  }
}

class Page extends BaseComponent<{ now?: number; max?: number }> {
  @state now: number;
  @state max: number;
  constructor(props: { now?: number; max?: number }) {
    super(props);
    this.now = props.now ?? 0;
    this.max = props.max ?? 0;
  }

  protected renderComponent(): JSX.Element {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TextLabel fontSize="large" color={themeColor.subText}>
          {((this.now ?? 0) + 1).toString()}
        </TextLabel>
        <TextLabel fontSize="large" color={themeColor.subText}>
          /
        </TextLabel>
        <TextLabel fontSize="large" color={themeColor.subText}>
          {(this.max ?? 1).toString()}
        </TextLabel>
      </View>
    );
  }
  didPropChange(): void {
    this.now = this.props.now ?? 0;
    this.max = this.props.max ?? 0;
  }
}
class Space extends BaseComponent<{ x?: number; y?: number }> {
  @state x: number;
  @state y: number;
  constructor(props: { x?: number; y?: number }) {
    super(props);
    this.x = props.x ?? 0;
    this.y = props.y ?? 0;
  }
  setX(x: number): void {
    this.x = x;
  }
  setY(y: number): void {
    this.y = y;
  }

  protected renderComponent(): JSX.Element {
    return <View pointerEvents="box-none" style={{ width: `${this.x * 100}%`, height: `${this.y * 100}%` }}></View>;
  }
}

export class CurrentReaderData {
  bookTitle: string | null = "";
  authorName: string | null = "";
  bookId: string | null = "";
  chapterTitle: string | null = "";
  subTitle: string | null = "";
  episodeId: string | null = "";
  line: number | null = null;
  text: string | null = "";
}

// <CollapsableTabView containerRef={this.collapsableTabViewRef} headerContents={<View style={{ backgroundColor: themeColor.itemBG }}></View>}>
//   {[
//     new CollapsableScrollTab(
//       "新しい拡張",
//       (
//         <GridView>
//           <GridChildView title="検索">
//             <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
//               <View style={{ flexGrow: 1, flex: 1, flexShrink: 1 }}>
//                 <RoundedInput placeholder="URL、サイト名を入力して検索"></RoundedInput>
//               </View>
//               <RoundedButton
//                 width={40}
//                 height={40}
//                 onPress={() => {
//                   /* */
//                 }}
//                 centerImage={require("@assets/images/icon_search_white.png")}
//               ></RoundedButton>
//             </View>
//           </GridChildView>
//           <GridChildView title="手動で追加">
//             <View style={{ flexDirection: "row", alignItems: "center" }}>
//               <BottomTitleButton
//                 title="URLから追加"
//                 buttonColor={themeColor.main}
//                 buttonColorGradation={themeColor.mainGradation}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//               <BottomTitleButton
//                 title="ファイルから追加"
//                 buttonColor={themeColor.gray}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//               <BottomTitleButton
//                 title="テキストを入力"
//                 buttonColor={themeColor.gray}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//             </View>
//           </GridChildView>
//           <GridChildView oneColumn title="人気のサイト拡張">
//             <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//             </View>
//           </GridChildView>
//         </GridView>
//       ),
//       false
//     ),
//     new CollapsableScrollTab(
//       "間",
//       (
//         <GridView>
//           <GridChildView title="検索">
//             <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
//               <View style={{ flexGrow: 1, flex: 1, flexShrink: 1 }}>
//                 <RoundedInput placeholder="URL、サイト名を入力して検索"></RoundedInput>
//               </View>
//               <RoundedButton
//                 width={40}
//                 height={40}
//                 onPress={() => {
//                   /* */
//                 }}
//                 centerImage={require("@assets/images/icon_search_white.png")}
//               ></RoundedButton>
//             </View>
//           </GridChildView>
//           <GridChildView title="手動で追加">
//             <View style={{ flexDirection: "row", alignItems: "center" }}>
//               <BottomTitleButton
//                 title="URLから追加"
//                 buttonColor={themeColor.main}
//                 buttonColorGradation={themeColor.mainGradation}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//               <BottomTitleButton
//                 title="ファイルから追加"
//                 buttonColor={themeColor.gray}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//               <BottomTitleButton
//                 title="テキストを入力"
//                 buttonColor={themeColor.gray}
//                 height={80}
//                 flex={2}
//                 flexGrow={2}
//                 icon={require("@assets/images/icon_search_white.png")}
//                 onPress={() => {}}
//               ></BottomTitleButton>
//             </View>
//           </GridChildView>
//           <GridChildView oneColumn title="人気のサイト拡張">
//             <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//               <SiteExtensionItem></SiteExtensionItem>
//             </View>
//           </GridChildView>
//         </GridView>
//       ),
//       false
//     ),
//   ]}
// </CollapsableTabView>;
