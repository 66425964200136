// import _ from "lodash";
import { BaseComponent } from "./BaseComponent";

const sharedStates: { [key: string]: unknown } = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sharedStateListeners: { [key: string]: any[] } = {};

// export function showSharedStates() {
//   console.log("sharedStates", _.clone(sharedStates));
//   console.log("sharedStateListeners", _.clone(sharedStateListeners));
// }
export function addSharedStateListener<T extends BaseComponent<P>, P>(target: T, key: string): void {
  if (!(key in sharedStateListeners)) {
    sharedStateListeners[key] = [];
  }
  sharedStateListeners[key].push(target);
}
export function removeSharedStateListener<T extends BaseComponent<P>, P>(target: T, key: string): void {
  if (!(key in sharedStateListeners)) {
    return;
  }
  sharedStateListeners[key] = sharedStateListeners[key].filter((v) => v !== target);
  if (countSharedStateListener(key) === 0) {
    delete sharedStates[key];
    delete sharedStateListeners[key];
  }
}
export function updateSharedState(key: string, value: unknown, notify = true): void {
  sharedStates[key] = value;
  if (notify) {
    sharedStateListeners[key]?.forEach((v) => v?.addStateCount());
  }
}

export function getSharedState(key: string): unknown {
  return sharedStates[key];
}

export function countSharedStateListener(key: string): number {
  return sharedStateListeners[key]?.length ?? 0;
}

interface Params {
  key?: string;
  notify?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sharedState<T extends BaseComponent<P>, P>(this: unknown, target: T, propertyKey: string, propertyDescriptor?: PropertyDescriptor): any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sharedState<T extends BaseComponent<P>, P>(key: Params): (this: unknown, target: T, propertyKey: string, propertyDescriptor?: PropertyDescriptor) => any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sharedState<T extends BaseComponent<P>, P>(this: unknown, target: T | Params, propertyKey?: string, propertyDescriptor?: PropertyDescriptor): any {
  if (propertyKey === undefined) {
    //const key = (target as unknown) as T;
    const params = (target as unknown) as Params;
    const key = params.key;
    const notify = params.notify;
    return function (this: unknown, target: T, propertyKey: string, propertyDescriptor?: PropertyDescriptor) {
      return sharedStateImpl(target, propertyKey, propertyDescriptor, key, notify);
    };
  } else {
    return sharedStateImpl(target as T, propertyKey, propertyDescriptor);
  }
}
export function sharedStateImpl<T extends BaseComponent<P>, P>(
  this: unknown,
  target: T,
  propertyKey: string,
  propertyDescriptor?: PropertyDescriptor,
  sharedKey?: string,
  notify = true
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const initializer = (propertyDescriptor as { initializer?: () => {} } | undefined)?.initializer;
  const key = sharedKey ?? propertyKey;
  if (initializer && typeof initializer === "function") {
    if (!(key in sharedStates)) {
      const value = initializer();
      sharedStates[key] = value;
    }
  }

  const t = (target as unknown) as { _sharedStateKeys_default: { [key: string]: boolean } };
  if (!t._sharedStateKeys_default) {
    t._sharedStateKeys_default = {};
  }
  if (notify) {
    t._sharedStateKeys_default[key] = true;
  }

  const descriptor: PropertyDescriptor = {
    set: function (this: T, value: unknown) {
      updateSharedState(key, value);
    },
    get: function (this: T) {
      return sharedStates[key];
    },
    enumerable: true,
  };
  return descriptor;
}
