import React from "react";
import { Image, View } from "react-native";
import Animated from "react-native-reanimated";
import { BaseComponent, BasePropsJSXChildren } from "@core/BaseComponent";
import styles from "@core/Styles";

export interface Props extends BasePropsJSXChildren {
  imageUrl?: string;
  small?: boolean;
}

export class BookshelfBookIcon extends BaseComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderComponent(): JSX.Element {
    return (
      <View
        style={[
          styles.colorShadow,
          {
            width: this.props.small ? 74 : 118,
            height: this.props.small ? 106 : 169,
            marginRight: 5,
            borderRadius: 5,
          },
        ]}
      >
        {this.props.imageUrl ? (
          <Animated.Image
            source={{ uri: this.props.imageUrl }}
            style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, opacity: 1, overflow: "hidden", borderRadius: 5 }}
          ></Animated.Image>
        ) : (
          <Image
            style={{ width: this.props.small ? 74 : 118, height: this.props.small ? 106 : 169, alignSelf: "center" }}
            resizeMode={"contain"}
            source={require("@assets/images/book_fantasy_thumb.png")}
          ></Image>
        )}
      </View>
    );
  }
}
